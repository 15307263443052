import '../FlowsPage';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllFlowConfigurationMaps, selectFlowComponentInfo, selectFlowLiveDataByComponentInfo, selectFlowRuntimeInformation } from '../../../../redux/entities/service/Flow';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../../helpers';



const FlowInstance_StatusContent_AuxCell = ({ flowId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [returnDrainStateComponentInfo]
    = useSelector((state) => selectFlowComponentInfo(state, flowId, "ReturnDrainValve", ["state"]), _.isEqual)
  const [supplyDrainStateComponentInfo]
    = useSelector((state) => selectFlowComponentInfo(state, flowId, "SupplyDrainValve", ["state"]), _.isEqual)
  const [auxPumpStateComponentInfo]
    = useSelector((state) => selectFlowComponentInfo(state, flowId, "AuxROPump", ["relay"]), _.isEqual)
  const [systemPressureComponentInfo]
    = useSelector((state) => selectFlowComponentInfo(state, flowId, "LiquidPressureTransducer", ["input"]), _.isEqual)


  //const [flowRateComponentInfo]
  //  = useSelector((state) => selectFlowComponentInfo(state, flowId, "FlowMeter", ["input"]), _.isEqual)

  const returnDrainState = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, returnDrainStateComponentInfo), _.isEqual)
  const supplyDrainState = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, supplyDrainStateComponentInfo), _.isEqual)
  const auxPumpState = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, auxPumpStateComponentInfo), _.isEqual)
  const systemPressure = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, systemPressureComponentInfo), _.isEqual)


  let dataListItems = [
    {
      label: "Pressure", value: systemPressure.value, suffix: "psi", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 70, status: "normal" }
      }
    },
    {
      label: "Return Drain Valve", value: parseBool(returnDrainState.value) ? "Open" : "Closed",
    },
    {
      label: "Supply Drain Valve", value: parseBool(supplyDrainState.value) ? "Open" : "Closed",
    },
    {
      label: "Auxiliary Pump", value: parseBool(auxPumpState.value) ? "On" : "Off",
    },
  ]


  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Auxiliary</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default FlowInstance_StatusContent_AuxCell