import '../FlowsPage.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore'; 
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button'; 
import { AdditionalOptions, Pause, Play, TrendDownArrow, TrendUpArrow } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { selectFlowComponentInfo, selectFlowLiveDataByComponentInfo } from '../../../../redux/entities/service/Flow';


const FlowInstance_StatusContent_DOSensorCell = ({ flowId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  
  const [leftSensorDOComponentInfo, rightSensorDOComponentInfo, activeDOComponentInfo] 
  = useSelector((state) => selectFlowComponentInfo(state, flowId, "LiquidDOSensor", ["input-1", "input-2", "do"]), _.isEqual)
  const leftSensorDO = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, leftSensorDOComponentInfo), _.isEqual)
  const rightSensorDO = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, rightSensorDOComponentInfo), _.isEqual)
  const activeDO = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, activeDOComponentInfo), _.isEqual)
  
  let dataListItems = [
    {label: "Active DO", value: activeDO.value, suffix: "mg/L", visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 10, status: "normal"}
    }},  
    {label: "Left DO", value: leftSensorDO.value, suffix: "mg/L", visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 10, status: "normal"}
    }},  
    {label: "Right DO", value: rightSensorDO.value, suffix: "mg/L", visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 10, status: "normal"}
    }},  
  ]

  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">DO Sensor</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} /> 
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default FlowInstance_StatusContent_DOSensorCell