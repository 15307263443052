import './GrowManagerPage.scss';
import variables from '../../globals.scss';
import _ from 'underscore';
 
import React from 'react'

import { selectGrowBladeZoneInfo, selectGrowBladeZoneUIDs, selectGrowById, selectGrowDisplayName, selectGrowStatus, selectGrowTotalScore, selectGrowUpcomingTaskId} from '../../redux/entities/Grow'
import { selectAllGrowPlans} from '../../redux/entities/GrowPlans'
import { selectAllRecipes } from '../../redux/entities/Recipes';
import { useSelector, useDispatch } from 'react-redux'

import {TabControl, TabControlTab} from '../../components/TabControl.js';
import DropDownInput from '../../components/input/DropDownInput.js'

import { FormatDate } from '../../helpers';

import 'react-circular-progressbar/dist/styles.css';
import { RecipeTimeline } from '../RecipesAndPlans/Recipes/RecipeTimeline';
import CircularGauge from '../../components/CircularGauge';

import RowGroupRow from '../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import GrowStatus_InfoContent from './GrowStatus/GrowStatus_InfoContent';
import GrowStatus_PerformanceContent from './GrowStatus/GrowStatus_PerformanceContent';
import GrowStatus_GrowProgress from './GrowStatus/GrowStatus_GrowProgress.js';
import { MaintainBladeZoneActiveRecipe, selectAllBladeZones, selectBladeZoneDisplayNameByUID, selectBladeZoneDisplayNamesByUIDs } from '../../redux/entities/service/Blade.js';
import { GrowTimeline } from './GrowTimeline';
import TaskHyperlink from '../Tasks/TaskHyperlink';
import TaskPopup from '../Tasks/TaskPopup';
import { MaintainTasksForCategoryAndReferenceId } from '../../redux/entities/Tasks';




const GrowStatusList_Instance = ({growId, isPlaceholder, columnSizes, columnSizesChanged, showOnlyCard}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });



  const growTotalScore = useSelector((state) => selectGrowTotalScore(state, growId), _.isEqual)
  const growDisplayName = useSelector((state) => selectGrowDisplayName(state, growId), _.isEqual)
  const growStatus = useSelector((state) => selectGrowStatus(state, growId), _.isEqual)
  const growNextTaskId = useSelector((state) => selectGrowUpcomingTaskId(state, growId), _.isEqual)
  const growBladeZoneInfo = useSelector((state) => selectGrowBladeZoneInfo(state, growId), _.isEqual)
  const growBladeZoneUIDs = useSelector((state) => selectGrowBladeZoneUIDs(state, growId), _.isEqual)
  const activeBladeZoneDisplayNames = useSelector((state) => selectBladeZoneDisplayNamesByUIDs(state, growBladeZoneUIDs), _.isEqual)

  
  let additionalFunctions = [
    {key: "manage_lights", label: "Manage Lights"},
    {key: "rename", label: "Rename Rack"},
    {key: "move", label: "Move Rack"},
    {key: "change_sides", label: "Change Sides"},
    {key: "light_up", label: "Light Up Rack"},
    {key: "delete", label: "Delete", status: "Critical"},
  ]

  //console.log(growInfo)

  const timelineMinWidth = isDesktop ? 300 : isTablet ? 200 : 150

  
  let timelineComponent = <>
    {!isPlaceholder && 
      <GrowTimeline 
        growId={growId}
        minWidth={timelineMinWidth}
      />}
    {isPlaceholder && <>
      <div style={{textAlign:"center"}}>No Recipe Found</div>
    </>}
  </>

  let secondaryHeaderContent = []
  if (!isMobile) {
    secondaryHeaderContent.push({
      key: "grow_status", content: <>
        <div style={{fontSize:14}}>
          <div>{(!isPlaceholder ? <>{growStatus}</> : <>Unknown Status</>)}</div>
          <div>{(!isPlaceholder ? <>{<TaskHyperlink taskId={growNextTaskId} taskClicked={() => {SetViewingTask(growNextTaskId)}}/>}</> : <>Some Long Task</>)}</div>
        </div>
      </>
    })
    secondaryHeaderContent.push({
      key: "timeline", content: timelineComponent, flex: true, minWidth: timelineMinWidth
    })
  }


  let havePercentage = false
  let percentage = 0
  if (!isPlaceholder) {
    percentage = growTotalScore
  }


  secondaryHeaderContent.push(
    {key: "score", content: <>
      <div style={{width:"40px"}}>
        <CircularGauge value={parseInt(percentage)} text={havePercentage ? percentage : "N/A"} unit={""} valueDisplayFontSize={12}/>
      </div>
    </>}
  )
  

  let extendedHeaderContent = null
  if (isMobile) {
    extendedHeaderContent = {triggersExpandToggle: true, content: <div style={{display: "flex", alignSelf: "stretch", margin:10, flexDirection: "column"}}>
        {timelineComponent}
      </div>
    }
  }


  const [expandedContentPage, SetExpandedContentPage] = React.useState("grow_info")




  let tabSelected = (tab) => {
    SetExpandedContentPage(tab)
  }




  
  const [zoneOptions, SetZoneOptions] = React.useState([])
  const [selectedZoneOption, SetSelectedZoneOption] = React.useState(null)
  React.useLayoutEffect(() => {
    if (isPlaceholder) {
      return
    }
    let updatedZoneOptions = []
    if (growBladeZoneInfo)  {
      for (let zoneInfo of growBladeZoneInfo) {
        //console.log(allBladeZones)
        if (activeBladeZoneDisplayNames[zoneInfo.zone_uid] !== undefined)  {
          updatedZoneOptions.push({
            label: activeBladeZoneDisplayNames[zoneInfo.zone_uid] + (!zoneInfo.active ? " - Completed" : ""),
            value: zoneInfo.zone_uid,
          })
        }
      }
    }

    SetZoneOptions(updatedZoneOptions)

  }, [isPlaceholder, growBladeZoneInfo, activeBladeZoneDisplayNames])


  React.useLayoutEffect(() => {
    if (selectedZoneOption === null && zoneOptions.length > 0)  {
      SetSelectedZoneOption(zoneOptions[0].value)
    }
  }, [zoneOptions, selectedZoneOption])


  const zoneSelectionChanged = (zone) => {
    SetSelectedZoneOption(zone)
  }


  const [viewingTask, SetViewingTask] = React.useState(null)
  const onViewingTask = (taskId) => {
    SetViewingTask(taskId)
  }
  const closeViewingTask = () => {
    SetViewingTask(null)
  }

  return (<>
    <MaintainBladeZoneActiveRecipe bladeZoneUIDs={growBladeZoneUIDs} interval={10000}/>
    <MaintainTasksForCategoryAndReferenceId category={"grow"} referenceId={growId}/>
    <RowGroupRow
      key={isPlaceholder ? 0 : growId}
      title={isPlaceholder ? "Placeholder" : growDisplayName }
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      subtitle={<div className="GrowRacksStatus-VerticalRackGroup-Rack-Sides">{isPlaceholder ? "#G12512312" : "#G" + growId}</div>}
      showStatusIndicator={false}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      statusContainerPadding={!showOnlyCard ? 6 : 0}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={!showOnlyCard}
      customRowPadding={0}
      hasAdditionalFunctions={!showOnlyCard}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      extendedHeaderContent={extendedHeaderContent}
      additionalFunctions={!showOnlyCard ? additionalFunctions : []}
      content={<>
        {!showOnlyCard && <>
          {isPlaceholder && <></>}
          {!isPlaceholder && <>
            <div style={{display:"flex", flexDirection:"column", border:"1px solid #DDE3EB", gap:0, borderRadius: 4, backgroundColor:"#FFF"}}>
              <div style={{padding: "12px 20px 0px 20px"}}>
                {!isMobile && <>
                  <TabControl 
                    onTabSelected={tabSelected}
                    tabLocation={"flex"}
                    tabs={[
                      new TabControlTab("Grow Info", "grow_info", "", expandedContentPage === "grow_info"),
                      new TabControlTab("Performance", "performance", "", expandedContentPage === "performance"),
                      /*new TabControlTab("Metrics", "metrics", "", expandedContentPage === "metrics"),*/
                      new TabControlTab("Grow Progress", "grow_progress", "", expandedContentPage === "grow_progress"),
                      /*new TabControlTab("KPI's", "kpis", "", expandedContentPage === "kpis")*/
                    ]}
                  />
                </>}
                {isMobile && <>
                  <DropDownInput 
                    status="Neutral"
                    value={expandedContentPage}
                    options={[
                      {key: 'grow_info', label: 'Grow Info'},
                      {key: 'performance', label: 'Performance'},
                      /*{key: 'metrics', label: 'Metrics'},*/
                      {key: 'grow_progress', label: 'Grow Progress'},
                      /*{key: 'kpis', label: 'KPI\'s'}*/
                    ]} 
                    optionsKey="key"
                    onSelectionChange={(action) => {
                      SetExpandedContentPage(action)
                    }}/>
                </>}
              </div>

              {expandedContentPage === "grow_info" && <>
                <GrowStatus_InfoContent growId={growId}/> 
              </>}

              {expandedContentPage === "performance" && <>
                <GrowStatus_PerformanceContent 
                  growId={growId} 
                  zoneOptions={zoneOptions}
                  selectedZoneUID={selectedZoneOption}
                  onZoneSelectionChanged={zoneSelectionChanged}/> 
              </>}
                      
              {expandedContentPage === "grow_progress" && <>
                <GrowStatus_GrowProgress 
                  growId={growId}
                  zoneOptions={zoneOptions}
                  selectedZoneUID={selectedZoneOption}
                  onZoneSelectionChanged={zoneSelectionChanged}/> 
              </>}       
            </div>
          
          </>}
        
        </>}

      </>}
    />
    {viewingTask && <>
      <TaskPopup taskId={viewingTask} onCloseTask={closeViewingTask} viewingTaskCallback={onViewingTask}/>
    </>}
</>)
}
GrowStatusList_Instance.defaultProps = {
  isPlaceholder: false,
  showOnlyCard: false,
}
export default GrowStatusList_Instance
