import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import navigationReducer from './Navigation.js'
import appInfoReducer from './AppInfo'
import accountReducer from './entities/Account.js'
import inventoryReducer from './entities/Inventory'
import taskEntitiesReducer from './entities/Tasks.js'
import growEntitiesReducer from './entities/Grow.js'
import growPlansEntitiesReducer from './entities/GrowPlans.js'
import productEntitiesReducer from './entities/Products.js'
import recipesEntitiesReducer from './entities/Recipes.js'
import raftTypesEntitiesReducer from './entities/RaftTypes'
import plantTypesEntitiesReducer from './entities/PlantTypes.js'
import facilitiesReducer from './entities/Facilities'
import controlDevicesReducer from './entities/service/ControlDevice.js'
import BladeEntitiesReducer from './entities/service/Blade.js'
import flowsEntitiesReducer from './entities/service/Flow.js'
import HVACsEntitiesReducer from './entities/service/HVAC.js'
import GerminationStationReducer from './entities/service/GerminationStation.js'
//import logger from 'redux-logger'

export const store = configureStore({
    reducer: {
        navigation: navigationReducer,
        appInfo: appInfoReducer,
        account: accountReducer,
        inventory: inventoryReducer,
        tasks: taskEntitiesReducer,
        recipes: recipesEntitiesReducer,
        grows: growEntitiesReducer, 
        growPlans: growPlansEntitiesReducer, 
        products: productEntitiesReducer,
        raftTypes: raftTypesEntitiesReducer,
        plantTypes: plantTypesEntitiesReducer,

        facilities: facilitiesReducer,
        controlDevice: controlDevicesReducer,
        blade: BladeEntitiesReducer,
        flow: flowsEntitiesReducer,
        HVAC: HVACsEntitiesReducer,
        germinationStation: GerminationStationReducer,
    },
    middleware: getDefaultMiddleware({
      serializableCheck: {
          ignoredPaths: ['grows']
      }
    })/*.concat(logger)*/,
})