import './GrowTimeline.scss';
import React from 'react';

import { FiEdit } from 'react-icons/fi'
import useMeasure from '../../useMeasure';
import { useSelector, useDispatch } from 'react-redux'
import { selectAllGrowPlans } from '../../redux/entities/GrowPlans';
import { selectAllRecipes } from '../../redux/entities/Recipes';
import { selectGrowById } from '../../redux/entities/Grow';
import { FormatDate } from '../../helpers';


const cycleColors = [
    { background: "#E8DECF", foreground: "#916A00" },
    { background: "#E8D0CF", foreground: "#D82C0D" },
    { background: "#CEE8DE", foreground: "#008060" },
]


export const GrowTimeline = ({
    growId,
    minWidth,
}) => {

    const grow = useSelector((state) => selectGrowById(state, growId))
    const allGrowPlans = useSelector(selectAllGrowPlans)
    const allRecipes = useSelector(selectAllRecipes)


    const activeRecipe = allRecipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
    const activeGrowPlan = allGrowPlans.find((gP) => gP.id === grow.grow_plan_id)



    let growStartDate = null
    let growEndDate = null


    if (activeRecipe !== undefined) {
        if (!grow.pending_start && grow.started_on) {
            growStartDate = new Date(grow.started_on).getTime()
            if (grow.completed) {
                growEndDate = new Date(grow.finished_on).getTime()
            } else {
                growEndDate = growStartDate + activeRecipe.expected_duration * 1000
            }
        } else {
            if (grow.scheduled_type === "nad" || grow.scheduled_type === "start_date") {
                growStartDate = new Date().getTime()
                growEndDate = growStartDate + (activeRecipe.expected_duration * 1000)
            } else if (new Date().getTime() + (activeRecipe.expected_duration * 1000) > new Date(grow.scheduled_for).getTime()) {
                growStartDate = new Date().getTime()
                growEndDate = growStartDate + (activeRecipe.expected_duration * 1000)
            } else {
                growEndDate = new Date(grow.scheduled_for).getTime()
                growStartDate = growEndDate - (activeRecipe.expected_duration * 1000)
            }
        }
    } else {
        growStartDate = new Date().getTime()
    }

    let currentProgress = 0
    if (growStartDate && !grow.completed) {
        currentProgress = new Date().getTime() - new Date(growStartDate).getTime()
    }


    const [visualizationRef, { width: visualizationWidth }] = useMeasure()
    const [lastVisualizationWidth, SetLastVisualizationWidth] = React.useState(0);
    const [visualizationTicks, SetVisualizationTicks] = React.useState([]);
    const [recipeDuration, SetRecipeDuration] = React.useState(0);
    const [totalDurationDisplay, SetTotalDurationDisplay] = React.useState("");


    const [timeline, SetTimeline] = React.useState([]);
    const [germinationCycles, SetGerminationCycles] = React.useState([]);
    const [nurseryCycles, SetNurseryCycles] = React.useState([]);
    const [growZoneCycles, SetGrowZoneCycles] = React.useState([]);


    //const currentProgress = 500000000
    React.useEffect(() => {
        if (activeRecipe === undefined) {
            SetTimeline([])
            SetRecipeDuration(0)
            SetVisualizationTicks([])
            SetTotalDurationDisplay("")
            return
        }




        //Produce the timeline
        let timeline = []
        let currentGerminationCycles = []
        let currentNurseryCycles = []
        let currentGrowZoneCycles = []

        let totalDuration = 0
        if (activeRecipe.timeline_items) {
            let recipeTimelineItems = [...activeRecipe.timeline_items].sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
            for (let timelineItem of recipeTimelineItems) {
                if (timelineItem.item) {
                    timeline.push({ ...timelineItem })
                    switch (timelineItem.type) {
                        case "duration_action":
                            if (timelineItem.item.type === "place_into_germination_chamber_duration") {
                                totalDuration += timelineItem.item.duration
                                currentGerminationCycles.push({ ...timelineItem })
                            }
                            break
                        case "germination_cycle":
                            currentGerminationCycles.push({ ...timelineItem })
                            totalDuration += timelineItem.item.iterations * timelineItem.item.duration
                            break
                        case "nursery_cycle":
                            currentNurseryCycles.push({ ...timelineItem })
                            totalDuration += timelineItem.item.iterations * timelineItem.item.duration
                            break

                        case "grow_zone_cycle":
                            currentGrowZoneCycles.push({ ...timelineItem })
                            totalDuration += timelineItem.item.iterations * timelineItem.item.duration
                            break

                        default:
                            break
                    }
                }
            }
        }

        SetRecipeDuration(totalDuration)

        //Cycle selection options and visualization sizing
        let currentCycleContribution = 0;
        for (let timelineItem of timeline) {
            if (timelineItem.type === "duration_action") {
                if (timelineItem.item.type === "place_into_germination_chamber_duration") {
                    let cycle = timelineItem.item
                    let cycleContribution = cycle.duration / totalDuration
                    timelineItem.width = visualizationWidth * cycleContribution
                    timelineItem.x = visualizationWidth * currentCycleContribution
                    timelineItem.startsOn = currentCycleContribution * totalDuration * 1000
                    currentCycleContribution += cycleContribution
                    timelineItem.endsOn = currentCycleContribution * totalDuration * 1000

                }
            } else if (timelineItem.type === "germination_cycle" || timelineItem.type === "nursery_cycle" || timelineItem.type === "grow_zone_cycle") {
                let cycle = timelineItem.item

                let cycleContribution = (cycle.duration * cycle.iterations) / totalDuration
                timelineItem.width = visualizationWidth * cycleContribution
                timelineItem.x = visualizationWidth * currentCycleContribution
                timelineItem.startsOn = currentCycleContribution * totalDuration * 1000
                currentCycleContribution += cycleContribution
                timelineItem.endsOn = currentCycleContribution * totalDuration * 1000

            }
        }
        SetTimeline(timeline)
        SetGerminationCycles(currentGerminationCycles)
        SetNurseryCycles(currentNurseryCycles)
        SetGrowZoneCycles(currentGrowZoneCycles)




        //Calculate visualization
        const desiredNumberOfTicks = 12
        const hourDuration = 60 * 60
        const dayDuration = hourDuration * 24
        const totalDurationInHours = Math.floor(totalDuration / hourDuration)
        const totalDurationInDays = Math.floor(totalDuration / dayDuration)
        const remainingHours = (totalDuration - (totalDurationInDays * dayDuration)) / hourDuration
        let tickDayDelta = Math.ceil(totalDurationInDays / desiredNumberOfTicks)
        let numberOfTicks, startingDayTick, ticksCoveringDuration, currentDay;
        if (tickDayDelta == 0) {
            tickDayDelta = 1
            numberOfTicks = totalDurationInDays
            ticksCoveringDuration = currentDay = startingDayTick = 1
        } else {
            numberOfTicks = Math.floor(totalDurationInDays / tickDayDelta)
            ticksCoveringDuration = (numberOfTicks - 1) * tickDayDelta
            startingDayTick = Math.floor((totalDurationInDays - ticksCoveringDuration) / 2)

            currentDay = startingDayTick
        }

        let ticks = []
        while (startingDayTick < totalDurationInDays) {
            if (startingDayTick !== 0) {
                ticks.push({
                    day: startingDayTick, relativePosition: startingDayTick / totalDurationInDays
                })
            }
            startingDayTick += tickDayDelta
        }
        SetVisualizationTicks(ticks)


        let currentTotalDuration = ""
        if (totalDurationInDays !== 0) {
            currentTotalDuration += totalDurationInDays.toString() + "d"
            if (remainingHours !== 0) {
                currentTotalDuration += " " + remainingHours.toString() + "h"
            }
        } else {
            currentTotalDuration += remainingHours.toString() + "h"
        }

        SetTotalDurationDisplay(currentTotalDuration)


    }, [activeRecipe, visualizationWidth])



    const timelineItemsWithDuration = timeline.filter((t) => (t.type === "duration_action" && t.item.type === "place_into_germination_chamber_duration") || t.type === "germination_cycle" || t.type === "nursery_cycle" || t.type === "grow_zone_cycle")
    let currentDay = Math.ceil(currentProgress / (1000 * 60 * 60 * 24))
    if (currentDay === 0)   {
        currentDay = 1
    }
    let numberOfDays = Math.round(recipeDuration / (60 * 60 * 24))
    return (
        <div className="GrowTimelineBar-Container" style={{minWidth: minWidth}}>
            <div className="GrowTimelineBar">
                <div className="GrowTimelineBar-Content" ref={visualizationRef}>
                    <div className="GrowTimelineBar-Bars">
                        {timelineItemsWithDuration.map((timelineItem, index) => {
     
                            let cycle = timelineItem.item

                            let margin = visualizationWidth * 0.005
                            let usedMargin = 0

                            let barStyles = {}
                            if (index > 0)    {
                                barStyles.marginLeft = margin
                                usedMargin += margin
                            }
                            if (index < timelineItemsWithDuration.length - 1)    {
                                barStyles.marginRight = margin
                                usedMargin += margin
                            }
                            let barProps = {
                                style: barStyles
                            }


                            let barProgress = 0
                            if (currentProgress !== undefined) {
                                if (currentProgress >= timelineItem.startsOn && currentProgress <= timelineItem.endsOn) {
                                    barProgress = 1 - ((timelineItem.endsOn - currentProgress) / (timelineItem.endsOn - timelineItem.startsOn))
                                } else if (currentProgress > timelineItem.endsOn) {
                                    barProgress = 1
                                }
                            }

                            if (barProgress > 0)    {

                            }

                            let barContentWidth = timelineItem.width - usedMargin

                            let progressBarWidth = barContentWidth * barProgress



                            return (
                                <div className={"GrowTimelineBar-Bar" + (barProgress > 0 ? " GrowTimelineBar-Bar-Active" : "")}
                                    style={{
                                        width: timelineItem.width,
                                        left: timelineItem.x,
                                    }} key={timelineItem.id}>
                                    <div className="GrowTimelineBar-Bar-Content" {...barProps}>

                                        <div
                                            className="GrowTimelineBar-Bar-ProgressBar"
                                            style={{
                                                width: progressBarWidth,
                                            }}></div>
                                        {progressBarWidth > 0 &&
                                            <div
                                                className="GrowTimelineBar-Bar-ProgressBarLine"
                                                style={{
                                                    left: progressBarWidth,
                                                    backgroundColor: "#000"
                                                }}></div>
                                        }


                                    </div>
                                </div>
                            )
                        })}
                    </div>


                </div>

            </div>
            <div className="GrowTimelineBar-Dates-Container">
                <div className="GrowTimelineBar-Dates-StartDate Text-Alt12">
                    {growStartDate && <>
                        {FormatDate(new Date(growStartDate), "MM/dd/yy")}
                    </>}
                </div>
                <div className="GrowTimelineBar-Dates-Progress">
                    <div className="Text-Alt12">Day:</div>
                    <div className="Text-S12">{currentDay}/{numberOfDays}</div>
                </div>
                <div className="GrowTimelineBar-Dates-EndDate Text-Alt12">
                    {growEndDate && <>
                        {FormatDate(new Date(growEndDate), "MM/dd/yy")}
                    </>}
                </div>
            </div>
        </div>
    )
}

GrowTimeline.defaultProps = {
    minWidth: 200
}