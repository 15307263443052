import '../FlowsPage';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllFlowConfigurationMaps, selectFlowComponentInfo, selectFlowLiveDataByComponentInfo, selectFlowRuntimeInformation } from '../../../../redux/entities/service/Flow';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { parseBool, RoundToNearest } from '../../../../helpers';



const FlowInstance_StatusContent_ROPumpCell = ({ flowId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [ROPumpStateComponentInfo, flowRateComponentInfo]
    = useSelector((state) => selectFlowComponentInfo(state, flowId, "ROLargePump", ["relay", "flow_rate"]), _.isEqual)

  const pumpState = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, ROPumpStateComponentInfo), _.isEqual)
  const flowRate = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, flowRateComponentInfo), _.isEqual)
  let flowRateToLitresPerMinute = 0
  if (flowRate.value && flowRate.value > 0) {
    flowRateToLitresPerMinute = RoundToNearest(flowRate.value * 60 / 1000, 0.01)
  }

  let pumpStatus = "Idle"
  if (parseBool(pumpState.value)) {
    pumpStatus = "Pumping"
  }

  let dataListItems = [
    {
      label: "Flow Rate", value: flowRateToLitresPerMinute, suffix: "L/min", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 5, status: "normal" }
      }
    },
  ]


  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">RO Pump</div>
          <div className="DataStatus-Cell-Header-PrimaryContent-StatusChip">{pumpStatus}</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default FlowInstance_StatusContent_ROPumpCell