import '../GerminationStationInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore'; 
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button'; 
import { AdditionalOptions, Pause, Play, TrendDownArrow, TrendUpArrow } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { setGerminationStationRuntimeProperty, selectGerminationStationComponentInfo, selectGerminationStationLiveDataByComponentInfo, selectGerminationStationRuntimeInformation, selectGerminationStationUIDById  } from '../../../../redux/entities/service/GerminationStation';

 

const GerminationStationInstance_StatusContent_CanopyAirStatusCell = ({ germinationStationId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  const [airControlStatus] = useSelector((state) => selectGerminationStationRuntimeInformation(state, germinationStationId, "air_control_status"))
  const [airTempManagementStatus] = useSelector((state) => selectGerminationStationRuntimeInformation(state, germinationStationId, "air_temp_management_mode"))
  const [airRHManagementStatus] = useSelector((state) => selectGerminationStationRuntimeInformation(state, germinationStationId, "air_rh_management_mode"))

  const isAirPaused = airControlStatus !== "active"
  const germinationStationResumingOn = useSelector((state) => selectGerminationStationRuntimeInformation(state, germinationStationId, "resume_controls_on"), _.isEqual)

  
  const [airTempComponentInfo, airTempSetpointComponentInfo, airRHComponentInfo, airRHSetpointComponentInfo, airVPDComponentInfo, airVPDSetpointComponentInfo, leafTempComponentInfo, airCO2ComponentInfo, dewpointComponentInfo, coolingPIDComponentInfo, heatingPIDComponentInfo, dehumidPIDComponentInfo, humidPIDComponentInfo] 
  = useSelector((state) => selectGerminationStationComponentInfo(state, germinationStationId, "CanopyAir", ["at", "ats", "arh", "arhs", "vpd", "vpds", "lt", "ac", "dp", "tcpid", "thpid", "rhdpid", "rhhpid"]), _.isEqual)
  const airTemp = useSelector((state) => selectGerminationStationLiveDataByComponentInfo(state, germinationStationId, airTempComponentInfo), _.isEqual)
  const airTempSetpoint = useSelector((state) => selectGerminationStationLiveDataByComponentInfo(state, germinationStationId, airTempSetpointComponentInfo), _.isEqual)
  const airRH = useSelector((state) => selectGerminationStationLiveDataByComponentInfo(state, germinationStationId, airRHComponentInfo), _.isEqual)
  const airRHSetpoint = useSelector((state) => selectGerminationStationLiveDataByComponentInfo(state, germinationStationId, airRHSetpointComponentInfo), _.isEqual)
  const dewpoint = useSelector((state) => selectGerminationStationLiveDataByComponentInfo(state, germinationStationId, dewpointComponentInfo), _.isEqual)
  
  let dataListItems = [
    {
      type: "setpoint_bar", label: <>
        <div className="FlexContent-H FlexContent-Center" style={{gap:2}}>
          {airTempManagementStatus === "Cooling" && <TrendDownArrow/>}
          {airTempManagementStatus === "Heating" && <TrendUpArrow/>}
          <div>Temp</div>
        </div>
      </>, value: airTemp.value, setpoint: airTempSetpoint.value, suffix: "°C", range: 5, status: "normal"
    },
    {
      type: "setpoint_bar", label: <>
        <div className="FlexContent-H FlexContent-Center" style={{gap:2}}>
          {airRHManagementStatus === "Dehumidify" && <TrendDownArrow/>}
          {airRHManagementStatus === "Humidify" && <TrendUpArrow/>}
          <div>RH</div>
        </div>
      </>, value: airRH.value, setpoint: airRHSetpoint.value, suffix: "%", range: 10, status: "normal"
    },
    {
      label: "Dewpoint", value: dewpoint.value, suffix: "°C",
    }, 
  ]

  const defaultPauseDuration = 6000
  const [pendingGerminationStationToPause, SetPendingGerminationStationToPause] = React.useState(false)
  const pauseAirControlClicked = () => {
    //First set the default pause duration if the current pause duration is too short
    const minResumeAt = Math.floor((new Date().getTime() / 1000) + defaultPauseDuration)
    if (germinationStationResumingOn === undefined || parseFloat(germinationStationResumingOn) < minResumeAt) {
      dispatch(setGerminationStationRuntimeProperty({
        germinationStationId: germinationStationId,
        properties: { "resume_controls_on": minResumeAt.toString() },
        callback: (success) => {
          if (success)  {
            SetPendingGerminationStationToPause(true)
          }else {

          }
        }
      }))
    }else {
      SetPendingGerminationStationToPause(true)
    }
  }
  React.useLayoutEffect(() => {
    if (pendingGerminationStationToPause)  {
      SetPendingGerminationStationToPause(false)
      dispatch(setGerminationStationRuntimeProperty({
        germinationStationId: germinationStationId,
        properties: { "air_control_status": "pause" },
        callback: (success) => {
          if (success)  {

          }else {

          }
        }
      }))
    }
  }, [pendingGerminationStationToPause])
  const resumeAirControlClicked = () => {
    dispatch(setGerminationStationRuntimeProperty({
      germinationStationId: germinationStationId,
      properties: { "air_control_status": "resume" },
      callback: (success) => {
        
      }
    }))
  }
 

  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Canopy Air</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          {isAirPaused && <>
            <Button status={"Primary-Inverted"} size={"Small"} content={<Play fill={"#2E72D2"} />} onClick={resumeAirControlClicked} disabled={!isAirPaused} />
          </>}
          {!isAirPaused &&
            <Button status={"Small-IconAction"} size={"Small"} content={<Pause />} onClick={pauseAirControlClicked} disabled={isAirPaused} />
          }
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} /> 
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default GerminationStationInstance_StatusContent_CanopyAirStatusCell