import './HarvestTask.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalOptions, GenericLeaf } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { RoundToNearest } from '../../../../helpers';
import { selectAllInventoryItemTypes } from '../../../../redux/entities/Inventory';
import { selectBladeZoneDisplayNameByUID } from '../../../../redux/entities/service/Blade';
import Checkbox from '../../../../components/input/Checkbox';


const HarvestTask_ReviewContainersWalkthroughStep = ({
        activeGrow,
        activeGrowPlan,
        harvestingZoneUID,
        harvestingZoneInfo,
        containers,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const dispatch = useDispatch()

    const activeZoneDisplayName = useSelector((state) => selectBladeZoneDisplayNameByUID(state, harvestingZoneUID))
    const allInventoryItemsTypes = useSelector(selectAllInventoryItemTypes)

    const [isPartialHarvest, SetIsPartialHarvest] = React.useState(false)
  
    const backClicked = () => {
        transitionToPreviousStep(["container_list", null])
    }

    const [isStoringToInventory, SetIsStoringToInventory] = React.useState(false)

    const completeClicked = () => {
        SetIsStoringToInventory(true)

        //dispatch(storeInInventory)
        
        if (isPartialHarvest) {
            transitionToNextStep(["post_partial_harvest_confirm", null])   
        }else {
            transitionToNextStep(["clean_options", null])   
        }
    }


    

    let totalWeight = 0
    for (let container of containers)   {
        totalWeight += (container.weight * container.number_of)
    }

    let weightPerUnit = 0
    if (harvestingZoneInfo.number_of_units !== undefined)   {
        weightPerUnit = RoundToNearest(totalWeight * 1000 / harvestingZoneInfo.number_of_units, 0.1)
    }
    //console.log(activeGrow)
    

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Review {activeZoneDisplayName} Harvest info                    
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className="FlexContent-10" style={{flex:1, overflowY: "hidden"}}>
                    <div className="HarvestTask-ContainerList-Header">
                        <div className="Text-S20">Total Yield:</div>
                        <div className="FlexContent-H-10">
                            <div className="Text-Medium-S20">{totalWeight}kg</div>
                            <div className="Text-Alt20">({weightPerUnit}g/site)</div>                        
                        </div>
                    </div>
                    <div className={isMobile ? "FlexContent-5" : "FlexContent-10"} style={{flex: 1, alignItems: "flex-start", overflowY: "hidden"}}>
                        <div className={isMobile ? "FlexContent-5" : "FlexContent-10"} style={{alignSelf: "stretch", overflowY: "auto"}}>
                            {containers.map((container, containerIndex) => {
                                let contentsName = ""
                                if (container.contents.length > 1)   {
                                    contentsName = "Mix"
                                }else {
                                    let foundInventoryItemType = allInventoryItemsTypes.find((iT) => iT.id === container.contents[0].id)
                                    if (foundInventoryItemType !== undefined) {
                                        contentsName = foundInventoryItemType.name
                                    }
                                }

                                return <>
                                    <div className={(isMobile ? "FlexContent-10" : "FlexContent-H-30") + " HarvestTask-ContainerList-Item"}>
                                        <div className="FlexContent-10" style={{flex: 1, alignSelf: "stretch"}}>
                                            <div className={"FlexContent" + (container.contents === "mix" ? " Linebreak-10" : "")} style={{flex: 1}}>
                                                <div className="FlexContent-H" style={{justifyContent:"space-between"}}>
                                                    <div className="FlexContent-H-20 FlexContent-Center" style={{flex:1}}>
                                                        <div className="Text-S24">x{container.number_of}</div>
                                                        <div className="FlexContent" style={{gap:2}}>
                                                            <div className="Text-Medium-S18">Container {containerIndex + 1}</div>
                                                            <div className="Text-AltI14">{contentsName}</div>
                                                        </div>
                                                    </div>
                                                    <div className="FlexContent-H-20 FlexContent-Center">
                                                        <div className="Text-Medium-S20">{container.weight}kg</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            })}
                        </div>
                    </div>
                    
                </div>
                <div className="FlexContent-H-5 FlexContent-Center"><Checkbox style="check" state={isPartialHarvest} onChange={(state) => {SetIsPartialHarvest(state)}} label ={<>Mark as partial harvest</>}/></div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={"Flex100"}/>                
                <Button status="Primary" content={"Confirm & Complete"} onClick={completeClicked} size={"Flex100"} disabled={isStoringToInventory}/>                
            </div>
        </div>
    )
    
}


export default HarvestTask_ReviewContainersWalkthroughStep