import './../ManageFlowWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { createNewBladeGroup, selectAllBlades } from '../../../../redux/entities/service/Blade';
import { useDispatch, useSelector } from 'react-redux';


import { nanoid, customAlphabet  } from 'nanoid'
import LoadingBar from '../../../../components/LoadingBar';
import { selectAllFacilities } from '../../../../redux/entities/Facilities';
import { selectAllFlows } from '../../../../redux/entities/service/Flow';
import { FlowConnectionPortSide, FlowConnectionPortSideNarrow, FlowPortsSelection, ScanFlow } from '../../../../assets/icons/FlowIcons';
import { useMeasure, useMeasureWithRef } from '../../../../helpers';
import DropDownInput from '../../../../components/input/DropDownInput';
import Switch from '../../../../components/Switch';

  

const ManagePortsWalkthroughStep = ({ports, numberOfPorts, facilityId, transitionToPreviousStep, transitionToNextStep, FlowPortsUpdatedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const Flows = useSelector(selectAllFlows)

    
    const facilityInfo = useSelector(state => state.facilities.facilities.find((f) => f.id === facilityId))
    const allVerticalRackGroups = useSelector(selectAllBlades)

    const [verticalRackGroups, SetVerticalRackGroups] = React.useState([])
    React.useEffect(() => {
        SetVerticalRackGroups(allVerticalRackGroups.filter((vRG) => facilityInfo !== undefined && facilityInfo.services.find((s) => s.id === vRG.service_id) !== undefined))
    }, [facilityId, allVerticalRackGroups])


    const [selectedPort, SetSelectedPort] = React.useState(0)
    const [selectedPortInformation, SetSelectedPortInformation] = React.useState(null)
    React.useEffect(() => {
        let portInformation = ports[selectedPort.toString()] ?? null
        if (portInformation)    {
            SetIsPortActive(portInformation.active ? true : false)
            SetIsRackGroupSelected(portInformation.rack_group_uid ? true : false)
            SetIsRackSelected(portInformation.rack_uid ? true : false)
            SetIsZoneSelected(portInformation.zone_uid ? true : false)
        }
        SetSelectedPortInformation(portInformation)
    }, [selectedPort, ports])


    const [isPortActive, SetIsPortActive] = React.useState(false)
    const [isRackGroupSelected, SetIsRackGroupSelected] = React.useState(false)
    const [isRackSelected, SetIsRackSelected] = React.useState(false)
    const [isZoneSelected, SetIsZoneSelected] = React.useState(false)

    const [rackGroupOptions, SetRackGroupOptions] = React.useState([]);
    const [rackOptions, SetRackOptions] = React.useState([]);
    const [zoneOptions, SetZoneOptions] = React.useState([])

    
    
  
    let calculateNumberOfAvailableZones = (verticalRackGroup, verticalRack) => {
        let numberOfZones = 0
        let numberOfUsableZones = 0


        if (verticalRackGroup.zone_map.nursery_zones && verticalRackGroup.zone_map.nursery_zones.length > 0)    {
            verticalRackGroup.zone_map.nursery_zones.map((sZ) => {
                if (sZ.grow_rack_uid === verticalRack.uid)  {
                    numberOfZones += 1
                    if (Object.values(ports).find((p) => p.zone_uid === sZ.uid) === undefined) {
                        numberOfUsableZones += 1
                    }
                }                        
            })
        }
        if (verticalRackGroup.zone_map.standard_grow_out_zones && verticalRackGroup.zone_map.standard_grow_out_zones.length > 0)    {
            verticalRackGroup.zone_map.standard_grow_out_zones.map((sZ) => {
                if (sZ.grow_rack_uid === verticalRack.uid)  {
                    numberOfZones += 1
                    if (Object.values(ports).find((p) => p.zone_uid === sZ.uid) === undefined) {
                        numberOfUsableZones += 1
                    }
                }                        
            })
        }

        return [numberOfZones, numberOfUsableZones]
    }

    React.useEffect(() => {
        //Check location to see if we are in services, if so we need to limit the options to not include all farms
        let currentRackGroupOptions = []
        for (let verticalRackGroup of verticalRackGroups)  {
            let newOption = {
                value: verticalRackGroup.uid,
                label: verticalRackGroup.display_name
            }

            let numberOfUsableRacks = 0
            let numberOfRacks = 0

            for (let verticalRack of verticalRackGroup.rack_map.racks)  {
                numberOfRacks += 1
                let [numberOfZones, numberOfUsableZones] = calculateNumberOfAvailableZones(verticalRackGroup, verticalRack)

                if (numberOfUsableZones > 0)    {
                    numberOfUsableRacks += 1
                }
            }


            //

            if (numberOfRacks === 0)    {
                newOption.disabled = true
                newOption.info = "No Racks"
            }else if (numberOfUsableRacks === 0) {
                newOption.disabled = true
                newOption.info = "All zones in use"
            }

            currentRackGroupOptions.push(newOption)
        }
        SetRackGroupOptions(currentRackGroupOptions)

        if (isRackGroupSelected)    {
            let selectedVerticalRackGroup = verticalRackGroups.find((vRG) => vRG.uid === selectedPortInformation.rack_group_uid)
            let currentRackOptions = []
            for (let verticalRack of selectedVerticalRackGroup.rack_map.racks)  {
                let newOption = {
                    value: verticalRack.uid,
                    label: verticalRack.display_name
                }
                
                let [numberOfZones, numberOfUsableZones] = calculateNumberOfAvailableZones(selectedVerticalRackGroup, verticalRack)

                if (numberOfZones === 0)    {
                    newOption.disabled = true
                    newOption.info = "No Zones"
                }else if (numberOfUsableZones === 0)    {
                    newOption.disabled = true
                    newOption.info = "Zones in use"
                }
                currentRackOptions.push(newOption)
            }
            SetRackOptions(currentRackOptions)

            if (isRackSelected) {

                let selectedRack = selectedVerticalRackGroup.rack_map.racks.find((vR) => vR.uid === selectedPortInformation.rack_uid)
                let currentZoneOptions = []
                if (selectedVerticalRackGroup.zone_map.nursery_zones !== undefined) {
                    for (let nurseryZone of selectedVerticalRackGroup.zone_map.nursery_zones)   {

                    }
                }
                if (selectedVerticalRackGroup.zone_map.standard_grow_out_zones !== undefined) {
                    for (let standardZone of selectedVerticalRackGroup.zone_map.standard_grow_out_zones.filter((sZ) => sZ.grow_rack_uid === selectedRack.uid))   {
                        currentZoneOptions.push({
                            value: standardZone.uid,
                            label: standardZone.display_name
                        })
                    }
                }

                if (currentZoneOptions.length === 1 && !isZoneSelected)    {
                    FlowPortsUpdatedCallback({ports: {...ports, [selectedPort]: {...selectedPortInformation, zone_uid: currentZoneOptions[0].value, linked: true}}})
                }
                
                SetZoneOptions(currentZoneOptions)
                //Check if more than one zone exists

            }
        }
    }, [selectedPortInformation, facilityInfo, verticalRackGroups, isRackGroupSelected, isRackSelected, isZoneSelected])

    const portActiveStateChanged = (state) =>   {
        if (state)  {
            FlowPortsUpdatedCallback({ports: {...ports, [selectedPort]: {...selectedPortInformation, active: true, rack_group_uid: null, rack_uid: null, zone_uid: null, linked: false}}})
        }else {
            FlowPortsUpdatedCallback({ports: {...ports, [selectedPort]: {...selectedPortInformation, active: false, rack_group_uid: null, rack_uid: null, zone_uid: null, linked: false}}})
        }
    }
    const selectedRackGroupChanged = (value) => {
        FlowPortsUpdatedCallback({ports: {...ports, [selectedPort]: {...selectedPortInformation, rack_group_uid: value, rack_uid: null, zone_uid: null, linked: false}}})
    }
    const selectedRackChanged = (value) => {
        FlowPortsUpdatedCallback({ports: {...ports, [selectedPort]: {...selectedPortInformation, rack_uid: value, zone_uid: null, linked: false}}})
    }
    const selectedZoneChanged = (value) => {
        FlowPortsUpdatedCallback({ports: {...ports, [selectedPort]: {...selectedPortInformation, zone_uid: value, linked: true}}})
    }
  




    const backClicked = () =>   {
        transitionToPreviousStep(["name_Flow", null])
    }

    const nextClicked = () =>   {
        transitionToNextStep(["manage_reservoirs", null])
    }


    const tableKeyColumnBind = React.useRef(null)
    const [{ width: tableKeyColumnWidth }] = useMeasureWithRef(tableKeyColumnBind)


    const rackGroupSelectionProps = {style:{}}
    const rackGroupSelectionContentProps = {style:{whiteSpace: "nowrap"}}
    if (!isPortActive)  {
        rackGroupSelectionContentProps.style.opacity = 0.4
    }

    const rackSelectionProps = {style:{}}
    const rackSelectionContentProps = {style:{whiteSpace: "nowrap", width: tableKeyColumnWidth}}
    if (!isPortActive || !isRackGroupSelected)   {
        rackSelectionContentProps.style.opacity = 0.4
    }
    const zoneSelectionProps = {style:{}}
    const zoneSelectionContentProps = {style:{whiteSpace: "nowrap", width: tableKeyColumnWidth}}
    if (!isPortActive || !isRackSelected)   {
        zoneSelectionContentProps.style.opacity = 0.4
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Manage Ports</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                 {isDesktop && 
                    <div className={"Walkthrough-Card-Content"}>
                        <div className="FlexContent-H-30 FlexContent-Center">
                            <div className="FlexContent-HFill">
                                <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                    <div className="Button-Large-Icon"><FlowConnectionPortSideNarrow width={isShortDisplay ? 50 : 75}/></div>
                                </>}/>
                            </div>
                            <div className="FlexContent FlexContent-HFlex">
                                <div className="FlexContent-H FlexContent-HFlex" style={{gap:80}}>
                                    <div className="FlexContent-H-20 FlexContent-HFlex">
                                        <div className="ManageFlowWalkthrough_Popup-ManagePorts-Ports">
                                            {Object.entries(ports).map(([pI, p]) => {
                                                let portIndex = parseInt(pI)
                                                let portClicked = () => { 
                                                    SetSelectedPort(portIndex)
                                                }
                                                if (portIndex < numberOfPorts / 2) {
                                                    return (
                                                        <div 
                                                            key={pI} 
                                                            className="ManageFlowWalkthrough_Popup-ManagePorts-Port" 
                                                            onClick={portClicked}>
                                                            
                                                            <div>{portIndex + 1}</div>
                                                            <FlowPortsSelection selected={selectedPort === portIndex} completed={p.zone_uid !== null}/>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                        <div className="ManageFlowWalkthrough_Popup-ManagePorts-Ports">
                                            {Object.entries(ports).map(([pI, p]) => {
                                                let portIndex = parseInt(pI)
                                                let portClicked = () => {
                                                    SetSelectedPort(portIndex)
                                                }
                                                if (portIndex >= numberOfPorts / 2) {
                                                    return (
                                                        <div 
                                                            key={pI} 
                                                            className="ManageFlowWalkthrough_Popup-ManagePorts-Port" 
                                                            onClick={portClicked}>
                                                            
                                                            <div>{portIndex + 1}</div>
                                                            <FlowPortsSelection selected={selectedPort === portIndex} completed={p.zone_uid !== null}/>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="FlexContent-Center">
                                        <div className="FlexContent-30 FlexContent-HFill">
                                            <div className="FlexContent-H-30 FlexContent-HFlex">
                                                <div className="FlexContent FlexContent-HFlex" style={{alignSelf:"center"}}>Port {selectedPort + 1}</div>
                                                <Switch offLabel={"Deactivate"} onLabel={"Activate"} state={selectedPortInformation ? selectedPortInformation.active : false} onSwitch={portActiveStateChanged}/>
                                            </div>
                                            
                                            <div className="FlexContent-10 FlexContent-HFill">
                                                <div className="FlexContent-H-20" {...rackGroupSelectionProps}>
                                                    <div className="FlexContent-Center" ref={tableKeyColumnBind} {...rackGroupSelectionContentProps}>Rack Group</div>
                                                    <div className="FlexContent FlexContent-HFlex">
                                                        <DropDownInput 
                                                            placeholder={"Select Group"} 
                                                            options={rackGroupOptions} 
                                                            value={isRackGroupSelected ? selectedPortInformation.rack_group_uid : null} 
                                                            onSelectionChange={selectedRackGroupChanged} 
                                                            disabled={!isPortActive}
                                                            flex={true}
                                                            displayWidth={"10ch"}/>
                                                    </div>
                                                </div>
                                                <div className="FlexContent-H-20" {...rackSelectionProps}>
                                                    <div className="FlexContent-Center" {...rackSelectionContentProps}>Rack</div>
                                                    <div className="FlexContent FlexContent-HFlex">
                                                        <DropDownInput 
                                                            placeholder={"Select Rack"} 
                                                            options={rackOptions} 
                                                            value={isRackSelected ? selectedPortInformation.rack_uid : null}
                                                            onSelectionChange={selectedRackChanged} 
                                                            disabled={!isPortActive || !isRackGroupSelected} 
                                                            flex={true}
                                                            displayWidth={"15ch"}/> 
                                                    </div>
                                                </div>
                                                <div className="FlexContent-H-20" {...zoneSelectionProps}>
                                                    <div className="FlexContent-Center" {...zoneSelectionContentProps}>Zone</div>
                                                    <div className="FlexContent FlexContent-HFlex">
                                                        <DropDownInput 
                                                            placeholder={"Select Zone"} 
                                                            options={zoneOptions} 
                                                            value={isZoneSelected ? selectedPortInformation.zone_uid : null}
                                                            onSelectionChange={selectedZoneChanged} 
                                                            disabled={!isPortActive || !isRackSelected} 
                                                            flex={true}
                                                            displayWidth={"15ch"}/>                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>        
                }
                
                {!isDesktop && 
                    <div className={"Walkthrough-Card-Content"}>
                            <div className="FlexContent-H-20 FlexContent-Center">
                                <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                    <div className="Button-Large-Icon"><FlowConnectionPortSideNarrow/></div>
                                </>}/>

                                <div className="FlexContent-H-20 FlexContent-Center">
                                    <div className="ManageFlowWalkthrough_Popup-ManagePorts-Ports">
                                        {Object.entries(ports).map(([pI, p]) => {
                                            let portIndex = parseInt(pI)
                                            let portClicked = () => {
                                                SetSelectedPort(portIndex)
                                            }
                                            if (portIndex < numberOfPorts / 2) {
                                                return (
                                                    <div 
                                                        key={pI} 
                                                        className="ManageFlowWalkthrough_Popup-ManagePorts-Port" 
                                                        onClick={portClicked}>
                                                        
                                                        <div>{portIndex + 1}</div>
                                                        <FlowPortsSelection selected={selectedPort === portIndex} completed={p.zone_uid !== null}/>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className="ManageFlowWalkthrough_Popup-ManagePorts-Ports">
                                        {Object.entries(ports).map(([pI, p]) => {
                                            let portIndex = parseInt(pI)
                                            let portClicked = () => {
                                                SetSelectedPort(portIndex)
                                            }
                                            if (portIndex >= numberOfPorts / 2) {
                                                return (
                                                    <div 
                                                        key={pI} 
                                                        className="ManageFlowWalkthrough_Popup-ManagePorts-Port" 
                                                        onClick={portClicked}>
                                                        
                                                        <div>{portIndex + 1}</div>
                                                        <FlowPortsSelection selected={selectedPort === portIndex} completed={p.zone_uid !== null}/>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        <div className="FlexContent-HFill">
                               
                        <div className="FlexContent-5 FlexContent-Center">
                            <div className="FlexContent-30 FlexContent-Center">
                                <div className="FlexContent-H-30 FlexContent-HFlex">
                                    <div className="FlexContent FlexContent-HFlex" style={{alignSelf:"center"}}>Port {selectedPort + 1}</div>
                                    <Switch offLabel={"Deactivate"} onLabel={"Activate"} state={selectedPortInformation ? selectedPortInformation.active : false} onSwitch={portActiveStateChanged}/>
                                </div>
                                <div className="FlexContent-5">
                                    <div className="FlexContent-H-20" {...rackGroupSelectionProps}>
                                        <div className="FlexContent-Center" ref={tableKeyColumnBind} {...rackGroupSelectionContentProps}>Rack Group</div>
                                        <div className="FlexContent FlexContent-HFlex">
                                            <DropDownInput 
                                                placeholder={"Select Group"} 
                                                options={rackGroupOptions} 
                                                value={isRackGroupSelected ? selectedPortInformation.rack_group_uid : null} 
                                                onSelectionChange={selectedRackGroupChanged} 
                                                disabled={!isPortActive}
                                                flex={true}
                                                displayWidth={"15ch"}/>                                        
                                        </div>
                                    </div>
                                    <div className="FlexContent-H-20" {...rackSelectionProps}>
                                        <div className="FlexContent-Center" {...rackSelectionContentProps}>Rack</div>
                                        <div className="FlexContent FlexContent-HFlex">
                                            <DropDownInput 
                                                placeholder={"Select Rack"} 
                                                options={rackOptions} 
                                                value={isRackSelected ? selectedPortInformation.rack_uid : null}
                                                onSelectionChange={selectedRackChanged} 
                                                disabled={!isPortActive || !isRackGroupSelected} 
                                                flex={true}
                                                displayWidth={"15ch"}/> 
                                        </div>
                                    </div>
                                    <div className="FlexContent-H-20" {...zoneSelectionProps}>
                                        <div className="FlexContent-Center" {...zoneSelectionContentProps}>Zone</div>
                                        <div className="FlexContent FlexContent-HFlex">
                                            <DropDownInput 
                                                placeholder={"Select Zone"} 
                                                options={zoneOptions} 
                                                value={isZoneSelected ? selectedPortInformation.zone_uid : null}
                                                onSelectionChange={selectedZoneChanged} 
                                                disabled={!isPortActive || !isRackSelected} 
                                                flex={true}
                                                displayWidth={"15ch"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                            
                        </div>
                    </div>  
                }

            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status={"Neutral"} content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default ManagePortsWalkthroughStep