import './TasksPage.scss';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import _ from 'underscore';
import { selectTaskById } from '../../redux/entities/Tasks';

const TaskHyperlink = ({taskId, taskClicked}) => {
  const activeTask = useSelector((state) => selectTaskById(state, taskId), _.isEqual)

  if (activeTask !== undefined) {
    const onTaskClicked = (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (taskClicked !== undefined) {
        taskClicked()
      }
    }

    return (<>
      <div className="Task-Hyperlink" onClick={onTaskClicked}>
        {activeTask.name}
      </div>
    </>)
  }else {
    return (<>
      <div className="Task-Hyperlink-NoLink">
        Unknown Task
      </div>
    </>)
  }
} 

export default TaskHyperlink