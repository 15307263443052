import './GrowZonesPage.scss'; 
import React, { useContext } from 'react'

import _ from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { getBladeAnalyticsData, getBladeByUID, getBladeConfigurationMap, getBladeZoneConfigurationMap, initializeComponentForAnalyticsData, MaintainBladeAnalyticsData, selectAllBladeConfigurationMaps, selectAllBlades, selectAllBladeZoneComponentsByUID, selectAllBladeZoneConfigurationMaps, selectBladeAnalyticsDataForBladeIds, selectBladeConfigurationIdByUIDs, selectBladeIdsForBladeZoneUID, selectBladeUIDsForBladeZoneUID, selectBladeZoneByUID, selectBladeZoneConfigurationIdByUID, selectBladeZoneDisplayNameByUID, selectBladeZoneIdByUID, selectBladeZoneTypeByUID } from '../../redux/entities/service/Blade';
import { selectAllChartAxisTypes, selectAllDataRecordingTimePeriodTypes } from '../../redux/AppInfo';
import { AxisTickStrategies, ColorHEX, ColorRGBA, EmptyFill, emptyLine, emptyTick, FontSettings, lightningChart, SolidFill, SolidLine, Themes, UIElementBuilders } from '@lightningchart/lcjs';
import { makeFlatTheme } from '@lightningchart/lcjs-themes'
import { FormatDate, FormatTime, RoundToNearest, RoundedDateToNearestLastDay, RoundedDateToNearestLastHour, RoundedDateToNearestLastMinute, RoundedDateToNearestMinute, RoundedDateToNearestNextDay, RoundedDateToNearestNextHour, RoundedDateToNearestNextMinute, binaryClosestIdx, remapRange, useAnimationFrame, useRenderingTrace } from '../../helpers';
import Switch from '../../components/Switch';
import { Close, CollapseContent, ExpandContent, ExpandContentAlt } from '../../assets/icons/Icons';
import { useMediaQuery } from 'react-responsive';
import Button from '../../components/Button';
import useMeasure from '../../useMeasure';
import { createLightningChart } from '../../LC';



const mainChartTheme = makeFlatTheme({
    isDark: false,
    fontFamily: 'Segoe UI, -apple-system, Verdana, Helvetica',
    backgroundColor: ColorRGBA(255, 255, 255, 0),
    textColor: ColorHEX('#191C22FF'),
    dataColors: [ColorHEX('#ffff5b'), ColorHEX('#ffcd5b'), ColorHEX('#ff9b5b')],
    axisColor: ColorHEX('#858585FF'),
    gridLineColor: ColorHEX('#303030ff'),
    uiBackgroundColor: ColorRGBA(255, 255, 255, 0),
    uiBorderColor: ColorRGBA(255, 255, 255, 0),
    dashboardSplitterColor: ColorRGBA(255, 255, 255, 0),
})


const GrowZone_DataAnalytics = ({ facilityId, zoneUID, dataActive }) => {
    const isTouchDevice = window.matchMedia("(pointer: coarse)").matches
    const isLargeDesktop = useMediaQuery({ minWidth: 1200 });
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    /* React useEffects */
    const dispatch = useDispatch()


    /* Config */
    const [mainChartAxisHeight, SetMainChartAxisHeight] = React.useState(25)
    const [overviewChartAxisHeight, SetOverviewChartAxisHeight] = React.useState(20)



    /* Data */
    

    const haveAppInfo = useSelector((state) => state.appInfo.haveAppInfo)
    const dataRecordingTimePeriodTypes = useSelector(selectAllDataRecordingTimePeriodTypes)
    const chartAxisTypes = useSelector(selectAllChartAxisTypes)

    const bladeZoneDisplayName = useSelector((state) => selectBladeZoneDisplayNameByUID(state, zoneUID), _.isEqual)
    const bladeZoneId = useSelector((state) => selectBladeZoneIdByUID(state, zoneUID), _.isEqual)
    const bladeZoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
    const bladeZoneConfiguratonId = useSelector((state) => selectBladeZoneConfigurationIdByUID(state, zoneUID), _.isEqual)
    const bladeConfigurationMaps = useSelector(selectAllBladeConfigurationMaps)
    const bladeZoneConfigurationMaps = useSelector(selectAllBladeZoneConfigurationMaps)

    const bladeZoneComponents = useSelector((state) => selectAllBladeZoneComponentsByUID(state, zoneUID), _.isEqual)

    const activeBladeUIDs = useSelector((state) => selectBladeUIDsForBladeZoneUID(state, zoneUID), _.isEqual)
    const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID, "uid"), _.isEqual)
    const bladeConfigurationIds = useSelector((state) => selectBladeConfigurationIdByUIDs(state, Object.values(activeBladeUIDs)), _.isEqual)
    const bladeAnalyticsData = useSelector((state) => selectBladeAnalyticsDataForBladeIds(state, Object.values(bladeIds)), _.isEqual)


    /* Data Load */
    const loadingZoneConfigurationMapsStatus = useSelector((state) => state.blade.loadingZoneConfigurationMaps)
    const [zoneConfigurationMap, SetZoneConfigurationMap] = React.useState(undefined)
    React.useEffect(() => {
        let zoneConfigurationMap = bladeZoneConfigurationMaps.find((cM) => cM.id === bladeZoneConfiguratonId)
        if (zoneConfigurationMap === undefined) {
            if (loadingZoneConfigurationMapsStatus !== "pending")   {
                dispatch(getBladeZoneConfigurationMap({ maps: { [bladeZoneConfiguratonId]: { "component_map": null, "analytics_categories": null } } }))
            }
        }
        SetZoneConfigurationMap(zoneConfigurationMap)
    }, [bladeZoneConfiguratonId, bladeZoneConfigurationMaps, loadingZoneConfigurationMapsStatus])


    const loadingConfigurationMapsStatus = useSelector((state) => state.blade.loadingConfigurationMaps)
    React.useEffect(() => {
        if (loadingConfigurationMapsStatus !== "pending")   {
            let bladeConfigurationMapsToLoad = {}
            for (let bladeUID of Object.values(activeBladeUIDs))   {
                if (bladeConfigurationIds[bladeUID] !== undefined)  {
                    if (bladeConfigurationMapsToLoad[bladeConfigurationIds[bladeUID]] === undefined)    {
                        if (bladeConfigurationMaps.find((cM) => cM.id === bladeConfigurationIds[bladeUID]) === undefined) {
                            bladeConfigurationMapsToLoad[bladeConfigurationIds[bladeUID]] = { "component_map": null }
                        }
                    }
                }
            }
            if (Object.entries(bladeConfigurationMapsToLoad).length > 0) {
                dispatch(getBladeConfigurationMap({ maps: bladeConfigurationMapsToLoad }))
            }
        }
    }, [activeBladeUIDs, bladeConfigurationIds, bladeConfigurationMaps, loadingConfigurationMapsStatus])


    


    /* State */

    const [componentToggles, SetComponentToggles] = React.useState({})
    
    /*{
       
        
        
    });*/

    const airComponentToggles = {
        label: "Air", selected: true, expanded: true, color: "rgb(51,160,44)", dataTypes: {
            temp: { componentName: "CanopyAirSpace", identifier: "at", label: "Temp", color: "rgb(51,160,44)", yAxis: "temp", selected: true, defaultSelected: true, resolution: 0.1, unit: "°C", width: 90 },
            rh: { componentName: "CanopyAirSpace", identifier: "arh", label: "RH", color: "rgb(135,125,185)", yAxis: "rh", selected: true, defaultSelected: true, resolution: 1, unit: "%", width: 90 },
            vpd: { componentName: "CanopyAirSpace", identifier: "vpd", label: "VPD", color: "rgb(21,120,90)", yAxis: "vpd", selected: true, defaultSelected: true, resolution: 0.1, unit: "kPa", width: 90 },
            cO2: { componentName: "CanopyAirSpace", identifier: "ac", label: "cO2", color: "rgb(150,155,0)", yAxis: "co2", selected: false, defaultSelected: false, resolution: 1, unit: "ppm", width: 90 },
            leafTemp: { componentName: "CanopyAirSpace", identifier: "lt", label: "Leaf Temp", color: "rgb(31,120,84)", yAxis: "temp", selected: false, defaultSelected: false, resolution: 0.5, unit: "°C", width: 90 },
            supply_fan_speed: { componentName: "SupplyFan", identifier: "control", label: "Supply Fan Speed", color: "rgb(255,110,65)", yAxis: "speed", selected: true, defaultSelected: true, resolution: 1, unit: "%", width: 90 },
            bypass_damper: { componentName: "BypassDamper", identifier: "position", label: "Bypass Damper", color: "rgb(255,110,65)", yAxis: "actuator", selected: true, defaultSelected: true, resolution: 1, unit: "%", width: 90 },
            rh_at_temp_sp: { componentName: "CanopyAirSpace", identifier: "acrh", label: "RH At Temp SP", color: "rgb(135,125,185)", yAxis: "rh", selected: false, defaultSelected: false, resolution: 1, unit: "%", width: 90 },
            airCoolPID: { componentName: "CanopyAirSpace", identifier: "tcpid", label: "Cooling Load", color: "rgb(255,110,65)", yAxis: "speed", selected: false, defaultSelected: false, resolution: 1, unit: "%", width: 90 },
            airHeatPID: { componentName: "CanopyAirSpace", identifier: "thpid", label: "Heating Load", color: "rgb(255,110,65)", yAxis: "speed", selected: false, defaultSelected: false, resolution: 1, unit: "%", width: 90 },
            airDehumidPID: { componentName: "CanopyAirSpace", identifier: "rhdpid", label: "Dehumid Load", color: "rgb(255,110,65)", yAxis: "speed", selected: false, defaultSelected: false, resolution: 1, unit: "%", width: 90 },
            airHumidPID: { componentName: "CanopyAirSpace", identifier: "rhhpid", label: "Humid Load", color: "rgb(255,110,65)", yAxis: "speed", selected: false, defaultSelected: false, resolution: 1, unit: "%", width: 90 },
            
        }
    }
    const primaryWaterComponentToggles = {
        label: "Water", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            temp: { componentName: "PrimaryReservoirOWTB", identifier: "t", label: "Temp", color: "rgb(101,120,64)", yAxis: "temp", selected: true, defaultSelected: true, resolution: 0.5, unit: "°C", width: 90 },
            pH: { componentName: "PrimaryWaterReservoir", identifier: "ph", label: "pH", color: "rgb(255,0,86)", yAxis: "ph", selected: true, defaultSelected: true, resolution: 0.1, unit: "", width: 90 },
            EC: { componentName: "PrimaryWaterReservoir", identifier: "ec", label: "EC", color: "rgb(0,0,139)", yAxis: "ec", selected: true, defaultSelected: true, resolution: 1, unit: "S/m", width: 90 },
            DO: { componentName: "PrimaryWaterReservoir", identifier: "do", label: "DO", color: "rgb(158,0,142)", yAxis: "do", selected: false, defaultSelected: false, resolution: 1, unit: "", width: 90 },
            ORP: { componentName: "PrimaryWaterReservoir", identifier: "orp", label: "ORP", color: "rgb(1,0,103)", yAxis: "orp", selected: false, defaultSelected: false, resolution: 1, unit: "mV", width: 90 }
        }
    }
    
    const nurseryWaterComponentToggles = {
        label: "Water", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            a_temp: { componentName: "AuxAReservoirOWTB", identifier: "t", label: "Upper Zone Temp", color: "rgb(101,120,64)", yAxis: "temp", selected: true, defaultSelected: true, resolution: 0.5, unit: "°C", width: 90 },
            b_temp: { componentName: "AuxBReservoirOWTB", identifier: "t", label: "Lower Zone Temp", color: "rgb(101,120,64)", yAxis: "temp", selected: true, defaultSelected: true, resolution: 0.5, unit: "°C", width: 90 },
            a_pH: { componentName: "AuxAWaterReservoir", identifier: "ph", label: "Upper Zone pH", color: "rgb(255,0,86)", yAxis: "ph", selected: true, defaultSelected: true, resolution: 0.1, unit: "", width: 90 },
            b_pH: { componentName: "AuxBWaterReservoir", identifier: "ph", label: "Lower Zone pH", color: "rgb(255,0,86)", yAxis: "ph", selected: true, defaultSelected: true, resolution: 0.1, unit: "", width: 90 },
            a_EC: { componentName: "AuxAWaterReservoir", identifier: "ec", label: "Upper Zone EC", color: "rgb(0,0,139)", yAxis: "ec", selected: true, defaultSelected: true, resolution: 1, unit: "S/m", width: 90 },
            b_EC: { componentName: "AuxBWaterReservoir", identifier: "ec", label: "Lower Zone EC", color: "rgb(0,0,139)", yAxis: "ec", selected: true, defaultSelected: true, resolution: 1, unit: "S/m", width: 90 },
            a_DO: { componentName: "AuxAWaterReservoir", identifier: "do", label: "Upper Zone DO", color: "rgb(158,0,142)", yAxis: "do", selected: false, defaultSelected: false, resolution: 1, unit: "", width: 90 },
            b_DO: { componentName: "AuxBWaterReservoir", identifier: "do", label: "Lower Zone DO", color: "rgb(158,0,142)", yAxis: "do", selected: false, defaultSelected: false, resolution: 1, unit: "", width: 90 },
            a_ORP: { componentName: "AuxAWaterReservoir", identifier: "orp", label: "Upper Zone ORP", color: "rgb(1,0,103)", yAxis: "orp", selected: false, defaultSelected: false, resolution: 1, unit: "mV", width: 90 },
            b_ORP: { componentName: "AuxBWaterReservoir", identifier: "orp", label: "Lower Zone ORP", color: "rgb(1,0,103)", yAxis: "orp", selected: false, defaultSelected: false, resolution: 1, unit: "mV", width: 90 },
        }
    }
    const primaryPumpingSystemComponentToggles = {
        label: "Pumping System", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            pressure: { componentName: "PrimaryLiquidSystemPressureTransducer", identifier: "pressure", label: "Pressure", color: "rgb(0,19,56)", yAxis: "pressure", selected: true, defaultSelected: true, resolution: 1, unit: "psi", width: 90 },
            volume: { componentName: "PrimaryWaterReservoir", identifier: "water_volume", label: "Water Volume", color: "rgb(0,19,56)", yAxis: "litres", selected: true, defaultSelected: true, resolution: 0.1, unit: "L", width: 90 },
            water: { label: "Water (Total)", identifier: "water", color: "rgb(0,19,56)", yAxis: "millilitres", selected: false, defaultSelected: false, resolution: 0.1, unit: "mL", width: 90 },
            nutrients_total: { label: "Nutrients (Total)", identifier: "nutrients_total", color: "rgb(0, 0, 0)", yAxis: "litres", selected: false, defaultSelected: false, resolution: 0.1, unit: "L", width: 90 },
            nutrients: { label: "Nutrient Doses", identifier: "nutrients", color: "rgb(0, 0, 0)", yAxis: "millilitres", selected: false, defaultSelected: false, resolution: 0.1, unit: "mL", width: 90 },
        }
    }
    const nurseryPumpingSystemComponentToggles = {
        label: "Pumping System", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            a_pressure: { componentName: "AuxALiquidSystemPressureTransducer", identifier: "pressure", label: "Top Zone Pressure", color: "rgb(0,19,56)", yAxis: "pressure", selected: true, defaultSelected: true, resolution: 1, unit: "psi", width: 90 },
            a_volume: { componentName: "AuxAWaterReservoir", identifier: "water_volume", label: "Top Zone Water Volume", color: "rgb(0,19,56)", yAxis: "litres", selected: true, defaultSelected: true, resolution: 0.1, unit: "L", width: 90 },
            b_pressure: { componentName: "AuxBLiquidSystemPressureTransducer", identifier: "pressure", label: "Bottom Zone Pressure", color: "rgb(0,19,56)", yAxis: "pressure", selected: true, defaultSelected: true, resolution: 1, unit: "psi", width: 90 },
            b_volume: { componentName: "AuxBWaterReservoir", identifier: "water_volume", label: "Bottom Zone Water Volume", color: "rgb(0,19,56)", yAxis: "litres", selected: true, defaultSelected: true, resolution: 0.1, unit: "L", width: 90 },            
            water: { label: "Water (Total)", identifier: "water", color: "rgb(0,19,56)", yAxis: "millilitres", selected: false, defaultSelected: false, resolution: 0.1, unit: "mL", width: 90 },
            nutrients_total: { label: "Nutrients (Total)", identifier: "nutrients_total", color: "rgb(0, 0, 0)", yAxis: "litres", selected: false, defaultSelected: false, resolution: 0.1, unit: "L", width: 90 },
            nutrients: { label: "Nutrient Doses", identifier: "nutrients", color: "rgb(0, 0, 0)", yAxis: "millilitres", selected: false, defaultSelected: false, resolution: 0.1, unit: "mL", width: 90 },
        }
    }
    const standardLightsComponentToggles = {
        label: "Lights", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            total_intensity: { componentName: "LightingController", identifier: "int-a-all", label: "Total Intensity", color: "rgb(100,100,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s", width: 120 },
            /*red: { componentName: "LightingController", identifier: "red", label: "Red", color: "rgb(255,100,100)", yAxis: "light_intensity", selected: false, defaultSelected: false, resolution: 1, unit: "µmol/m²/s", width: 120 },
            green: { componentName: "LightingController", identifier: "green", label: "Green", color: "rgb(100,255,100)", yAxis: "light_intensity", selected: false, defaultSelected: false, resolution: 1, unit: "µmol/m²/s", width: 120 },
            blue: { componentName: "LightingController", identifier: "blue", label: "Blue", color: "rgb(100,100,255)", yAxis: "light_intensity", selected: false, defaultSelected: false, resolution: 1, unit: "µmol/m²/s", width: 120 },
            farRed: { componentName: "LightingController", identifier: "far_red", label: "Far Red", color: "rgb(255,150,150)", yAxis: "light_intensity", selected: false, defaultSelected: false, resolution: 1, unit: "µmol/m²/s", width: 120 },*/
        }
    }
    const nurseryLightsComponentToggles = {
        label: "Lights", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            total_intensity_1: { componentName: "LightingController", identifier: "int-a-1", label: "Total Intensity (Z1)", color: "rgb(100,100,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s", width: 120 },
            total_intensity_2: { componentName: "LightingController", identifier: "int-a-2", label: "Total Intensity (Z2)", color: "rgb(100,100,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s", width: 120 },
            total_intensity_3: { componentName: "LightingController", identifier: "int-a-3", label: "Total Intensity (Z3)", color: "rgb(100,100,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s", width: 120 },
            total_intensity_4: { componentName: "LightingController", identifier: "int-a-4", label: "Total Intensity (Z4)", color: "rgb(100,100,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s", width: 120 },
            total_intensity_5: { componentName: "LightingController", identifier: "int-a-5", label: "Total Intensity (Z5)", color: "rgb(100,100,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s", width: 120 },
            total_intensity_6: { componentName: "LightingController", identifier: "int-a-6", label: "Total Intensity (Z6)", color: "rgb(100,100,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s", width: 120 },
            total_intensity_7: { componentName: "LightingController", identifier: "int-a-7", label: "Total Intensity (Z7)", color: "rgb(100,100,100)", yAxis: "light_intensity", selected: true, defaultSelected: true, resolution: 1, unit: "µmol/m²/s", width: 120 },
        }
    }
    const condensateComponentToggles = {
        label: "Condensate", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            condensateVolume: { componentName: "CondensateSubsystem", identifier: "current_total_condensate_volume", label: "Total", color: "rgb(255,150,150)", yAxis: "litres", selected: true, defaultSelected: true, resolution: 0.1, unit: "L", width: 90 },
            evapMetric: { componentName: "CondensateSubsystem", identifier: "average_evap_metric", label: "Evap Metric", color: "rgb(213,255,0)", yAxis: "g/m2/s", selected: true, defaultSelected: true, resolution: 0.0001, unit: "g/m2/s", width: 120 },
            evapImperial: { componentName: "CondensateSubsystem", identifier: "average_evap_imperial", label: "Evap Imperial", color: "rgb(70,130,180)", yAxis: "lbs/sqft/h", selected: true, defaultSelected: true, resolution: 0.0001, unit: "lbs/sqft/h", width: 120 },
            pumpState: { componentName: "CondensateSubsystem", identifier: "p_state", label: "Pump State", color: "rgb(70,130,180)", yAxis: "state", selected: true, defaultSelected: true, unit: "", width: 90 },
        }
    }
    const refrigerationComponentToggles = {
        label: "Refrigeration", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            compressor_speed: { componentName: "Compressor", identifier: "control", label: "Compressor Speed", color: "rgb(255,110,65)", yAxis: "speed", selected: true, defaultSelected: true, resolution: 1, unit: "%", width: 90 },
            discharge_temp: { componentName: "ACDischargeOWTB", identifier: "t", label: "Discharge Temp", color: "rgb(255,110,65)", yAxis: "temp", selected: false, defaultSelected: false, resolution: 0.5, unit: "°C", width: 90 },

            condenser_fan_speed: { componentName: "CondenserFan", identifier: "control", label: "Condenser Fan Speed", color: "rgb(255,110,65)", yAxis: "speed", selected: true, defaultSelected: true, resolution: 1, unit: "%", width: 90 },
            common_sc_pressure: { componentName: "CondenserSubcoolPressureTransducer", identifier: "pressure", label: "Com SC Pres.", color: "rgb(164,36,0)", yAxis: "pressure", selected: true, defaultSelected: true, resolution: 1, unit: "psi", width: 90 },
            condenser_subcool: { componentName: "CondenserSubcoolPressureTransducer", identifier: "sc", label: "Subcool", color: "rgb(0,174,126)", yAxis: "temp", selected: false, defaultSelected: false, resolution: 0.5, unit: "°C", width: 90 },
            common_sc_temp: { componentName: "ACCommonHighSideOWTB", identifier: "t", label: "Com SC Temp", color: "rgb(255,177,103)", yAxis: "temp", selected: false, defaultSelected: false, resolution: 0.5, unit: "°C", width: 90 },

            precool_eev_position: { componentName: "PrecoolCoilEEV", identifier: "output_signal", label: "Precool EEV", color: "rgb(0,143,156)", yAxis: "valve", selected: true, defaultSelected: false, resolution: 1, unit: "%", width: 90 },
            precool_prv_position: { componentName: "PrecoolCoilPRV", identifier: "output_signal", label: "Precool PRV", color: "rgb(0,143,156)", yAxis: "valve", selected: false, defaultSelected: false, resolution: 1, unit: "%", width: 90 },
            precool_pressure: { componentName: "AccumulatorSubcoolPressureTransducer", identifier: "pressure", label: "Precool Pressure", color: "rgb(0,71,84)", yAxis: "pressure", selected: true, defaultSelected: true, resolution: 1, unit: "psi", width: 90 },
            precool_coil_leaving_temp: { componentName: "PrecoolCoil", identifier: "l-temp", label: "Precool Temp", color: "rgb(0,143,156)", yAxis: "temp", selected: false, defaultSelected: false, resolution: 0.5, unit: "°C", width: 90 },
            precool_coil_superheat: { componentName: "PrecoolCoil", identifier: "sh", label: "Precool SH", color: "rgb(0,143,156)", yAxis: "temp", selected: false, defaultSelected: false, resolution: 0.5, unit: "°C", width: 90 },

            dehumid_eev_position: { componentName: "DehumidCoilEEV", identifier: "output_signal", label: "Dehumid EEV", color: "rgb(0,143,156)", yAxis: "valve", selected: true, defaultSelected: false, resolution: 1, unit: "%", width: 90 },
            dehumid_coil_leaving_temp: { componentName: "DehumidCoil", identifier: "l-temp", label: "Dehumid Temp", color: "rgb(0,143,156)", yAxis: "temp", selected: false, defaultSelected: false, resolution: 0.5, unit: "°C", width: 90 },
            dehumid_coil_superheat: { componentName: "DehumidCoil", identifier: "sh", label: "Dehumid SH", color: "rgb(0,143,156)", yAxis: "temp", selected: false, defaultSelected: false, resolution: 0.5, unit: "°C", width: 90 },
            suction_pressure: { componentName: "CompressorInletPressureTransducer", identifier: "pressure", label: "Suction Pressure", color: "rgb(14,76,161)", yAxis: "pressure", selected: true, defaultSelected: true, resolution: 1, unit: "psi", width: 90 },
        }
    }
    const rackMotionComponentToggles = {
        label: "Rack Motion", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            distance: { componentName: "MotionDistanceSensor", identifier: "cd", label: "Distance", color: "rgb(0, 0, 0)", yAxis: "mm", selected: true, defaultSelected: true, resolution: 0.1, unit: "mm", width: 90 },
        }
    }
    const auxilliaryComponentToggles = {
        label: "Auxilliary", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            left_door_safety_switch: { componentName: "LeftDoorSafetySwitch", identifier: "state", label: "Left Door Switch", color: "rgb(0, 0, 0)", yAxis: "state", selected: true, defaultSelected: true, unit: "", width: 90 },
            right_door_safety_switch: { componentName: "RightDoorSafetySwitch", identifier: "state", label: "Right Door Switch", color: "rgb(0, 0, 0)", yAxis: "state", selected: true, defaultSelected: true, unit: "", width: 90 },
        },
    }
    const energyComponentToggles = {
        label: "Energy", selected: false, expanded: false, color: "rgb(51,160,44)", dataTypes: {
            power: { label: "Power", identifier: "power", isEnergyData: true, color: "rgb(21,120,90)", yAxis: "kilowatt", selected: true, defaultSelected: true, resolution: 0.5, unit: "kW", width: 90 },
            //cost: {label: "Cost", identifier: "cost", color: "rgb(21,120,90)", yAxis: "cost", selected: false,defaultSelected: true},
            current: { label: "Current", identifier: "cur", color: "rgb(21,120,90)", yAxis: "current", selected: false, defaultSelected: true, width: 90 },
        }
    }

    React.useLayoutEffect(() => {
        let initialComponentToggles = {}

        initialComponentToggles.air = airComponentToggles
        if (bladeZoneType === "nursery")    {
            initialComponentToggles.water = nurseryWaterComponentToggles
            initialComponentToggles.pumping_system = nurseryPumpingSystemComponentToggles
            initialComponentToggles.lights = nurseryLightsComponentToggles
        }else {
            initialComponentToggles.water = primaryWaterComponentToggles
            initialComponentToggles.pumping_system = primaryPumpingSystemComponentToggles
            initialComponentToggles.lights = standardLightsComponentToggles
        }
        initialComponentToggles.condensate = condensateComponentToggles
        initialComponentToggles.refrigeration = refrigerationComponentToggles
        initialComponentToggles.rack_motion = rackMotionComponentToggles
        initialComponentToggles.auxillary = auxilliaryComponentToggles
        initialComponentToggles.energy = energyComponentToggles



        SetComponentToggles(initialComponentToggles)
    }, [bladeZoneType])

    /* Active Blade Specific Component Ids for loading analytics data */
    const [activeBladeComponents, SetActiveBladeComponents] = React.useState({})
    React.useEffect(() => {
        let existingComponentToggles = componentToggles
        let currentActiveBladeComponents = {}
        for (const componentGroupName in existingComponentToggles) {
            const componentGroup = existingComponentToggles[componentGroupName]
            for (const [dataType, dataTypeInfo] of Object.entries(componentGroup.dataTypes)) {
                let identifier = dataTypeInfo.identifier

                if (componentGroup.selected && dataTypeInfo.selected) {

                    let foundZoneComponents = bladeZoneComponents.filter((c) => c.componentInfo.name === dataTypeInfo.componentName && (dataTypeInfo.zoneType === undefined || c.zoneType === dataTypeInfo.zoneType))
                    for (let zoneComponent of foundZoneComponents) {
                        dataTypeInfo.zoneComponent = zoneComponent

                        if (currentActiveBladeComponents[zoneComponent.bladeId] === undefined)   {
                            currentActiveBladeComponents[zoneComponent.bladeId] = []
                        }
                        if (!currentActiveBladeComponents[zoneComponent.bladeId].includes(zoneComponent.componentInfo.id))  {
                            currentActiveBladeComponents[zoneComponent.bladeId].push(zoneComponent.componentInfo.id)
                        }
                    }
                }
            }
        }
        SetActiveBladeComponents(currentActiveBladeComponents)
        SetComponentToggles(existingComponentToggles)
    }, [componentToggles, bladeZoneComponents])




    //UseEffects

    //useMeasures
    const chartingAreaRef = React.useRef()
    const [chartsAreaRef, { height: chartsAreaHeight }] = useMeasure()
    const [chartsContainerRef, { height: chartsContainerHeight, documentTop: chartsContainerAreaTop, documentLeft: chartsContainerAreaLeft }] = useMeasure()
    const [mainChartContainerRef, { height: mainChartContainerHeight, width: mainChartContainerWidth, documentTop: mainChartContainerTop, documentLeft: mainChartContainerLeft }] = useMeasure()
    const [overviewChartContainerRef, { height: overviewChartContainerHeight, width: overviewChartContainerWidth, documentTop: overviewChartContainerTop, documentLeft: overviewChartContainerLeft }] = useMeasure()



    const [mainChartAreaRef, { ele: mainChartAreaEle, height: mainChartAreaHeight, width: mainChartAreaWidth, documentTop: mainChartAreaTop, documentLeft: mainChartAreaLeft }] = useMeasure()
    const [overviewChartAreaRef, { height: overviewChartAreaHeight, width: overviewChartAreaWidth, documentTop: overviewChartAreaTop, documentLeft: overviewChartAreaLeft }] = useMeasure()


    //Chart stuff
    const chartRef = React.useRef(undefined)

    const [chartReady, SetChartReady] = React.useState(false)
    const [activeDataSeries, SetActiveDataSeries] = React.useState({});
    const [isForceLive, SetIsForceLive] = React.useState(true);
    React.useEffect(() => {
        if (chartRef.current)   {
            chartRef.current.isForceLive = isForceLive
        }
    }, [chartRef, isForceLive])
    const [energySubselectionActive, SetEnergySubselectionActive] = React.useState(false);
    const overviewSubSelectionCanvasRef = React.useRef(undefined)



    

    const componentExpandedStateToggled = React.useCallback((componentName, expanded) => {
        componentToggles[componentName].expanded = expanded
        SetComponentToggles({ ...componentToggles })
    })
    const componentToggled = React.useCallback((componentName, selected) => {
        if (componentName === "energy") {
            SetEnergySubselectionActive(selected)
        }
        componentToggles[componentName].selected = selected
        SetComponentToggles({ ...componentToggles })
    })
    const componentDataTypeToggled = React.useCallback((componentName, dataTypeName, selected) => {
        componentToggles[componentName].dataTypes[dataTypeName].selected = selected
        SetComponentToggles({ ...componentToggles })
    })


    /* Apply Chart Axis Requirements  */
    React.useEffect(() => {
        if (!chartRef.current) {
            return
        }
        if (haveAppInfo) {
            let currentRequiredYAxes = []
            for (const componentGroupName in componentToggles) {
                const componentGroup = componentToggles[componentGroupName]
                if (componentGroup.selected) {
                    for (const dataType in componentGroup.dataTypes) {
                        const dataTypeInfo = componentGroup.dataTypes[dataType]
                        if (dataTypeInfo.yAxis !== undefined && dataTypeInfo.selected && currentRequiredYAxes.indexOf(dataTypeInfo.yAxis) === -1) {
                            currentRequiredYAxes.push(dataTypeInfo.yAxis)
                        }
                    }
                }
            }
            for (const yAxisIdentifier of currentRequiredYAxes) {
                if (chartRef.current.activeYAxes[yAxisIdentifier] === undefined) {
                    //YAxis is missing, lets create it
                    const yAxisInfo = chartAxisTypes.find(type => type.identifier === yAxisIdentifier)
                    if (yAxisInfo !== undefined) {
                        chartRef.current.activeYAxes[yAxisIdentifier] = {}
                        chartRef.current.activeYAxes[yAxisIdentifier].main = chartRef.current.mainChart.addAxisY()
                        chartRef.current.activeYAxes[yAxisIdentifier].main.setInterval({ start: yAxisInfo.min, end: yAxisInfo.max })
                            .setMouseInteractions(false)
                            .setTickStrategy(AxisTickStrategies.Empty)
                            .setScrollStrategy(undefined)
                            .setStrokeStyle(emptyLine)
                            .onIntervalChange((axis, start, end) => {
                                let min = (chartRef.current.tempYAxisInterval[yAxisIdentifier] !== undefined) ? chartRef.current.tempYAxisInterval[yAxisIdentifier].min : yAxisInfo.min
                                let max = (chartRef.current.tempYAxisInterval[yAxisIdentifier] !== undefined) ? chartRef.current.tempYAxisInterval[yAxisIdentifier].max : yAxisInfo.max

                                if (start !== min || end !== max) {
                                    chartRef.current.activeYAxes[yAxisIdentifier].main.setInterval({ start: min, end: max })
                                }
                            })
                        chartRef.current.activeYAxes[yAxisIdentifier].overview = chartRef.current.overviewChart.addAxisY()
                        chartRef.current.activeYAxes[yAxisIdentifier].overview.setInterval({ start: yAxisInfo.min, end: yAxisInfo.max })
                            .setMouseInteractions(false)
                            .setTickStrategy(AxisTickStrategies.Empty)
                            .setScrollStrategy(undefined)
                            .setStrokeStyle(emptyLine)
                            .onIntervalChange((axis, start, end) => {
                                if (start !== yAxisInfo.min || end !== yAxisInfo.max) {
                                    chartRef.current.activeYAxes[yAxisIdentifier].overview.setInterval({ start: yAxisInfo.min, end: yAxisInfo.max })
                                }
                            })
                    }
                }
            }
            for (const yAxisIdentifier in chartRef.current.activeYAxes) {
                //Check that we require all of the ones here
                if (currentRequiredYAxes.indexOf(yAxisIdentifier) === -1) {
                    //We need to remove this one
                    chartRef.current.activeYAxes[yAxisIdentifier].main.dispose()
                    chartRef.current.activeYAxes[yAxisIdentifier].overview.dispose()
                    delete chartRef.current.activeYAxes[yAxisIdentifier]
                }
            }
        }

    }, [chartRef, haveAppInfo, chartReady, componentToggles])












    /* Zone Component Map Setup */
    
    React.useEffect(() => {
        if (!chartRef.current || !dataActive || zoneConfigurationMap === undefined) {
            return
        }

        //Check if we can make a data request
        if (loadingConfigurationMapsStatus === "idle" || loadingConfigurationMapsStatus === "fulfilled") {

            //Make sure we have loaded all the matching rack component info
            for (let zoneComponent of zoneConfigurationMap.component_map.components) {
                let foundZoneComponent = bladeZoneComponents.find((zoneComponent) => zoneComponent.id === zoneComponent.id)
                if (foundZoneComponent === undefined) {
                    let associatedBladeUID = activeBladeUIDs[bladeZoneType === "nursery" ? "nursery" : zoneComponent.rack_type]
                    if (associatedBladeUID !== undefined)   {
                        if (bladeConfigurationIds[associatedBladeUID] !== undefined && bladeIds[associatedBladeUID] !== undefined)  {
                            let foundVerticalRackConfigurationMap = bladeConfigurationMaps.find((cM) => cM.id === bladeConfigurationIds[associatedBladeUID])
                            if (foundVerticalRackConfigurationMap !== undefined) {
                                //Time to find the matching component
                                for (let rackComponent of foundVerticalRackConfigurationMap.component_map.components) {
                                    if (rackComponent.name === zoneComponent.name && rackComponent.index === zoneComponent.index) {
                                        dispatch(initializeComponentForAnalyticsData({
                                            bladeId: bladeIds[associatedBladeUID],
                                            dataRecordingTimePeriodTypes: dataRecordingTimePeriodTypes,
                                            zoneId: bladeZoneId,
                                            zoneType: bladeZoneType,
                                            componentInfo: rackComponent,
                                            rackComponentId: rackComponent.id,
                                            zoneComponentId: zoneComponent.id
                                        }))
                                        break //found matching component
                                    } else {

                                    }
                                }
                            }
                        }
                    }
                }
            }

        }



    }, [chartRef, dataRecordingTimePeriodTypes, dataActive, bladeZoneId, bladeZoneType, activeBladeUIDs, bladeZoneComponents, bladeConfigurationIds, bladeIds, bladeConfigurationMaps, zoneConfigurationMap, loadingConfigurationMapsStatus])



    const [chartOriginDate, SetChartOriginDate] = React.useState(new Date().getTime());



    


    /* Main and overview chart sizing */
    const [mainChartArea, SetMainChartArea] = React.useState({top: 0, left: 0, bottom: 0, right: 0, width: 0, height: 0})
    const [mainChartAreaPadding, SetMainChartAreaPadding] = React.useState({top: 0, left: 0, right: 0, bottom: 0})
    const [mainChartDateAxisThickness, SetMainChartDateAxisThickness] = React.useState(0)
    React.useLayoutEffect(() => {
        let chartArea = {
            top: mainChartAreaTop,
            left: mainChartAreaLeft,
            x1: mainChartAreaPadding.left,
            x2: mainChartAreaWidth - mainChartAreaPadding.right,
            y1: mainChartAreaPadding.top,
            y2: mainChartAreaHeight - mainChartAreaPadding.bottom - mainChartDateAxisThickness
        }
        chartArea.width = chartArea.x2 - chartArea.x1
        chartArea.height = chartArea.y2 - chartArea.y1
        SetMainChartArea(chartArea)
    }, [mainChartAreaTop, mainChartAreaLeft, mainChartAreaWidth, mainChartAreaHeight, mainChartAreaPadding, mainChartDateAxisThickness])

    const [overviewChartArea, SetOverviewChartArea] = React.useState({top: 0, left: 0, bottom: 0, right: 0, width: 0, height: 0})
    const [overviewChartAreaPadding, SetOverviewChartAreaPadding] = React.useState({top: 0, left: 0, right: 0, bottom: 0})
    const [overviewChartDateAxisThickness, SetOverviewChartDateAxisThickness] = React.useState(0)
    React.useLayoutEffect(() => {
        let chartArea = {
            top: overviewChartAreaTop,
            left: overviewChartAreaLeft,
            x1: overviewChartAreaPadding.left,
            x2: overviewChartAreaWidth - overviewChartAreaPadding.right,
            y1: overviewChartAreaPadding.top,
            y2: overviewChartAreaHeight - overviewChartAreaPadding.bottom - overviewChartDateAxisThickness
        }
        chartArea.width = chartArea.x2 - chartArea.x1
        chartArea.height = chartArea.y2 - chartArea.y1
        SetOverviewChartArea(chartArea)
    }, [overviewChartAreaTop, overviewChartAreaLeft, overviewChartAreaWidth, overviewChartAreaHeight, overviewChartAreaPadding, overviewChartDateAxisThickness])
    




    /* Main Chart And Overview Chart Visible Range Management */
    const defaultMainChartInterval = { start: (new Date().getTime() - (1000 * 60 * 60 * 3)), end: new Date().getTime() }
    const [pendingManualMainChartVisibleRangeChange, SetPendingManualMainChartVisibleRangeChange] = React.useState(false)
    const [mainChartVisibleRange, SetMainChartVisibleRange] = React.useState(defaultMainChartInterval)
    const getMainChartVisibleRange = () => {
        if (chartRef.current) {
            const interval = chartRef.current.mainChartDateAxis.getInterval()
            return {start: interval.start + chartOriginDate, end: interval.end + chartOriginDate}
        }
        return defaultMainChartInterval
    }
    const MainChartVisibleRangeChanged = (range) => {
        if (chartRef.current !== undefined)  {
            chartRef.current.mainChartDateAxis.setInterval({ start: range.start - chartOriginDate, end: range.end - chartOriginDate})        
            chartRef.current.mainChartTimeTicks = updateChartAxisTicks(chartRef.current.mainChartDateAxis, chartRef.current.mainChartTimeTicks, chartRef.current.lastMainChartTickRange, range.start, range.end)
        }
        const timeDelta = (range.end - range.start) / 1000
        let targetDataRecordingTimePeriodType = null
        for (let dataRecordingTimePeriodType of dataRecordingTimePeriodTypes) {
            if (targetDataRecordingTimePeriodType === null) {
                targetDataRecordingTimePeriodType = dataRecordingTimePeriodType
            } else {
                if (timeDelta > dataRecordingTimePeriodType.duration) {
                    targetDataRecordingTimePeriodType = dataRecordingTimePeriodType
                }
            }
        }
        SetMainDataRecordingTimePeriodType(targetDataRecordingTimePeriodType) 

        if (lastMainChartingAreaPointerLocation !== null)   {
            calculateActiveDataPoints(lastMainChartingAreaPointerLocation)
        }
    }


    const [mainDataRecordingTimePeriodType, SetMainDataRecordingTimePeriodType] = React.useState(null)
    const SetMainChartVisibleRangeInstantly = (range) => {
        MainChartVisibleRangeChanged(range)
    }
    React.useLayoutEffect(() => {
        MainChartVisibleRangeChanged(mainChartVisibleRange)
    }, [chartRef, mainChartVisibleRange, dataRecordingTimePeriodTypes])


    const defaultOverviewChartInterval = { start: (new Date().getTime() - (1000 * 60 * 60 * 24 * 7)), end: new Date().getTime()}
    const [overviewChartVisibleRange, SetOverviewChartVisibleRange] = React.useState(defaultOverviewChartInterval)
    const [overviewDataRecordingTimePeriodType, SetOverviewDataRecordingTimePeriodType] = React.useState(null)
    const getOverviewChartVisibleRange = () => {
        if (chartRef.current) {
            const interval = chartRef.current.overviewChartDateAxis.getInterval()
            return {start: interval.start + chartOriginDate, end: interval.end + chartOriginDate}
        }
        return defaultOverviewChartInterval
    }
    React.useLayoutEffect(() => {
        if (chartRef.current !== undefined)  {
            chartRef.current.overviewChartVisibleRange = overviewChartVisibleRange
            chartRef.current.overviewChartDateAxis.setInterval({ start: overviewChartVisibleRange.start - chartOriginDate, end: overviewChartVisibleRange.end - chartOriginDate})        
            chartRef.current.overviewChartTimeTicks = updateOverviewChartAxisTicks(chartRef.current.overviewChartDateAxis, chartRef.current.overviewChartTimeTicks, chartRef.current.lastOverviewChartTickRange, overviewChartVisibleRange.start, overviewChartVisibleRange.end)
    
        }
        const timeDelta = overviewChartVisibleRange.end - overviewChartVisibleRange.start

        let targetDataRecordingTimePeriodType = null
        for (let dataRecordingTimePeriodType of dataRecordingTimePeriodTypes) {
            if (targetDataRecordingTimePeriodType === null) {
                targetDataRecordingTimePeriodType = dataRecordingTimePeriodType
            } else {
                if (timeDelta > dataRecordingTimePeriodType.duration) {
                    targetDataRecordingTimePeriodType = dataRecordingTimePeriodType
                }
            }
        }
        SetOverviewDataRecordingTimePeriodType(targetDataRecordingTimePeriodType)
    }, [chartRef, overviewChartVisibleRange])



    let processingMainChartInterval = false
    let processingOverviewChartInterval = false

    const checkMainChartInterval = (start, end) => {

        if ((start == chartRef.current.lastMainChartInterval.start && end == chartRef.current.lastMainChartInterval.end))
            return

        const overviewChartVisibleRange = getOverviewChartVisibleRange()


        processingMainChartInterval = true
        let changed = false;
        //const overviewChartVisibleRange = getOverviewChartInterval()

        const timeDelta = end - start
        let newEnergySubselection = { start: chartRef.current.energySubSelection.start + chartOriginDate, end: chartRef.current.energySubSelection.end + chartOriginDate }

        /*if (isForceLive) {
            let newEnd = new Date().getTime()
            overviewChartVisibleRange.end = newEnd
        }*/


        if (Math.floor(timeDelta) != Math.floor(chartRef.current.lastMainChartInterval.end - chartRef.current.lastMainChartInterval.start)) {
            //Zoom
            if (start < overviewChartVisibleRange.start || start > overviewChartVisibleRange.end) {
                start = overviewChartVisibleRange.start
                changed = true
            }
            if (chartRef.current.isForceLive && (end !== overviewChartVisibleRange.end))    {
                end = overviewChartVisibleRange.end
                changed = true
            }else if (end > overviewChartVisibleRange.end || end < overviewChartVisibleRange.start) {
                end = overviewChartVisibleRange.end
                changed = true
            }


            //Handle current position of energy subselection 
            if (newEnergySubselection.start < start) {
                newEnergySubselection.start = start
                if (newEnergySubselection.end < start) {
                    newEnergySubselection.end = start + 1000
                }
                //newEnergySubselection.end = start + (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
            }
            if (newEnergySubselection.end > end) {
                newEnergySubselection.end = end
                if (newEnergySubselection.start > end) {
                    newEnergySubselection.start = end - 1000
                }
                //newEnergySubselection.start = end - (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
            }



        } else {
            //Pan
            if (end > overviewChartVisibleRange.end) {
                start = overviewChartVisibleRange.end - timeDelta
                end = overviewChartVisibleRange.end
                changed = true
            }
            if (start < overviewChartVisibleRange.start) {
                start = overviewChartVisibleRange.start
                end = overviewChartVisibleRange.start + timeDelta
                changed = true
            }

            //Handle current position of energy subselection 
            if (newEnergySubselection.start < start) {
                newEnergySubselection.start = start
                newEnergySubselection.end = start + (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
            }
            if (newEnergySubselection.end > end) {
                newEnergySubselection.end = end
                newEnergySubselection.start = end - (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
            }
        }


        //console.log(new Date(chartRef.current.energySubSelection.start + chartOriginDate), new Date(chartRef.current.energySubSelection.end + chartOriginDate), new Date(newEnergySubselection.start), new Date(newEnergySubselection.end), new Date(start), new Date(end))
        chartRef.current.energySubSelection = { start: newEnergySubselection.start - chartOriginDate, end: newEnergySubselection.end - chartOriginDate }


        chartRef.current.lastMainChartInterval.start = start
        chartRef.current.lastMainChartInterval.end = end
        if (changed)    {
            SetMainChartVisibleRangeInstantly({start: start, end: end})
        }
        SetMainChartVisibleRange({ start: start, end: end })
        
        processingMainChartInterval = false
    }

    const checkOverviewChartInterval = React.useCallback((start, end) => {
        if (chartRef.current === undefined)
            return

        if ((start == chartRef.current.lastOverviewChartInterval.start && end == chartRef.current.lastOverviewChartInterval.end) || processingOverviewChartInterval)
            return
        processingOverviewChartInterval = true



        if (isForceLive) {
            let newEnd = new Date().getTime()
            start += newEnd - end
            end = newEnd
        }
        chartRef.current.lastOverviewChartInterval.start = start
        chartRef.current.lastOverviewChartInterval.end = end
        SetOverviewChartVisibleRange({ start: start, end: end })


        processingOverviewChartInterval = false

    })

    const mainChartConvertDateToPosition = (date) => {
        const mainChartVisibleRange = getMainChartVisibleRange()
        return ((date - mainChartVisibleRange.start) / (mainChartVisibleRange.end - mainChartVisibleRange.start)) * mainChartArea.width
    }
    const mainChartConvertPositionToDate = (x) => {
        const mainChartVisibleRange = getMainChartVisibleRange()
        if (x < 0)
            x = 0
        if (x > mainChartArea.width)
            x = mainChartArea.width
        return mainChartVisibleRange.start + (x / mainChartArea.width) * (mainChartVisibleRange.end - mainChartVisibleRange.start)
    }


    const overviewChartConvertDateToPosition = (date) => {
        const overviewChartVisibleRange = getOverviewChartVisibleRange()
        return ((date - overviewChartVisibleRange.start) / (overviewChartVisibleRange.end - overviewChartVisibleRange.start)) * overviewChartArea.width
    }
    const overviewChartConvertPositionToDate = (x) => {
        const overviewChartVisibleRange = getOverviewChartVisibleRange()
        if (x < 0)
            x = 0
        if (x > overviewChartArea.width)
            x = overviewChartArea.width
        return overviewChartVisibleRange.start + (x / overviewChartArea.width) * (overviewChartVisibleRange.end - overviewChartVisibleRange.start)
    }





    


    const getTimeDeltaType = (timeDelta) => {
        if (timeDelta <= 1000 * 60 * 1)
            return ["minute", 1000 * 10, 1000 * 60]
        if (timeDelta <= 1000 * 60 * 5)
            return ["minute5", 1000 * 30, 1000 * 60]
        if (timeDelta <= 1000 * 60 * 10)
            return ["minute10", 1000 * 60, 1000 * 60 * 5]
        if (timeDelta <= 1000 * 60 * 30)
            return ["minute30", 1000 * 60 * 2, 1000 * 60 * 60]
        if (timeDelta <= 1000 * 60 * 60)
            return ["hour", 1000 * 60 * 5, 1000 * 60 * 60]
        if (timeDelta <= 1000 * 60 * 60 * 3)
            return ["hour3", 1000 * 60 * 15, 1000 * 60 * 60]
        if (timeDelta <= 1000 * 60 * 60 * 6)
            return ["hour6", 1000 * 60 * 60, 1000 * 60 * 60 * 24]
        if (timeDelta <= 1000 * 60 * 60 * 12)
            //if (isLargeDesktop) {
            return ["hour12", 1000 * 60 * 60, 1000 * 60 * 60 * 24]
        //}else {
        //    return ["hour12", 1000 * 60 * 60 * 3, 1000 * 60 * 60 * 24]
        //}
        if (timeDelta <= 1000 * 60 * 60 * 24)
            //if (isLargeDesktop) {
            return ["day", 1000 * 60 * 60 * 3, 1000 * 60 * 60 * 24]
        //}else {
        //    return ["day", 1000 * 60 * 60 * 6, 1000 * 60 * 60 * 24]
        //}
        if (timeDelta <= 1000 * 60 * 60 * 24 * 3)
            //if (isLargeDesktop) {
            return ["3day", 1000 * 60 * 60 * 6, 1000 * 60 * 60 * 24]
        //}else {
        //    return ["day", 1000 * 60 * 60 * 6, 1000 * 60 * 60 * 24]
        //}
        if (timeDelta <= 1000 * 60 * 60 * 24 * 7)
            //if (isLargeDesktop) {
            return ["week", 1000 * 60 * 60 * 12, 1000 * 60 * 60 * 24]
        //}else {
        //    return ["week", 1000 * 60 * 60 * 12, 1000 * 60 * 60 * 24]
        //}

        return ["month", 1000 * 60 * 60 * 24, 1000 * 60 * 60 * 24 * 7]
    }

    const createTicksInRangeX = (axis, tickList, timeDelta, start, end) => {

        //let minorTickInterval = 1000 * 60 * 60 * 24 //every day
        //let majorTickInterval = 1000 * 60 * 60 * 24 * 7 //every week

        let minorTickFormat = 'MMM dd'
        let majorTickFormat = 'MMM dd'
        const [deltaType, minorTickInterval, majorTickInterval] = getTimeDeltaType(timeDelta)
        if (deltaType === "minute") { //1 minute span -- we want ticks every 30 seconds
            minorTickFormat = 'HH:mm:ss'
            majorTickFormat = 'HH:mm'
            let minuteCount = 1
            start = RoundedDateToNearestLastMinute(minuteCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextMinute(minuteCount, new Date(end)).getTime()
        } else if (deltaType === "minute5") { //5 minute span -- we want ticks every 30 seconds
            minorTickFormat = 'HH:mm:ss'
            majorTickFormat = 'HH:mm'
            let minuteCount = 5
            start = RoundedDateToNearestLastMinute(minuteCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextMinute(minuteCount, new Date(end)).getTime()

        } else if (deltaType === "minute10") { //10 minute span -- we want ticks every minute
            if (isLargeDesktop) {
                minorTickFormat = 'HH:mm:ss'
                majorTickFormat = 'HH:mm'
            } else {
                minorTickFormat = 'HH:mm'
                majorTickFormat = 'HH:mm'
            }
            let minuteCount = 10
            start = RoundedDateToNearestLastMinute(minuteCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextMinute(minuteCount, new Date(end)).getTime()

        } else if (deltaType === "minute30") { //30 minute span -- we want ticks every 5 minutes
            if (isLargeDesktop) {
                minorTickFormat = 'HH:mm:ss'
                majorTickFormat = 'HH:mm'
            } else {
                minorTickFormat = 'HH:mm'
                majorTickFormat = 'HH:mm'
            }
            let minuteCount = 30
            start = RoundedDateToNearestLastMinute(minuteCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextMinute(minuteCount, new Date(end)).getTime()
        } else if (deltaType === "hour") { //60 minute span -- we want ticks every 15 minutes
            minorTickFormat = 'HH:mm'
            majorTickFormat = 'HH:mm'
            let minuteCount = 60
            //start = RoundedDateToNearestLastHour(1, new Date(start)).getTime()
            start = RoundedDateToNearestLastMinute(minuteCount, new Date(start)).getTime()

            //end = RoundedDateToNearestNextHour(1, new Date(start)).getTime()
            end = RoundedDateToNearestNextMinute(minuteCount, new Date(end)).getTime()
        } else if (deltaType === "hour3") { //3 hour span -- we want ticks every 30 minutes
            minorTickFormat = 'HH:mm'
            majorTickFormat = 'HH:mm'
            let hourCount = 3
            start = RoundedDateToNearestLastHour(hourCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextHour(hourCount, new Date(end)).getTime()

        } else if (deltaType === "hour6") { //6 hour span -- we want ticks every hour
            minorTickFormat = 'HH:mm'
            majorTickFormat = 'MMM dd'
            let hourCount = 6
            start = RoundedDateToNearestLastHour(hourCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextHour(hourCount, new Date(end)).getTime()

        } else if (deltaType === "hour12") { //12 hour span -- we want ticks every hour
            minorTickFormat = 'HH:mm'
            majorTickFormat = 'MMM dd'
            let hourCount = 12
            start = RoundedDateToNearestLastHour(hourCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextHour(hourCount, new Date(end)).getTime()
        } else if (deltaType === "day") { //1 day span -- we want ticks every 6 hours
            minorTickFormat = 'HH:mm'
            majorTickFormat = 'MMM dd'
            let dayCount = 1
            start = RoundedDateToNearestLastDay(dayCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextDay(dayCount, new Date(end)).getTime()

        } else if (deltaType === "3day") { //1 day span -- we want ticks every 6 hours
            minorTickFormat = 'HH:mm'
            majorTickFormat = 'MMM dd'
            let dayCount = 3
            start = RoundedDateToNearestLastDay(dayCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextDay(dayCount, new Date(end)).getTime()

        } else if (deltaType === "week") { //7 day span -- we want ticks every day
            minorTickFormat = 'HH:mm'
            majorTickFormat = 'MMM dd'
            let dayCount = 7
            start = RoundedDateToNearestLastDay(dayCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextDay(dayCount, new Date(end)).getTime()
        }


        // Major ticks every 1000 units.
        let majorTickPositions = []
        for (let majorTickPos = start; majorTickPos <= end; majorTickPos += majorTickInterval) {


            if (majorTickPos >= start && majorTickPos <= end) {
                let tickPos = majorTickPos - chartOriginDate

                majorTickPositions.push(majorTickPos)
                const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
                    .setTextFormatter(() => FormatDate(new Date(majorTickPos), majorTickFormat))
                    .setValue(tickPos)
                    .setMarker(marker => marker
                        .setTextFont(new FontSettings({ size: 14, style: '' }))
                    )
                    .setTickLength(8)
                    .setTickLabelPadding(-4)
                    .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA(100)))
                tickList.push(tick)
            }
        }
        // Major ticks every 100 units, but not at same interval as major ticks.
        for (let minorTickPos = start; minorTickPos <= end; minorTickPos += minorTickInterval) {
            if (minorTickPos >= start && majorTickPositions.indexOf(minorTickPos) === -1) {
                let tickPos = minorTickPos - chartOriginDate
                const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
                    .setTextFormatter(() => FormatDate(new Date(minorTickPos), minorTickFormat))
                    .setValue(tickPos)
                    .setMarker(marker => marker
                        .setTextFont(new FontSettings({ size: 12, style: '' }))
                    )
                    .setTickLabelPadding(-4)
                    .setTickLength(4)
                    .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA(50)))
                tickList.push(tick)
            }
        }

    }




    const updateChartAxisTicks = (axis, ticks, lastRange, start, end) => {
        if (start == lastRange.start && end == lastRange.end)
            return ticks

        const timeDelta = end - start
        if (Math.floor(timeDelta) != Math.floor(lastRange.end - lastRange.start)) {
            //Zoom

            ticks = ticks.filter(tick => {
                tick.dispose()
                return false
            })
            createTicksInRangeX(axis, ticks, timeDelta, start, end)


        } else {
            //Pan
            /*const [deltaType, minorTickInterval, majorTickInterval] = getTimeDeltaType(timeDelta)
            let minuteCount = 1
            if (deltaType === "minute")  { //1 minute span -- we want ticks every 30 seconds
            }else if (deltaType === "minute5")  { //5 minute span -- we want ticks every 30 seconds
                minuteCount = 5
            }else if (deltaType === "minute10")  { //10 minute span -- we want ticks every minute
                minuteCount = 10
            }else if (deltaType === "minute30")  { //30 minute span -- we want ticks every 5 minutes
                minuteCount = 30
            }else if (deltaType === "hour")  { //60 minute span -- we want ticks every 15 minutes
                minuteCount = 60
            }else if (deltaType === "hour3")  { //3 hour span -- we want ticks every 30 minutes
                minuteCount = 60 * 3
            }else if (deltaType === "hour6")  { //6 hour span -- we want ticks every hour
                minuteCount = 60 * 6
            }else if (deltaType === "hour12")  { //12 hour span -- we want ticks every hour
                minuteCount = 60 * 12
            }else if (deltaType === "day")  { //1 day span -- we want ticks every 6 hours
                minuteCount = 60 * 24
            }else if (deltaType === "week")  { //7 day span -- we want ticks every day
                minuteCount = 60 * 24 * 7
            }
            start = RoundedDateToNearestLastMinute(minuteCount, new Date(start)).getTime()
            end = RoundedDateToNearestNextMinute(minuteCount, new Date(end)).getTime()
            

            
            //compare last vs now to see if we need to remove ticks
            ticks = ticks.filter(tick => {
                console.log(tick.getValue(), start - chartOriginDate, end - chartOriginDate)
                if (tick.getValue() < (start - chartOriginDate) || tick.getValue() > (end - chartOriginDate)) {
                    // Tick is out of view.
                    tick.dispose()
                    return false
                } else {
                    return true
                }
            })
            //compare last vs now to see if we need to add ticks
            
            if (end > lastRange.end) {
                createTicksInRangeX(axis, ticks, timeDelta, lastRange.end, end)
            }
            if (start < lastRange.start)    {
                createTicksInRangeX(axis, ticks, timeDelta, start, lastRange.start)
            }


            */

            ticks = ticks.filter(tick => {
                tick.dispose()
                return false
            }
            )
            createTicksInRangeX(axis, ticks, timeDelta, start, end)




        }

        lastRange.start = start
        lastRange.end = end


        return ticks
    }






    const createOverviewTicksInRangeX = (axis, tickList, timeDelta, start, end) => {

        //let minorTickInterval = 1000 * 60 * 60 * 24 //every day
        //let majorTickInterval = 1000 * 60 * 60 * 24 * 7 //every week

        let minorTickFormat = 'dd'
        //let majorTickFormat = 'MMM dd'
        const minorTickInterval = 1000 * 60 * 60 * 24 //days
        //const majorTickInterval = 0

        let minuteCount = 60 * 24
        start = RoundedDateToNearestLastMinute(minuteCount, new Date(start)).getTime()
        end = RoundedDateToNearestNextMinute(minuteCount, new Date(end)).getTime()


        // Major ticks every 100 units, but not at same interval as major ticks.
        const majorTickPositions = []
        for (let minorTickPos = start; minorTickPos <= end; minorTickPos += minorTickInterval) {
            if (minorTickPos >= start && majorTickPositions.indexOf(minorTickPos) === -1) {
                let tickPos = minorTickPos - chartOriginDate
                const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
                    .setTextFormatter(() => FormatDate(new Date(minorTickPos), minorTickFormat))
                    .setValue(tickPos)
                    .setMarker(marker => marker
                        .setTextFont(new FontSettings({ size: 12, style: '' }))
                    )
                    .setTickLabelPadding(-4)
                    .setTickLength(4)
                    .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA(50)))
                tickList.push(tick)
            }
        }

    }



    const updateOverviewChartAxisTicks = (axis, ticks, lastRange, start, end) => {
        if (start == lastRange.start && end == lastRange.end)
            return ticks

        const timeDelta = end - start
        ticks = ticks.filter(tick => {
            tick.dispose()
            return false
        }
        )
        createOverviewTicksInRangeX(axis, ticks, timeDelta, start, end)


        lastRange.start = start
        lastRange.end = end


        return ticks
    }





    

    /* Interactivity */
    
    const [chartingAreaPointerId, SetChartingAreaPointerId] = React.useState(null)
    const [lastMainChartingAreaPointerLocation, SetLastMainChartingAreaPointerLocation] = React.useState(null)
    const [lastMainChartingAreaPointerLocationWithinChart, SetLastMainChartingAreaPointerLocationWithinChart] = React.useState(null)
    const [chartAreaPointerInitialPosition, SetChartAreaPointerInitialPosition] = React.useState({x: 0, y: 0})
    const [activePointerDatetime, SetActivePointerDatetime] = React.useState(null)

    const [numberOfPointersDownOnMainChart, SetNumberOfPointersDownOnMainChart] = React.useState(0)
    const [isPanningMainChart, SetIsPanningMainChart] = React.useState(false)
    const [panningMainChartOriginDates, SetPanningMainChartOriginDates] = React.useState(null)

    const mainChartPointerDown = (e) => {
        const numberOfPointersDown = numberOfPointersDownOnMainChart + 1
        SetNumberOfPointersDownOnMainChart(numberOfPointersDown)
        SetChartAreaPointerInitialPosition({top: e.clientY, left: e.clientX})
        return false
    }
    const mainChartPointerMove = (e) => {
        const pointerPosition = {top: e.clientY, left: e.clientX}
        SetLastMainChartingAreaPointerLocation(pointerPosition)
        SetLastMainChartingAreaPointerLocationWithinChart({top: pointerPosition.top - mainChartArea.top, left: pointerPosition.left - mainChartArea.left})
        SetActivePointerDatetime(mainChartConvertPositionToDate(e.clientX - mainChartArea.left))
        calculateActiveDataPoints(pointerPosition)        
        if (!isPanningMainChart && ((isTouchDevice && numberOfPointersDownOnMainChart === 2) || !isTouchDevice && numberOfPointersDownOnMainChart === 1))    {
            SetIsPanningMainChart(true)
            SetPanningMainChartOriginDates(getMainChartVisibleRange())
            if (mainChartAreaRef.current !== undefined && mainChartAreaRef.current.setPointerCapture) {
                SetChartingAreaPointerId(e.pointerId)
                mainChartAreaRef.current.setPointerCapture(e.pointerId);
            }    
        }else if (isPanningMainChart)   {
            handleMainChartPan(pointerPosition)
        }
    }
    const mainChartPointerUp = (e) => {
        if (!isPanningMainChart)    {
            const numberOfPointersDown = numberOfPointersDownOnMainChart - 1
            SetNumberOfPointersDownOnMainChart(numberOfPointersDown)    
        }else {
            doneMainChartPan()
        }
    }
    const mainChartPointerLeave = (e) => {
        pointerNoLongerOverMainChart()
        SetLastMainChartingAreaPointerLocation(null)
        SetLastMainChartingAreaPointerLocationWithinChart(null)
        SetActivePointerDatetime(null)
    }
    const handleMainChartPan = (pointerPosition) => {
        if (isForceLive)
            return

        const offsetPosition = {top: pointerPosition.top - chartAreaPointerInitialPosition.top, left: pointerPosition.left - chartAreaPointerInitialPosition.left}

        const existingDateDelta = panningMainChartOriginDates.end - panningMainChartOriginDates.start
        const perPixelDateRange = existingDateDelta / mainChartAreaWidth

        const overviewChartVisibleRange = getOverviewChartVisibleRange()
        let start = panningMainChartOriginDates.start + (-offsetPosition.left * perPixelDateRange)
        let end = panningMainChartOriginDates.end + (-offsetPosition.left * perPixelDateRange)
        if (start < overviewChartVisibleRange.start)    {
            start = overviewChartVisibleRange.start
            end = overviewChartVisibleRange.start + existingDateDelta
        }else if (end > overviewChartVisibleRange.end)    {
            end = overviewChartVisibleRange.end
            start = overviewChartVisibleRange.end - existingDateDelta
        }
        SetMainChartVisibleRangeInstantly({start: start, end: end})
        SetNumberOfPointersDownOnMainChart(0)
    }
    const doneMainChartPan = () => {
        SetIsPanningMainChart(false)
        if (chartingAreaRef.current !== undefined && chartingAreaRef.current.releasePointerCapture && chartingAreaPointerId !== null) {
            chartingAreaRef.current.releasePointerCapture(chartingAreaPointerId);
        }
        SetNumberOfPointersDownOnMainChart(0)
    }








    const [activeDataPoints, SetActiveDataPoints] = React.useState({})
    const [activeDataPoint, SetActiveDataPoint] = React.useState(null)
    const calculateActiveDataPoints = (pointerPosition) => {
        const mainChartVisibleRange = getMainChartVisibleRange()

        let currentActiveDataPoints = {}
        let nearestDataPoint = null
        let maxDistanceForActivePoint = 20
        for (const [componentIdAsString, componentDataSeries] of Object.entries(chartRef.current.dataSeries)) {
            const componentId = parseInt(componentIdAsString)
            for (const [identifier, dataSeries] of Object.entries(componentDataSeries))   {
                const d = dataSeries.main.solveNearest({clientX: pointerPosition.left, clientY: pointerPosition.top}, 'show-nearest')
                if (d !== undefined)    {
                    d.x += chartOriginDate
                    const yAxisInterval = dataSeries.main.axisY.getInterval()

                    if (currentActiveDataPoints[componentId] === undefined) {
                        currentActiveDataPoints[componentId] = {}
                    }
                    const dX = mainChartConvertDateToPosition(d.x)
                    const dY = remapRange(d.y, [yAxisInterval.end, yAxisInterval.start], [0, mainChartArea.height])
                    const distFromPointer = Math.sqrt( Math.pow(((pointerPosition.left - mainChartArea.left) - dX), 2) + Math.pow(((pointerPosition.top - mainChartArea.top) - dY), 2) )
                    currentActiveDataPoints[componentId][identifier] = {
                        chartDate: d.x,
                        x: dX,
                        value: d.y,
                        y: dY,
                        distFromPointer: distFromPointer
                    }


                    //Find out if its the closest point

                    if (distFromPointer < maxDistanceForActivePoint && (nearestDataPoint === null || distFromPointer < nearestDataPoint.distFromPointer))  {
                        nearestDataPoint = {
                            componentId: componentId,
                            identifier: identifier,
                            ...currentActiveDataPoints[componentId][identifier]
                        }
                    }
                    
                    //console.log(componentId, identifier, d.location)
                }
            }
        }

        SetActiveDataPoints(currentActiveDataPoints)
        SetActiveDataPoint(nearestDataPoint)
    }
    const pointerNoLongerOverMainChart = () => {
        SetActiveDataPoints({})
        SetActiveDataPoint(null)
    }





        

    const chartLoop = () => {
        if (!chartRef.current)
            return

        const usingSingleDataset = false
        let activeDataSeriesUpdated = false

        if (haveAppInfo) {

            const [mainChartDeltaType, mainChartMinorDelta, mainChartMajorDelta] = getTimeDeltaType(mainChartVisibleRange.end - mainChartVisibleRange.start)

            //Make sure all y axes are properly loaded in
            //selectChartAxisTypeByIdentifier





            if (chartRef.current.dataSeries === undefined) {
                chartRef.current.dataSeries = {}
                //activeDataSeriesUpdated = true
            }



            for (const componentGroupName in componentToggles) {
                const componentGroup = componentToggles[componentGroupName]
                for (const dataType in componentGroup.dataTypes) {
                    const dataTypeInfo = componentGroup.dataTypes[dataType]
                    let identifier = dataTypeInfo.identifier
                    //console.log(bladeZoneComponents)

                    let foundZoneComponents = bladeZoneComponents.filter((c) => c.componentInfo.name === dataTypeInfo.componentName && (dataTypeInfo.zoneType === undefined || c.zoneType === dataTypeInfo.zoneType))
                    for (let zoneComponent of foundZoneComponents) {
                        let currentBladeAnalyticsData = bladeAnalyticsData[zoneComponent.bladeId]
                        if (currentBladeAnalyticsData !== undefined) {
                            if (componentGroup.selected && dataTypeInfo.selected) {
                                if (chartRef.current.activeYAxes[dataTypeInfo.yAxis] !== undefined) {

                                    const lineColor = dataTypeInfo.color.replace("rgb(", '').replace(")", '').split(',')

                                    // Validate that this series is added
                                    if (chartRef.current.dataSeries[zoneComponent.componentInfo.id] === undefined) {
                                        chartRef.current.dataSeries[zoneComponent.componentInfo.id] = {}
                                    }
                                    if (chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier] === undefined) {
                                        chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier] = {
                                            main: chartRef.current.mainChart.addLineSeries({
                                                dataPattern: { pattern: "ProgressiveX" },
                                                yAxis: chartRef.current.activeYAxes[dataTypeInfo.yAxis].main
                                            }).setStrokeStyle(new SolidLine({ thickness: 0.5, fillStyle: new SolidFill({ color: ColorRGBA(...lineColor) }) }))
                                                //}).setStrokeStyle(new SolidLine({ thickness: 0.5, color: ColorRGBA(lineColor)}))
                                                .setMouseInteractions(false)
                                                .setEffect(false)
                                                .setCursorInterpolationEnabled(false),
                                            overview: chartRef.current.overviewChart.addLineSeries({
                                                dataPattern: { pattern: "ProgressiveX" },
                                                yAxis: chartRef.current.activeYAxes[dataTypeInfo.yAxis].overview
                                            }).setStrokeStyle(new SolidLine({ thickness: 0.5, fillStyle: new SolidFill({ color: ColorRGBA(...lineColor) }) }))
                                                .setMouseInteractions(false)
                                                .setCursorInterpolationEnabled(false),
                                            mainVersion: -1,
                                            overviewVersion: -1,
                                            changedVersion: -1,
                                            lastLiveUpdateOn: new Date()
                                        }
                                    }

                                    let analyticsData = currentBladeAnalyticsData[zoneComponent.componentInfo.id]
                                    if (analyticsData !== undefined) {
                                        //console.log(verticalRack, analyticsData)
                                        if (dataTypeInfo.isEnergyData !== true) {
                                            if (analyticsData[mainDataRecordingTimePeriodType.id] !== undefined) {
                                                if (analyticsData[mainDataRecordingTimePeriodType.id].data[identifier] !== undefined) {
                                                    //console.log(analyticsData[mainDataRecordingTimePeriodType.id].data[identifier])
                                                    if (analyticsData[mainDataRecordingTimePeriodType.id].changedVersion > chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].mainVersion || chartRef.current.lastMainDataRecordingTimePeriodType != mainDataRecordingTimePeriodType) {
                                                        chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].mainVersion = analyticsData[mainDataRecordingTimePeriodType.id].changedVersion

                                                        let newData = []
                                                        if (analyticsData[mainDataRecordingTimePeriodType.id].data[identifier].length > 0) {
                                                            newData = [...analyticsData[mainDataRecordingTimePeriodType.id].data[identifier], {
                                                                x: (mainChartVisibleRange.end),
                                                                y: analyticsData[mainDataRecordingTimePeriodType.id].data[identifier][analyticsData[mainDataRecordingTimePeriodType.id].data[identifier].length - 1].y
                                                            }]
                                                        }
                                                        chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].main.clear().add(newData)

                                                        chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].lastLiveUpdateOn = new Date()
                                                    }


                                                }
                                            }

                                            if (analyticsData[overviewDataRecordingTimePeriodType.id] !== undefined) {
                                                if (analyticsData[overviewDataRecordingTimePeriodType.id].data[identifier] !== undefined) {
                                                    if (analyticsData[overviewDataRecordingTimePeriodType.id].changedVersion > chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overviewVersion) {
                                                        chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overviewVersion = analyticsData[overviewDataRecordingTimePeriodType.id].changedVersion
                                                        chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overview.clear().add(analyticsData[overviewDataRecordingTimePeriodType.id].data[identifier]);
                                                    }
                                                }
                                            }
                                        }




                                        if (analyticsData[mainDataRecordingTimePeriodType.id] !== undefined) {
                                            if (analyticsData[mainDataRecordingTimePeriodType.id].energyData[identifier] !== undefined) {
                                                if (analyticsData[mainDataRecordingTimePeriodType.id].changedEnergyVersion > chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].mainVersion || chartRef.current.lastMainDataRecordingTimePeriodType != mainDataRecordingTimePeriodType) {
                                                    chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].mainVersion = analyticsData[mainDataRecordingTimePeriodType.id].changedEnergyVersion
                                                    chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].main.clear().add(analyticsData[mainDataRecordingTimePeriodType.id].energyData[identifier]);
                                                }
                                            }
                                        }
                                        if (analyticsData[overviewDataRecordingTimePeriodType.id] !== undefined) {
                                            if (analyticsData[overviewDataRecordingTimePeriodType.id].energyData[identifier] !== undefined) {
                                                if (analyticsData[overviewDataRecordingTimePeriodType.id].changedEnergyVersion > chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overviewVersion) {
                                                    chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overviewVersion = analyticsData[overviewDataRecordingTimePeriodType.id].changedEnergyVersion
                                                    chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overview.clear().add(analyticsData[overviewDataRecordingTimePeriodType.id].energyData[identifier]);
                                                }
                                            }
                                        }
                                    }
                                }

                            } else {
                                // Validate that this series is removed
                                if (chartRef.current.dataSeries[zoneComponent.componentInfo.id] !== undefined) {
                                    if (chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier] !== undefined) {
                                        chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].main.dispose()
                                        chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier].overview.dispose()
                                        delete chartRef.current.dataSeries[zoneComponent.componentInfo.id][identifier]
                                    }
                                }
                            }
                        }
                    }
                }
            }



            chartRef.current.lastMainDataRecordingTimePeriodType = mainDataRecordingTimePeriodType
            chartRef.current.lastMainChartDeltaType = mainChartDeltaType
        }


        chartRef.current.lastRenderLoopCompletedOn = new Date().getTime()
    }





    /* Chart Update Animation */
    const chartAnimationFrameRef = React.useRef();
    React.useEffect(() => {
        /*if (chartRef.current) {
            const handleChartRender = () => {
                if (dataActive) {
                    chartLoop()
                }
                chartAnimationFrameRef.current = requestAnimationFrame(handleChartRender);
            }
            chartAnimationFrameRef.current = requestAnimationFrame(handleChartRender);
        }
        return () => {
            cancelAnimationFrame(chartAnimationFrameRef.current);
        }*/
       if (dataActive)  {
        chartLoop()
       }
    }, [chartRef, haveAppInfo, dataActive, componentToggles, isForceLive, chartReady, bladeZoneComponents, zoneConfigurationMap, bladeConfigurationMaps, bladeAnalyticsData, mainChartVisibleRange, overviewChartVisibleRange]);





    
    /* Live Animation */
    const liveChartAnimationFrameRef = React.useRef();
    React.useEffect(() => {
        if (dataActive && isForceLive && chartRef.current) {
            const handleLiveChartRender = () => {
                //console.log(mainChartVisibleRange)
                const mainChartVisibleRange = getMainChartVisibleRange()
                const overviewChartVisibleRange = chartRef.current.overviewChartDateAxis.getInterval()
                let newEndDate = new Date().getTime()// - chartOriginDate                
                //if (chartRef.current.pendingMainVisibleRangeUpdate) {
                    SetMainChartVisibleRangeInstantly({start: newEndDate - (mainChartVisibleRange.end - mainChartVisibleRange.start), end: newEndDate})
                //}
                SetOverviewChartVisibleRange({start: overviewChartVisibleRange.start + (newEndDate - overviewChartVisibleRange.end), end: newEndDate})
                
                liveChartAnimationFrameRef.current = requestAnimationFrame(handleLiveChartRender);
            }
            liveChartAnimationFrameRef.current = requestAnimationFrame(handleLiveChartRender);
        }
        return () => {
            cancelAnimationFrame(liveChartAnimationFrameRef.current);
        }
    }, [chartRef, dataActive, isForceLive, chartReady, chartOriginDate]);





    const [overviewCursor, SetOverviewCursor] = React.useState("default")





    //const lc = useContext(LCContext)
    React.useEffect(() => {
        if (chartRef.current)
            return


        const lc = createLightningChart({})
        if (!lc) {
            return
        }

        const mainChart = lc.ChartXY({
            container: mainChartAreaEle.current,
            theme: Themes.light,
        }).setMouseInteractionRectangleZoom(false)
            .setMouseInteractionRectangleFit(false)
            .setMouseInteractionWheelZoom(true)
            .setMouseInteractionPan(false)
            .setTitle("")
            .setPadding({ top: 0, left: 0, right: 0, bottom: 0 })
            .setCursorMode(undefined)
            

        let currentDate = new Date().getTime()

        mainChart.getDefaultAxisY()
            .setMouseInteractions(false)
            .setStrokeStyle(emptyLine)
            .setThickness(0)
            .setTickStrategy(AxisTickStrategies.Empty)

        const defaultMainChartInterval = { start: (currentDate - (1000 * 60 * 60 * 3)) - chartOriginDate, end: currentDate - chartOriginDate }
        const mainChartDateAxis = mainChart.getDefaultAxisX()
        mainChartDateAxis.setTickStrategy(
            AxisTickStrategies.DateTime,
            (tickStrategy) => tickStrategy.setDateOrigin(new Date(chartOriginDate)),
        )
            .setAnimationsEnabled(false)
            .setChartInteractionPanByDrag(false)
            .setChartInteractionZoomByWheel(true)
            .setNibInteractionScaleByWheeling(false)
            .setInterval({ start: defaultMainChartInterval.start, end: defaultMainChartInterval.end })
            .setScrollStrategy(undefined)
            .setTickStrategy(AxisTickStrategies.Empty)
            .setThickness(mainChartAxisHeight)

        mainChartDateAxis.onIntervalChange((axis, start, end) => {
            checkMainChartInterval(start + chartOriginDate, end + chartOriginDate)
        })
    



        let mainChartTimeTicks = []
        let lastMainChartInterval = { start: 0, end: 0 }
        let lastMainChartTickRange = { start: 0, end: 0 }
        mainChartTimeTicks = updateChartAxisTicks(mainChartDateAxis, mainChartTimeTicks, lastMainChartTickRange, defaultMainChartInterval.start + chartOriginDate, defaultMainChartInterval.end + chartOriginDate)

        const overviewChart = lc.ChartXY({
            container: "GrowZones-Charts-OverviewChart-" + + bladeZoneId,
            theme: mainChartTheme,
        }).setMouseInteractionRectangleZoom(false)
            .setMouseInteractionRectangleFit(false)
            .setMouseInteractionWheelZoom(false)
            .setMouseInteractions(false)
            .setTitle("")
            .setPadding({ top: 0, left: 0, right: 0, bottom: 0 })
            .setCursorMode(undefined)
            .setBackgroundFillStyle(new SolidFill({ color: ColorHEX("#FFFFFF") }))
            .setSeriesBackgroundFillStyle(new SolidFill({ color: ColorHEX("#FFFFFF") }))
            .setSeriesBackgroundStrokeStyle(new SolidLine({ thickness: 3, fillStyle: new SolidFill({ color: ColorHEX("#858585") }) }))

        overviewChart.getDefaultAxisY()
            .setMouseInteractions(false)
            .setStrokeStyle(emptyLine)
            .setThickness(0)
            .setTickStrategy(AxisTickStrategies.Empty)


        const defaultOverviewChartInterval = { start: (currentDate - (1000 * 60 * 60 * 24 * 7)) - chartOriginDate, end: currentDate - chartOriginDate }
        const overviewChartDateAxis = overviewChart.getDefaultAxisX()
            .setTickStrategy(
                AxisTickStrategies.DateTime,
                (tickStrategy) => tickStrategy.setDateOrigin(new Date(chartOriginDate)),
            )
            .setMouseInteractions(false)
            .setChartInteractionPanByDrag(false)
            .setChartInteractionZoomByWheel(false)
            .setNibInteractionScaleByWheeling(false)
            .setInterval({ start: defaultOverviewChartInterval.start, end: defaultOverviewChartInterval.end })
            .setScrollStrategy(undefined)
            .setStrokeStyle(emptyLine)
            .setTickStrategy(AxisTickStrategies.Empty)
            .setThickness(overviewChartAxisHeight)

        let overviewChartTimeTicks = []
        let lastOverviewChartInterval = { start: 0, end: 0 }
        let lastOverviewChartTickRange = { start: 0, end: 0 }
        overviewChartTimeTicks = updateOverviewChartAxisTicks(overviewChartDateAxis, overviewChartTimeTicks, lastOverviewChartTickRange, defaultOverviewChartInterval.start + chartOriginDate, defaultOverviewChartInterval.end + chartOriginDate)

        overviewChartDateAxis.onIntervalChange((axis, start, end) => {
            checkOverviewChartInterval(start + chartOriginDate, end + chartOriginDate)
        })





        chartRef.current = {
            mainChart,
            mainChartDateAxis,
            overviewChart,
            overviewChartDateAxis,
            dataSeries: {},
            mainChartTimeTicks,
            overviewChartTimeTicks,

            lastMousePosition: { x: 0, y: 0 },
            lastMainChartPointerOffset: { x: 0, y: 0 },
            lastOverviewChartPointerOffset: { x: 0, y: 0 },
            lastSelectedGrows: [],
            lastMainChartInterval,
            lastOverviewChartInterval,
            lastMainChartTickRange,
            lastOverviewChartTickRange,
            lastMainDataRecordingTimePeriodType: null,
            lastMainChartDeltaType: null,
            growPositionIndicator: {},
            /*verticalRackGroup : {},
            verticalRacks: [],*/
            activeYAxes: {},
            lastEnergySubSelection: { start: 0, end: 0 },
            energySubSelection: defaultMainChartInterval,
            energyTotals: {},
            energySubselectionActive: false,

            nutrientDosingBarsRequiresReset: true,
            nutrientDosingLargestVolume: 0,
            nutrientDosingBars: {},

            tempYAxisInterval: {},

            isForceLive: isForceLive,

            lastRenderLoopCompletedOn: 0,
            lastRequestLoopCompletedOn: 0
        }

        SetMainChartAreaPadding(chartRef.current.mainChart.getPadding())
        SetMainChartDateAxisThickness(chartRef.current.mainChartDateAxis.getThickness().max)

        SetOverviewChartAreaPadding(chartRef.current.overviewChart.getPadding())
        SetOverviewChartDateAxisThickness(chartRef.current.overviewChartDateAxis.getThickness().max)

        SetChartReady(true)

        return () => {
            /*mainChart.dispose()
            overviewChart.dispose()
            mainChartTimeTicks.filter(tick => {
                tick.dispose()
                return false
            })
            mainChartTimeTicks.filter(tick => { 
                tick.dispose()
                return false
            })
            lastMainChartTickRange = { start: 0, end: 0 }
            lastOverviewChartTickRange = { start: 0, end: 0 }
            //chartRef.current = undefined*/
        }
    }, [mainChartTheme])








    const [dataLegendExpandedState, SetDataLegendExpandedState] = React.useState(true)
    const [dataLegendRef, { width: dataLegendWidth }] = useMeasure()
    const [dataLegendExpandButtonRef, { width: dataLegendExpandButtonWidth }] = useMeasure()



    
  

    //useRenderingTrace("ZonesPage_ZoneAnalytics", [bladeZoneDisplayName, activeBladeUIDs, bladeIds, bladeZoneComponents, chartRef, dataActive, bladeZoneId, bladeZoneType, bladeConfigurationIds, bladeConfigurationMaps, zoneConfigurationMap, componentToggles, pointerOverMainChartPosition])
    //console.log("Rerender")
    return (<>
        {dataActive && 
            <MaintainBladeAnalyticsData 
                bladeComponents={activeBladeComponents}
                chartOriginDate={chartOriginDate} 
                mainRange={mainChartVisibleRange} 
                overviewRange={overviewChartVisibleRange} 
                mainDataRecordingTimePeriodType={mainDataRecordingTimePeriodType}
                overviewDataRecordingTimePeriodType={overviewDataRecordingTimePeriodType}/>
        }
        <div className="FlexContent-10 FlexContent-HFlex" style={{ overflowX: "hidden" }}>
            <div className="FlexContent-H-10 FlexContent-HFill">
                <div className="GrowZones-DataAnalytics-DataLegend-Wrapper">
                    <div className="GrowZones-DataAnalytics-DataLegend-Container"
                        style={{ marginRight: dataLegendExpandedState ? 5 : 0, height: chartsAreaHeight, width: dataLegendExpandedState ? dataLegendWidth : 0 }}>
                        <div className="GrowZones-DataAnalytics-DataLegend" ref={dataLegendRef}>
                            <div className="GrowZones-DataAnalytics-DataLegend-Content">
                                <div className="GrowZones-DataAnalytics-DataLegend-Header">
                                    <div className="GrowZones-DataAnalytics-DataLegend-Header-Title">
                                        Data Legend
                                    </div>
                                    <div className="GrowZones-DataAnalytics-DataLegend-Header-Toggle" onClick={() => SetDataLegendExpandedState(!dataLegendExpandedState)}>
                                        <Close />
                                    </div>
                                </div>
                                <div className="GrowZones-DataAnalytics-DataLegend-DataToggles-Container">
                                    <div className="GrowZones-DataAnalytics-DataLegend-DataToggles">
                                        {Object.entries(componentToggles).map(([key, info]) => {
                                            return <ComponentToggle key={key} name={key} info={info} componentExpandedStateToggled={componentExpandedStateToggled} componentToggled={componentToggled} componentDataTypeToggled={componentDataTypeToggled} />
                                        })}
                                    </div>
                                </div>
                                <Button status={"Primary-Toggle"} state={isForceLive} onClick={() => { SetIsForceLive(!isForceLive) }} content={<>
                                    <div className="FlexContent-H-10 FlexContent-Center"><span>Live Mode</span><div style={{ width: 13, height: 13, borderRadius: 13, backgroundColor: isForceLive ? "#4DBE3B" : "#DDE3EB" }}></div></div>
                                </>} />
                            </div>
                        </div>
                    </div>
                    <div className="GrowZones-DataAnalytics-DataLegend-ExpandToggleContainer"
                        style={{ width: !dataLegendExpandedState ? dataLegendExpandButtonWidth : 0 }}
                        onClick={() => SetDataLegendExpandedState(!dataLegendExpandedState)}>
                        <div className="GrowZones-DataAnalytics-DataLegend-ExpandToggle" ref={dataLegendExpandButtonRef}>
                            <div className="GrowZones-DataAnalytics-DataLegend-ExpandToggle-Content">
                                <div className="GrowZones-DataAnalytics-DataLegend-ExpandToggle-Button">
                                    <ExpandContentAlt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="GrowZones-DataAnalytics-ChartingArea-Container" ref={chartsAreaRef}>

                    <div className="GrowZones-DataAnalytics-ChartingArea">

                        <div className="GrowZones-Charts" ref={chartsContainerRef}>
                            <div className="GrowZones-Charts-ChartingArea noselect"
                                ref={chartingAreaRef}>
                                <div className="GrowZones-Charts-MainChartingArea">
                                    <div className="GrowZones-Charts-MainChartingWrapper" ref={mainChartContainerRef}>
                                        <div className="GrowZones-Charts-MainChartingContent"
                                            style={{ height: isLargeDesktop ? 500 : isDesktop ? 350 : isTablet ? 250 : 200 }}>
                                            <div 
                                                ref={mainChartAreaRef} 
                                                className="GrowZones-Charts-MainChart"
                                                onPointerDown={mainChartPointerDown}
                                                onPointerMove={mainChartPointerMove}
                                                onPointerUp={mainChartPointerUp}
                                                onPointerLeave={mainChartPointerLeave}></div>
                                            <div className="GrowZones-Charts-MainCharting-OverlayContainer">
                                                <ChartActivePointCrosshair
                                                    calculatePositionFromDate={mainChartConvertDateToPosition}
                                                    chartAreaWidth={mainChartArea.width}
                                                    chartAreaHeight={mainChartArea.height}
                                                    activeDateTime={activePointerDatetime}
                                                    pointerPosition={lastMainChartingAreaPointerLocationWithinChart}
                                                    nearestActiveDataPoint={activeDataPoint}/>
                                                <ChartDataLegendTooltip
                                                    isTouchDevice={isTouchDevice}
                                                    isPanningChart={isPanningMainChart}
                                                    calculatePositionFromDate={mainChartConvertDateToPosition}
                                                    dataToggles={componentToggles}
                                                    chartArea={mainChartArea}
                                                    activeDataPoints={activeDataPoints} 
                                                    activeDateTime={activePointerDatetime}
                                                    pointerPosition={lastMainChartingAreaPointerLocationWithinChart}
                                                    nearestActiveDataPoint={activeDataPoint}/>
                                                <ChartActiveDateTimeTooltip
                                                    calculatePositionFromDate={mainChartConvertDateToPosition}
                                                    activeDateTime={activePointerDatetime}
                                                    nearestActiveDataPoint={activeDataPoint}
                                                    chartAreaWidth={mainChartArea.width}
                                                    chartAreaHeight={mainChartArea.height}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="GrowZones-Charts-OverviewChartingArea"
                                    ref={overviewChartContainerRef}>
                                    <div className="GrowZones-Charts-OverviewChartingWrapper">
                                        <div className="GrowZones-Charts-OverviewChartingContent"
                                            style={{ height: isLargeDesktop ? 100 : isDesktop ? 60 : isTablet ? 40 : 40, marginBottom: -overviewChartAxisHeight }}>
                                            <div ref={overviewChartAreaRef} id={"GrowZones-Charts-OverviewChart-" + bladeZoneId}
                                                className="GrowZones-Charts-OverviewChart"
                                                style={{ cursor: overviewCursor }}></div>
                                            
                                            <OverviewChartMainChartSubselection 
                                                isForceLive={isForceLive}
                                                mainChartArea={mainChartArea}
                                                overviewChartArea={overviewChartArea}
                                                calculatePositionFromDate={overviewChartConvertDateToPosition}
                                                calculateDateFromPosition={overviewChartConvertPositionToDate}
                                                getMainChartVisibleRange={getMainChartVisibleRange}
                                                updateMainChartVisibleRange={SetMainChartVisibleRangeInstantly}/>
                                            

                                        </div>
                                        <div className="GrowZones-Charts-OverviewCharting-DateSelectors" style={{ minHeight: overviewChartAxisHeight }}>
                                            <div className="GrowZones-Charts-OverviewCharting-DateSelector">
                                                {FormatDate(new Date(overviewChartVisibleRange.start), "MM/dd/yy")}
                                            </div>
                                            <div className="GrowZones-Charts-OverviewCharting-DateSelector">
                                                {FormatDate(new Date(overviewChartVisibleRange.end), "MM/dd/yy")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </>)
}



const ComponentToggle = ({ name, info, componentExpandedStateToggled, componentToggled, componentDataTypeToggled }) => {

    /* const onOptionChange = (option, selected) =>  {
         if (info.dataTypes[option.id] !== undefined)    {
             if (onDataTypeToggle !== undefined) {
                 //onGroupToggle(name, true)
                 onDataTypeToggle(info.dataTypes[option.id], selected)
             }
             //info.dataTypes[option.id].selected = selected
             //console.log(info.dataTypes[option.id])
         }
     }*/


    const [dataItemsContainerRef, { height: dataItemsContainerHeight }] = useMeasure()


    const dataItemsContentProps = { style: {} }
    if (!info.selected) {
        dataItemsContentProps.style.opacity = 0.4
        dataItemsContentProps.style.pointerEvents = "none"
    }

    return (
        <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-Container">
            <div className={"GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle" + (info.expanded ? " GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Expanded" : "")}>
                <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Info" onClick={() => componentExpandedStateToggled(name, !info.expanded)}>
                    <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Info-ExpandToggle">
                        {!info.expanded && <CollapseContent />}
                        {info.expanded && <ExpandContent />}
                    </div>
                    <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Info-Key noselect">
                        <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Info-Key-Title">{info.label}</div>
                    </div>
                </div>
                <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-ToggleGroupActiveButton">
                    <Switch state={info.selected} onSwitch={(state) => componentToggled(name, state)} />
                </div>
            </div>

            <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-DataItems-Container" style={{ height: info.expanded ? dataItemsContainerHeight : 0 }}>
                <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-DataItems" ref={dataItemsContainerRef}>
                    <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-DataItems-Content" {...dataItemsContentProps}>
                        {Object.entries(info.dataTypes).map(([key, dataTypeInfo]) => {
                            return (
                                <div key={key}
                                    className="GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle">
                                    <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle-Info">
                                        <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle-Info-Key noselect">
                                            <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle-Info-Key-Color"
                                                style={{ backgroundColor: dataTypeInfo.color }}></div>
                                            <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle-Info-Key-Title" style={{ opacity: dataTypeInfo.selected ? 1.0 : 0.4 }}>{dataTypeInfo.label}</div>
                                        </div>
                                    </div>
                                    <div className="GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle-ToggleGroupActiveButton">
                                        <Switch state={dataTypeInfo.selected} onSwitch={(state) => componentDataTypeToggled(name, key, state)} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}




const ChartActivePointCrosshair = ({calculatePositionFromDate, chartAreaHeight, chartAreaWidth, pointerPosition, activeDateTime, nearestActiveDataPoint}) => {
    if (nearestActiveDataPoint !== null || pointerPosition !== null)    {
        const horizontalLineProps = {style:{
            width: chartAreaWidth
        }}
        const verticalLineProps = {style:{
            height: chartAreaHeight
        }}
        if (nearestActiveDataPoint !== null)    {
            horizontalLineProps.style.top = nearestActiveDataPoint.y
            verticalLineProps.style.left = nearestActiveDataPoint.x
        }else {
            horizontalLineProps.style.top = pointerPosition.top
            verticalLineProps.style.left = pointerPosition.left
        }

        return (<>
            <div className="GrowZones-Charts-MainCharting-ActivePointCrosshair-VerticalLine" {...verticalLineProps}></div>
            <div className="GrowZones-Charts-MainCharting-ActivePointCrosshair-HorizontalLine" {...horizontalLineProps}></div>
        </>)
    }
}

const ChartDataLegendTooltip = ({
        isTouchDevice, isPanningChart, pointerPosition,
        chartArea, calculatePositionFromDate, 
        dataToggles, activeDateTime, nearestActiveDataPoint, activeDataPoints }) => {

    const [tooltipRef, { width: tooltipWidth, height: tooltipHeight }] = useMeasure()


    const tooltipSpacing = isTouchDevice ? 15 : 5;

    const tooltipContainerProps = {style:{}}
    const tooltipContentProps = {style:{}}
    if ((!isPanningChart || !isTouchDevice) && (nearestActiveDataPoint !== null || pointerPosition !== null))    {
        if (nearestActiveDataPoint !== null)    {
            tooltipContainerProps.style.left = nearestActiveDataPoint.x + tooltipSpacing
            tooltipContainerProps.style.top = nearestActiveDataPoint.y + tooltipSpacing
        }else {
            //const position = calculatePositionFromDate(activeDateTime)
            tooltipContainerProps.style.left = pointerPosition.left + tooltipSpacing
            tooltipContainerProps.style.top = pointerPosition.top + tooltipSpacing    
        }


        if (tooltipContainerProps.style.left + tooltipWidth + tooltipSpacing > chartArea.width) {
            tooltipContentProps.style.left = -tooltipWidth - tooltipSpacing
        }
        if (tooltipContainerProps.style.left + tooltipContentProps.style.left < 0)   {
            tooltipContainerProps.style.left = 0
            tooltipContentProps.style.left = 0
        }
        
        if (tooltipContainerProps.style.top + tooltipHeight + tooltipSpacing > chartArea.height) {
            tooltipContentProps.style.top = -tooltipHeight - tooltipSpacing
        }    
        if (tooltipContainerProps.style.top + tooltipContentProps.style.top < 0)    {
            tooltipContainerProps.style.top = 0
            tooltipContentProps.style.top = 0
        }
    }else {
        tooltipContainerProps.style.visibility = "hidden"        
    }





    //console.log(nearestActiveDataPoint)
    return (
        <div className="GrowZones-Charts-MainCharting-Tooltip-Wrapper"  {...tooltipContainerProps}>
            <div className="GrowZones-Charts-MainCharting-Tooltip-Container">
                <div className="GrowZones-Charts-MainCharting-Tooltip-Content" ref={tooltipRef} {...tooltipContentProps}>
                    {Object.entries(dataToggles).map(([key, info]) => {
                        if (info.selected) {
                            return (<>
                                <div className="FlexContent" style={{gap:2}}>
                                    <div className="FlexContent FlexContent-Center">
                                        <div className="Text-Medium-S10">{info.label}</div>
                                    </div>
                                    <div className="FlexContent" style={{gap:2}}>
                                        {Object.entries(info.dataTypes).map(([dataTypeKey, dataTypeInfo]) => {
                                            if (dataTypeInfo.selected) {
                                                let isDataPointActive = false

                                                let foundDatapoint = null
                                                if (dataTypeInfo.zoneComponent !== undefined && dataTypeInfo.zoneComponent !== null)    {
                                                    if (nearestActiveDataPoint !== null && nearestActiveDataPoint.componentId === dataTypeInfo.zoneComponent.componentInfo.id && nearestActiveDataPoint.identifier === dataTypeInfo.identifier) {
                                                        isDataPointActive = true
                                                    }
    
                                                    const componentDataPoints = activeDataPoints[dataTypeInfo.zoneComponent.componentInfo.id]
                                                    if (componentDataPoints !== undefined && componentDataPoints[dataTypeInfo.identifier] !== undefined)  {
                                                        foundDatapoint = componentDataPoints[dataTypeInfo.identifier]
                                                    }
                                                }
                                                const pointValue = foundDatapoint !== null ? RoundToNearest(foundDatapoint.value, dataTypeInfo.resolution).toString() + dataTypeInfo.unit : "N/A"
                                                return (<>
                                                    <div className={"GrowZones-Charts-MainCharting-Tooltip-DataItem" + (isDataPointActive ? " GrowZones-Charts-MainCharting-Tooltip-DataItem-Active" : "")}>
                                                        <div className="GrowZones-Charts-MainCharting-Tooltip-DataColorIndicator" style={{backgroundColor:dataTypeInfo.color}}></div>
                                                        <div className="GrowZones-Charts-MainCharting-Tooltip-DataItem-Label Text-Medium-S12" style={{flex:1}}>
                                                            {/*<div className="GrowZones-Charts-MainCharting-Tooltip-DataItem-ActiveDataItemIndicator"></div>*/}
                                                            <div>{dataTypeInfo.label}</div>
                                                        </div>
                                                        <div className="GrowZones-Charts-MainCharting-Tooltip-DataItem-Value Text-Medium-S12">{pointValue}</div>
                                                    </div>
                                                </>)
                                            }
                                        })}
                                    </div>
                                </div>
                            </>)
                        }
                    })}
                </div>
            </div>
        </div>
    )
}




const ChartActiveDateTimeTooltip = ({calculatePositionFromDate, nearestActiveDataPoint, activeDateTime, chartAreaWidth, chartAreaHeight}) => {
    const [tooltipRef, { width: tooltipWidth }] = useMeasure()
    const tooltipProps = {style:{
        top: chartAreaHeight
    }}


    let dateDisplayA = "00/00/00"
    let dateDisplayB = "00:00:00"
    if (nearestActiveDataPoint !== null || activeDateTime !== null) {
        let date
        if (nearestActiveDataPoint !== null)    {
            date = new Date(nearestActiveDataPoint.chartDate)
        }else {
            date = new Date(activeDateTime)
        }
        dateDisplayA = FormatDate(date, "MM/dd/yy")
        dateDisplayB = FormatDate(date, "HH:mm:ss")

        const position = calculatePositionFromDate(date)
        let chartPositionX = position
        if (position - (tooltipWidth / 2) < 0) {
            chartPositionX = (tooltipWidth / 2)
        }
        if (position + (tooltipWidth / 2) > chartAreaWidth) {
            chartPositionX = chartAreaWidth - (tooltipWidth / 2)
        }
        tooltipProps.style.left = chartPositionX
    }else {
        tooltipProps.style.visibility = "hidden"
    }


    return (<>
        <div className="GrowZones-Charts-MainCharting-ActiveTimeLabel" ref={tooltipRef} {...tooltipProps}>
            <div className="GrowZones-Charts-MainCharting-ActiveTimeLabel-Content">
                <span>{dateDisplayA}</span>
                <span>{dateDisplayB}</span>
            </div>
        </div>
    </>)
}



const OverviewChartMainChartSubselection = ({
        isForceLive, 
        calculatePositionFromDate, calculateDateFromPosition, overviewChartArea,
        getMainChartVisibleRange, mainChartArea,
        updateMainChartVisibleRange
    }) => {
    
    const mainChartVisibleRange = getMainChartVisibleRange()

    const subselectionRef = React.useRef(null)

    const [draggingPointerId, SetDraggingPointerId] = React.useState(null)
    const [isDragging, SetIsDragging] = React.useState(null)
    const [draggingOriginalDates, SetDraggingOriginalDates] = React.useState(null)
    const [draggingOriginalPosition, SetDraggingOriginalPosition] = React.useState({x: 0, y: 0})
    const beginDragStart = (e) => {
        e.stopPropagation()
        
        SetIsDragging("start")
        SetDraggingOriginalPosition({top: e.clientY, left: e.clientX})
        if (subselectionRef.current !== undefined && subselectionRef.current.setPointerCapture) {
            SetDraggingPointerId(e.pointerId)
            subselectionRef.current.setPointerCapture(e.pointerId);
        }
    }


    const beginDragEnd = (e) => {
        e.stopPropagation()

        SetIsDragging("end")
        SetDraggingOriginalPosition({top: e.clientY, left: e.clientX})
        if (subselectionRef.current !== undefined && subselectionRef.current.setPointerCapture) {
            SetDraggingPointerId(e.pointerId)
            subselectionRef.current.setPointerCapture(e.pointerId);
        }        
    }

    const beginDragFull = (e) => {
        if (isForceLive)
            return
        e.stopPropagation()

        SetIsDragging("full")
        SetDraggingOriginalPosition({top: e.clientY, left: e.clientX})
        SetDraggingOriginalDates(mainChartVisibleRange)
        if (subselectionRef.current !== undefined && subselectionRef.current.setPointerCapture) {
            SetDraggingPointerId(e.pointerId)
            subselectionRef.current.setPointerCapture(e.pointerId);
        }
        
    }


    const subselectionPointerMove = (e) => {
        const pointerPosition = {top: e.clientY, left: e.clientX}
        if (isDragging === "start")  {
            let newStart = calculateDateFromPosition(pointerPosition.left - mainChartArea.left)
            if (newStart > mainChartVisibleRange.end)   {
                newStart = mainChartVisibleRange.end - 1000 * 60
            }
            updateMainChartVisibleRange({start: newStart, end: mainChartVisibleRange.end})
        }else if (isDragging === "end")  {
            let newEnd = calculateDateFromPosition(pointerPosition.left - mainChartArea.left)
            if (newEnd < mainChartVisibleRange.start)   {
                newEnd = mainChartVisibleRange.start + 1000 * 60
            }
            updateMainChartVisibleRange({start: mainChartVisibleRange.start, end: newEnd})
        }else if (isDragging === "full")  {
            const offsetPosition = {top: pointerPosition.top - draggingOriginalPosition.top, left: pointerPosition.left - draggingOriginalPosition.left}

            const existingStartDatePosition = calculatePositionFromDate(draggingOriginalDates.start)
            const existingEndDatePosition = calculatePositionFromDate(draggingOriginalDates.end)
            const existingDateDelta = draggingOriginalDates.end - draggingOriginalDates.start

            if (existingStartDatePosition + offsetPosition.left < 0)    {
                const newStartDate = calculateDateFromPosition(0)
                updateMainChartVisibleRange({start: newStartDate, end: newStartDate + existingDateDelta})
            }else if (existingEndDatePosition + offsetPosition.left > mainChartArea.width)   {
                const newEndDate = calculateDateFromPosition(mainChartArea.width)
                updateMainChartVisibleRange({start: newEndDate - existingDateDelta, end: newEndDate})
            }else {
                updateMainChartVisibleRange({start: calculateDateFromPosition(existingStartDatePosition + offsetPosition.left), end: calculateDateFromPosition(existingEndDatePosition + offsetPosition.left)})
            }
        }
    }
    const subselectionPointerUp = () => {
        if (isDragging) {
            SetIsDragging(null)
            if (subselectionRef.current !== undefined && subselectionRef.current.releasePointerCapture && subselectionRef !== null) {
                subselectionRef.current.releasePointerCapture(draggingPointerId);
            }
        }
    }



    const posX1 = calculatePositionFromDate(mainChartVisibleRange.start)
    const posX2 = calculatePositionFromDate(mainChartVisibleRange.end)

    let contentProps = {style:{
        left: posX1 + 1,
        width: posX2 - posX1 - 2,
        height: overviewChartArea.height
    }} 
    let preStartOverlayProps = {style:{
        right: posX1,
        width: posX1,
        height: overviewChartArea.height
    }}
    let postEndOverlayProps = {style:{
        left: posX2,
        width: overviewChartArea.width - posX2,
        height: overviewChartArea.height
    }}


    //const draggersInside = posX2 - posX1 > 20

    return (<>
        <div className="GrowZones-Charts-OverviewChart-Subselection-Wrapper" 
            ref={subselectionRef}
            onPointerMove={subselectionPointerMove}
            onPointerUp={subselectionPointerUp}>

            <div className="GrowZones-Charts-OverviewChart-Subselection-Container">
                <div className="GrowZones-Charts-OverviewChart-Subselection-PreStartOverlay" {...preStartOverlayProps}></div>            
                    <div className={"GrowZones-Charts-OverviewChart-Subselection-Content" + (!isForceLive ? " GrowZones-Charts-OverviewChart-Subselection-Content-Draggable" : "")}
                        onPointerDown={beginDragFull}
                        {...contentProps}>
                        <div className="GrowZones-Charts-OverviewChart-Subselection-LeftGrabber"
                            onPointerDown={beginDragStart}>
                            <div></div>
                        </div>
                        {!isForceLive && 
                            <div className="GrowZones-Charts-OverviewChart-Subselection-RightGrabber"
                                onPointerDown={beginDragEnd}>
                                <div></div>
                            </div>
                        }
                    </div>
                {!isForceLive &&
                    <div className="GrowZones-Charts-OverviewChart-Subselection-PostEndOverlay" {...postEndOverlayProps}></div>                
                }
            </div>
        </div>
        
    </>)

}



export default GrowZone_DataAnalytics