import './HarvestTask.scss';
import React from 'react';

import _ from 'underscore';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllBladeZones, selectBladeIdsForBladeZoneUID, selectBladeZoneTypeByUID, setBladeRuntimeProperty, setBladeZoneRuntimeProperty } from '../../../../redux/entities/service/Blade';
import { SoftWarning } from '../../../../assets/icons/Icons';


const HarvestTask_PrepareForHarvestWalkthroughStep = ({
        activeGrow,
        activeGrowPlan,
        allBladeZones,
        harvestingZoneUID,
        cancelTaskCallback,
        containerContentsConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const dispatch = useDispatch()
    const bladeZoneType = useSelector((state) => selectBladeZoneTypeByUID(state, harvestingZoneUID), _.isEqual)
    const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, harvestingZoneUID), _.isEqual)


    const backClicked = () => {
        transitionToPreviousStep(["zone_list"], null)
    }


    const [applyingHarvestState, SetApplyingHarvestState] = React.useState(false)
    const [pendingApplyHarvestStateToBlades, SetPendingApplyHarvestStateToBlades] = React.useState(false)
    const [wasHarvestStateApplySuccessful, SetWasHarvestStateApplySuccessful] = React.useState(null)
    const [bladeFeaturesToPause, SetBladeFeaturesToPause] = React.useState([])
    const continueClicked = () => {
        SetApplyingHarvestState(true)
        const resumeAt = Math.floor((new Date().getTime() / 1000) + (60 * 60 * 24)) //Default to 1 day to guarentee enough time
        dispatch(setBladeZoneRuntimeProperty({
            bladeZoneUID: harvestingZoneUID,
            properties: { "resume_controls_on": resumeAt.toString() },
            callback: (success) => {
              if (success)  {
                SetPendingApplyHarvestStateToBlades(true)
                if (bladeZoneType === "nursery")    {
                    SetBladeFeaturesToPause([
                        {bladeId: bladeIds["nursery"], features: { "air_control_status": "pause", "lights_control_status": "pause", "aux_a_liquid_control_status": "pause", "aux_b_liquid_control_status": "pause" } }
                    ])
                }else {
                    SetBladeFeaturesToPause([
                        {bladeId: bladeIds["grow"], features: { "primary_liquid_control_status": "pause"} },
                        {bladeId: bladeIds["environment"], features: {"air_control_status": "pause", "lights_control_status": "pause"} }
                    ])
                }                    
              }else {
    
              }
            }
        }))
    }

    const settingBladeRuntimePropertyStatus = useSelector((state) => state.blade.settingBladeRuntimeProperty)
    React.useLayoutEffect(() => {
        if (pendingApplyHarvestStateToBlades)   {
            if (bladeFeaturesToPause.length > 0 && settingBladeRuntimePropertyStatus !== "pending") {
                dispatch(setBladeRuntimeProperty({
                    bladeId: bladeFeaturesToPause[0].bladeId,
                    properties: bladeFeaturesToPause[0].features,
                    callback: (success) => {
                        bladeFeaturesToPause.shift()
                        SetBladeFeaturesToPause([...bladeFeaturesToPause])
                    }
                }))
            }else if (bladeFeaturesToPause.length === 0)   {
                SetWasHarvestStateApplySuccessful(true)
                SetPendingApplyHarvestStateToBlades(false)
                //SetApplyingHarvestState(false)
            }
        }
    }, [pendingApplyHarvestStateToBlades, bladeFeaturesToPause, settingBladeRuntimePropertyStatus])


    React.useLayoutEffect(() => {
        if (wasHarvestStateApplySuccessful === true)    {
            if (activeGrowPlan.plant_variants !== undefined && activeGrowPlan.plant_variants.length > 1)    {
                transitionToNextStep(["container_contents", null])
            }else {
                containerContentsConfirmedCallback({containerContents: [{id: activeGrowPlan.final_product_id, ratio: 100}]})
                transitionToNextStep(["container_weight", null])
            }
        }
    }, [wasHarvestStateApplySuccessful])

    let activeBladeZone = allBladeZones.find((bZ) => bZ.uid === harvestingZoneUID)

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Prepare to harvest {activeBladeZone !== undefined && activeBladeZone.display_name}
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"} style={{flex:1}}>
                    <div className={"FlexContent-30 FlexContent-Center"} style={{padding: isMobile ? "0px 10px" : "0px 100px"}}>
                        <div className="FlexContent-H-10 Linebreak-30">
                            <SoftWarning fill={"#EC3C3C"}/>
                            <div className="Text-Medium-S16 Text-Wrap">The zone will be paused for the duration of the harvest meaning:</div>
                        </div>
                        <div className="FlexContent-30" style={{alignSelf:"stretch"}}>
                            <div className="Text-S16">Lights will turn to <span style={{fontStyle:"italic"}}>Nice Light</span></div>
                            <div className="Text-S16">Air conditioning will pause</div>
                            {activeBladeZone !== undefined && <div className="Text-S16">
                                {activeBladeZone.zone_type === "grow_boards" && "Aeroponics"}
                                {activeBladeZone.zone_type === "berry_troughs" && "Drip Irrigation"}
                                &nbsp;will be disabled
                            </div>}
                        </div>
                    </div>                    
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Continue"} onClick={continueClicked} size={"Flex50"} disabled={applyingHarvestState}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_PrepareForHarvestWalkthroughStep