import '../FlowsPage.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore'; 
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button'; 
import { AdditionalOptions, Pause, Play, TrendDownArrow, TrendUpArrow } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { selectFlowComponentInfo, selectFlowLiveDataByComponentInfo } from '../../../../redux/entities/service/Flow';


const FlowInstance_StatusContent_ECSensorCell = ({ flowId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  
  const [leftSensorECComponentInfo, rightSensorECComponentInfo, activeECComponentInfo] 
  = useSelector((state) => selectFlowComponentInfo(state, flowId, "LiquidECSensor", ["input-1", "input-2", "ec"]), _.isEqual)
  const leftSensorEC = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, leftSensorECComponentInfo), _.isEqual)
  const rightSensorEC = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, rightSensorECComponentInfo), _.isEqual)
  const activeEC = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, activeECComponentInfo), _.isEqual)
  
  let dataListItems = [
    {label: "Active EC", value: activeEC.value, suffix: "μS/cm", visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 5000, status: "normal"}
    }},  
    {label: "Left EC", value: leftSensorEC.value, suffix: "μS/cm", visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 5000, status: "normal"}
    }},  
    {label: "Right EC", value: rightSensorEC.value, suffix: "μS/cm", visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 5000, status: "normal"}
    }},  
  ]

  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">EC Sensor</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} /> 
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default FlowInstance_StatusContent_ECSensorCell