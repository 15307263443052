import './GrowManagerPage.scss';
import variables from '../../globals.scss';
import _ from 'underscore';
 
import React from 'react'

import { selectAllGrows, selectAllGrowGroups, scheduleNewGrow, scheduleNewGrowGroup, selectAllGrowIds, selectGrowLastLiveDataLoadedOn, MaintainGrowLiveData, MaintainLoadAllGrows, selectAllGrowPlanIdsForGrows, selectAllRecipeInfoForGrows, selectAllGrowIdsCategorized, selectGrowById, selectAllGrowIdsSortedByScheduledOn, selectAllGrowIdsCategorizedAndSortedByScheduledOn, selectAllGrowIdsCategorizedAndSortedByDate} from '../../redux/entities/Grow'
import { getGrowPlanById, InitialLoadAllGrowPlans, selectAllGrowPlans} from '../../redux/entities/GrowPlans'
import { selectAllRecipes, getRecipeById, GetCurrentGrowZoneCycle, GetCLIForCycle, MaintainRecipeVersions } from '../../redux/entities/Recipes';
import { useSelector, useDispatch } from 'react-redux'

import { animated, useSpring } from 'react-spring'
import {  useRenderingTrace } from '../../helpers'
import { Routes, Route, Navigate } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';
import {GanttChart} from '../../components/GanttChart';

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import Button from '../../components/Button.js'
import {BsPinFill, BsPin, BsChevronDown, BsClockHistory, BsAlignStart, BsAlignEnd} from 'react-icons/bs'

import { FormatDate } from '../../helpers';

import 'react-circular-progressbar/dist/styles.css';
import { RecipeTimeline } from '../RecipesAndPlans/Recipes/RecipeTimeline';
import CircularGauge from '../../components/CircularGauge';

import RowGroupRow from '../../components/RowGroup/RowGroupRow';
import RowGroup from '../../components/RowGroup/RowGroup';
import { useMediaQuery } from 'react-responsive';
import ControlBar from '../../components/ControlBar';
import RowGroupSubGroup from '../../components/RowGroup/RowGroupSubGroup';
import { SearchIcon } from '../../assets/icons/Icons.js';
import ScheduleGrowPopup from './ScheduleGrowPlanWalkthroughSteps/ScheduleGrowPopup.js';
import GrowStatus_InfoContent from './GrowStatus/GrowStatus_InfoContent';
import GrowStatus_PerformanceContent from './GrowStatus/GrowStatus_PerformanceContent';
import GrowStatus_GrowProgress from './GrowStatus/GrowStatus_GrowProgress.js';
import { getAllBladeGroupsByFacilityId, getBladeZoneByUID, InitialLoadAllBladeZones, MaintainBladeZoneActiveRecipe, selectAllBladeZones } from '../../redux/entities/service/Blade.js';
import { selectAllFacilityIds, selectAllFarmFacilities } from '../../redux/entities/Facilities.js';
import { IsSessionActive } from '../Account/Common.js';
import GrowStatusList_Instance from './GrowStatusList_Instance.js';







const GrowStatusList = ({pageHeaderHelper, selectedFacility}) => { 
  
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  const dispatch = useDispatch()


  const allFacilityIds = useSelector(selectAllFacilityIds, _.isEqual)
  const activeFacilityIds = selectedFacility === "all" ? allFacilityIds : [selectedFacility]
  
  const activeGrowIds = useSelector(selectAllGrowIds, _.isEqual)
  const activeGrowPlanIds = useSelector((state) => selectAllGrowPlanIdsForGrows(state, activeGrowIds), _.isEqual)
  const activeGrowRecipesInfo = useSelector((state) => selectAllRecipeInfoForGrows(state, activeGrowIds), _.isEqual)
  const categorizedGrowIds = useSelector(selectAllGrowIdsCategorizedAndSortedByDate, _.isEqual)



  const [allGrowsList, SetGrowsList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")



  

  const [schedulingGrow, SetSchedulingGrow] = React.useState(false)
  const closeSchedulingGrowPopup = () =>  {
    SetSchedulingGrow(false)
  }
  const schedulingGrowFinalized = (finalizedGrow) =>  {
    dispatch(scheduleNewGrow({grow: finalizedGrow}))
    SetSchedulingGrow(false)
  }
  


  const columnHeaders = {
    status: {label: <>Name</>, setPosition: (bounds, contentBounds) => {return {left: bounds.left}}},
    grow_status: {label: <>Status</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
    timeline: {label: <>Timeline</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
    score: {label: <>Score</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
  }

  const [columnSizes, SetColumnSizes] = React.useState({})
  return (<>
    <MaintainLoadAllGrows facilityIds={activeFacilityIds}/>
    <MaintainGrowLiveData growIds={activeGrowIds}/>
    <InitialLoadAllGrowPlans growPlanIds={activeGrowPlanIds}/>
    <MaintainRecipeVersions activeRecipes={activeGrowRecipesInfo}/>
    <InitialLoadAllBladeZones facilityIds={activeFacilityIds}/>
    <ControlBar controls={<>
        <TextInput placeHolder="Search" icon={<SearchIcon/>}/>
        <DropDownInput status="AltNeutral" options={[{ value: 'all', label: 'All Categories' }]} defaultValue="all"/>
        <DropDownInput status="AltNeutral" options={[{ value: 'all', label: 'All Years' }]} defaultValue="all"/>
        <DropDownInput status="AltNeutral" options={[{ value: 'grouped_by_crop_name', label: 'Grouped By Crop Name' }]} defaultValue="grouped_by_crop_name"/>
        <DropDownInput status="AltNeutral" options={[{ value: 'name', label: 'Name' }]} defaultValue="name" prefix="Sort By"/>
      </>} secondaryControls={<>
        <Button content="Start Grow" onClick={() => {SetSchedulingGrow(true)}} disabled={selectedFacility==="all"}/>
      </>}
    />
    
    <RowGroup
      title={""}
      hasGroupHeader={false}
      hasSettings={false}
      isExpandable={false}
      itemsScroll={true}
      hasContainer={false}
      customContentPadding={isMobile ? 0 : 20}
      customContentVerticalPadding={isMobile ? 0 : 20}
      hasColumnHeaders={isMobile ? false : true}
      columnHeaders={columnHeaders}
      columnSizes={columnSizes}
      containerHasBorder={false}>
      <GrowStatusList_Instance isPlaceholder={true} columnSizesChanged={(columns) => {
        SetColumnSizes(columns)
      }}/>
      
      <RowGroupSubGroup title={<>Completed</>} expandedState={false} showRowSeperators={false} rows={
        categorizedGrowIds["completed"].map((growId) => {
          return <GrowStatusList_Instance key={growId} growId={growId} columnSizes={columnSizes}/>
        })
      } numRowsDisplay="grows" emptyRowsDisplay="no grows found"/>
      <RowGroupSubGroup title={<>Active</>} expandedState={true} showRowSeperators={false} rows={
        categorizedGrowIds["active"].map((growId) => {
          return <GrowStatusList_Instance key={growId} growId={growId} columnSizes={columnSizes}/>
        })
      } numRowsDisplay="grows" emptyRowsDisplay="no grows found"/>      
    </RowGroup>

    {schedulingGrow && 
      <ScheduleGrowPopup selectedFacility={selectedFacility} closeCallback={closeSchedulingGrowPopup} growCompleteCallback={schedulingGrowFinalized}/>
    }  
  
  </>)
}


export default GrowStatusList 