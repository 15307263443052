import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';

import { LowContainer } from '../../../../assets/icons/Icons';
import { ProcessSuffixForUnit, ProcessValueForUnit, RoundToNearest } from '../../../../helpers';

  

const ConfirmExternalReservoirWaterTopUpWalkthroughStep = ({flowId, initialReservoirVolume, reservoirInfo, selectedUnit, transitionToPreviousStep, transitionToNextStep}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop



    const [availableReservoirVolume, SetAvailableReservoirVolume] = React.useState(() => {        
        let maxReservoirVolume = 0
        if (reservoirInfo["reservoir_volume"] !== undefined)  {
            maxReservoirVolume = parseFloat(reservoirInfo["reservoir_volume"]) / 1000;
        }

        return RoundToNearest(ProcessValueForUnit(parseFloat(maxReservoirVolume - initialReservoirVolume), selectedUnit, "litres"), 0.01)
    })

    
    

    const backClicked = () => {
        transitionToPreviousStep(["select_topup_type", null])
    }

    const nextClicked = () =>   {
        transitionToNextStep(["confirm_external_reservoir_water_volume", null])
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Add Water</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    {isMobile && <>
                        <div className="FlexContent-20 FlexContent-Center">
                            <LowContainer width={100}/>
                            <div style={{fontSize:18,textAlign:"center",fontWeight:300}}>Fill reservoir up with water up to</div>
                            <div style={{fontSize:40,textAlign:"left",fontWeight:500, flexShrink: 1}}>{availableReservoirVolume}{ProcessSuffixForUnit(selectedUnit)}</div>
                        </div>                    
                    </>}
                    {!isMobile && <>
                        <div className="FlexContent-H-40 FlexContent-Center" style={{padding: "0 10%"}}>
                            <div style={{flexShrink:0}}>
                                <LowContainer width={100}/>
                            </div>
                            <div className="FlexContent-10">
                                <div style={{fontSize:24,textAlign:"left",fontWeight:300, flexShrink: 1}}>Fill reservoir up with water up to</div>
                                <div style={{fontSize:44,textAlign:"left",fontWeight:500, flexShrink: 1}}>{availableReservoirVolume}{ProcessSuffixForUnit(selectedUnit)}</div>
                            </div>
                        </div>
                    </>}
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status={"Neutral"} content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default ConfirmExternalReservoirWaterTopUpWalkthroughStep