import './BladeInstance.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import BladeInstance_StatusContent_CompressorCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_CompressorCell';
import BladeInstance_StatusContent_SupplyFanCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_SupplyFanCell';
import BladeInstance_StatusContent_BypassDamperCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_BypassDamperCell';
import BladeInstance_StatusContent_CondensateSubsystemCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_CondensateSubsystemCell';
import BladeInstance_StatusContent_EnergyCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_EnergyCell';
import BladeInstance_StatusContent_AuxiliaryCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_AuxiliaryCell';
import BladeInstance_StatusContent_CondenserCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_CondenserCell';
import BladeInstance_StatusContent_DehumidCoilCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_DehumidCoilCell';
import BladeInstance_StatusContent_PrecoolCoilCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_PrecoolCoilCell';
import BladeInstance_StatusContent_RackMotionCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_RackMotionCell';
import BladeInstance_StatusContent_LightsCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_LightsCell';
import BladeInstance_StatusContent_PrimaryLiquidCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_PrimaryLiquidCell';
import BladeInstance_StatusContent_NurseryLowerLiquidCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_NurseryLowerLiquidCell';
import BladeInstance_StatusContent_NurseryUpperLiquidCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_NurseryUpperLiquidCell';
import { getUniqueConfigurationByName, selectBladeUniqueConfigurationByName } from '../../../redux/entities/service/Blade';
import { parseBool } from '../../../helpers';
import BladeInstance_StatusContent_CanopyAirStatusCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_CanopyAirStatusCell';
import DataStatus_HumidificationCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_HumidificationCell';
import BladeInstance_StatusContent_IrrigationCell from './BladeInstance_StatusContent_Cells/BladeInstance_StatusContent_IrrigationCell';


 


const BladeInstance_StatusContent = ({ bladeId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  //const tileKeys = []
  const auxALiquidSystemActiveConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, bladeId, "AuxALiquidSystemInstalled"), _.isEqual)
  const auxBLiquidSystemActiveConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, bladeId, "AuxBLiquidSystemInstalled"), _.isEqual)

  var isAuxAPumpingSystemActive = parseBool(auxALiquidSystemActiveConfiguration ?? false)
  var isAuxBPumpingSystemActive = parseBool(auxBLiquidSystemActiveConfiguration ?? false)

  let tileColumns = []
  if (isWideDesktop) {
    tileColumns.push(["canopy_air", "supply_fan", "bypass_damper", "condensate_ss"])
    tileColumns.push(["compressor", "condenser", "dehumid_coil", "precool_coil"])
    tileColumns.push(["energy", "lights", "humidification", "aux", "rack_motion"])
    let forthColumnCells = ["primary_liquid", "irrigation"]
    if (isAuxAPumpingSystemActive)  {
      forthColumnCells.push("nursery_upper")
    }
    if (isAuxBPumpingSystemActive)  {
      forthColumnCells.push("nursery_lower")
    }
    
    tileColumns.push(forthColumnCells)
  } else if (isDesktop) {
    tileColumns.push(["canopy_air", "supply_fan", "bypass_damper", "condensate_ss", "energy", "aux"])
    tileColumns.push(["compressor", "condenser", "dehumid_coil", "precool_coil", "rack_motion", "lights", "humidification"])
    let thirdColumnCells = ["primary_liquid", "irrigation"]
    if (isAuxAPumpingSystemActive)  {
      thirdColumnCells.push("nursery_upper")
    }
    if (isAuxBPumpingSystemActive)  {
      thirdColumnCells.push("nursery_lower")
    }
    
    tileColumns.push(thirdColumnCells)
  } else if (isTablet) {
    tileColumns.push(["canopy_air", "supply_fan", "compressor", "condenser", "dehumid_coil", "precool_coil", "bypass_damper", "condensate_ss", "energy", "aux"])
    let secondColumnCells = ["primary_liquid", "irrigation"]
    if (isAuxAPumpingSystemActive)  {
      secondColumnCells.push("nursery_upper")
    }
    if (isAuxBPumpingSystemActive)  {
      secondColumnCells.push("nursery_lower")
    }

    secondColumnCells.push(...["rack_motion", "lights", "humidification"])
    
    tileColumns.push(secondColumnCells)
  } else {
    let singleColumnCells = ["canopy_air", "primary_liquid", "irrigation", ]
    if (isAuxAPumpingSystemActive)  {
      singleColumnCells.push("nursery_upper")
    }
    if (isAuxBPumpingSystemActive)  {
      singleColumnCells.push("nursery_lower")
    }
    singleColumnCells.push(...["supply_fan", "compressor", "condenser", "dehumid_coil", "precool_coil", "rack_motion", "lights", "humidification", "bypass_damper", "condensate_ss", "energy", "aux"])
    
    tileColumns.push(singleColumnCells)
  }



  return (<>
    <div className="BladeInstance_StatusContent">
      {tileColumns.map((cellKeys) => {

        return (<>
          <div className="BladeInstance_StatusContent-Column">
            {cellKeys.map((cellKey) => {
              switch (cellKey) {
                case "canopy_air":
                  return <BladeInstance_StatusContent_CanopyAirStatusCell bladeId={bladeId} />
                case "supply_fan":
                  return <BladeInstance_StatusContent_SupplyFanCell bladeId={bladeId} />
                case "bypass_damper":
                  return <BladeInstance_StatusContent_BypassDamperCell bladeId={bladeId} />
                case "condensate_ss":
                  return <BladeInstance_StatusContent_CondensateSubsystemCell bladeId={bladeId} />
                case "energy":
                  return <BladeInstance_StatusContent_EnergyCell bladeId={bladeId} />
                case "aux":
                  return <BladeInstance_StatusContent_AuxiliaryCell bladeId={bladeId} />
                case "compressor":
                  return <BladeInstance_StatusContent_CompressorCell bladeId={bladeId} />
                case "condenser":
                  return <BladeInstance_StatusContent_CondenserCell bladeId={bladeId} />
                case "humidification":
                  return <DataStatus_HumidificationCell bladeId={bladeId} />
                case "dehumid_coil":
                  return <BladeInstance_StatusContent_DehumidCoilCell bladeId={bladeId} />
                case "precool_coil":
                  return <BladeInstance_StatusContent_PrecoolCoilCell bladeId={bladeId} />
                case "rack_motion":
                  return <BladeInstance_StatusContent_RackMotionCell bladeId={bladeId} />
                case "lights":
                  return <BladeInstance_StatusContent_LightsCell bladeId={bladeId} />
                case "primary_liquid":
                  return <BladeInstance_StatusContent_PrimaryLiquidCell bladeId={bladeId} />
                case "irrigation":
                  return <BladeInstance_StatusContent_IrrigationCell bladeId={bladeId} />
                case "nursery_upper":
                  return <BladeInstance_StatusContent_NurseryUpperLiquidCell bladeId={bladeId} />
                case "nursery_lower":
                  return <BladeInstance_StatusContent_NurseryLowerLiquidCell bladeId={bladeId} />
                default:
                  break
              }
            })}
          </div>
        </>)
      })}
    </div>
  </>)
}

export default BladeInstance_StatusContent