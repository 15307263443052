import './GrowAnalyticsPage.scss';
import _ from 'underscore';

import React from 'react'
import { Close, CollapseContent, ExpandContent } from '../../assets/icons/Icons';
import Button from '../../components/Button';
import { useMediaQuery } from 'react-responsive';
import Switch from '../../components/Switch';
import useMeasure from '../../useMeasure';
import SelectGrowsPopup from '../../model_components/SelectGrowsPopup';
import { selectAllChartAxisTypes, selectAllDataRecordingTimePeriodTypes } from '../../redux/AppInfo';

import { selectAllFacilityIds } from '../../redux/entities/Facilities';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllGrowPlanIdsForGrows, selectGrowById } from '../../redux/entities/Grow';
import { InitialLoadAllGrowPlans, selectGrowPlanById } from '../../redux/entities/GrowPlans';
import { makeFlatTheme } from '@lightningchart/lcjs-themes';
import { AutoCursorModes, AxisTickStrategies, ColorHEX, ColorRGBA, emptyLine, FontSettings, SolidFill, SolidLine, Themes, UIElementBuilders } from '@lightningchart/lcjs';
import { FormatTime, RoundedTimeToNearestLastDay, RoundedTimeToNearestLastHour, RoundedTimeToNearestLastMinute, RoundedTimeToNearestNextDay, RoundedTimeToNearestNextHour, RoundedTimeToNearestNextMinute, RoundToNearest } from '../../helpers';
import { createLightningChart } from '../../LC';
import GrowAnalyticsPage_Charting from './GrowAnalyticsPage_Charting/GrowAnalyticsPage_Charting';



const mainChartTheme = makeFlatTheme({
  isDark: false,
  fontFamily: 'Segoe UI, -apple-system, Verdana, Helvetica',
  backgroundColor: ColorRGBA(255, 255, 255, 0),
  textColor: ColorHEX('#191C22FF'),
  dataColors: [ColorHEX('#ffff5b'), ColorHEX('#ffcd5b'), ColorHEX('#ff9b5b')],
  axisColor: ColorHEX('#858585FF'),
  gridLineColor: ColorHEX('#303030ff'),
  uiBackgroundColor: ColorRGBA(255, 255, 255, 0),
  uiBorderColor: ColorRGBA(255, 255, 255, 0),
  dashboardSplitterColor: ColorRGBA(255, 255, 255, 0),
})


const GrowAnalyticsPage = ({
  selectedFacility,
  pageHeaderHelper
}) => {
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Grow Analytics")
  }, [])

  const isLargeDesktop = useMediaQuery({ minWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const allFacilityIds = useSelector(selectAllFacilityIds, _.isEqual)
  const activeFacilityIds = selectedFacility === "all" ? allFacilityIds : [selectedFacility]

  const [dataLegendExpandedState, SetDataLegendExpandedState] = React.useState(true)

  const [dataTogglesInitialized, SetDataTogglesInitialized] = React.useState(false)
  const [dataTypeToggles, SetDataTypeToggles] = React.useState({
    air: {
      label: "Air", defaultSelected: true, dataTypes: {
        temp: { label: "Temp", identifier: "airt", color: "rgb(51,160,44)", yAxis: "temp", defaultSelected: true, resolution: 0.5, unit: "°C" },
        rh: { label: "RH", identifier: "airrh", color: "rgb(135,125,185)", yAxis: "rh", defaultSelected: true, resolution: 1, unit: "%" },
        vpd: { label: "VPD", identifier: "airvpd", color: "rgb(21,120,90)", yAxis: "vpd", defaultSelected: true, resolution: 0.1, unit: "kPa" },
        cO2: { label: "cO2", identifier: "airc", color: "rgb(150,155,0)", yAxis: "co2", defaultSelected: false, resolution: 1, unit: "ppm" },
        leafTemp: { label: "Leaf Temp", identifier: "leaft", color: "rgb(31,120,84)", yAxis: "temp", defaultSelected: false, resolution: 0.5, unit: "°C" }
      }
    },
    water: {
      label: "Water", defaultSelected: false, dataTypes: {
        temp: { label: "Temp", identifier: "watert", color: "rgb(101,120,64)", yAxis: "temp", defaultSelected: true, resolution: 0.5, unit: "°C" },
        pH: { label: "pH", identifier: "waterph", color: "rgb(255,0,86)", yAxis: "ph", defaultSelected: true, resolution: 0.1, unit: "" },
        EC: { label: "EC", identifier: "waterec", color: "rgb(0,0,139)", yAxis: "ec", defaultSelected: true, resolution: 1, unit: "S/m" },
        DO: { label: "DO", identifier: "waterdo", color: "rgb(158,0,142)", yAxis: "do", defaultSelected: false, resolution: 1, unit: "" },
        ORP: { label: "ORP", identifier: "waterorp", color: "rgb(1,0,103)", yAxis: "orp", defaultSelected: false, resolution: 1, unit: "mV" }
      }
    },
    lights: {
      label: "Lights", defaultSelected: false, dataTypes: {
        red: { label: "Red", identifier: "lightr", color: "rgb(255,100,100)", yAxis: "light_intensity", defaultSelected: true, resolution: 1, unit: "µmol/m²/s" },
        green: { label: "Green", identifier: "lightg", color: "rgb(100,255,100)", yAxis: "light_intensity", defaultSelected: true, resolution: 1, unit: "µmol/m²/s" },
        blue: { label: "Blue", identifier: "lightb", color: "rgb(100,100,255)", yAxis: "light_intensity", defaultSelected: true, resolution: 1, unit: "µmol/m²/s" },
        farRed: { label: "Far Red", identifier: "lightfr", color: "rgb(255,150,150)", yAxis: "light_intensity", defaultSelected: true, resolution: 1, unit: "µmol/m²/s" },
      }
    },
    condensate: {
      label: "Condensate", defaultSelected: false, dataTypes: {
        condensateVolume: { label: "Total", identifier: "condensate-t", color: "rgb(255,150,150)", yAxis: "litres", defaultSelected: true, resolution: 0.1, unit: "L" },
        evapMetric: { label: "Evap Metric", identifier: "evap-avg-m", color: "rgb(213,255,0)", yAxis: "g/m2/s", defaultSelected: true, resolution: 0.0001, unit: "g/m2/s" },
        evapImperial: { label: "Evap Imperial", identifier: "evap-avg-i", color: "rgb(70,130,180)", yAxis: "lbs/sqft/h", defaultSelected: true, resolution: 0.0001, unit: "lbs/sqft/h" },
      }
    },
    dosing: {
      label: "Dosing", defaultSelected: false, dataTypes: {
        water: { label: "Water (Total)", identifier: "water", color: "rgb(0,19,56)", yAxis: "millilitres", defaultSelected: true, resolution: 0.1, unit: "mL" },
        nutrients_total: { label: "Nutrients (Total)", identifier: "nutrients_total", color: "rgb(0, 0, 0)", yAxis: "litres", defaultSelected: true, resolution: 0.1, unit: "L" },
        nutrients: { label: "Nutrient Doses", identifier: "nutrients", color: "rgb(0, 0, 0)", yAxis: "millilitres", defaultSelected: true, resolution: 0.1, unit: "mL" },
      }
    },
    energy: {
      label: "Energy", defaultSelected: false, dataTypes: {
        power: { label: "Power", identifier: "power", isEnergyData: true, color: "rgb(21,120,90)", yAxis: "kilowatt", defaultSelected: true, resolution: 0.5, unit: "kW" },
        //cost: {label: "Cost", identifier: "cost", color: "rgb(21,120,90)", yAxis: "cost", defaultSelected: true},
        current: { label: "Current", identifier: "cur", color: "rgb(21,120,90)", yAxis: "current", defaultSelected: true },
      }
    }
  })

  const [activeDataTypeToggles, SetActiveDataTypeToggles] = React.useState({})
  React.useLayoutEffect(() => {
    if (!dataTogglesInitialized)  {
      let defaultActiveDataTypeToggles = {}
      for (let [key, groupInfo] of Object.entries(dataTypeToggles)) {
        if (defaultActiveDataTypeToggles[key] === undefined) {
          defaultActiveDataTypeToggles[key] = { active: groupInfo.defaultSelected, keys: [] }
        } 

        for (let [dataKey, toggle] of Object.entries(groupInfo.dataTypes)) {
          if (toggle.defaultSelected) {
            defaultActiveDataTypeToggles[key].keys.push(dataKey)
          }
        }
      }
      SetActiveDataTypeToggles(defaultActiveDataTypeToggles)
      SetDataTogglesInitialized(true)
    }
  }, [dataTogglesInitialized])




  const dataTypeGroupToggled = (dataTypeGroupName, selected) => {
    SetActiveDataTypeToggles({ ...activeDataTypeToggles, [dataTypeGroupName]: { ...activeDataTypeToggles[dataTypeGroupName], active: selected } })
  }
  const dataTypeToggled = (dataTypeGroupName, dataTypeName, selected) => {
    if (selected) {
      SetActiveDataTypeToggles({ ...activeDataTypeToggles, [dataTypeGroupName]: { ...activeDataTypeToggles[dataTypeGroupName], keys: [...activeDataTypeToggles[dataTypeGroupName].keys, dataTypeName] } })
    } else {
      SetActiveDataTypeToggles({ ...activeDataTypeToggles, [dataTypeGroupName]: { ...activeDataTypeToggles[dataTypeGroupName], keys: activeDataTypeToggles[dataTypeGroupName].keys.filter((k) => k !== dataTypeName) } })
    }
  }


  const [selectingGrows, SetSelectingGrows] = React.useState(false)
  const [activeGrowIds, SetActiveGrowIds] = React.useState([])
  const doneSelectingGrows = (growIds) => {
    SetActiveGrowIds(growIds)
    SetSelectingGrows(false)
  }

  return (
    <>
      <div className="GrowAnalyticsPage">
        <div className="GrowAnalytics-Charting">
          <div className="GrowAnalytics-Charting-DataToggles-Container">
            <div className="GrowAnalytics-Charting-DataToggles-Header">
              <div className="Text-S14">Data Legend</div>
              <div className="GrowAnalytics-Charting-DataToggles-Header-Toggle" onClick={() => SetDataLegendExpandedState(!dataLegendExpandedState)}>
                <Close />
              </div>
            </div>
            <div className="GrowAnalytics-Charting-DataToggles-Content">
              {Object.entries(dataTypeToggles).map(([key, info]) => {
                if (activeDataTypeToggles[key] !== undefined) {
                  return (
                    <GrowAnalyticsPage_DataTypeToggle
                      name={key} key={key} info={info}
                      activeDataTypes={activeDataTypeToggles}
                      onGroupToggle={dataTypeGroupToggled}
                      onDataTypeToggle={dataTypeToggled} />
                  )
                }
              })}
            </div>
          </div>
          <GrowAnalyticsPage_Charting
            activeGrowIds={activeGrowIds}
            onSelectGrows={() => {SetSelectingGrows(true)}}
            dataTypeToggles={dataTypeToggles}
            activeDataTypeToggles={activeDataTypeToggles} />
        </div>

        <GrowAnalyticsPage_GrowLegend
          activeGrowIds={activeGrowIds}
          dataTypeToggles={dataTypeToggles}
          activeDataTypeToggles={activeDataTypeToggles}
          onSelectGrows={() => { SetSelectingGrows(true) }}
        />

      </div>
      {selectingGrows &&
        <SelectGrowsPopup
          selectedFacility={selectedFacility}
          selectedGrowIds={activeGrowIds}
          allowMultiselect={true}
          onGrowsSelected={doneSelectingGrows}
          maxSelected={3}
          closeCallback={() => { SetSelectingGrows(false) }}
        />}
    </>
  )
}

export default GrowAnalyticsPage




const GrowAnalyticsPage_DataTypeToggle = ({
  name, info, activeDataTypes, onGroupToggle, onDataTypeToggle
}) => {

  const [expandedState, SetExpandedState] = React.useState(false)
  const isGroupActive = activeDataTypes[name].active



  const [dataItemsContainerRef, { height: dataItemsContainerHeight }] = useMeasure()

  const dataItemsContentProps = { style: {} }
  if (!isGroupActive) {
    dataItemsContentProps.style.opacity = 0.4
    dataItemsContentProps.style.pointerEvents = "none"
  }


  return (<>
    <div className="GrowAnalytics-Charting-DataToggle-Container">
      <div className={"GrowAnalytics-Charting-DataToggle-GroupToggle" + (expandedState ? " GrowAnalytics-Charting-DataToggle-GroupToggle-Expanded" : "")}>
        <div className="GrowAnalytics-Charting-DataToggle-GroupToggle-Info" onClick={() => SetExpandedState(!expandedState)}>
          <div className="GrowAnalytics-Charting-DataToggle-GroupToggle-Info-ExpandToggle">
            {expandedState && <CollapseContent />}
            {!expandedState && <ExpandContent />}
          </div>
          <div className="GrowAnalytics-Charting-DataToggle-GroupToggle-Info-Key noselect">
            <div className="GrowAnalytics-Charting-DataToggle-GroupToggle-Info-Key-Title">{info.label}</div>
          </div>
        </div>
        <div className="GrowAnalytics-Charting-DataToggle-GroupToggle-ToggleGroupActiveButton">
          <Switch state={isGroupActive} onSwitch={(state) => onGroupToggle(name, state)} />
        </div>
      </div>

      <div className="GrowAnalytics-Charting-DataToggle-DataItems-Container" style={{ height: expandedState ? dataItemsContainerHeight : 0 }}>
        <div className="GrowAnalytics-Charting-DataToggle-DataItems" ref={dataItemsContainerRef}>
          <div className="GrowAnalytics-Charting-DataToggle-DataItems-Content" {...dataItemsContentProps}>
            {Object.entries(info.dataTypes).map(([key, dataTypeInfo]) => {
              let dataTypeActive = activeDataTypes[name].keys.includes(key)
              return (
                <div key={key}
                  className="GrowAnalytics-Charting-DataToggle-DataItemToggle">
                  <div className="GrowAnalytics-Charting-DataToggle-DataItemToggle-Info">
                    <div className="GrowAnalytics-Charting-DataToggle-DataItemToggle-Info-Key noselect">
                      <div className="GrowAnalytics-Charting-DataToggle-DataItemToggle-Info-Key-Color"
                        style={{ backgroundColor: dataTypeInfo.color }}></div>
                      <div className="GrowAnalytics-Charting-DataToggle-DataItemToggle-Info-Key-Title" style={{ opacity: dataTypeActive ? 1.0 : 0.4 }}>{dataTypeInfo.label}</div>
                    </div>
                  </div>
                  <div className="GrowAnalytics-Charting-DataToggle-DataItemToggle-ToggleGroupActiveButton">
                    <Switch state={dataTypeActive} onSwitch={(state) => onDataTypeToggle(name, key, state)} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  </>)
}






const GrowAnalyticsPage_GrowLegend = ({
  onSelectGrows,
  activeGrowIds,
  dataTypeToggles,
  activeDataTypeToggles
}) => {

  const isLargeDesktop = useMediaQuery({ minWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const activeGrowPlanIds = useSelector((state) => selectAllGrowPlanIdsForGrows(state, activeGrowIds), _.isEqual)


  const [selectGrowsButtonRef, { height: headerHeight }] = useMeasure()


  const growColumnWidth = isMobile ? 60 : (isTablet ? 120 : 240)
  const [dataColumnWidths, SetDataColumnWidths] = React.useState({})
  const updateColumnWidth = (groupKey, dataTypeKey, width) => {
    let currentColumnWidths = { ...dataColumnWidths }
    if (currentColumnWidths[groupKey] === undefined) {
      currentColumnWidths[groupKey] = {}
    }
    currentColumnWidths[groupKey][dataTypeKey] = width
    SetDataColumnWidths(currentColumnWidths)
  }

  const [growRowHeights, SetGrowRowHeight] = React.useState({})
  const updateGrowHeight = (growId, height) => {
    let currentGrowHeights = { ...growRowHeights }
    currentGrowHeights[growId] = height
    SetGrowRowHeight(currentGrowHeights)
  }
  

  return (<>
    <InitialLoadAllGrowPlans growPlanIds={activeGrowPlanIds} />
    <div className="GrowAnalytics-GrowLegend-Container">
      <div className="GrowAnalytics-GrowLegend-GrowColumn">
        <div className="GrowAnalytics-GrowLegend-GrowColumn-SelectGrowsButton" style={{ width: growColumnWidth }} ref={selectGrowsButtonRef}>
          <Button status="Hyperlink" content={"Select Grow"} onClick={onSelectGrows} />
        </div>
        <div className="GrowAnalytics-GrowLegend-GrowColumn-Grows">
          {activeGrowIds.map((growId) => {
            return (<>
              <GrowAnalyticsPage_GrowLegend_GrowIdentifierCell
                key={growId}
                growId={growId}
                growColumnWidth={growColumnWidth}
                growRowHeights={growRowHeights}
                cellHeightChanged={(height) => updateGrowHeight(growId, height)}
              />
            </>)
          })}
        </div>
      </div>
      <div className="GrowAnalytics-GrowLegend-DataTypes-Container">
        <div className="GrowAnalytics-GrowLegend-DataTypes-Header" style={{height: headerHeight}}>
          {Object.entries(activeDataTypeToggles).filter(([_, gT]) => gT.active).map(([groupKey, groupInfo]) => {
            const dataTypeGroup = dataTypeToggles[groupKey]
            return groupInfo.keys.map((dataTypeKey) => {
              return (
                <GrowAnalyticsPage_GrowLegend_DataTypeHeaderColumn
                  key={groupKey + "-" + dataTypeKey}
                  dataTypeInfo={dataTypeGroup.dataTypes[dataTypeKey]}
                  dataColumnWidths={dataColumnWidths}
                  columnWidthChanged={(width) => updateColumnWidth(groupKey, dataTypeKey, width)}
                />
              )
            })


          })}

        </div>
        <div className="GrowAnalytics-GrowLegend-DataTypes-Grows">
          {activeGrowIds.map((growId) => {
            return (<>
              <GrowAnalyticsPage_GrowLegend_Grow
                key={growId}
                growId={growId}
                dataColumnHeights={growRowHeights}
                dataColumnWidths={dataColumnWidths}
                dataTypeToggles={dataTypeToggles}
                activeDataTypeToggles={activeDataTypeToggles} />
            </>)
          })}
        </div>
      </div>
    </div>
  </>)
}

const GrowAnalyticsPage_GrowLegend_DataTypeHeaderColumn = ({
  dataTypeInfo,
  columnWidthChanged,
  dataColumnWidths,
}) => {




  const [containerRef, { width: containerWidth }] = useMeasure()
  React.useLayoutEffect(() => {
    columnWidthChanged(containerWidth)
  }, [containerWidth])

  return (<>
    <div className="GrowAnalytics-GrowLegend-Header-DataType-Container" ref={containerRef}>
      <div className="GrowAnalytics-GrowLegend-Header-DataType"> 
        <div className="GrowAnalytics-Charting-DataToggle-DataItemToggle-Info-Key-Color" style={{ backgroundColor: dataTypeInfo.color }}></div>
        {dataTypeInfo.label}
      </div>
    </div>
  </>)
}




const GrowAnalyticsPage_GrowLegend_GrowIdentifierCell = ({
  growId,
  growColumnWidth,
  cellHeightChanged,
  growRowHeights
}) => {




  const [containerRef, { height: containerHeight}] = useMeasure()
  React.useLayoutEffect(() => {
    if (growRowHeights[growId] !== containerHeight) {
      cellHeightChanged(containerHeight)
    }
  }, [containerHeight, growRowHeights])

  const grow = useSelector((state) => selectGrowById(state, growId))
  const growPlan = useSelector((state) => selectGrowPlanById(state, grow.grow_plan_id))

  return (<>
    <div className="GrowAnalytics-GrowLegend-Grow-Identifier-CellContainer" ref={containerRef}>
      <div className="GrowAnalytics-GrowLegend-Grow-Identifier-Content" style={{ width: growColumnWidth }}>
        <div className="GrowAnalytics-GrowLegend-Grow-Identifier">
          <div className="Text-S14 Text-SoftTruncate">{growPlan.name}</div>
          <div className="Text-Alt14 Text-SoftTruncate">#{grow.id}</div>
        </div>
      </div>
    </div>
  </>)
}


const GrowAnalyticsPage_GrowLegend_Grow = ({
  growId,
  dataColumnHeights,
  dataColumnWidths,
  dataTypeToggles,
  activeDataTypeToggles
}) => {


  const rowHeight = dataColumnHeights[growId] ?? "auto"
  return (<>
    <div className="GrowAnalytics-GrowLegend-Grow" style={{height: rowHeight}}>
      <div className="GrowAnalytics-GrowLegend-Grow-DataItems-Container">
        {Object.entries(activeDataTypeToggles).filter(([_, gT]) => gT.active).map(([groupKey, groupInfo]) => {
          const dataTypeGroup = dataTypeToggles[groupKey]
          return groupInfo.keys.map((dataTypeKey) => {
            const dataTypeInfo = dataTypeGroup.dataTypes[dataTypeKey]
            let containerWidth = "auto"
            if (dataColumnWidths[groupKey] !== undefined && dataColumnWidths[groupKey][dataTypeKey] !== undefined) {
              containerWidth = dataColumnWidths[groupKey][dataTypeKey]
            }
            return (<>
              <div key={groupKey + "-" + dataTypeKey} className="GrowAnalytics-GrowLegend-Grow-DataItem-Container" style={{ width: containerWidth }}>
                <div className="GrowAnalytics-GrowLegend-Grow-DataItem">
                  <div className="Text-S14">
                    23C
                  </div>
                </div>
              </div>
            </>)
          })

        })}
      </div>
    </div>
  </>)
}