import './HarvestTask.scss';
import React from 'react';


import { useSelector, useDispatch} from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import HarvestTask_ZoneListWalkthroughStep from './HarvestTask_ZoneListWalkthroughStep';
import { getAllServicesForFacility, selectAllFacilities } from '../../../../redux/entities/Facilities';
import { getGerminationChamberByServiceId, selectAllGerminationChamber } from '../../../../redux/entities/service/GerminationChamber';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllGrowSeeds } from '../../../../redux/entities/PlantTypes';
import { getRecipeById } from '../../../../redux/entities/Recipes';
import { getGrowById, getGrowGrowoutBoardLocations } from '../../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../../redux/entities/GrowPlans';
import Walkthrough from '../../../../components/Walkthrough';
import { getBladeZoneByUID, selectAllBladeZones } from '../../../../redux/entities/service/Blade';
import HarvestTask_ContainerContentsWalkthroughStep from './HarvestTask_ContainerContentsWalkthroughStep';
import HarvestTask_ContainerWeightWalkthroughStep from './HarvestTask_ContainerWeightWalkthroughStep';
import { selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import HarvestTask_ContainerListWalkthroughStep from './HarvestTask_ContainerListWalkthroughStep';
import HarvestTask_PrepareForHarvestWalkthroughStep from './HarvestTask_PrepareForHarvestWalkthroughStep';
import HarvestTask_ZoneCleanOptionsWalkthroughStep from './HarvestTask_ZoneCleanOptionsWalkthroughStep';
import { LoadInventoryItemTypes } from '../../../../redux/entities/Inventory';
import HarvestTask_ReviewContainersWalkthroughStep from './HarvestTask_ReviewContainersWalkthroughStep';
import HarvestTask_PostPartialHarvestConfirmStep from './HarvestTask_PostPartialHarvestConfirmStep';


const HarvestTask = ({task, onCloseTask, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const closeTask = () =>  {
    onCloseTask()
  }
  const closePopup = () =>  {
    onClosePopup()
  }


  const allFacilities = useSelector(selectAllFacilities)


  



  let allGrows = useSelector((state) => state.grows.grows)
  const activeGrow = allGrows.find((g) => g.id === task.reference_id)
  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }
  }, [task, allGrows])


  let allGrowPlans = useSelector((state) => state.growPlans.growPlans)
  const [activeInventoryItemTypeIds, SetActiveInventoryItemTypeIds] = React.useState([])
  const activeGrowPlan =  activeGrow !== undefined ? allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id) : undefined
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let foundGrowPlan = allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id)
      if (foundGrowPlan === undefined)  {
        dispatch(getGrowPlanById({growPlanId: activeGrow.grow_plan_id}))
      }else {
        let inventoryItemTypeIds = [25]
        if (activeGrowPlan.final_product_id !== undefined)  {
          inventoryItemTypeIds.push(activeGrowPlan.final_product_id)
        }
        for (let varient of activeGrowPlan.plant_variants)  {
          if (varient.inventory_item_type_id !== undefined) {
            inventoryItemTypeIds.push(varient.inventory_item_type_id)
          }
        }
        SetActiveInventoryItemTypeIds(inventoryItemTypeIds)
      }
    }
  }, [activeGrow, allGrowPlans])
    
  
  let allRecipes = useSelector((state) => state.recipes.recipes)
  const activeRecipe =  activeGrow !== undefined ? allRecipes.find((r) => r.id === activeGrow.recipe_id && r.version === activeGrow.recipe_version) : undefined
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let foundRecipe = allRecipes.find((r) => r.id === activeGrow.recipe_id && r.version === activeGrow.recipe_version)
      if (foundRecipe === undefined)  {
        dispatch(getRecipeById({recipes: [{id: activeGrow.recipe_id, version: activeGrow.recipe_version}]}))
      }
    }
  }, [activeGrow, allGrowPlans])
  

  
  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow !== undefined)  {
      if (!foundGrow.loaded_growout_locations && !foundGrow.loading_growout_locations) {
        dispatch(getGrowGrowoutBoardLocations({growId: foundGrow.id}))
      }
    }
  }, [allGrows])


  const allPlantVarieties = useSelector((state) => selectAllPlantVarieties(state))



  const allBladeZones = useSelector((state) => selectAllBladeZones(state))
  
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let bladeZonesToLoad = []
      if (activeGrow.growout_location_entries !== null) {
        for (let zoneInfo of activeGrow.growout_location_entries) {
          let foundBladeZone = allBladeZones.find((z) => z.uid === zoneInfo.grow_out_uid)
          if (foundBladeZone === undefined) {
            bladeZonesToLoad.push(zoneInfo.grow_out_uid)
          }
        }
      }
      if (bladeZonesToLoad.length > 0) {
        dispatch(getBladeZoneByUID({UIDs: bladeZonesToLoad}))
      }
    }
  }, [allBladeZones, activeGrow])


  const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))
  


  const [harvestingZoneUID, SetHarvestingZoneUID] = React.useState(null)
  const [harvestingZoneInfo, SetHarvestingZoneInfo] = React.useState(null)
  const beginHarvestingZoneConfirmed = ({zoneUID, zoneInfo}) => {
    SetHarvestingZoneUID(zoneUID)
    SetHarvestingZoneInfo(zoneInfo)
  }

  const [containerContents, SetContainerContents] = React.useState(null)
  const containerContentsConfirmed = ({containerContents}) => {
    //containerContents = [{id: 24, ratio: 33.33}, {id: 25, ratio:66.67}]
    SetContainerContents(containerContents)
  }

  const [containers, SetContainers] = React.useState([])
  const addContainerCallback = ({containerInfo}) => {
    SetContainers([...containers, containerInfo])
  }
  const containersConfirmed = ({containers}) => {
    SetContainers([...containers])
  }



  const resetForNewContainer = () => {
    SetContainerContents(null)
  }
   
  const taskConfirmedCompleted = () =>  {
    onCloseTask()
  }


  /* Walkthrough requirements */
  let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["zone_list", null])
  let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
  let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
  const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })
  const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })

  const transitionToPreviousStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetPreviousWalkthroughStep(stepKeys)
  }
  const transitionToNextStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetNextWalkthroughStep(stepKeys)
  }




  const loadWalkthroughStep = (stepKeys) =>    {
      const stepKey = stepKeys[0]
      const subStepKey = stepKeys[1]
      switch (stepKey)    {
        case "zone_list":
          return (<HarvestTask_ZoneListWalkthroughStep
            task={task}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            beginHarvestingZoneConfirmedCallback={beginHarvestingZoneConfirmed}
            cancelTaskCallback={onCloseTask}
            containerContentsConfirmedCallback={containerContentsConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} 
            taskCompletedCallback={taskConfirmedCompleted}/>) 

        case "prepare_for_harvest":
          return (<HarvestTask_PrepareForHarvestWalkthroughStep
            task={task}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            allBladeZones={allBladeZones}
            harvestingZoneUID={harvestingZoneUID}
            cancelTaskCallback={onCloseTask}
            containerContentsConfirmedCallback={containerContentsConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />)
    
        case "container_contents":
          return (<HarvestTask_ContainerContentsWalkthroughStep
            task={task}
            allPlantVarieties={allPlantVarieties}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            initialContainerContents={containerContents}
            containerContentsConfirmedCallback={containerContentsConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}/>)

        case "container_weight":
          return (<HarvestTask_ContainerWeightWalkthroughStep
            task={task}
            allPlantVarieties={allPlantVarieties}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            containerContents={containerContents}
            addContainerCallback={addContainerCallback}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}/>) 
    
        case "container_list":
          return (<HarvestTask_ContainerListWalkthroughStep
            task={task}
            harvestingZoneUID={harvestingZoneUID}
            harvestingZoneInfo={harvestingZoneInfo}
            allPlantVarieties={allPlantVarieties}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            containerContents={containerContents}
            containers={containers}
            resetForNewContainerCallback={resetForNewContainer}
            containerContentsConfirmedCallback={containerContentsConfirmed}
            containersConfirmedCallback={containersConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}/>)  
        case "review_containers":
          return (<HarvestTask_ReviewContainersWalkthroughStep
            task={task}
            harvestingZoneUID={harvestingZoneUID}
            harvestingZoneInfo={harvestingZoneInfo}
            allPlantVarieties={allPlantVarieties}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            containerContents={containerContents}
            containers={containers}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}/>)
        case "post_partial_harvest_confirm":
          return (<HarvestTask_PostPartialHarvestConfirmStep
            task={task}
            harvestingZoneUID={harvestingZoneUID}
            harvestingZoneInfo={harvestingZoneInfo}
            allPlantVarieties={allPlantVarieties}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}/>
          )
        case "clean_options":
          return (<HarvestTask_ZoneCleanOptionsWalkthroughStep
            task={task}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            harvestingZoneUID={harvestingZoneUID}
            containers={containers}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}/>) 
        default:
            return <></>
      }
  }


  /*let initialWalkthroughStep = null
  if (activeGrowPlan !== undefined) {
    if (activeGrowPlan.plant_variants.length > 1) {
      initialWalkthroughStep = "seeding_list"
    }else {
      initialWalkthroughStep = "seeding"
    }
    console.log(activeGrowPlan.plant_variants)
  }*/


  return (<>
    <LoadInventoryItemTypes inventoryItemTypeIds={activeInventoryItemTypeIds}/>
    <Walkthrough
      title="Harvest"
      subtitle={<>{activeGrowPlan !== undefined ? activeGrowPlan.name : ""}</>}
      size="full_screen"
      closeCallback={closePopup}
      hideMainSeparators={false}
      initialWalkthroughStep={["zone_list", null]}
      transitionToWalkthroughStep={nextWalkthroughStep}
      transitionBackToWalkthroughStep={previousWalkthroughStep}
      buildWalkthroughStepCallback={loadWalkthroughStep}
      transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
      transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}

    />
  </>)
} 

export default HarvestTask