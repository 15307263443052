import './GerminationStationsPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getAllServicesForFacility, selectAllFacilities } from '../../../redux/entities/Facilities';
import { activateTemporaryEditMode, addGerminationStationToGroup, createNewGroup, getLiveDataItem, getUniqueConfigurationByName, getGerminationStationConfigurationMap, getGerminationStationByServiceId, getGerminationStationLiveDataById, getGerminationStationStatusById, hideGerminationStationStatusContent, removeGroup, selectAllGerminationStationConfigurationMaps, selectAllGerminationStations, setGerminationStationRuntimeProperty, showGerminationStationStatusContent, selectAllGerminationStationGroups, selectAllGerminationStationZones, getGerminationStationGroupById, getAllGerminationStationGroupsByFacilityId, MaintainGerminationStationLiveData, MaintainGerminationStationStatus, MaintainGerminationStationUniqueConfigurationMaps, InitialLoadAllGerminationStations, InitialLoadAllGerminationStationGroups, MaintainGerminationStationConfigurationMaps } from '../../../redux/entities/service/GerminationStation';
import ControlBar from '../../../components/ControlBar';
import CircularGauge from '../../../components/CircularGauge';
import { GridTile } from '../../../components/GridTile';
import NumberInput from '../../../components/input/NumberInput';
import Switch from '../../../components/Switch';
import { RoundToNearest, convertToBool, parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import TextInput from '../../../components/input/TextInput';
import ToggleCellButton from '../../../components/ToggleCellButton';
import { AddItem, BadX, GoodCheckmark, Pause, Play, PlusAlt, RSSI, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import BasicAlert from '../../../components/BasicAlert';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import GerminationStationInstance from './GerminationStationInstance';






const GerminationStationsPage = ({ selectedFacility, setRightSideContent }) => {

  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const activeGerminationStationIds = useSelector((state) => state.germinationStation.germinationStation.filter((b) => b.facility_id === selectedFacility).map((b) => b.id), _.isEqual)
  const germinationStationUIDsToIds = useSelector((state) => state.germinationStation.germinationStationUIDsToIds)

 
  const updateHeader = React.useCallback(() => {
    if (setRightSideContent === undefined)
      return
    setRightSideContent(
      <>
        {activeGerminationStationIds.length > 0 &&
          <Button content="Add GerminationStation" status="Primary" onClick={addNewGerminationStation} />
        }
      </>
    )
  })

  React.useLayoutEffect(() => {
    updateHeader()
  }, [])


  const [addingNewGerminationStation, SetAddingNewGerminationStation] = React.useState(false)
  const addNewGerminationStation = React.useCallback(() => {
    SetAddingNewGerminationStation(true)
  })



  const addGerminationStationWalkthroughCloseCallback = React.useCallback(() => {
    SetAddingNewGerminationStation(false)
  })

   
  return (<>
    <InitialLoadAllGerminationStations/>
    <MaintainGerminationStationStatus germinationStationIds={activeGerminationStationIds} />
    <MaintainGerminationStationConfigurationMaps germinationStationIds={activeGerminationStationIds} />
    <MaintainGerminationStationUniqueConfigurationMaps germinationStationIds={activeGerminationStationIds} />
    <MaintainGerminationStationLiveData germinationStationIds={activeGerminationStationIds} interval={500} />
    <div id="GerminationStationsStatus">

      {activeGerminationStationIds.length === 0 && 
        <div className="GerminationStationsStatus-NoGroupsContent">
          <span>No active Flows</span>
        </div>
      }
      {activeGerminationStationIds.length > 0 &&
        <div className="GerminationStationsStatus-GerminationStations">
          <RowGroup
            hasSettings={false}
            hasGroupHeader={false}
            hasColumnHeaders={false}
            verticalFill={true}
            >
              {activeGerminationStationIds.map((germinationStationId) => {
                return <GerminationStationInstance
                  key={germinationStationId} 
                  germinationStationId={germinationStationId} 
                  isDefaultExpanded={activeGerminationStationIds.length === 1}/>
                
              })}

            </RowGroup>
          </div>
            
        }
    </div>
    {/*addingNewGerminationStation && <AddGerminationStationWalkthrough facilityId={selectedFacility} completeCallback={addGerminationStationWalkthroughCloseCallback} closeCallback={addGerminationStationWalkthroughCloseCallback} />*/}
  </>)
}

export default GerminationStationsPage