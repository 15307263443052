import './TransplantToNurseryFromGerminationTask.scss';


import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../../../components/input/DropDownInput';
import TextInput from '../../../../components/input/TextInput';
import Tag from '../../../../components/Tag';
import { useSelector } from 'react-redux';
import { selectAllGerminationStations } from '../../../../redux/entities/service/GerminationStation';

const TransplantToNurseryFromGernimationTask_MoveCropsStep = ({
    allBladeZones,
    activeGrow,
    initialNurseryLocations,
    cancelTaskCallback,
    locationsConfirmed,
    transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 }); 

    const allGerminationChambers = useSelector((state) => selectAllGerminationStations(state))


    const finishedTransplantingClicked = () =>  {
        locationsConfirmed({locations: currentNurseryLocations})
        transitionToNextStep(["review", null])
    }
    


    const [currentSelectedNursery, SetCurrentSelectedNursery] = React.useState(null)
    const nurserySelected = (uid) =>  {
        SetCurrentSelectedNursery(uid)
    }

    const [currentSelectedNurseryRow, SetCurrentSelectedNurseryRow] = React.useState(null)
    const nurseryRowSelected = (index) =>  {
        SetCurrentSelectedNurseryRow(index)
    }



    const [currentLocation, SetCurrentLocation] = React.useState("")
    const currentLocationChanged = (value) =>   {
        SetCurrentLocation(value)
    }



    const [currentNurseryLocations, SetCurrentLocations] = React.useState({})
    React.useLayoutEffect(() => {
        SetCurrentLocations(initialNurseryLocations)
    }, [initialNurseryLocations])

    const addLocationClicked = () =>    {
        let nurseryLocations = currentNurseryLocations
        if (nurseryLocations[currentSelectedNursery] === undefined)  {
            nurseryLocations[currentSelectedNursery] = {}
        }
        if (nurseryLocations[currentSelectedNursery][currentSelectedNurseryRow] === undefined)  {
            nurseryLocations[currentSelectedNursery][currentSelectedNurseryRow] = []
        }
        if (nurseryLocations[currentSelectedNursery][currentSelectedNurseryRow].indexOf(currentLocation) === -1)   {
            nurseryLocations[currentSelectedNursery][currentSelectedNurseryRow].push(currentLocation)
        }
        SetCurrentLocations({...nurseryLocations})
        SetCurrentLocation("")
    }

    const removeLocation = (nurseryUID, rowIndex, location) => {
        let nurseryLocations = currentNurseryLocations
        if (nurseryLocations[nurseryUID] !== undefined && nurseryLocations[nurseryUID][rowIndex] !== undefined)    {
            nurseryLocations[nurseryUID][rowIndex] = nurseryLocations[nurseryUID][rowIndex].filter((l) => l != location)
            if (nurseryLocations[nurseryUID][rowIndex].length === 0)    {
                delete nurseryLocations[nurseryUID][rowIndex]
            }
            if (Object.entries(nurseryLocations[nurseryUID]).length === 0)  {
                delete nurseryLocations[nurseryUID]
            }
        }
        SetCurrentLocations({...nurseryLocations})
    }



    React.useEffect(() => {
        locationsConfirmed({locations: currentNurseryLocations})
    }, [currentNurseryLocations])

    


    

    let nurseryOptions = []
    allBladeZones.map((bZ) => {
        if (bZ.zone_type === "nursery") {
            nurseryOptions.push({value: bZ.uid, label: bZ.display_name})
        }
    })
    

    /*let nurseryRowOptions = allBladeZones.map((bZ) => {
        if (bZ.id === 5)    {
            return {value: bZ.uid, label: bZ.display_name}
        }
    })*/
    let nurseryRowOptions = [
        {value: 1, label: "Row A", tagLabel: "A"},
        {value: 2, label: "Row B", tagLabel: "B"},
        {value: 3, label: "Row C", tagLabel: "C"},
        {value: 4, label: "Row D", tagLabel: "D"},
        {value: 5, label: "Row E", tagLabel: "E"},
        {value: 6, label: "Row F", tagLabel: "F"},
        {value: 7, label: "Row G", tagLabel: "G"},
    ]
    
    let isLocationValid = (currentSelectedNursery && currentSelectedNurseryRow && currentLocation !== "") ? true : false
    


    let haveGerminationLocations = activeGrow !== undefined && activeGrow.loaded_germination_locations
    
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-30" style={{padding: isMobile ? "0 10px" : "0 100px"}}>
                        <div className={isMobile ? "FlexContent-10" : "FlexContent-20"}>
                            <div className="FlexContent-H-10" style={{alignSelf:"flex-start"}}>
                                <div className="Text-FormHeader-Medium">Remove plants from the following germination chambers and place them into a nursery</div>
                            </div>
                            <div className="FlexContent-10" style={{padding:"16px 0", borderTop: "1px solid #DDE3EB", borderBottom: "1px solid #DDE3EB"}}>
                                {haveGerminationLocations && <>
                                    {Object.entries(activeGrow.germination_locations).length === 0 && <>
                                        <div className="Text-AuxSubtext">Germination locations were not tracked</div>
                                    </>}
                                    {Object.entries(activeGrow.germination_locations).map(([germinationUID, locations]) => {
                                        let germinationChamber = allGerminationChambers.find((gC) => gC.uid === germinationUID)
                                        return (
                                            <div className="FlexContent-10">
                                                <div className="Text-Subtext">{germinationChamber !== undefined ? germinationChamber.display_name : ""}</div>
                                                <div className="FlexWrap FlexWrap-Size2" style={{flexGrow: 0}}>
                                                    {locations.map((location) => {
                                                        return (
                                                            <Tag content={location} status={"Neutral"}/>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>}
                            </div>
                        </div>
                        <div className={isMobile ? "FlexContent-10" : "FlexContent-20"}>
                            <div className="FlexContent-H-10" style={{alignSelf:"flex-start"}}>
                                <div className="Text-FormHeader-Medium">Enter new locations of all of the seedling boards</div>
                            </div>
                            <div className="FlexContent-10">
                                <div className="Grid2 Grid-Space20">
                                    <div className="">
                                        <div className="Text-FormAltLabel-Small" style={{paddingBottom:2}}>Nursery</div>
                                        <DropDownInput
                                            options={nurseryOptions} 
                                            placeholder={"Select nursery"}
                                            onSelectionChange={nurserySelected}/>
                                    </div>
                                    <div className="">
                                        <div className="Text-FormAltLabel-Small" style={{paddingBottom:2}}>Row</div>
                                        <DropDownInput
                                            options={nurseryRowOptions} 
                                            placeholder={"Select row"}
                                            onSelectionChange={nurseryRowSelected}/>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="Text-FormAltLabel-Small" style={{paddingBottom:2}}>Location</div>
                                    <TextInput
                                        placeHolder={"Enter Location"} 
                                        submitButton={<div className="Text-Hyperlink" 
                                        style={{opacity: isLocationValid ? 1 : 0.4}}>Add</div>} 
                                        value={currentLocation}
                                        onChange={currentLocationChanged}
                                        onSubmit={addLocationClicked}/>
                                </div>
                                <div className="FlexContent-10">
                                    {Object.entries(currentNurseryLocations).map(([nurseryUID, nurseryRowLocations]) => {
                                        let nursery = allBladeZones.find((bZ) => bZ.uid === nurseryUID)
                                        return <>
                                            <div className="FlexContent-5">
                                                <div className="Text-FormAltLabel-Small" style={{fontWeigth:400}}>{nursery.display_name}</div>
                                                <div className="FlexWrap FlexWrap-Size2" style={{flexGrow: 0}}>
                                                    {Object.entries(nurseryRowLocations).map(([rowIndex, locations]) => {
                                                        let foundRowOption = nurseryRowOptions.find((nRO) => nRO.value === parseInt(rowIndex))
                                                        return <>
                                                            {locations.map((location) => {
                                                                const removeLocationClicked = () => {
                                                                    removeLocation(nursery.uid, parseInt(rowIndex), location)
                                                                }
                                                                return (
                                                                    <Tag canRemove={true} onRemove={removeLocationClicked} content={foundRowOption.tagLabel + "-" + location} status={"Neutral"}/>
                                                                )
                                                            })}
                                                        </>
                                                    })}
                                                </div>
                                            </div>
                                        </>
                                    })}
                                    {Object.entries(currentNurseryLocations).length === 0 &&                                 
                                        <div className="FlexContent-H-5">
                                            <Tag content={"---"} status={"Neutral"}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Cancel"} status={"Neutral"} onClick={cancelTaskCallback} size={"Flex50"}/>
                <Button status="Primary" content={"Complete Task"} onClick={finishedTransplantingClicked} size={"Flex50"} disabled={Object.entries(currentNurseryLocations).length === 0}/>
            </div>
        </div>
    )
    
}


export default TransplantToNurseryFromGernimationTask_MoveCropsStep