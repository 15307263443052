import './ScheduleGrowPopup.scss';

import React from 'react';
import Button from '../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { RoundToNearest } from '../../../helpers';
import Checkbox from '../../../components/input/Checkbox';
import NumberInput from '../../../components/input/NumberInput';
import { useSelector } from 'react-redux';
import { selectAllGrowPlans } from '../../../redux/entities/GrowPlans';
import { SimpleGrowBag, StandardBerryTroughZone, StandardGrowOutBoard, StandardGrowOutZone, StandardSeedlingBoard } from '../../../assets/icons/ProductIcons';
import { selectAllRecipes } from '../../../redux/entities/Recipes';
import { selectAllRaftTypes } from '../../../redux/entities/RaftTypes';
import { GenericLeaf } from '../../../assets/icons/Icons';
import { calculateUnitsFromZones, calculateZonesFromUnits } from '../../../redux/entities/Grow';

const allBladeTypes = [
    {
        id: 1,
        display_name: "Blade Gen S",
        props: {
            "number_of_nursery_troughs": 7,
            "nursery_trough_length": 144, //in inches
            "number_of_grow_out_board_columns": 6,
            "number_of_grow_out_board_rows": 6,
            "number_of_grow_out_berry_troughs": 6,
            "grow_out_berry_trough_length": 144,       
        }
    }
]

const ScheduleGrow_SelectGrowInfoWalkthroughStep = ({
        selectedGrowPlan,  
        initialSelectedBladeTypeId,
        initialUnitSelectionType,
        initialSelectedUnits, 
        growInfoConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const allGrowPlans = useSelector((state) => selectAllGrowPlans(state))
    const activeGrowPlan = allGrowPlans.find((gP) => gP.id === selectedGrowPlan)
    const allRecipes = useSelector((state) => selectAllRecipes(state))
    const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))

    let selectedRecipe = undefined
    if (activeGrowPlan !== undefined)   {
        selectedRecipe = allRecipes.find((r) => r.id === activeGrowPlan.recipe_id && r.version === activeGrowPlan.recipe_version)
    }
    let selectedSeedlingRaftTypes = []
    let selectedGrowOutRaftType = undefined
    if (activeGrowPlan !== undefined)   {
        for (let variant of activeGrowPlan.plant_variants)  {
            let foundRaftType = allRaftTypes.find((rT) => rT.id === variant.seedling_raft_type_id)
            if (foundRaftType !== undefined)    {
                selectedSeedlingRaftTypes.push(foundRaftType)
            }
        }
        selectedGrowOutRaftType = allRaftTypes.find((rT) => rT.id === activeGrowPlan.grow_out_raft_type_id)
    }

    

    const updateGrowInfoValues = () => {
        growInfoConfirmedCallback({
            bladeTypeId: bladeTypeId,
            numberOfUnits: numberOfUnits,
            unitSelectionType: unitType
        })
    }
    const backClicked = () => {
        updateGrowInfoValues()
        transitionToPreviousStep(["select_grow_plan", null])
    }
    const nextClicked = () =>   {
        updateGrowInfoValues()
        transitionToNextStep(["select_grow_timing", null])
    }

    const [bladeTypeId, SetBladeTypeId] = React.useState(null)
    React.useLayoutEffect(() => {
        SetBladeTypeId(initialSelectedBladeTypeId)
    }, [initialSelectedBladeTypeId])
    const bladeTypeIdSelected = (id) => {
        SetBladeTypeId(id)
    }
    
    const [activeBladeType, SetActiveBladeType] = React.useState(undefined)
    React.useEffect(() => {
        SetActiveBladeType(allBladeTypes.find((bT) => bT.id === bladeTypeId))
    }, [allBladeTypes, bladeTypeId])
    const bladeProps = activeBladeType !== undefined ? activeBladeType.props : {}

    const [unitType, SetUnitType] = React.useState("by_zone")
    const unitTypeSelected = (type) => {
        SetUnitType(type)
        numberOfZonesChanged(RoundToNearest(numberOfZones, 0.01), "ceil")        
    }
    React.useLayoutEffect(() => {
        SetUnitType(initialUnitSelectionType)
    }, [initialUnitSelectionType])


    const [numberOfZones, SetNumberOfZones] = React.useState(0)
    const numberOfZonesChanged = (zones) => {
        SetNumberOfZones(zones)
        SetNumberOfUnits(calculateUnitsFromZones(selectedRecipe, activeBladeType, zones, selectedGrowOutRaftType, activeGrowPlan.grow_out_raft_usage))
    }
    const [numberOfUnits, SetNumberOfUnits] = React.useState(0)
    const numberOfUnitsChanged = (units) => {
        SetNumberOfUnits(units)
        SetNumberOfZones(calculateZonesFromUnits(selectedRecipe, activeBladeType, units, selectedGrowOutRaftType, activeGrowPlan.grow_out_raft_usage))
    }
    React.useEffect(() => {
        updateGrowInfoValues() 
    }, [bladeTypeId, numberOfZones, numberOfUnits])

    React.useLayoutEffect(() => {
        SetNumberOfZones(calculateZonesFromUnits(selectedRecipe, activeBladeType, initialSelectedUnits, selectedGrowOutRaftType, activeGrowPlan.grow_out_raft_usage))
        SetNumberOfUnits(initialSelectedUnits)
    }, [activeBladeType, initialSelectedBladeTypeId, initialSelectedUnits, initialUnitSelectionType, selectedRecipe])
    

    

    const [canContinue, SetCanContinue] = React.useState(false)
    React.useLayoutEffect(() => {
        if (bladeTypeId !== null && numberOfUnits !== 0)  {
            SetCanContinue(true)
        }else {
            SetCanContinue(false)
        }
    }, [numberOfUnits, bladeTypeId])

    const buildResultContent = (() => {
        let numberOfSeedlingBoards = 0
        if (numberOfUnits !== 0) {
            if (activeGrowPlan !== undefined)   {
                for (let variant of activeGrowPlan.plant_variants)  {
                    let foundRaftType = allRaftTypes.find((rT) => rT.id === variant.seedling_raft_type_id)
                    if (foundRaftType !== undefined)    {
                        numberOfSeedlingBoards += Math.ceil((numberOfUnits * (variant.ratio / 100)) / foundRaftType.info.number_of_plant_sites)
                    }
                }
            }
        }

        

        let numberOfUnitsAvailableWithinUsedZones = calculateUnitsFromZones(selectedRecipe, activeBladeType, Math.ceil(numberOfZones), selectedGrowOutRaftType, activeGrowPlan.grow_out_raft_usage)
        let percentOfAvailableUnitsWithinZones = (numberOfUnits / numberOfUnitsAvailableWithinUsedZones) * 100
        
        // Math.ceil(numberOfZones)
                            


        return (
            <div className="FlexContent-HFill">
                <div className="FlexContent-H-10">
                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Icon"><GenericLeaf/></div>
                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Display">{numberOfUnits}/{numberOfUnitsAvailableWithinUsedZones} Plant Sites ({RoundToNearest(percentOfAvailableUnitsWithinZones, 1)}%)</div>
                </div>
                <div className="FlexContent-H-10">
                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Icon"><StandardSeedlingBoard/></div>
                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Display">{numberOfSeedlingBoards} Seedling Boards</div>
                </div>
                {(selectedRecipe !== undefined && selectedSeedlingRaftTypes.length !== 0 && selectedGrowOutRaftType !== undefined) && <>
                    {selectedRecipe.grow_out_type === "grow_boards" && <>
                    {(() => {
                            let numberOfGrowOutBoards = 0
                            if (activeGrowPlan !== undefined)   {
                                numberOfGrowOutBoards = Math.ceil(numberOfUnits / parseFloat(activeGrowPlan.grow_out_raft_usage))
                            }

                            let remainderOfZones = (numberOfZones % 1) * 100
                            let zonesUsageStyle = {}
                            if (remainderOfZones > 0 && remainderOfZones <= 33)  {
                                zonesUsageStyle.color = "#D82C0D"
                            }else if (remainderOfZones > 33 && remainderOfZones < 66)    {
                                zonesUsageStyle.color = "#E99E0B"
                            }

                            return <>
                                <div className="FlexContent-H-10">
                                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Icon"><StandardGrowOutZone/></div>
                                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Display" style={zonesUsageStyle}>
                                        {RoundToNearest(numberOfZones, 0.01)} Grow Out Zones
                                        </div>
                                </div>
                                <div className="FlexContent-H-10">
                                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Icon"><StandardGrowOutBoard/></div>
                                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Display">{numberOfGrowOutBoards} Grow Out Boards</div>
                                </div>
                            </>
                        })()}
                    </>}
                    {selectedRecipe.grow_out_type === "berry_troughs" && <>
                        {(() => {
                            let numberOfBerryTroughs = 0
                            if (activeGrowPlan !== undefined)   {
                                numberOfBerryTroughs = Math.ceil(numberOfUnits / parseFloat(activeGrowPlan.grow_out_raft_usage))
                            }

                            let remainderOfZones = (numberOfZones % 1) * 100
                            let zonesUsageStyle = {}
                            if (remainderOfZones > 0 && remainderOfZones <= 33)  {
                                zonesUsageStyle.color = "#D82C0D"
                            }else if (remainderOfZones > 33 && remainderOfZones < 66)    {
                                zonesUsageStyle.color = "#E99E0B"
                            }
                            
                            return <>
                                <div className="FlexContent-H-10">
                                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Icon"><StandardBerryTroughZone/></div>
                                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Display" style={zonesUsageStyle}>
                                        {RoundToNearest(numberOfZones, 0.01)} Berry Trough Zones
                                        </div>
                                </div>
                                <div className="FlexContent-H-10">
                                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Icon"><SimpleGrowBag/></div>
                                    <div className="ScheduleGrowPopup_GrowInfoUnitResults-Display">{numberOfBerryTroughs} Grow Bags</div>
                                </div>
                            </>
                        })()}
                    </>}
                </>}
            </div>
        )
    })

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper  Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    {isMobile && <>
                        <div className="FlexContent-20">
                            <div className="FlexContent-40">
                                <div className="FlexContent-10 FlexContent-HFill">
                                    <div style={{display:"flex", alignContent: "flex-start", alignSelf: "stretch"}}>Which blade type would you like to use?</div>
                                    {allBladeTypes.map((bladeType) => {
                                        return <>
                                            <Button 
                                                status="Primary-Toggle" 
                                                state={bladeType.id === 1} 
                                                content={bladeType.display_name}
                                                width={"Flex100"}
                                                onClick={() => {bladeTypeIdSelected(bladeType.id)}}/>                                        
                                        </>
                                    })}
                                </div>
                                <div className="FlexContent-10 FlexContent-HFill">
                                    <div style={{display:"flex", alignContent: "flex-start", alignSelf: "stretch"}}>How much do you want to grow?</div>
                                    <div className="FlexContent-10 FlexContent-HFill">
                                        <Button 
                                            status="Primary-Toggle" 
                                            state={unitType === "by_zone"} 
                                            content="By Zone" 
                                            width={"Flex100"} 
                                            onClick={() => {unitTypeSelected("by_zone")}}/>
                                        <Button 
                                            status="Primary-Toggle" 
                                            state={unitType === "by_unit"} 
                                            content="By Unit" 
                                            width={"Flex100"} 
                                            onClick={() => {unitTypeSelected("by_unit")}}/>
                                    </div>
                                    <div className="FlexContent-HFill">
                                        {unitType === "by_zone" && <>
                                            <NumberInput value={numberOfZones} onBlur={numberOfZonesChanged} suffix={"Zones"} min={0} stepResolution={1} displayResolution={0.01}/>
                                        </>}
                                        {unitType === "by_unit" && <>
                                            <NumberInput value={numberOfUnits} onBlur={numberOfUnitsChanged} suffix={"Units"} min={0}/>
                                        </>}
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "100%", height: 1, background:"#DDE3EB"}}></div>
                            <div style={{alignSelf:"center"}}>
                                {buildResultContent()}
                            </div>
                        </div>
                    </>}
                    {!isMobile && <>
                        <div className="FlexContent-H-40 FlexContent-HFlex" style={{margin: "0 60px"}}>
                            <div className="FlexContent-40">
                                <div className="FlexContent-10 FlexContent-HFill">
                                    <div style={{display:"flex", alignContent: "flex-start", alignSelf: "stretch"}}>Which blade type would you like to use?</div>
                                    {allBladeTypes.map((bladeType) => {
                                        return <>
                                            <Button 
                                                status="Primary-Toggle" 
                                                state={bladeType.id === 1} 
                                                content={bladeType.display_name}
                                                width={"Flex100"}
                                                onClick={() => {bladeTypeIdSelected(bladeType.id)}}/>                                        
                                        </>
                                    })}
                                </div>
                                <div className="FlexContent-10 FlexContent-HFill">
                                    <div style={{display:"flex", alignContent: "flex-start", alignSelf: "stretch"}}>How much do you want to grow?</div>
                                    <div className="FlexContent-H-10 FlexContent-HFill">
                                        <Button 
                                            status="Primary-Toggle" 
                                            state={unitType === "by_zone"} 
                                            content="By Zone" 
                                            width={"Flex50"} 
                                            onClick={() => {unitTypeSelected("by_zone")}}/>
                                        <Button 
                                            status="Primary-Toggle" 
                                            state={unitType === "by_unit"} 
                                            content="By Unit" 
                                            width={"Flex50"} 
                                            onClick={() => {unitTypeSelected("by_unit")}}/>
                                    </div>
                                    <div className="FlexContent-HFill">
                                        {unitType === "by_zone" && <>
                                            <NumberInput value={numberOfZones} onBlur={numberOfZonesChanged} suffix={"Zones"} min={0} stepResolution={1} displayResolution={0.01}/>
                                        </>}
                                        {unitType === "by_unit" && <>
                                            <NumberInput value={numberOfUnits} onBlur={numberOfUnitsChanged} suffix={"Units"} min={0}/>
                                        </>}
                                    </div>
                                </div>
                            </div>
                            <div style={{width: 1, height: "100%", background:"#DDE3EB"}}></div>
                            <div style={{alignSelf:"center"}}>
                                {buildResultContent()}
                            </div>
                        </div>
                    </>}
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Continue"} disabled={!canContinue} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default ScheduleGrow_SelectGrowInfoWalkthroughStep