import './HarvestTask.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllBladeZones } from '../../../../redux/entities/service/Blade';
import { GenericLeaf } from '../../../../assets/icons/Icons';
import DropDownInput from '../../../../components/input/DropDownInput';


const HarvestTask_ContainerContentsWalkthroughStep = ({
        allPlantVarieties,
        activeGrow,
        activeGrowPlan,
        harvestingZoneUID,
        containerOption,
        initialContainerContents,
        containerContentsConfirmedCallback,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = () => {
        transitionToPreviousStep(["prepare_for_harvest", null])
    }
    const nextClicked = () => {
        containerContentsConfirmedCallback({containerContents: containerContents})
        transitionToNextStep(["container_weight", null])
    }


    const [containerContents, SetContainerContents] = React.useState("mix")
    React.useLayoutEffect(() => {
        if (initialContainerContents !== null)  {
            SetContainerContents(initialContainerContents)
        }
    }, [initialContainerContents])
    const [otherContainerContentsSelected, SetOtherContainerContentsSelected] = React.useState(null)
    React.useLayoutEffect(() => {
        if (otherContainerContentsSelected === null && activeGrowPlan !== undefined)    {
            let foundPlantVariety = allPlantVarieties.find((pV) => pV.id === activeGrowPlan.plant_variants[0].plant_variety_id)
            if (foundPlantVariety !== undefined)    {
                SetOtherContainerContentsSelected(foundPlantVariety.id)
            }
        }
    }, [activeGrowPlan, allPlantVarieties, otherContainerContentsSelected])



    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Select Container Contents
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={isMobile ? "FlexContent-10" : "FlexContent-H-10"} style={{flex:1}}>
                    <div className="FlexContent" style={isMobile ? {height: "50%"} : {width: "50%"}}>
                        <Button content={<>
                                <div className={isMobile ? "FlexContent-H-30" : "FlexContent-30"} style={{padding: isMobile ? "30px 20px" : "40px 30px", flex: 1}}>
                                <GenericLeaf fill={"#262A31"}/>
                                <div className="Text-Medium-S20" style={{textAlign:"left"}}>Mix</div>
                            </div>
                        </>} status={"Primary-Toggle"} state={containerContents === "mix"} width={"Flex100"} onClick={() => {SetContainerContents("mix")}}/>
                    </div>
                    {activeGrowPlan !== undefined && <>
                        {activeGrowPlan.plant_variants.length <= 4 && <>
                            {isMobile && <>
                                <div className="FlexContent-10" style={{height: "50%"}}>
                                    {activeGrowPlan.plant_variants.map((plantVariant) => {
                                        let foundPlantVariety = allPlantVarieties.find((pV) => pV.id === plantVariant.plant_variety_id)
                                        if (foundPlantVariety !== undefined)    {
                                            return <>
                                                <Button content={<>
                                                    <div className={"FlexContent-H-30"} style={{padding: "0px 20px", flex: 1}}>
                                                        <GenericLeaf fill={"#262A31"}/>
                                                        <div className="Text-Medium-S20" style={{textAlign:"left"}}>{foundPlantVariety.name}</div>
                                                    </div>
                                                </>} status={"Primary-Toggle"} state={containerContents === plantVariant.plant_variety_id} width={"Flex100"} onClick={() => {SetContainerContents(plantVariant.plant_variety_id)}}/>
                                            </>
                                        }
                                    })}
                                </div>
                            </>}
                            {!isMobile && <>
                                {activeGrowPlan.plant_variants.length <= 3 && <>
                                    <div className="FlexContent-10" style={{flex:1}}>
                                        {activeGrowPlan.plant_variants.map((plantVariant) => {
                                            let foundPlantVariety = allPlantVarieties.find((pV) => pV.id === plantVariant.plant_variety_id)
                                            
                                            if (foundPlantVariety !== undefined)    {
                                                return <>
                                                    <Button content={<>
                                                        <div className={"FlexContent-H-30"} style={{padding: "30px 20px", flex: 1}}>
                                                            <GenericLeaf fill={"#262A31"}/>
                                                            <div className="Text-Medium-S20" style={{textAlign:"left"}}>{foundPlantVariety.name}</div>
                                                        </div>
                                                    </>} status={"Primary-Toggle"} state={containerContents === plantVariant.plant_variety_id} width={"Flex100"} onClick={() => {SetContainerContents(plantVariant.plant_variety_id)}}/>
                                                </>
                                            }
                                        })}
                                    </div>
                                </>}
                                {(activeGrowPlan.plant_variants.length === 4) && <>
                                    <div className="FlexWrap FlexWrap-Size2" style={{flex:1}}>
                                        {activeGrowPlan.plant_variants.map((plantVariant) => {
                                            let foundPlantVariety = allPlantVarieties.find((pV) => pV.id === plantVariant.plant_variety_id)
                                            if (foundPlantVariety !== undefined)    {
                                                return <>
                                                    <Button content={<>
                                                        <div className={"FlexContent-H-30"} style={{padding: "30px 20px", flex: 1}}>
                                                            <GenericLeaf fill={"#262A31"}/>
                                                            <div className="Text-Medium-S20" style={{textAlign:"left"}}>{foundPlantVariety.name}</div>
                                                        </div>
                                                    </>} status={"Primary-Toggle"} state={containerContents === plantVariant.plant_variety_id} width={"Flex100"} onClick={() => {SetContainerContents(plantVariant.plant_variety_id)}}/>
                                                </>
                                            }
                                        })}
                                    </div>
                                </>}
                            </>}
                        </>}
                        {activeGrowPlan.plant_variants.length >= 5 && <>
                            <div className="FlexContent" style={isMobile ? {height: "50%"} : {width: "50%"}}>
                                {(() => {
                                    let otherPlantClicked = () => {
                                        SetContainerContents(otherContainerContentsSelected)
                                    }
                                    let otherPlantTypeSelected = (plantType) => {
                                        SetOtherContainerContentsSelected(plantType)
                                        SetContainerContents(plantType)
                                    }
                                    let plantTypeOptions = activeGrowPlan.plant_variants.map((plantVariant) => {
                                        let foundPlantVariety = allPlantVarieties.find((pV) => pV.id === plantVariant.plant_variety_id)
                                        return {
                                            value: plantVariant.plant_variety_id,
                                            label: foundPlantVariety !== undefined ? foundPlantVariety.name : "Plant Variety"
                                        }
                                    })

                                    return <>
                                        <Button content={<>
                                            <div className={isMobile ? "FlexContent-H-30" : "FlexContent-30"} style={{padding: isMobile ? "30px 20px" : "40px 30px", flex: 1}}>
                                                <GenericLeaf fill={"#262A31"}/>
                                                <div className="Text-Medium-S20" style={{textAlign:"left"}}>Other</div>
                                                <DropDownInput value={otherContainerContentsSelected} placeholder={"Select Plant"} options={plantTypeOptions} onSelectionChange={otherPlantTypeSelected}/>                              
                                            </div>
                                        </>} status={"Primary-Toggle"} state={containerContents !== null && containerContents !== "mix"} width={"Flex100"} onClick={otherPlantClicked}/>                                    
                                    </>
                                })()}
                            </div>
                        </>}
                    </>}
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Next"} onClick={nextClicked} size={"Flex50"} disabled={containerContents === null}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_ContainerContentsWalkthroughStep