import '../GrowZonesPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalOptions, Pause, Play } from '../../../assets/icons/Icons';
import DropDownButton from '../../../components/DropDownButton';
import { getLiveDataItem, getUniqueConfigurationByName, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeIdsForBladeZoneUID, selectBladeLiveDataByComponentInfo, selectBladePrimaryLiquidSystemStatus, selectBladeRuntimeInformation, selectBladeUniqueConfigurationByName, selectBladeZoneRuntimeInformation, selectBladeZoneTypeByUID, setBladeRuntimeProperty, setBladeZoneRuntimeProperty } from '../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../helpers';
import Button from '../../../components/Button';



const GrowZone_StatusContent_PrimaryWaterCellForFlow = ({ zoneUID }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 }); 
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  const zoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)
  
  const waterReservoirBladeId = (zoneType === "nursery" ? bladeIds["nursery"] : bladeIds["grow"])

  const [liquidControlStatus] = useSelector((state) => selectBladeRuntimeInformation(state, waterReservoirBladeId, "primary_liquid_control_status"))
  const liquidStatus = useSelector((state) => selectBladePrimaryLiquidSystemStatus(state, waterReservoirBladeId), _.isEqual)

  const reservoirMinConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, waterReservoirBladeId, "PrimaryReservoirLowLiquidVolume"), _.isEqual)
  const reservoirMaxConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, waterReservoirBladeId, "PrimaryReservoirFullLiquidVolume"), _.isEqual)
  const reservoirMin = reservoirMinConfiguration ?? 0
  const reservoirMax = reservoirMaxConfiguration ?? 20


  const [reservoirVolumeUsageComponentInfo, reservoirVolumeComponentInfo, waterECComponentInfo, waterPHComponentInfo, waterDOComponentInfo, waterORPComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "PrimaryWaterReservoir", ["water_level", "water_volume", "ec", "ph", "do", "orp"]), _.isEqual)
  const [waterTemperatureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "PrimaryReservoirOWTB", ["t"]), _.isEqual)
  const [liquidPressureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "PrimaryLiquidSystemPressureTransducer", ["pressure"]), _.isEqual)
  const [waterECSetpointComponentInfo, waterPHSetpointComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "GrowoutSetpointMonitor", ["ec-0-sp", "ph-0-sp"]), _.isEqual)

  let reservoirVolume = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, reservoirVolumeComponentInfo), _.isEqual)
  let reservoirVolumeUsage = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, reservoirVolumeUsageComponentInfo), _.isEqual)
  let liquidPressure = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, liquidPressureComponentInfo), _.isEqual)
  let waterTemperature = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterTemperatureComponentInfo), _.isEqual)
  let waterEC = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterECComponentInfo), _.isEqual)
  let waterECSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterECSetpointComponentInfo), _.isEqual)
  let waterPH = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterPHComponentInfo), _.isEqual)
  let waterPHSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterPHSetpointComponentInfo), _.isEqual)
  let waterDO = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterDOComponentInfo), _.isEqual)
  let waterORP = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterORPComponentInfo), _.isEqual)


  let dataListItems = [
    {
      type: "large_bar", label: "Reservoir Volume", value: reservoirVolumeUsage.value, valueToDisplay: reservoirVolume.value, suffix: "L", min: 0, max: 100, status: "normal"
    },
    {
      type: "setpoint_bar", label: "EC", value: waterEC.value, setpoint: waterECSetpoint.value, suffix: "mS/m", range: 500, status: "normal"
    },
    {
      type: "setpoint_bar", label: "pH", value: waterPH.value, setpoint: waterPHSetpoint.value, suffix: "", range: 2, status: "normal"
    },
    {
      label: "Pressure", value: liquidPressure.value, suffix: "psi", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 130, status: "normal" }
      }
    },
    {
      label: "Temperature", value: waterTemperature.value, suffix: "°C"
    },
    {
      label: "DO", value: waterDO.value, suffix: "ppm",
    },
    {
      label: "ORP", value: waterORP.value, suffix: "mV",
    },
  ]


  let additionalFunctions = [
    
  ]



  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Zone Reservoir</div>
          <div className="DataStatus-Cell-Header-PrimaryContent-StatusChip">{liquidStatus}</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} options={additionalFunctions} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default GrowZone_StatusContent_PrimaryWaterCellForFlow