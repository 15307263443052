import '../FlowsPage.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore'; 
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button'; 
import { AdditionalOptions, Pause, Play, TrendDownArrow, TrendUpArrow } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { selectFlowComponentInfo, selectFlowLiveDataByComponentInfo } from '../../../../redux/entities/service/Flow';


const FlowInstance_StatusContent_PHSensorCell = ({ flowId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  
  const [leftSensorPHComponentInfo, rightSensorPHComponentInfo, activePHComponentInfo] 
  = useSelector((state) => selectFlowComponentInfo(state, flowId, "LiquidPHSensor", ["input-1", "input-2", "ph"]), _.isEqual)
  const leftSensorPH = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, leftSensorPHComponentInfo), _.isEqual)
  const rightSensorPH = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, rightSensorPHComponentInfo), _.isEqual)
  const activePH = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, activePHComponentInfo), _.isEqual)
  
  let dataListItems = [
    {label: "Active PH", value: activePH.value, suffix: null, visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 14, status: "normal"}
    }},  
    {label: "Left PH", value: leftSensorPH.value, suffix: null, visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 14, status: "normal"}
    }},  
    {label: "Right PH", value: rightSensorPH.value, suffix: null, visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 14, status: "normal"}
    }},  
  ]

  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">PH Sensor</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} /> 
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default FlowInstance_StatusContent_PHSensorCell