import './../TasksPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate } from '../../../helpers'
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import Button from '../../../components/Button';
import { getFlowById, getFlowProperty, setFlowProperty} from '../../../redux/entities/service/Flow';
import ControlBar from '../../../components/ControlBar';
import PopupModal from '../../../model_components/PopupModal';
import NumberInput from '../../../components/input/NumberInput';
  

const TaskPopup_FlowSensorCalibration = ({task, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

 
  const closePopup = () =>  {
    onClosePopup()
  }

  const [currentTimelineStep, SetCurrentTimelineStep] = React.useState("select_sensor_bank")
  const timelineSteps = {
      select_sensor_bank: { render: (<span>Sensor bank</span>) },
      calibrating: { render: (<span>Calibrating</span>) },
      finished_calibrating : { render: (<span>Finalize</span>) },
      pH_calibration : { render: (<span>pH</span>) },
      EC_calibration : { render: (<span>EC</span>) },
      DO_calibration : { render: (<span>DO</span>) },
      ORP_calibration : { render: (<span>ORP</span>) },
      temp_calibration : { render: (<span>Temperature</span>) },
  }


  let ndsList = useSelector((state) => state.Flow.Flow)
  const [Flow, SetFlow] = React.useState(null)

  const [selectedSensorBank, SetSelectedSensorBank] = React.useState(null)
  //const [selectedSensorBankInBypass, SetSelectedSensorBankInBypass] = React.useState(null)
  const [selectedSensorType, SetSelectedSensorType] = React.useState(null)

  const [pendingSensorCalibration, SetPendingSensorCalibration] = React.useState(null)
  
  
  React.useEffect(() => {
    let foundFlow = ndsList.find((n) => n.id === task.reference_id)
    if (foundFlow === undefined)  {
      dispatch(getFlowById({FlowId: task.reference_id}))
    }else {
      SetFlow({...foundFlow})
    }
  }, [task, ndsList])


  const requestLoopRef = React.useRef({lastRequestLoopCompletedOn: 0})
  const requestLoop = React.useCallback(() => {
    if (Flow)  {
      dispatch(getFlowProperty({FlowId: Flow.id, keys: ["sensor_banks", "sensors"]}))
    }

    requestLoopRef.current.lastRequestLoopCompletedOn = new Date().getTime()
  })
  React.useEffect(() => {
    if (Flow)  {

      //Step #1: Make sure we know the number of banks
      if (!Flow.numberOfSensorBanks) {
        dispatch(getFlowProperty({FlowId: Flow.id, key: "number_of_sensor_banks"}))
        return
      }
    }

    //Step #2: Set up recurring load runtime properties loop
    const requestInterval = setInterval(() => {
      if (requestLoopRef.current.lastRequestLoopCompletedOn + 1000 < new Date().getTime()) {
          requestLoop()
      }
    }, 1);
    return () => {
      clearInterval(requestInterval)
    };
  }, [Flow])

  



  const switchToSensorBanksSelectionClicked = React.useCallback(() => {
    SetCurrentTimelineStep("select_sensor_bank")
  })
  const switchToSensorTypeSelectionClicked = React.useCallback(() => {
    SetCurrentTimelineStep("calibrating")
  })
  const switchToFinishCalibratingPromptClicked = React.useCallback(() => {
    SetCurrentTimelineStep("finished_calibrating")
  })


  const beginCalibratingClicked = React.useCallback(() => {
    if (selectedSensorBank) {
      SetCurrentTimelineStep("calibrating")
    }
    //dispatch(markTaskAsCompleted({taskId: task.id, params: {"number_of_plants": selectedNumberOfPlugs}}))
    
  })

  

  const startTaskClicked = React.useCallback(() => {
    //dispatch(markTaskAsStarted({taskId: task.id}))
    //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
  })

  const completeTaskClicked = React.useCallback(() => {
    //dispatch(markTaskAsCompleted({taskId: task.id, params: {"number_of_plants": selectedNumberOfPlugs}}))
    onClosePopup()
  })


  



  let bodyContent = (<></>)
  let footerContent = (<></>)
  const isInBypass = (selectedSensorBank && Flow && Flow.sensorBanks && Flow.sensorBanks[selectedSensorBank] && Flow.sensorBanks[selectedSensorBank]["in_bypass"])
  switch (currentTimelineStep)    {
      case "select_sensor_bank":
          const sensorBankClicked = (sensorBankIndex) => {
            SetSelectedSensorBank(sensorBankIndex)
          }

          bodyContent = (<>
            {Flow && 
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  Select a sensor bank to begin calibration
                </div>
                <div className="PopupModal-FormItem-Answer">
                  
                  {Flow.numberOfSensorBanks && 
                    <div className="FlowSensorCalibration-SensorBanks">
                      {new Array(Flow.numberOfSensorBanks).fill(0).map((_, i) => {
                        var sensorBankIndex = i + 1
                        return (
                          <div 
                            key={sensorBankIndex}
                            className={"FlowSensorCalibration-SensorBank FlowSensorCalibration-SensorBank-Selectable " + (selectedSensorBank == sensorBankIndex ? "FlowSensorCalibration-SensorBank-Selected" : "")}
                            onClick={(e) => {sensorBankClicked(sensorBankIndex)}}>
                            <div className="FlowSensorCalibration-SensorBank-Header">
                              Sensor Bank {sensorBankIndex}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  }
                </div>
              </div>
            }
          </>)
          footerContent = (
              <ControlBar controls={(<>
                  <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
              </>)} secondaryControls={(<>
                  {selectedSensorBank && <Button status="Primary" content="Begin calibrating" onClick={beginCalibratingClicked}/>}
              </>)}/>
          )
          break
      case "calibrating":
        const markSensorBankAsInBypass = () =>  {
          dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_bank_bypass_state": 1, "sensor_bank_index": selectedSensorBank}}))
        }

        const sensorTypeClicked = (sensorType) => {
          SetSelectedSensorType(sensorType)
          SetCurrentTimelineStep(sensorType + "_calibration")
        }

        //If there is a selected sensor, we should resolve body content to the sensor calibration procedure

        const sensorTypes = {
          "pH": "pH Sensor",
          "EC": "EC Sensor",
          "DO": "DO Sensor",
          "ORP": "ORP Sensor",
          "temp": "Temperature Sensor"}
          
        bodyContent = (<>
            {!isInBypass &&
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  <span>Place <span style={{fontWeight:"bold"}}>Sensor Bank {selectedSensorBank}</span> into bypass</span>
                </div>
                <div className="PopupModal-FormItem-Answer">
                  <div style={{display:"flex", alignSelf:"flex-start"}}>
                    <Button content={"Confirm - In Bypass"} status={"Neutral"} onClick={markSensorBankAsInBypass}/>
                  </div>
                </div>
              </div>
            }

            {!!isInBypass && <>
              <div className="PopupModal-FormItem">
                <div 
                  className="FlowSensorCalibration-SensorBank">
                  <div className="FlowSensorCalibration-SensorBank-Header">
                    Sensor Bank {selectedSensorBank} - In Bypass
                  </div>
                </div>
              </div>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  Select a sensor type to begin calibrating
                </div>
                <div className="PopupModal-FormItem-Answer">
                  <div className="FlowSensorCalibration-SensorTypes">
                    {Object.keys(sensorTypes).map((sensorType) => {

                      return (
                        <div
                          key={sensorType} 
                          className="FlowSensorCalibration-SensorType"
                          onClick={(e) => {sensorTypeClicked(sensorType)}}>
                          <div className="FlowSensorCalibration-SensorType-Header">
                            {sensorTypes[sensorType]}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  

                </div>
              </div>
            </>}
          
        </>)
        footerContent = (
            <ControlBar controls={(<>
              {(Flow === null || Flow.sensorBanks === null || Flow.sensorBanks[selectedSensorBank] === undefined || !Flow.sensorBanks[selectedSensorBank]["in_bypass"]) && <>
                <Button content={"Previous: Sensor Banks"} status={"Neutral"} onClick={switchToSensorBanksSelectionClicked}/>
              </>}
            </>)} secondaryControls={(<>
              {!!(Flow === null || Flow.sensorBanks === null || Flow.sensorBanks[selectedSensorBank] === undefined || Flow.sensorBanks[selectedSensorBank]["in_bypass"]) && <>
                <Button content={"Finish Calibrating"} status={"Neutral"} onClick={switchToFinishCalibratingPromptClicked}/>
              </>}
            </>)}/>
        )
        break

      case "EC_calibration":
        

        //const ECCalibrationState = Flow.sensors !== null && Flow.sensors["EC"] !== undefined && Flow.sensors["EC"][selectedSensorBank] !== undefined && Flow.sensors["EC"][selectedSensorBank]["calibrated"]
        const ECUserCalibratingState = Flow.sensors !== null && Flow.sensors["EC"] !== undefined && Flow.sensors["EC"][selectedSensorBank] !== undefined && Flow.sensors["EC"][selectedSensorBank]["user_calibrating"]

        const ECDryCalibrationState = Flow.sensors !== null && Flow.sensors["EC"] !== undefined && Flow.sensors["EC"][selectedSensorBank] !== undefined && Flow.sensors["EC"][selectedSensorBank]["dry-end_calibrated"]
        const ECDryCalibratingState = Flow.sensors !== null && Flow.sensors["EC"] !== undefined && Flow.sensors["EC"][selectedSensorBank] !== undefined && Flow.sensors["EC"][selectedSensorBank]["dry-end_calibrating"]
        const ECLowCalibrationState = Flow.sensors !== null && Flow.sensors["EC"] !== undefined && Flow.sensors["EC"][selectedSensorBank] !== undefined && Flow.sensors["EC"][selectedSensorBank]["low-end_calibrated"]
        const ECLowCalibratingState = Flow.sensors !== null && Flow.sensors["EC"] !== undefined && Flow.sensors["EC"][selectedSensorBank] !== undefined && Flow.sensors["EC"][selectedSensorBank]["low-end_calibrating"]
        const ECHighCalibrationState = Flow.sensors !== null && Flow.sensors["EC"] !== undefined && Flow.sensors["EC"][selectedSensorBank] !== undefined && Flow.sensors["EC"][selectedSensorBank]["high-end_calibrated"]
        const ECHighCalibratingState = Flow.sensors !== null && Flow.sensors["EC"] !== undefined && Flow.sensors["EC"][selectedSensorBank] !== undefined && Flow.sensors["EC"][selectedSensorBank]["high-end_calibrating"]
        
        if (pendingSensorCalibration === null)  {
          if (ECDryCalibratingState)  {
            SetPendingSensorCalibration("EC-dry")
          }else if (ECLowCalibratingState)  {
            SetPendingSensorCalibration("EC-low")
          }else if (ECHighCalibratingState)  {
            SetPendingSensorCalibration("EC-high")
          }
        }
        
        
        //We are in a state where we should prompt user to confirm calibration
        if (!ECUserCalibratingState)  {
          const beginCalibrationClicked = () => {
            dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_initialize_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "EC"}}))
          }

          bodyContent = (<>
            <div className="PopupModal-FormItem">
              <div className="PopupModal-FormItem-Question">
                Calibration requirements presented here
              </div>
            </div>
          </>)
          footerContent = (
            <ControlBar controls={(<>
              <Button content={"Previous: Sensor Type Selection"} status={"Neutral"} onClick={switchToSensorTypeSelectionClicked}/>
            </>)} secondaryControls={(<>
              <Button content={"Begin Calibration"} status={"Neutral"} onClick={beginCalibrationClicked}/>
            </>)}/>
          )
        }else {
          //We are already calibrating, show the necessary calibration procedure
          if (!ECDryCalibrationState) {
            if (!ECDryCalibratingState) {
              const sensorReadyClicked = () =>  {
                dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_ready_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "EC", "sensor_cal_step": "dry"}}))
              }

              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Instructions for prepping dry calibration here...
                    <br/><br/>
                    Please prompt when sensor is ready to calibrate
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>
                  
                </>)} secondaryControls={(<>
                  <Button content={"Sensor Ready"} status={"Neutral"} onClick={sensorReadyClicked}/>
                </>)}/>
              )
            }else {
              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Sensor is calibrating. Please wait...
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>

                </>)} secondaryControls={(<>
                  
                </>)}/>
              )

            }
          }else if (pendingSensorCalibration === "EC-dry")  {
            const nextClicked = () => {
              SetPendingSensorCalibration(null)
            }
            bodyContent = (<>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  EC Sensor dry calibrated. Please click next
                </div>
              </div>
            </>)
            footerContent = (
              <ControlBar controls={(<>

              </>)} secondaryControls={(<>
                <Button content={"Next: Low End"} status={"Neutral"} onClick={nextClicked}/>
              </>)}/>
            )


            
          }else if (!ECLowCalibrationState) {
            if (!ECLowCalibratingState) {
              const sensorReadyClicked = () =>  {
                dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_ready_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "EC", "sensor_cal_step": "low"}}))
              }

              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Instructions for prepping low calibration here...
                    <br/><br/>
                    Please prompt when sensor is ready to calibrate
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>
                  
                </>)} secondaryControls={(<>
                  <Button content={"Sensor Ready"} status={"Neutral"} onClick={sensorReadyClicked}/>
                </>)}/>
              )
            }else {
              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Sensor is calibrating. Please wait...
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>

                </>)} secondaryControls={(<>
                  
                </>)}/>
              )

            }
          }else if (pendingSensorCalibration === "EC-low")  {
            const nextClicked = () => {
              SetPendingSensorCalibration(null)
            }
            bodyContent = (<>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  EC Sensor low calibrated. Please click next
                </div>
              </div>
            </>)
            footerContent = (
              <ControlBar controls={(<>

              </>)} secondaryControls={(<>
                <Button content={"Next: High End"} status={"Neutral"} onClick={nextClicked}/>
              </>)}/>
            )



          }else if (!ECHighCalibrationState) {
            if (!ECHighCalibratingState) {
              const sensorReadyClicked = () =>  {
                dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_ready_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "EC", "sensor_cal_step": "high"}}))
              }

              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Instructions for prepping high calibration here...
                    <br/><br/>
                    Please prompt when sensor is ready to calibrate
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>
                  
                </>)} secondaryControls={(<>
                  <Button content={"Sensor Ready"} status={"Neutral"} onClick={sensorReadyClicked}/>
                </>)}/>
              )
            }else {
              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Sensor is calibrating. Please wait...
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>

                </>)} secondaryControls={(<>
                  
                </>)}/>
              )

            }
          }else if (pendingSensorCalibration === "EC-high")  {
            const finishClicked = () => {
              SetPendingSensorCalibration(null)
              switchToSensorTypeSelectionClicked()
            }
            bodyContent = (<>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  EC Sensor high calibrated. Sensor calibration is now complete.
                </div>
              </div>
            </>)
            footerContent = (
              <ControlBar controls={(<>

              </>)} secondaryControls={(<>
                <Button content={"Finished"} status={"Neutral"} onClick={finishClicked}/>
              </>)}/>
            )
          }

        }

        break


      case "pH_calibration":


        const pHUserCalibratingState = Flow.sensors !== null && Flow.sensors["pH"] !== undefined && Flow.sensors["pH"][selectedSensorBank] !== undefined && Flow.sensors["pH"][selectedSensorBank]["user_calibrating"]

        const pHMidCalibrationState = Flow.sensors !== null && Flow.sensors["pH"] !== undefined && Flow.sensors["pH"][selectedSensorBank] !== undefined && Flow.sensors["pH"][selectedSensorBank]["mid-end_calibrated"]
        const pHMidCalibratingState = Flow.sensors !== null && Flow.sensors["pH"] !== undefined && Flow.sensors["pH"][selectedSensorBank] !== undefined && Flow.sensors["pH"][selectedSensorBank]["mid-end_calibrating"]
        const pHLowCalibrationState = Flow.sensors !== null && Flow.sensors["pH"] !== undefined && Flow.sensors["pH"][selectedSensorBank] !== undefined && Flow.sensors["pH"][selectedSensorBank]["low-end_calibrated"]
        const pHLowCalibratingState = Flow.sensors !== null && Flow.sensors["pH"] !== undefined && Flow.sensors["pH"][selectedSensorBank] !== undefined && Flow.sensors["pH"][selectedSensorBank]["low-end_calibrating"]
        const pHHighCalibrationState = Flow.sensors !== null && Flow.sensors["pH"] !== undefined && Flow.sensors["pH"][selectedSensorBank] !== undefined && Flow.sensors["pH"][selectedSensorBank]["high-end_calibrated"]
        const pHHighCalibratingState = Flow.sensors !== null && Flow.sensors["pH"] !== undefined && Flow.sensors["pH"][selectedSensorBank] !== undefined && Flow.sensors["pH"][selectedSensorBank]["high-end_calibrating"]
        
        if (pendingSensorCalibration === null)  {
          if (pHMidCalibratingState)  {
            SetPendingSensorCalibration("pH-mid")
          }else if (pHLowCalibratingState)  {
            SetPendingSensorCalibration("pH-low")
          }else if (pHHighCalibratingState)  {
            SetPendingSensorCalibration("pH-high")
          }
        }
        
        
        //We are in a state where we should prompt user to confirm calibration
        if (!pHUserCalibratingState)  {
          const beginCalibrationClicked = () => {
            dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_initialize_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "pH"}}))
          }

          bodyContent = (<>
            <div className="PopupModal-FormItem">
              <div className="PopupModal-FormItem-Question">
                Calibration requirements presented here
              </div>
            </div>
          </>)
          footerContent = (
            <ControlBar controls={(<>
              <Button content={"Previous: Sensor Type Selection"} status={"Neutral"} onClick={switchToSensorTypeSelectionClicked}/>
            </>)} secondaryControls={(<>
              <Button content={"Begin Calibration"} status={"Neutral"} onClick={beginCalibrationClicked}/>
            </>)}/>
          )
        }else {
          //We are already calibrating, show the necessary calibration procedure
          if (!pHMidCalibrationState) {
            if (!pHMidCalibratingState) {
              const sensorReadyClicked = () =>  {
                dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_ready_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "pH", "sensor_cal_step": "mid"}}))
              }

              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Instructions for prepping mid calibration here...
                    <br/><br/>
                    Please prompt when sensor is ready to calibrate
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>
                  
                </>)} secondaryControls={(<>
                  <Button content={"Sensor Ready"} status={"Neutral"} onClick={sensorReadyClicked}/>
                </>)}/>
              )
            }else {
              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Sensor is calibrating. Please wait...
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>

                </>)} secondaryControls={(<>
                  
                </>)}/>
              )

            }
          }else if (pendingSensorCalibration === "pH-mid")  {
            const nextClicked = () => {
              SetPendingSensorCalibration(null)
            }
            bodyContent = (<>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  pH Sensor mid calibrated. Please click next
                </div>
              </div>
            </>)
            footerContent = (
              <ControlBar controls={(<>

              </>)} secondaryControls={(<>
                <Button content={"Next: Low End"} status={"Neutral"} onClick={nextClicked}/>
              </>)}/>
            )


            
          }else if (!pHLowCalibrationState) {
            if (!pHLowCalibratingState) {
              const sensorReadyClicked = () =>  {
                dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_ready_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "pH", "sensor_cal_step": "low"}}))
              }

              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Instructions for prepping low calibration here...
                    <br/><br/>
                    Please prompt when sensor is ready to calibrate
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>
                  
                </>)} secondaryControls={(<>
                  <Button content={"Sensor Ready"} status={"Neutral"} onClick={sensorReadyClicked}/>
                </>)}/>
              )
            }else {
              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Sensor is calibrating. Please wait...
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>

                </>)} secondaryControls={(<>
                  
                </>)}/>
              )

            }
          }else if (pendingSensorCalibration === "pH-low")  {
            const nextClicked = () => {
              SetPendingSensorCalibration(null)
            }
            bodyContent = (<>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  pH Sensor low calibrated. Please click next
                </div>
              </div>
            </>)
            footerContent = (
              <ControlBar controls={(<>

              </>)} secondaryControls={(<>
                <Button content={"Next: High End"} status={"Neutral"} onClick={nextClicked}/>
              </>)}/>
            )



          }else if (!pHHighCalibrationState) {
            if (!pHHighCalibratingState) {
              const sensorReadyClicked = () =>  {
                dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_ready_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "pH", "sensor_cal_step": "high"}}))
              }

              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Instructions for prepping high calibration here...
                    <br/><br/>
                    Please prompt when sensor is ready to calibrate
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>
                  
                </>)} secondaryControls={(<>
                  <Button content={"Sensor Ready"} status={"Neutral"} onClick={sensorReadyClicked}/>
                </>)}/>
              )
            }else {
              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Sensor is calibrating. Please wait...
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>

                </>)} secondaryControls={(<>
                  
                </>)}/>
              )

            }
          }else if (pendingSensorCalibration === "pH-high")  {
            const finishClicked = () => {
              SetPendingSensorCalibration(null)
              switchToSensorTypeSelectionClicked()
            }
            bodyContent = (<>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  pH Sensor high calibrated. Sensor calibration is now complete.
                </div>
              </div>
            </>)
            footerContent = (
              <ControlBar controls={(<>

              </>)} secondaryControls={(<>
                <Button content={"Finished"} status={"Neutral"} onClick={finishClicked}/>
              </>)}/>
            )
          }

        }

        break
      
      case "ORP_calibration":

        const ORPUserCalibratingState = Flow.sensors !== null && Flow.sensors["ORP"] !== undefined && Flow.sensors["ORP"][selectedSensorBank] !== undefined && Flow.sensors["ORP"][selectedSensorBank]["user_calibrating"]

        const ORPCalibrationState = Flow.sensors !== null && Flow.sensors["ORP"] !== undefined && Flow.sensors["ORP"][selectedSensorBank] !== undefined && Flow.sensors["ORP"][selectedSensorBank]["calibrated"]
        const ORPCalibratingState = Flow.sensors !== null && Flow.sensors["ORP"] !== undefined && Flow.sensors["ORP"][selectedSensorBank] !== undefined && Flow.sensors["ORP"][selectedSensorBank]["calibrating"]
        
        if (pendingSensorCalibration === null)  {
          if (ORPCalibratingState)  {
            SetPendingSensorCalibration("ORP")
          }
        }
        
        
        //We are in a state where we should prompt user to confirm calibration
        if (!ORPUserCalibratingState)  {
          const beginCalibrationClicked = () => {
            dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_initialize_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "ORP"}}))
          }

          bodyContent = (<>
            <div className="PopupModal-FormItem">
              <div className="PopupModal-FormItem-Question">
                Calibration requirements presented here
              </div>
            </div>
          </>)
          footerContent = (
            <ControlBar controls={(<>
              <Button content={"Previous: Sensor Type Selection"} status={"Neutral"} onClick={switchToSensorTypeSelectionClicked}/>
            </>)} secondaryControls={(<>
              <Button content={"Begin Calibration"} status={"Neutral"} onClick={beginCalibrationClicked}/>
            </>)}/>
          )
        }else {
          //We are already calibrating, show the necessary calibration procedure
          if (!ORPCalibrationState) {
            if (!ORPCalibratingState) {
              const sensorReadyClicked = () =>  {
                dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_ready_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "ORP"}}))
              }

              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Instructions for prepping calibration here...
                    <br/><br/>
                    Please prompt when sensor is ready to calibrate
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>
                  
                </>)} secondaryControls={(<>
                  <Button content={"Sensor Ready"} status={"Neutral"} onClick={sensorReadyClicked}/>
                </>)}/>
              )
            }else {
              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Sensor is calibrating. Please wait...
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>

                </>)} secondaryControls={(<>
                  
                </>)}/>
              )

            }
          }else if (pendingSensorCalibration === "ORP")  {
            const finishClicked = () => {
              SetPendingSensorCalibration(null)
              switchToSensorTypeSelectionClicked()
            }
            bodyContent = (<>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  ORP Sensor calibrated. Sensor calibration is now complete.
                </div>
              </div>
            </>)
            footerContent = (
              <ControlBar controls={(<>

              </>)} secondaryControls={(<>
                <Button content={"Finished"} status={"Neutral"} onClick={finishClicked}/>
              </>)}/>
            )
          }
        }

        break
      
      case "DO_calibration":

        const DOUserCalibratingState = Flow.sensors !== null && Flow.sensors["DO"] !== undefined && Flow.sensors["DO"][selectedSensorBank] !== undefined && Flow.sensors["DO"][selectedSensorBank]["user_calibrating"]

        const DOCalibrationState = Flow.sensors !== null && Flow.sensors["DO"] !== undefined && Flow.sensors["DO"][selectedSensorBank] !== undefined && Flow.sensors["DO"][selectedSensorBank]["calibrated"]
        const DOCalibratingState = Flow.sensors !== null && Flow.sensors["DO"] !== undefined && Flow.sensors["DO"][selectedSensorBank] !== undefined && Flow.sensors["DO"][selectedSensorBank]["calibrating"]
        
        if (pendingSensorCalibration === null)  {
          if (DOCalibratingState)  {
            SetPendingSensorCalibration("DO")
          }
        }
        
        
        //We are in a state where we should prompt user to confirm calibration
        if (!DOUserCalibratingState)  {
          const beginCalibrationClicked = () => {
            dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_initialize_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "DO"}}))
          }

          bodyContent = (<>
            <div className="PopupModal-FormItem">
              <div className="PopupModal-FormItem-Question">
                Calibration requirements presented here
              </div>
            </div>
          </>)
          footerContent = (
            <ControlBar controls={(<>
              <Button content={"Previous: Sensor Type Selection"} status={"Neutral"} onClick={switchToSensorTypeSelectionClicked}/>
            </>)} secondaryControls={(<>
              <Button content={"Begin Calibration"} status={"Neutral"} onClick={beginCalibrationClicked}/>
            </>)}/>
          )
        }else {
          //We are already calibrating, show the necessary calibration procedure
          if (!DOCalibrationState) {
            if (!DOCalibratingState) {
              const sensorReadyClicked = () =>  {
                dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_ready_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "DO"}}))
              }

              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Instructions for prepping calibration here...
                    <br/><br/>
                    Please prompt when sensor is ready to calibrate
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>
                  
                </>)} secondaryControls={(<>
                  <Button content={"Sensor Ready"} status={"Neutral"} onClick={sensorReadyClicked}/>
                </>)}/>
              )
            }else {
              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Sensor is calibrating. Please wait...
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>

                </>)} secondaryControls={(<>
                  
                </>)}/>
              )

            }
          }else if (pendingSensorCalibration === "DO")  {
            const finishClicked = () => {
              SetPendingSensorCalibration(null)
              switchToSensorTypeSelectionClicked()
            }
            bodyContent = (<>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  DO Sensor calibrated. Sensor calibration is now complete.
                </div>
              </div>
            </>)
            footerContent = (
              <ControlBar controls={(<>

              </>)} secondaryControls={(<>
                <Button content={"Finished"} status={"Neutral"} onClick={finishClicked}/>
              </>)}/>
            )
          }
        }

        break

      case "temp_calibration":

        const tempUserCalibratingState = Flow.sensors !== null && Flow.sensors["temp"] !== undefined && Flow.sensors["temp"][selectedSensorBank] !== undefined && Flow.sensors["temp"][selectedSensorBank]["user_calibrating"]

        const tempCalibrationState = Flow.sensors !== null && Flow.sensors["temp"] !== undefined && Flow.sensors["temp"][selectedSensorBank] !== undefined && Flow.sensors["temp"][selectedSensorBank]["calibrated"]
        const tempCalibratingState = Flow.sensors !== null && Flow.sensors["temp"] !== undefined && Flow.sensors["temp"][selectedSensorBank] !== undefined && Flow.sensors["temp"][selectedSensorBank]["calibrating"]
        
        if (pendingSensorCalibration === null)  {
          if (tempCalibratingState)  {
            SetPendingSensorCalibration("temp")
          }
        }
        
        
        //We are in a state where we should prompt user to confirm calibration
        if (!tempUserCalibratingState)  {
          const beginCalibrationClicked = () => {
            dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_initialize_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "temp"}}))
          }

          bodyContent = (<>
            <div className="PopupModal-FormItem">
              <div className="PopupModal-FormItem-Question">
                Calibration requirements presented here
              </div>
            </div>
          </>)
          footerContent = (
            <ControlBar controls={(<>
              <Button content={"Previous: Sensor Type Selection"} status={"Neutral"} onClick={switchToSensorTypeSelectionClicked}/>
            </>)} secondaryControls={(<>
              <Button content={"Begin Calibration"} status={"Neutral"} onClick={beginCalibrationClicked}/>
            </>)}/>
          )
        }else {
          //We are already calibrating, show the necessary calibration procedure
          if (!tempCalibrationState) {
            if (!tempCalibratingState) {
              const sensorReadyClicked = () =>  {
                dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_ready_for_calibration": 1, "sensor_bank_index": selectedSensorBank, "sensor_type": "temp"}}))
              }

              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Instructions for prepping calibration here...
                    <br/><br/>
                    Please prompt when sensor is ready to calibrate
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>
                  
                </>)} secondaryControls={(<>
                  <Button content={"Sensor Ready"} status={"Neutral"} onClick={sensorReadyClicked}/>
                </>)}/>
              )
            }else {
              bodyContent = (<>
                <div className="PopupModal-FormItem">
                  <div className="PopupModal-FormItem-Question">
                    Sensor is calibrating. Please wait...
                  </div>
                </div>
              </>)
              footerContent = (
                <ControlBar controls={(<>

                </>)} secondaryControls={(<>
                  
                </>)}/>
              )

            }
          }else if (pendingSensorCalibration === "temp")  {
            const finishClicked = () => {
              SetPendingSensorCalibration(null)
              switchToSensorTypeSelectionClicked()
            }
            bodyContent = (<>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  Temp Sensor calibrated. Sensor calibration is now complete.
                </div>
              </div>
            </>)
            footerContent = (
              <ControlBar controls={(<>

              </>)} secondaryControls={(<>
                <Button content={"Finished"} status={"Neutral"} onClick={finishClicked}/>
              </>)}/>
            )
          }
        }

        break


      case "finished_calibrating":
        const markSensorBankAsNotInBypass = (e) => {
          dispatch(setFlowProperty({FlowId: Flow.id, values: {"sensor_bank_bypass_state": 0, "sensor_bank_index": selectedSensorBank}}))
        }

        bodyContent = (<>
          {!!isInBypass && <>
            <div className="PopupModal-FormItem">
              <div className="PopupModal-FormItem-Question">
                <span>Place <span style={{fontWeight:"bold"}}>Sensor Bank {selectedSensorBank}</span> out of bypass</span>
              </div>
              <div className="PopupModal-FormItem-Answer">
                <div style={{display:"flex", alignSelf:"flex-start"}}>
                  <Button content={"Confirm - Out of bypass"} status={"Neutral"} onClick={markSensorBankAsNotInBypass}/>
                </div>
              </div>
            </div>
          </>}
          {!isInBypass && <>
            Calibration has been completed
          </>}
        </>)
        footerContent = (
            <ControlBar controls={(<>
              
            </>)} secondaryControls={(<>
              {(Flow === null || Flow.sensorBanks === null || Flow.sensorBanks[selectedSensorBank] === undefined || !Flow.sensorBanks[selectedSensorBank]["in_bypass"]) === true && <>
                <Button content={"Finish"} status={"Neutral"} onClick={switchToSensorBanksSelectionClicked}/>
              </>}
            </>)}/>
        )
        break


      default: 
          break
  }


  return (
    <PopupModal 
      title={"Sensor Calibration for Flow #" + (Flow ? Flow.id.toString() : "X")}
      closeCallback={closePopup}
      timelineSteps={timelineSteps} 
      timelineStep={currentTimelineStep} 
      footer={footerContent}>
      {bodyContent}
    </PopupModal>

  )
} 

export default TaskPopup_FlowSensorCalibration