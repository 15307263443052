import '../GerminationStationInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllGerminationStationConfigurationMaps, selectGerminationStationComponentInfo, selectGerminationStationLiveDataByComponentInfo, selectGerminationStationRuntimeInformation } from '../../../../redux/entities/service/GerminationStation';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../../helpers';



const GerminationStationInstance_StatusContent_Fan = ({ germinationStationId }) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  
  
    const [heatLoadComponentInfo] 
      = useSelector((state) => selectGerminationStationComponentInfo(state, germinationStationId, "CanopyAir", ["thpid"]), _.isEqual)
    
    const [fanComponentInfo] 
      = useSelector((state) => selectGerminationStationComponentInfo(state, germinationStationId, "FanRelay", ["state"]), _.isEqual)

    let heatLoad = useSelector((state) => selectGerminationStationLiveDataByComponentInfo(state, germinationStationId, heatLoadComponentInfo), _.isEqual)
    let fanState = useSelector((state) => selectGerminationStationLiveDataByComponentInfo(state, germinationStationId, fanComponentInfo), _.isEqual)


    let fanStatus = parseBool(fanState.value) ? "On" : "Off"

    
    let dataListItems = [
      {label: "Heat Load", value: heatLoad.value, suffix: "%", visualization: {
        type: "bar", info: {usesRange: true, min: 0, max: 100, status: "normal"}
      }},
    ]


    return (<>
      <div className="DataStatus-Cell">
        <div className="DataStatus-Cell-Header-Container">
          <div className="DataStatus-Cell-Header-PrimaryContent">
            <div className="Text-H14">Fan And Heat</div>
            <div className="DataStatus-Cell-Header-PrimaryContent-StatusChip">{fanStatus}</div>
          </div>
          <div className="DataStatus-Cell-Header-AuxContent">
            <DropDownButton content={<AdditionalOptions/>} status={"Neutral"}/>
          </div>
        </div>
        <div className="DataStatus-Cell-Content-Container">
          <DataStatus_DataList items={dataListItems}/>
        </div>
      </div>
    </>)
  }
  
  export default GerminationStationInstance_StatusContent_Fan