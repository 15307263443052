import { lightningChart } from '@lightningchart/lcjs'
import React, { useState, useRef, useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { customAlphabet  } from 'nanoid'
//import { install } from "resize-observer";
//install();

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => void (ref.current = value), [value])
  return ref.current
}

export function useMeasure(cb) {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0, documentTop: 0, documentLeft: 0, documentBottom: 0, documentRight: 0 })
  const [callback, setCallback] = useState(cb)


  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    
    let updatedBounds = {width: entry.contentRect.width, height: entry.contentRect.height, left: entry.contentRect.left, top: entry.contentRect.top, right: entry.contentRect.right, bottom: entry.contentRect.bottom}
    const bounds = entry.target.getBoundingClientRect()
    updatedBounds.documentTop = bounds.top
    updatedBounds.documentLeft = bounds.left
    updatedBounds.documentBottom = parseFloat(document.body.offsetHeight) - bounds.bottom
    updatedBounds.documentRight = parseFloat(document.body.offsetWidth) - bounds.right
    set(updatedBounds)
    if (cb !== undefined) {
      cb(updatedBounds)
    }

  }))


  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current)
    }
    return () => {
      ro.disconnect()
    }
  }, [])
  return [{ ref }, bounds]
}



export function useMeasureWithTrigger(cb) {
  const ref = useRef()
  const trigger = () => {
    handleBoundsChange()
  }
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0, documentTop: 0, documentLeft: 0, documentBottom: 0, documentRight: 0 })
  const [callback, setCallback] = useState(cb)


  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    
    let updatedBounds = {width: entry.contentRect.width, height: entry.contentRect.height, left: entry.contentRect.left, top: entry.contentRect.top, right: entry.contentRect.right, bottom: entry.contentRect.bottom}
    const newBounds = entry.target.getBoundingClientRect()
    updatedBounds.documentTop = newBounds.top
    updatedBounds.documentLeft = newBounds.left
    updatedBounds.documentBottom = parseFloat(document.body.offsetHeight) - newBounds.bottom
    updatedBounds.documentRight = parseFloat(document.body.offsetWidth) - newBounds.right
    set({...updatedBounds})
    if (cb !== undefined) {
      cb(updatedBounds)
    }

  }))


  const handleBoundsChange = () =>  {
    if (!ref.current)  {
      return
    }
    let updatedBounds = {...bounds}

    const newBounds = ref.current.getBoundingClientRect()
    updatedBounds.documentTop = newBounds.top
    updatedBounds.documentLeft = newBounds.left
    updatedBounds.documentBottom = parseFloat(document.body.offsetHeight) - newBounds.bottom
    updatedBounds.documentRight = parseFloat(document.body.offsetWidth) - newBounds.right
    set({...updatedBounds})
    if (cb !== undefined) {
      cb(updatedBounds)
    }
  }

  useEffect(() => {
    //console.log(ref, "Changed")
  }, [ref])

  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current)
    }
    return () => {
      ro.disconnect()
    }
  }, [])
  return [{ ref }, trigger, bounds]
}



export function useMeasureWithPosition({scrollContainerRef}, cb) {
  const ref = useRef()
  const [bounds, SetBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0, width: 0, height: 0})
  const [position, SetPosition] = useState({ documentTop: 0, documentLeft: 0 })
  const [callback, setCallback] = useState(cb)


  useEffect(() => {
    if (cb !== undefined) {
      cb({...bounds, ...position})
    }
  }, [bounds, position])

  
  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    if (ref.current === null)  {
      return
    }
    let updatedBounds = {width: entry.contentRect.width, height: entry.contentRect.height, left: entry.contentRect.left, top: entry.contentRect.top, right: entry.contentRect.right, bottom: entry.contentRect.bottom}
    SetBounds({...updatedBounds})
    
    const currentPosition = entry.target.getBoundingClientRect()
    if (position.documentLeft !== currentPosition.left || position.documentTop !== currentPosition.top) {
      SetPosition({documentLeft: currentPosition.left, documentTop: currentPosition.top})
    }
  }))


  const containerScrolled = React.useCallback((e) =>  {
    forceBoundsUpdate()
  })
  useEffect(() => {
    if (scrollContainerRef !== undefined && scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", containerScrolled)
    }
    return () =>  {
      if (scrollContainerRef !== undefined && scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", containerScrolled)
      }
    }
  }, [scrollContainerRef])

  
  const forceBoundsUpdate = React.useCallback((forceCallback) => {
    if (ref.current)  {
      const currentPositionBounds = ref.current.getBoundingClientRect()
      //if (bounds.width !== currentPositionBounds.width || bounds.height !== currentPositionBounds.height) {
      //  SetBounds({...bounds, width: currentPositionBounds.width, height: currentPositionBounds.height})
      //}
      if (position.documentLeft !== currentPositionBounds.left || position.documentTop !== currentPositionBounds.top) {
        SetPosition({documentLeft: currentPositionBounds.left, documentTop: currentPositionBounds.top})
      }

      if (forceCallback !== undefined && forceCallback && cb !== undefined) {
        cb({...bounds, documentLeft: currentPositionBounds.left, documentTop: currentPositionBounds.top})
      }
    }
  })



  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current)
    }
    return () => ro.disconnect()
  }, [])
  return [{ref}, forceBoundsUpdate, {...bounds, ...position}]
}


export function useMeasureWithRef(ref, cb) {
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0, documentTop: 0, documentLeft: 0 })
  const [callback, setCallback] = useState(cb)
  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    const bounds = entry.target.getBoundingClientRect()
    let documentWidth = Math.max(
        document.documentElement["clientWidth"],
        document.body["scrollWidth"],
        document.documentElement["scrollWidth"],
        document.body["offsetWidth"],
        document.documentElement["offsetWidth"]
    );
    let documentHeight = Math.max(
      document.documentElement["clientHeight"],
      document.body["scrollHeight"],
      document.documentElement["scrollHeight"],
      document.body["offsetHeight"],
      document.documentElement["offsetHeight"]
  );
    entry.contentRect.documentTop = bounds.top
    entry.contentRect.documentLeft = bounds.left
    entry.contentRect.documentBottom = documentHeight - bounds.bottom
    entry.contentRect.documentRight = documentWidth - bounds.right
    set(entry.contentRect)

    if (cb !== undefined) {
      cb(entry.contentRect)
    }
  }))

  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current)
    }
    return () => ro.disconnect()
  }, [ref])
  return [bounds]
}



export function useMeasureWithNodes(cb) {
  const nodeRef = React.useRef(null)
  const [node, SetNode] = React.useState(null)
  const handleNode = (newNode) => {
    //console.log(newNode)
    if (nodeRef.current !== newNode) {
      if (nodeRef.current !== undefined && nodeRef.current !== null)  {
        ro.disconnect(nodeRef.current)
      }
      nodeRef.current = newNode
      //SetNode(newNode)
      if (nodeRef.current !== undefined && nodeRef.current !== null)  {
        ro.observe(newNode)
      }
    }
  }
  const [bounds, setBounds] = useState({ left: 0, top: 0, width: 0, height: 0, documentTop: 0, documentLeft: 0 })
  const [lastBounds, SetLastBounds] = React.useState({ left: 0, top: 0, width: 0, height: 0, documentTop: 0, documentLeft: 0 })

  const [callback, setCallback] = useState(cb)
  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    const currentBounds = entry.target.getBoundingClientRect()
    let documentWidth = Math.max(
        document.documentElement["clientWidth"],
        document.body["scrollWidth"],
        document.documentElement["scrollWidth"],
        document.body["offsetWidth"],
        document.documentElement["offsetWidth"]
    );
    let documentHeight = Math.max(
      document.documentElement["clientHeight"],
      document.body["scrollHeight"],
      document.documentElement["scrollHeight"],
      document.body["offsetHeight"],
      document.documentElement["offsetHeight"]
  );
    entry.contentRect.documentTop = currentBounds.top
    entry.contentRect.documentLeft = currentBounds.left
    entry.contentRect.documentBottom = documentHeight - currentBounds.bottom
    entry.contentRect.documentRight = documentWidth - currentBounds.right

    let changed = false
    for (let [key, value] of Object.entries(entry.contentRect)) {
      if (bounds[key] === undefined || bounds[key] !== value) {
        changed = true
        break
      }
    }

    if (changed)  {
      setBounds(entry.contentRect)
      //SetLastBounds(entry.contentRect)
    }

    
  }))

  React.useLayoutEffect(() => {
    if (cb !== undefined) {
      cb(bounds)
    }
  }, [bounds])

  
  /*useEffect(() => {
    if (node) {
      ro.observe(node)
    }
    return () => ro.disconnect()
  }, [node])*/

  
  return [bounds, {nodeRef: nodeRef, handleNode: handleNode}]
}



export const useRenderingTrace = (componentName: string, propsAndStates: any, level: 'debug' | 'info' | 'log' = 'debug') => {
  const prev = useRef(propsAndStates);

  useEffect(() => {
    const changedProps: { [key: string]: { old: any, new: any } } = Object.entries(propsAndStates).reduce((property: any, [key, value]: [string, any]) => {
      if (prev.current[key] !== value) {
        property[key] = {
          old: prev.current[key],
          new: value,
        };
      }
      return property;
    }, {});

    if (Object.keys(changedProps).length > 0) {
      console[level](`[${componentName}] Changed props:`, changedProps);
    }

    prev.current = propsAndStates;
  });
};


export const useAnimationFrame = callback => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = React.useRef();
  const previousTimeRef = React.useRef();
  
  const animate = time => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime)
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }
  
  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []); // Make sure the effect runs only once
}



export const convertToBool = (v) => {
  if (v === "1" || v === "true" || v === true || v === 1)
    return true
  return false
}


export const distToSegment = (p, v, w) => {

  let sqr = (x) => { 
    return x * x 
  }
  
  let dist2 = (v, w) => { 
    return sqr(v.x - w.x) + sqr(v.y - w.y) 
  }
  
  let distToSegmentSquared = (p, v, w) => {
    var l2 = dist2(v, w);
      
    if (l2 == 0) return dist2(p, v);
      
    var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
      
    if (t < 0) return dist2(p, v);
    if (t > 1) return dist2(p, w);
      
    return dist2(p, { x: v.x + t * (w.x - v.x), y: v.y + t * (w.y - v.y) });
  }


  return Math.sqrt(distToSegmentSquared(p, v, w));
}


export const binaryClosestIdx = (arr, target, propKey) => {
  let start = 0;
  let end = arr.length - 1;
  let mid = Math.floor((start + end) / 2);

  while (1) {
    if (arr[mid] === undefined) {
      break
    }
      if (arr[mid][propKey] === target) {
          return mid;
      }
      else if (start >= end) {
          break;
      }
      else if (arr[mid][propKey] > target) {
          end = mid - 1;
      } else {
          start = mid + 1;
      }
      
      mid = Math.floor((start + end) / 2);
  }

  // Return the closest between the last value checked and it's surrounding neighbors
  const first = Math.max(mid - 1, 0);
  const neighbors = arr.slice(first, mid + 2);
  const best = neighbors.reduce((b, el) => Math.abs(el[propKey] - target) < Math.abs(b[propKey] - target) ? el : b);

  return first + neighbors.indexOf(best);
}


export function FormatTime(time, patternStr)  {
  
  const milliseconds = Math.floor(time % 1000),
        seconds = Math.floor((time / 1000) % 60),
        minutes = Math.floor((time / (1000 * 60)) % 60),
        hours = Math.floor((time / (1000 * 60 * 60)) % 24),
        days = Math.floor(time / (1000 * 60 * 60 * 24))


  const twoDigitPad = num => {
    return num < 10 ? "0" + num : num;
  }

  if (!patternStr) {
    patternStr = '~HH:~SS';
  }

  let result = patternStr
      .replace('~HH', twoDigitPad(hours)).replace('~H', hours)
      .replace('~MM', twoDigitPad(minutes)).replace('~M', minutes) 
      .replace('~SS', twoDigitPad(seconds)).replace('~S', seconds)
      .replace('~MS', milliseconds)
      .replace('~DD', twoDigitPad(days)).replace('~D+', days + 1).replace('~D', days)

  return result
}



export function FormatDate(date, patternStr)  {
  if (!date instanceof Date || !isFinite(date)) {
    return ""
  }
  const monthNames = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];
  const dayOfWeekNames = [
    "Sunday", "Monday", "Tuesday",
    "Wednesday", "Thursday", "Friday", "Saturday"
  ];
  const twoDigitPad = num => {
      return num < 10 ? "0" + num : num;
  }
  if (!patternStr) {
      patternStr = 'MM/d/yyyy';
  }
  const dayNth = (d) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  };

  let day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),
      miliseconds = date.getMilliseconds(),
      h = hour % 12,
      hh = twoDigitPad(h),
      HH = twoDigitPad(hour),
      mm = twoDigitPad(minute),
      ss = twoDigitPad(second),
      aaa = hour < 12 ? 'AM' : 'PM',
      yearStartedOn = new Date(date.getFullYear(), 0, 1),
      daysSinceYearStarted = Math.floor((date - yearStartedOn) / (24 * 60 * 60 * 1000)),
      weeksSinceYearStarted = Math.ceil(daysSinceYearStarted / 7),
      EEEE = dayOfWeekNames[date.getDay()],
      EEE = EEEE.substr(0, 3),
      E = EEEE.substr(0, 1),
      dd = twoDigitPad(day),
      dth = day.toString() + dayNth(day),
      M = month + 1,
      MM = twoDigitPad(M),
      MMMM = monthNames[month],
      MMM = MMMM.substr(0, 3),
      yyyy = year + "",
      yy = yyyy.substr(2, 2)
  ;


  // checks to see if month name will be used
  patternStr = patternStr
    .replace('dth', '@')
    .replace('hh', hh).replace('h', h)
    .replace('HH', HH).replace('H', hour)
    .replace('mm', mm).replace('m', minute) 
    .replace('ss', ss).replace('s', second)
    .replace('S', miliseconds)
    .replace('dd', dd).replace('d', day)
    
    .replace('EEEE', EEEE).replace('EEE', EEE).replace('E', E)
    .replace('yyyy', yyyy)
    .replace('yy', yy)
    .replace('aaa', aaa)
    .replace('wn', weeksSinceYearStarted)
    .replace('@', dth);

  if (patternStr.indexOf('MMM') > -1) {
      patternStr = patternStr
        .replace('MMMM', MMMM)
        .replace('MMM', MMM);
  }
  else {
      patternStr = patternStr
        .replace('MM', MM)
        //.replace('M', M);
  }
  return patternStr;
}



export const RoundedTimeToNearestMinute = (minutes, d=0) => {
  let ms = 1000 * 60 * minutes; // convert minutes to ms
  return Math.round(d / ms) * ms
}
export const RoundedTimeToNearestLastMinute = (minutes, d=0) => {
  let ms = 1000 * 60 * minutes; // convert minutes to ms
  return Math.floor(d / ms) * ms
}
export const RoundedTimeToNearestNextMinute = (minutes, d=0) => {
  let ms = 1000 * 60 * minutes; // convert minutes to ms
  return Math.ceil(d / ms) * ms
}

export const RoundedTimeToNearestLastHour = (hours, d=0) => {
  const ms = 1000 * 60 * 60 * hours; // convert hours to ms
  return Math.floor(d / ms) * ms;
}
export const RoundedTimeToNearestNextHour = (hours, d=0) => {
  const ms = 1000 * 60 * 60 * hours; // convert hours to ms
  return Math.ceil(d / ms) * ms;
}

export const RoundedTimeToNearestLastDay = (days, d=0) => {
  const ms = 1000 * 60 * 60 * 24 * days; // convert days to ms
  return Math.floor(d / ms) * ms;
}
export const RoundedTimeToNearestNextDay = (days, d=0) => {
  const ms = 1000 * 60 * 60 * 24 * days; // convert days to ms
  return Math.ceil(d / ms) * ms;
}





export const RoundedDateToNearestMinute = (minutes, d=new Date()) => {
  const ms = 1000 * 60 * minutes; // convert minutes to ms
  return new Date(Math.round(d.getTime() / ms) * ms);
}
export const RoundedDateToNearestLastMinute = (minutes, d=new Date()) => {
  const ms = 1000 * 60 * minutes; // convert minutes to ms
  return new Date(Math.floor(d.getTime() / ms) * ms);
}
export const RoundedDateToNearestNextMinute = (minutes, d=new Date()) => {
  const ms = 1000 * 60 * minutes; // convert minutes to ms
  return new Date(Math.ceil(d.getTime() / ms) * ms);
}

export const RoundedDateToNearestLastHour = (hours, d=new Date()) => {
  const ms = 1000 * 60 * 60 * hours; // convert hours to ms
  return new Date(Math.floor(d.getTime() / ms) * ms);
}
export const RoundedDateToNearestNextHour = (hours, d=new Date()) => {
  const ms = 1000 * 60 * 60 * hours; // convert hours to ms
  return new Date(Math.ceil(d.getTime() / ms) * ms);
}

export const RoundedDateToNearestLastDay = (days, d=new Date()) => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const ms = 1000 * 60 * 60 * 24 * days; // convert days to ms
  const roundedDate = new Date(Math.floor(d.getTime() / ms) * ms);
  roundedDate.addMinutes(timezoneOffset)
  return roundedDate
}
export const RoundedDateToNearestNextDay = (days, d=new Date()) => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const ms = 1000 * 60 * 60 * 24 * days; // convert days to ms
  const roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);
  roundedDate.addMinutes(timezoneOffset)
  return roundedDate
}


Date.prototype.addMinutes = function(m) {
  this.setTime(this.getTime() + (m*60*1000));
  return this;
}

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

export const ProcessSuffixForUnit = (unitType) => {
  switch (unitType) {
    case "litres":
      return "L"
    case "gallons":
      return "gal"
    default:
      return ""
  }
}
export const ProcessValueForUnit = (value, unitType, originalUnitType) => {
  switch (originalUnitType) {
    case "litres":
      switch (unitType) {
        case "gallons":
          return value * 0.264172
        default:
          return value
      }
    case "gallons":
      switch (unitType) {
        case "litres":
          return value * 3.78541
        default:
          return value
      }
    default:
      return value
  }
}


export function RoundToNearest(value, resolution, direction = "round") {
  /*if (value < 0)  {
    resolution *= -1
  }*/
  if (direction === "floor")  {
    return Math.floor(value * (1 / resolution)) / (1 / resolution);
  }else if (direction === "ceil")  {
    return Math.ceil(value * (1 / resolution)) / (1 / resolution);
  }else {
    return Math.round(value * (1 / resolution)) / (1 / resolution);
  }
}



export const daysInMonth = (y, m) => new Date(y, m + 1, 0).getDate()


export const addArrayItemAtIndex = ( array, index, newItem ) => {
  return [...array.slice(0, index), newItem, ...array.slice(index)];
}


export const remapRange = (value, rangeFrom, rangeTo) =>  {
  const leftSpan = rangeFrom[1] - rangeFrom[0]
  const rightSpan = rangeTo[1] - rangeTo[0]
  const valueScaled = (value - rangeFrom[0]) / leftSpan
  return rangeTo[0] + (valueScaled * rightSpan)
}

export const parseBool = (value: any) => {
  if (['true', true, 1, '1', 'yes'].includes(value)) {
    return true;
  }
  return false;
}





function usePreventZoom(scrollCheck = true, keyboardCheck = true) {
  useEffect(() => {
    const handleKeydown = (e) => {
      if (
        keyboardCheck &&
        e.ctrlKey &&
        (e.keyCode == "61" ||
          e.keyCode == "107" ||
          e.keyCode == "173" ||
          e.keyCode == "109" ||
          e.keyCode == "187" ||
          e.keyCode == "189")
      ) {
        e.preventDefault();
      }
    };

    const handleWheel = (e) => {
      if (scrollCheck && e.ctrlKey) {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("wheel", handleWheel);
    };
  }, [scrollCheck, keyboardCheck]);
}

export default usePreventZoom


/*


export function useMeasureWithPosition({boundingRef, scrollContainerRef}, cb) {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0, documentTop: 0, documentLeft: 0 })
  const [position, setPosition] = useState({ documentTop: 0, documentLeft: 0 })
  const [callback, setCallback] = useState(cb)

  const margin = 1

  const positionObserver = document.createElement('div');
  Object.assign(positionObserver.style, {
      position: 'absolute',
      pointerEvents: 'none',
      width: '2px',
      height: '2px',
      background: "blue"
  });

  React.useEffect(() => {
    if (ref.current && boundingRef !== undefined && boundingRef.current)  {
      const boundingRect = boundingRef.current.getBoundingClientRect();
      const currentBounds = ref.current.getBoundingClientRect()
      const offsetLeft = -(currentBounds.left - boundingRect.left - margin) + "px"
      const offsetTop = -(currentBounds.top - boundingRect.top - margin) + "px"
      positionObserver.style.transform = "translate(" + (offsetLeft) + "," + (offsetTop) + ")"
    }
  }, [bounds])

  const [ro] = useState(() => new ResizeObserver(([entry]) => {
    if (ref.current === null)  {
      return
    }
    let updatedBounds = {width: entry.contentRect.width, height: entry.contentRect.height, left: entry.contentRect.left, top: entry.contentRect.top, right: entry.contentRect.right, bottom: entry.contentRect.bottom}
    
    const currentBounds = entry.target.getBoundingClientRect()
    updatedBounds.documentTop = currentBounds.top
    updatedBounds.documentLeft = currentBounds.left
    set(updatedBounds)
    if (boundingRef !== undefined && boundingRef.current)  {
      const boundingRect = boundingRef.current.getBoundingClientRect();
      const offsetLeft = -(currentBounds.left - boundingRect.left - margin) + "px"
      const offsetTop = -(currentBounds.top - boundingRect.top - margin) + "px"
      positionObserver.style.transform = "translate(" + (offsetLeft) + "," + (offsetTop) + ")"
      //positionObserver.style.marginLeft = -(currentBounds.left - boundingRect.left - margin) + "px"
      //positionObserver.style.marginTop = -(currentBounds.top - boundingRect.top - margin) + "px"
    }
    if (cb !== undefined) {
      cb(updatedBounds)
    }
  }))

  const [io, setIO] = useState(null)

  useEffect(() => {
    if (bounds.documentLeft !== position.documentLeft || bounds.documentTop !== position.documentTop) {
      let updatedBounds = {...bounds, documentLeft: position.documentLeft, documentTop: position.documentTop}
      set(updatedBounds)
      if (cb !== undefined) {
        cb(updatedBounds)
      }
    }
  }, [position])
  
  useEffect(() => {
    let io
    if (ref.current && boundingRef !== undefined && boundingRef.current)  {
      //console.log(ref.current.getBoundingClientRect())
      io = new IntersectionObserver(([entry]) => {
        if (ref.current === null)  {
          return
        }
        const visiblePixels = Math.round(entry.intersectionRatio * 4);
        if (visiblePixels !== 1) {
          const boundingRect = boundingRef.current.getBoundingClientRect();
          const currentBounds = ref.current.getBoundingClientRect()
          if (currentBounds.documentLeft !== bounds.left || currentBounds.documentTop !== bounds.top) {
            let updatedBounds = {...bounds}
            updatedBounds.documentTop = currentBounds.top
            updatedBounds.documentLeft = currentBounds.left
            const offsetLeft = -(currentBounds.left - boundingRect.left - margin) + "px"
            const offsetTop = -(currentBounds.top - boundingRect.top - margin) + "px"
            positionObserver.style.transform = "translate(" + (offsetLeft) + "," + (offsetTop) + ")"
            //setPosition(updatedBounds)
          }
          /*if (cb !== undefined) {
            cb(updatedBounds)
          }* /
          //callback();
        }
      }, {threshold: [0.125, 0.375, 0.625, 0.875], root: boundingRef.current })
      io.observe(positionObserver)
    }
    return () => {
      if (io) {
        io.disconnect()
      }
    }
  }, [ref, bounds, position])


  const containerScrolled = (e) =>  {
    forceBoundsUpdate()
  }
  useEffect(() => {
    if (scrollContainerRef !== undefined && scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", containerScrolled)
    }
    return () =>  {
      if (scrollContainerRef !== undefined && scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", containerScrolled)
      }
    }
  }, [scrollContainerRef])

  useEffect(() => {
    if (ref.current) {
      if (boundingRef !== undefined && boundingRef.current) {
        ref.current.style.position = "relative"
        ref.current.appendChild(positionObserver);
      }
      ro.observe(ref.current)
    }
    return () => {
      ro.disconnect()
      positionObserver.remove();
    }
  }, [])

  const forceBoundsUpdate = () => {
    if (ref.current)  {
      const currentBounds = ref.current.getBoundingClientRect()
      if (bounds.documentLeft != currentBounds.left || bounds.documentTop != currentBounds.top) {
        let updatedBounds = {...bounds}
        updatedBounds.documentTop = currentBounds.top
        updatedBounds.documentLeft = currentBounds.left
        set(updatedBounds)
        if (cb !== undefined) {
          cb(updatedBounds)
        }
      }
    }
  }
  return [{ref}, forceBoundsUpdate, bounds]
}
*/




export const generateUID = (length = 8, chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz") => {
  const nanoId = customAlphabet(chars, length);
  return nanoId()
}