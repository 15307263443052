import './../ManageFlowWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackPowerOnTutorial, ScanRack } from '../../../../assets/icons/RackIcons';


  

const ConfirmPowerOnWalkthroughStep = ({transitionToNextStep, transitionToSubStep}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    

    
    const nextClicked = React.useCallback(() => {
        //powerOnConfirmedCallback()

        transitionToNextStep(["get_Flow_code", null])
    })

    return (
        <div className="Walkthrough-Card">
            <div className="Walkthrough-Card-Header">
                <div className="Walkthrough-Card-HeaderContent">
                    Ensure the Flow is turned on before scanning
                </div>
            </div>
            <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                <RackPowerOnTutorial/>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content="Start Scanning" onClick={nextClicked} status={"Primary"} size={isMobile ? "Medium" : "Large"} width="Flex100"/>
            </div>
        </div>
    )
}

  


export default ConfirmPowerOnWalkthroughStep