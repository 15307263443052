import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';

import { EmptyContainer } from '../../../../assets/icons/Icons';
import { KeypadInput_DisplayField, KeypadInput_Keypad } from '../../../../components/input/KeypadInput';
import DropDownInput from '../../../../components/input/DropDownInput';
import { ProcessSuffixForUnit, ProcessValueForUnit, RoundToNearest } from '../../../../helpers';
 
  

const TopUpInternalReservoirVolumeWalkthroughStep = ({flowId, selectedUnit, selectedUnitChanged, reservoirInfo, originalVolume, transitionToPreviousStep, transitionToNextStep, toppedUpInternalReservoirVolumeConfirmedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


    const [newReservoirVolume, SetNewReservoirVolume] = React.useState(() => {
        return RoundToNearest(ProcessValueForUnit(parseFloat(originalVolume), selectedUnit, "litres"), 0.01)
    })


    const [currentSelectedUnit, SetCurrentSelectedUnit] = React.useState(selectedUnit)
    React.useLayoutEffect(() => {
        if (currentSelectedUnit !== selectedUnit)   {
            SetNewReservoirVolume(RoundToNearest(ProcessValueForUnit(newReservoirVolume, selectedUnit, currentSelectedUnit), 0.01))
            SetCurrentSelectedUnit(selectedUnit)
        }
    }, [newReservoirVolume, selectedUnit])


    const [isNewReservoirVolumeValid, SetIsNewReservoirVolumeValid] = React.useState(false)

    let maxReservoirVolume = 0
    if (reservoirInfo["reservoir_volume"] !== undefined)  {
        maxReservoirVolume = parseFloat(reservoirInfo["reservoir_volume"]) / 1000;
    }

    const reservoirVolumeChanged = (value) => {
        SetNewReservoirVolume(parseFloat(value))
    }

    const availableUnits = [
            {"value": "litres", "label": "Litres"},
            {"value": "gallons", "label": "Gallons"},
    ]
    const unitChanged = (unit) => {
        if (selectedUnitChanged !== undefined)  {
            selectedUnitChanged(unit)
        }
    }

    const backClicked = () => {
        transitionToPreviousStep(["select_topup_type", null])        
    }
    const nextClicked = () =>   {
        toppedUpInternalReservoirVolumeConfirmedCallback(ProcessValueForUnit(parseFloat(newReservoirVolume), "litres", selectedUnit))
        transitionToNextStep(["confirm_internal_mix", null])
    }

    const keypadInputFieldRef = React.useRef()
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Water Added</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>

                    {isMobile && <>
                        <div className="FlexContent-20 FlexContent-HFill FlexContent-HFlex">
                            <div style={{textAlign:"center", fontSize:18, margin: "0 10px"}}>How much did you fill to?</div>
                            <div className="FlexContent-10 FlexContent-Center">
                                <KeypadInput_DisplayField value={newReservoirVolume} resolution={0.1} maxLength={8} suffix={ProcessSuffixForUnit(selectedUnit)} active={true} verticalPadding={14} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                <DropDownInput value={selectedUnit} options={availableUnits} onSelectionChange={unitChanged}/>
                                <div className="TopUpReservoirWalkthrough-OrigialVolumeDisplay">Originally {originalVolume}L, Max {maxReservoirVolume}L</div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={newReservoirVolume} 
                                    buttonVerticalPadding={5} 
                                    buttonHorizontalPadding={10}
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={(value) => {reservoirVolumeChanged(parseFloat(value))}}/>
                            </div>
                        </div>
                    </>}
                    {isTablet && <>
                        <div className="FlexContent-20 FlexContent-Center">
                            <div className="FlexContent-40" style={{margin:"20px 0"}}>
                                <div style={{textAlign:"center", fontSize:24}}>How much did you fill to?</div>
                                <div className="FlexContent-10 FlexContent-Center">
                                    <KeypadInput_DisplayField value={newReservoirVolume} resolution={0.1} maxLength={8} suffix={ProcessSuffixForUnit(selectedUnit)} verticalPadding={15} active={true} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                    <DropDownInput value={selectedUnit} options={availableUnits} onSelectionChange={unitChanged}/>
                                    <div className="TopUpReservoirWalkthrough-OrigialVolumeDisplay">Originally {originalVolume}L, Max {maxReservoirVolume}L</div>
                                </div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={newReservoirVolume} 
                                    buttonVerticalPadding={15} 
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={(value) => {reservoirVolumeChanged(parseFloat(value))}}/>
                            </div>
                        </div>
                    </>}
                    {(!isTablet && !isMobile) && <>
                        <div className="FlexContent-H-80">
                            <div className="FlexContent-40" style={{margin:"20px 0"}}>
                                <div style={{textAlign:"center", fontSize:24}}>How much did you fill to?</div>
                                <div className="FlexContent-10 FlexContent-Center">
                                    <KeypadInput_DisplayField value={newReservoirVolume} resolution={0.1} maxLength={8} suffix={ProcessSuffixForUnit(selectedUnit)} active={true} verticalPadding={30} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                    <DropDownInput value={selectedUnit} options={availableUnits} onSelectionChange={unitChanged}/>
                                    <div className="TopUpReservoirWalkthrough-OrigialVolumeDisplay">Originally {originalVolume}L, Max {maxReservoirVolume}L</div>
                                </div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={newReservoirVolume} 
                                    buttonVerticalPadding={15} 
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={(value) => {reservoirVolumeChanged(parseFloat(value))}}/>
                            </div>
                        </div>
                        
                    </>}
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status={"Neutral"} content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default TopUpInternalReservoirVolumeWalkthroughStep