import './HarvestTask.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllBladeZones } from '../../../../redux/entities/service/Blade';
import { GenericLeaf } from '../../../../assets/icons/Icons';
import { KeypadInput_DisplayField, KeypadInput_Keypad } from '../../../../components/input/KeypadInput';
import { selectAllInventoryItemTypes } from '../../../../redux/entities/Inventory';
import { RoundToNearest } from '../../../../helpers';


const HarvestTask_ContainerWeightWalkthroughStep = ({
        allPlantVarieties,
        activeGrow,
        activeGrowPlan,
        harvestingZoneUID,
        containerContents,
        addContainerCallback,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    
    

    const allInventoryItemsTypes = useSelector(selectAllInventoryItemTypes)
    let activeInventoryItemTypes = {}
    for (let c of containerContents)   {
        let foundInventoryItemType = allInventoryItemsTypes.find((iT) => iT.id === c.id)
        if (foundInventoryItemType !== undefined)   {
            activeInventoryItemTypes[c.id] = foundInventoryItemType
        }
    }
    const isMix = (containerContents.length > 1)




    const [containerWeight, SetContainerWeight] = React.useState(0)

    const [numberOfContainers, SetNumberOfContainers] = React.useState(1)

    const [activeDisplayField, SetActiveDisplayField] = React.useState(0)
    const [activeContainerWeight, SetActiveContainerWeight] = React.useState(0)
    React.useLayoutEffect(() => {
        if (activeDisplayField !== "num_containers")    {
            if (isMix)  {
                SetContainerWeight(activeContainerWeight * RoundToNearest(100 / containerContents[activeDisplayField].ratio, 0.1))
            }else {
                SetContainerWeight(activeContainerWeight)
            }
        }
    }, [isMix, activeDisplayField, activeContainerWeight])

    const [activeDisplayFieldRef, SetActiveDisplayFieldRef] = React.useState(undefined)
    const onFocus = (displayFieldKey) => {
        SetActiveDisplayField(displayFieldKey)
        if (displayFieldKey === "num_containers")   {
            SetActiveDisplayFieldRef(containersInputFieldRef.current)
        }else   {
            SetActiveContainerWeight(RoundToNearest(containerWeight * (containerContents[displayFieldKey].ratio / 100), 0.1))
            SetActiveDisplayFieldRef(keypadInputFieldRef.current[displayFieldKey])
        }
    }
    const keypadInputFieldRef = React.useRef({})
    const containersInputFieldRef = React.useRef()



    const backClicked = () => {
        if (activeGrowPlan.plant_variants !== undefined && activeGrowPlan.plant_variants.length > 1)    {
            transitionToPreviousStep(["container_contents", null])
        }else {
            transitionToPreviousStep(["prepare_for_harvest", null])
        }
    }
    const nextClicked = () => {        
        addContainerCallback({containerInfo: {
            weight: containerWeight,
            contents: containerContents,
            number_of: numberOfContainers
        }})
        transitionToNextStep(["container_list", null])
    }


    
    return (<>
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Harvest into containers
                    
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={isMobile ? "FlexContent-20" : "FlexContent-H-40"} style={{padding: isMobile ? "20px 0px" : "40px 0px", flex: 1, overflowY: "hidden"}}>
                    <div className="FlexContent-20 FlexContent-HCenter" style={{flex: 1, overflowY:"auto", alignSelf: "normal"}}>
                        {containerContents.length === 1 && <>
                            <div className="FlexContent-5 FlexContent-HFill">
                                <div className="Text-S18">
                                    {activeInventoryItemTypes[containerContents[0].id] !== undefined ? activeInventoryItemTypes[containerContents[0].id].name : "N/A"}
                                </div>
                                <KeypadInput_DisplayField
                                    value={containerWeight} 
                                    resolution={0.1} 
                                    maxLength={8} 
                                    suffix={"kg"} 
                                    active={activeDisplayField === 0} 
                                    verticalPadding={14}
                                    onFocus={() => {onFocus(0)}}
                                    inputFieldRefCb={(ref) => {keypadInputFieldRef.current[0] = ref}}/>
                                {containerContents === "mix" && <>
                                    <div className="Text-S14">Ratio: {activeGrowPlan.plant_variants[0].ratio}%</div>
                                </>}
                            </div>                        
                        </>}

                        {containerContents.length > 1 && <>
                            {containerContents.map((containerContent, containerIndex) => {
                                let currentContainerWeight = RoundToNearest(containerContent.ratio / 100 * containerWeight, 0.1)

                                return (
                                    <div className="FlexContent-5 FlexContent-HFill" key={containerContent.id}>
                                        <div className="FlexContent-H-5">
                                            <div className="Text-S18 Text-CPrimary">Ratio: {containerContent.ratio}%</div>
                                            <div className="Text-S18">
                                                {activeInventoryItemTypes[containerContent.id] !== undefined ? activeInventoryItemTypes[containerContent.id].name : "N/A"}
                                            </div>
                                        </div>
                                        <KeypadInput_DisplayField
                                            value={currentContainerWeight} 
                                            resolution={0.1}
                                            maxLength={8}
                                            suffix={"kg"}
                                            active={activeDisplayField === containerIndex} 
                                            verticalPadding={14}
                                            onFocus={() => {onFocus(containerIndex)}}
                                            inputFieldRefCb={(ref) => {keypadInputFieldRef.current[containerIndex] = ref}}/>
                                    </div>
                                )
                            })}
                        </>}
                        <div className="FlexContent-5 FlexContent-HFill">
                            <div className="Text-S18">Number of containers</div>
                            <KeypadInput_DisplayField
                                value={numberOfContainers} 
                                resolution={0.1} 
                                maxLength={8} 
                                suffix={""} 
                                active={activeDisplayField === "num_containers"} 
                                verticalPadding={14} 
                                onFocus={() => {onFocus("num_containers")}}
                                inputFieldRefCb={(ref) => {containersInputFieldRef.current = ref}}/>
                            
                        </div>  
                    </div>
                    <div className="FlexContent" style={{flex: 1}}>
                        <KeypadInput_Keypad
                            value={activeDisplayField === "num_containers" ? numberOfContainers : activeContainerWeight} 
                            buttonSize={"flex"}
                            inputFieldRef={activeDisplayFieldRef}
                            onChange={(value) => {
                                if (activeDisplayField === "num_containers")    {
                                    SetNumberOfContainers(parseInt(value))
                                }else {
                                    //If its a mix, calculate based on ratio
                                    if (isMix)  {
                                        console.log(value)
                                        SetActiveContainerWeight(parseFloat(value))
                                        //SetContainerWeight(parseFloat(value) * RoundToNearest(100 / containerContents[activeDisplayField].ratio, 0.1))
                                    }else {
                                        SetActiveContainerWeight(parseFloat(value))
                                        //SetContainerWeight(parseFloat(value))
                                    }
                                }
                            }}/>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Next"} onClick={nextClicked} size={"Flex50"} disabled={containerWeight === 0 || numberOfContainers === 0}/>
                
            </div>
        </div>
    </>)
    
}


export default HarvestTask_ContainerWeightWalkthroughStep