import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../../api'
import { getAccountSessionData } from '../../../pages/Account/Common'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'



const updateNutrientReservoirsForFlow = (flow) => {

  flow.nutrient_reservoirs = {}
  if (flow.maps !== undefined && flow.maps.versions["dosing_map"] !== undefined && flow.maps.maps["dosing_map"] !== undefined) {
    flow.haveMapVersions["dosing_map"] = flow.maps.versions["dosing_map"]

    //Process map
    for (let [index, info] of Object.entries(flow.maps.maps["dosing_map"]["entries"])) {
      flow.nutrient_reservoirs[parseInt(index)] = {...info, "runtime_information": {}, "item": {}}
    }
  
    for (let [key, value] of Object.entries(flow["runtime_information"]))  {
      if (key.indexOf("dosing_reservoir_info_") !== -1) {
        let index = parseInt(key.substring("dosing_reservoir_info_".length, key.length))
        if (flow.nutrient_reservoirs[index] !== undefined)  {
          flow.nutrient_reservoirs[index]["runtime_information"] = JSON.parse(value)
        }
      }
    }


  }else {
    flow.haveMapVersions["dosing_map"] = -1
  }

  

}

const updateAssignedBladeZoneMapForFlow = (flow) => {

  if (flow.maps !== undefined && flow.maps.versions["assigned_flow_zone_map"] !== undefined && flow.maps.maps["assigned_flow_zone_map"] !== undefined) {
    flow.haveMapVersions["assigned_flow_zone_map"] = flow.maps.versions["assigned_flow_zone_map"]

  }else {
    flow.haveMapVersions["assigned_flow_zone_map"] = -1
  }
}

const updateCurrentActionsForFlow = (flow) => {
  flow.currentActionInfo = {}
  if (flow.runtime_information["control_info_state"] !== undefined)  {
    flow.currentActionInfo = JSON.parse(flow.runtime_information["control_info_state"])
  }

  flow.currentAction = "N/A"
  flow.currentSubaction = null

  if (flow.runtime_information["control_state"] !== undefined) {
    switch (flow.runtime_information["control_state"]) {
      case "waiting":
        flow.currentAction = "Idle"
        flow.currentSubaction = null              
        break;

      case "cleanse":
        flow.currentAction = "Cleaning"
        if (flow.runtime_information["subcontrol_state"] !== undefined) {
          switch (flow.runtime_information["subcontrol_state"]) {
            case "cleansing-return":
              flow.currentSubaction = "Return"
              break

            case "cleansing-supply":
              flow.currentSubaction = "Supply"
              break
              

            case "cleansing-only-supply":
              flow.currentSubaction = "Supply"
              break

            case "cleansing-only-return":
              flow.currentSubaction = "Return"
              break

              
        
            default:
              flow.currentSubaction = flow.runtime_information["subcontrol_state"]
              break
          }
        }
        break;

    
      case "prime_dosing_reservoir":
        if (flow.currentActionInfo["reservoir_index"] !== undefined) {
          flow.currentAction = "Priming Reservoir #" + flow.currentActionInfo["reservoir_index"].toString()
        }else {
          flow.currentAction = "Priming Reservoir #X"
        }      
        flow.currentSubaction = null
        break;
  
      case "rack_reservoir_water_fill":
        if (flow.currentActionInfo["zone_uid"] !== undefined) {
          flow.currentAction = "Water Filling " + flow.currentActionInfo["zone_uid"]
        }else {
          flow.currentAction = "Water Filling #X"
        }
        flow.currentSubaction = null
        break

      case "rack_dose":
        if (flow.currentActionInfo["zone_uid"] !== undefined) {
          flow.currentAction = "Dosing Zone " + flow.currentActionInfo["zone_uid"]
        }else {
          flow.currentAction = "Dosing Zone #X"
        }
        flow.currentSubaction = null

        if (flow.runtime_information["subcontrol_state"] !== undefined) {
          switch (flow.runtime_information["subcontrol_state"]) {
            case "pending_rack_ready":
              flow.currentSubaction = "Waiting for zone"
              break

            case "filling_rack_to_minimum_volume":
              flow.currentSubaction = "Filling to minimum volume"
              break

            case "pending_rack_liquid":
              flow.currentSubaction = "Mixing"
              break

            case "pending_rack_liquid_stable":
              flow.currentSubaction = "Mixing"
              break              

            case "running_dosing_queue":
              flow.currentSubaction = "Injecting"
              break

            case "calculate_dose":
              flow.currentSubaction = "Calculating"
              break

            
            case "running_water_top_up":
              flow.currentSubaction = "Topping Up Water"
              break

            case "pause_pumping_for_dosing_queue":
              flow.currentSubaction = "Stopping Mixing "
              break

            case "resume_pump_for_dosing_queue_for_mixing":
              flow.currentSubaction = "Resuming Mixing"
              break

            
            case "check_for_water_top_up":
              flow.currentSubaction = "Checking For Water Top Up"
              break

            case "mix_for_dosing_queue":
              flow.currentSubaction = "Mixing"
              break
            
            case "pending_dose_completed_confirmation":
              flow.currentSubaction = "Dose Completed"
              break

            case "pending_rack_post_dose_stop":
              flow.currentSubaction = "Dose Completed"
              break
  

            default:
              flow.currentSubaction = flow.runtime_information["subcontrol_state"]
              break
          }
        }
        break

      case "rack_level_sensor_calibration":
        flow.currentAction = "Blade Level Sensor Calibration"
        switch (flow.runtime_information["subcontrol_state"]) {
          case "waiting_for_rack_to_purge":
            flow.currentSubaction = "Blade Purging Air"
            break

          default:
            flow.currentSubaction = flow.runtime_information["subcontrol_state"]
            break
        }
        break

      case "rack_cleanse_flush":
        if (flow.currentActionInfo["zone_uid"] !== undefined) {
          flow.currentAction = "Cleansing Zone " + flow.currentActionInfo["zone_uid"]
        }else {
          flow.currentAction = "Cleansing Zone #X"
        }
        flow.currentSubaction = null

        if (flow.runtime_information["subcontrol_state"] !== undefined) {
          switch (flow.runtime_information["subcontrol_state"]) {
        
            default:
              flow.currentSubaction = flow.runtime_information["subcontrol_state"]
              break
          }
        }
        break;

        
      default:
        flow.currentAction = flow.runtime_information["control_state"]
        break
    }
  }


}


const processFlowFromAPI = (state, flow) =>  {


  if (flow.control_device === undefined) {
    flow.control_device = null
  }
  if (flow.sensors === undefined) {
    flow.sensors = null
  }
  if (flow.sensorBanks === undefined) {
    flow.sensorBanks = null
  }
  if (flow.nutrient_reservoirs === undefined) {
    flow.nutrient_reservoirs = []
  }
  
  if (flow.haveMapVersions === undefined)  {
    flow.haveMapVersions = {}
  }
  
  if (flow.maps === undefined)  {
    //flow.maps = {versions:{}}
  }
  if (flow.unique_configuration === undefined) {
    flow.unique_configuration = {properties:{}}
  }
 
  
  if (flow.liveData === undefined) {
    flow.liveData = {}
  }

  if (flow.loading_status_data === undefined) {
    flow.loading_status_data = "idle"
  }
  if (flow.loading_live_data === undefined) {
    flow.loading_live_data = "idle"
  }
  if (flow.have_live_data_version === undefined) {
    flow.have_live_data_version = -1
  }
  if (flow.loading_analytics_data === undefined) {
    flow.loading_analytics_data = "idle"
  }
  if (flow.runtime_information === undefined) {
    flow.runtime_information = {}
  }
  if (flow.runtime_information_requested === undefined) {
    flow.runtime_information_requested = {}
  }


  if (flow.retrievingMapVersionsStatus === undefined)  {
    flow.retrievingMapVersionsStatus = "idle"
    flow.lastMapVersionsLoadedOn = 0
  }
  if (flow.retrievingMapsStatus === undefined)  {
    flow.retrievingMapsStatus = "idle"
    flow.lastMapsLoadedOn = 0
  }
  if (flow.retrievingStatus === undefined)  {
    flow.retrievingStatus = "idle"
  }

  if (flow.updatingFlowAssignedBladeZoneEntriesStatus === undefined)  {
    flow.updatingFlowAssignedBladeZoneEntriesStatus = "idle"
  }


  
  if (flow.loading_status_data === undefined)  {
    flow.loading_status_data = "idle"
  }
  if (flow.loading_live_data === undefined)  {
    flow.loading_live_data = "idle"
  }



  updateCurrentActionsForFlow(flow)
  updateNutrientReservoirsForFlow(flow)
  updateAssignedBladeZoneMapForFlow(flow)

  return flow
}

const processFlowPropertyFromAPI = (flow, key, value) =>  {
  console.log(key, value)
  switch (key)  {
    case "number_of_sensor_banks":
      flow.numberOfSensorBanks = parseInt(value)
    case "sensor_banks":
      flow.sensorBanks = value
    case "sensors":
      flow.sensors = value
    case "nutrient_reservoirs":
      flow.nutrientReservoirs = value
    default:
      break
  }
}


export const getAllFlows = createAsyncThunk('flow/getAllFlows', async ({}, { getState }) => {
  return await FetchPost(APIRepository.Flow.GetAllFlows, {
    ...getAccountSessionData(getState()),
  })  
},
{
  condition: (args, { getState }) => {
    const { flow } = getState()
    if (flow.loadingAllFlowsStatus === 'pending') {
      return false
    }
  },
})

export const getFlowById = createAsyncThunk('flow/getFlowById', async ({flowId, flowIds}, { getState }) => {
  console.log(flowId)
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (flowId !== undefined) {
    payload.flow_id = flowId
  }
  if (flowIds !== undefined) {
    payload.flow_ids = flowIds
  }
  return await FetchPost(APIRepository.Flow.GetFlowById, payload)  
},
{
  condition: (args, { getState }) => {
    const { flow } = getState()
    if (flow.status === 'pending') {
      return false
    }
  },
})





export const getFlowByServiceId = createAsyncThunk('flow/getFlowByServiceId', async ({flowServiceId, flowServiceIds}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (flowServiceId !== undefined) {
    payload.service_id = flowServiceId
  }
  if (flowServiceIds !== undefined) {
    payload.service_ids = flowServiceIds
  }
  return await FetchPost(APIRepository.Flow.GetFlowByServiceId, payload)  
},
{
  condition: (args, { getState }) => {
    const { flow } = getState()
    if (flow.status === 'pending') {
      return false
    }
  },
})



export const getFlowStatusById = createAsyncThunk('flow/getFlowStatusById', async ({flowId, flowIds}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    flow_id: flowId,
    flow_ids: flowIds
  }
  return await FetchPost(APIRepository.Flow.GetFlowStatusById, payload)  
})




export const getFlowLiveDataById = createAsyncThunk('flow/getFlowLiveDataById', async ({ flowId, flowIds, flowIdsWithVersions }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (flowId !== undefined) {
    payload.flow_id = flowId
  }
  if (flowIds !== undefined) {
    payload.flow_ids = flowIds
  }
  if (flowIdsWithVersions !== undefined) {
    payload.flow_ids_with_versions = flowIdsWithVersions
  }

  return await FetchPost(APIRepository.Flow.GetFlowLiveDataById, payload)
})


export const getFlowConfigurationMap = createAsyncThunk('germ/getFlowConfigurationMap', async ({ maps }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    maps: maps //Must be []configurationId:{mapKey:*version
  }
  return await FetchPost(APIRepository.Flow.GetFlowConfigurationMap, payload)
},
{
  condition: (args, { getState }) => {
    const { flow } = getState()
    if (flow.loadingConfigurationMaps === 'pending') {
      return false
    }
  },
})


export const getFlowUniqueConfigurationMap = createAsyncThunk('germ/getFlowUniqueConfigurationMap', async ({ flowIds }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    flow_ids: flowIds
  }
  return await FetchPost(APIRepository.Flow.GetFlowUniqueConfigurationMap, payload)
},
  {
    condition: (args, { getState }) => {
      const { flow } = getState()
      if (flow.loading_unique_configuration_map === 'pending') {
        return false
      }
    },
  })





export const getFlowProperty = createAsyncThunk('flow/getFlowProperty', async ({flowId, key, keys}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    flow_id: flowId
  }  
  if (key !== undefined) {
    payload.key = key
  }
  if (keys !== undefined) {
    payload.keys = keys
  }
  return await FetchPost(APIRepository.Flow.GetFlowProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { flow } = getState()
    if (flow.status === 'pending') {
      return false
    }
  },
})

export const setFlowProperty = createAsyncThunk('flow/setFlowProperty', async ({flowId, key, value, values, callback}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    flow_id: flowId
  }  
  if (key !== undefined && value !== undefined) {
    payload.key = key
    payload.value = value
  }
  if (values !== undefined) {
    payload.values = values
  }
  return await FetchPost(APIRepository.Flow.SetFlowProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { flow } = getState()
    if (flow.status === 'pending') {
      return false
    }
  },
})


export const setFlowRuntimeProperty = createAsyncThunk('flow/setFlowRuntimeProperty', async ({ flowId, properties, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    flow_id: flowId,
    properties: properties
  }
  return await FetchPost(APIRepository.Flow.SetFlowRuntimeProperty, payload)
},
  {
    condition: (args, { getState }) => {
      const { flow } = getState()
      if (flow.settingFlowRuntimeProperty === 'pending') {
        return false
      }
    },
  })






export const manageFlowAssignedBladeZoneEntry = createAsyncThunk('flow/manageFlowAssignedBladeZoneEntry', async ({flowId, portIndex, changeType, entryInfo, callback}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    flow_id: flowId,
    port_index: portIndex,
    change_type: changeType,
    entry_info: entryInfo,
  }  
  return await FetchPost(APIRepository.Flow.ManageFlowAssignedBladeZoneEntry, payload)  
},
{
  condition: (args, { getState }) => {
    const { flow } = getState()
    if (flow.updatingFlowAssignedBladeZoneEntriesStatus === 'pending') {
      return false
    }
  },
})




export const getFlowMapVersions = createAsyncThunk('flow/getFlowMapVersions', async ({flowIds}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    flow_ids: flowIds
  }
  return await FetchPost(APIRepository.Flow.GetFlowMapVersions, payload)  
})




export const getFlowMaps = createAsyncThunk('flow/getFlowMaps', async ({flowMapKeys}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    flow_map_keys: flowMapKeys
  }
  return await FetchPost(APIRepository.Flow.GetFlowMaps, payload)  
})

export const flowsSlice = createSlice({
  name: 'flow',
  initialState: {
    flow:  [

    ],
    configurationMaps: [],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false,

    loadingAllFlowsStatus: "idle",
    settingFlowRuntimeProperty: "idle",
    loadingConfigurationMaps: 'idle',
    loadedAllFlows: false,
  },
  reducers: {
    
  },
  extraReducers: {
    [getAllFlows.pending]: (state) => {
      state.loadingAllFlowsStatus = 'pending';
    },
    [getAllFlows.fulfilled]: (state, action) => {
      state.loadingAllFlowsStatus = 'fulfilled';
      if (action.payload.error !== undefined && action.payload.error !== null)  {
        //Failed in some way
      }else {
        state.loadedAllFlows = true
        if (action.payload.flows !== null) {
          for (let flow of action.payload.flows) {
            if (flow) {
              let exists = false
              for (let flowIndex in state.flow) {
                if (state.flow[flowIndex].id === flow.id) {
                  //Get the UID

                  state.flow[flowIndex] = processFlowFromAPI(state, { ...state.flow[flowIndex], ...flow })
                  exists = true
                  break
                }
              }
              if (!exists) {
                state.flow.push(processFlowFromAPI(state, flow))
              }
            }
          }
        }
      }

    },
    [getAllFlows.rejected]: (state) => {
      state.loadingAllFlowsStatus = 'rejected';
    },

    [getFlowById.pending]: (state) => {
      state.status = 'pending';
    },

    [getFlowById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      /*if (action.payload.flow !== null) {
        state.flow.push(processFlowFromAPI(action.payload.flow))
      }*/
      if (action.payload.flows !== null) {
        for (let flow of action.payload.flows)  {
          if (flow)  {
            state.flow.push(processFlowFromAPI(state, flow))
          }
        }
      }
    },

    [getFlowById.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getFlowByServiceId.pending]: (state) => {
      state.status = 'pending';
    },

    [getFlowByServiceId.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload.vertical_rack_groups)
      if (action.payload.flows !== undefined && action.payload.flows !== null) {
        for (let flow of action.payload.flows)  {
          if (flow)  {
            let exists = false
            for (let existingFlow of state.flow) {
              if (existingFlow.id === flow.id)  {
                existingFlow = processFlowFromAPI(state, {...existingFlow, ...flow})
                exists = true
                break
              }
            }
            if (!exists)  {
              state.flow.push(processFlowFromAPI(state, flow))              
            }
          }
        }
      }
    },

    [getFlowByServiceId.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getFlowStatusById.pending]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (action.meta.arg.flowId !== undefined && flow.id === action.meta.arg.flowId)  {
          return {...flow, loading_status_data: "pending"}
        }
        if (action.meta.arg.flowIds !== undefined) {
          for (let flowId of action.meta.arg.flowIds) {
            if (flow.id === flowId)  {
              return {...flow, loading_status_data: "pending"}
            }
          }
        }
        return flow
      })
    },

    [getFlowStatusById.fulfilled]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (action.meta.arg.flowId !== undefined && flow.id === action.meta.arg.flowId)  {
          return {...flow, loading_status_data: "fulfilled", last_status_data_loaded_on: new Date().getTime()}
        }
        if (action.meta.arg.flowIds !== undefined) {
          for (let flowId of action.meta.arg.flowIds) {
            if (flow.id === flowId)  {
              return {...flow, loading_status_data: "fulfilled", last_status_data_loaded_on: new Date().getTime()}
            }
          }
        }
        return flow
      })

      if (action.payload.flow_status_data !== null) {

        for (let [flowIdAsString, flowStatusPayload] of Object.entries(action.payload.flow_status_data)) {
          const flowId = parseInt(flowIdAsString)
          state.flow = state.flow.map((flow) => {
            if (flow.id !== flowId)
              return flow
            return processFlowFromAPI(state, { ...flow, ...flowStatusPayload })
          })
        }
      }
      
    },

    [getFlowStatusById.rejected]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (action.meta.arg.flowId !== undefined && flow.id === action.meta.arg.flowId)  {
          return {...flow, loading_status_data: "rejected"}
        }
        if (action.meta.arg.flowIds !== undefined) {
          for (let flowId of action.meta.arg.flowIds) {
            if (flow.id === flowId)  {
              return {...flow, loading_status_data: "rejected"}
            }
          }
        }
        return flow
      })
    },


    
    

    [getFlowLiveDataById.pending]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (action.meta.arg.flowId !== undefined && flow.id === action.meta.arg.flowId) {
          return { ...flow, loading_live_data: "pending" }
        }
        if (action.meta.arg.flowIds !== undefined) {
          for (let flowId of action.meta.arg.flowIds) {
            if (flow.id === flowId) {
              return { ...flow, loading_live_data: "pending" }
            }
          }
        }
        if (action.meta.arg.flowIdsWithVersions !== undefined) {
          for (let flowId of Object.keys(action.meta.arg.flowIdsWithVersions)) {
            if (flow.id === parseInt(flowId)) {
              return { ...flow, loading_live_data: "pending" }
            }
          }
        }
        return flow
      })
    },

    [getFlowLiveDataById.fulfilled]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (action.meta.arg.flowId !== undefined && flow.id === action.meta.arg.flowId) {
          return { ...flow, loading_live_data: "fulfilled", last_live_data_loaded_on: new Date().getTime() }
        }
        if (action.meta.arg.flowIds !== undefined) {
          for (let flowId of action.meta.arg.flowIds) {
            if (flow.id === flowId) {
              return { ...flow, loading_live_data: "fulfilled", last_live_data_loaded_on: new Date().getTime() }
            }
          }
        }
        if (action.meta.arg.flowIdsWithVersions !== undefined) {
          for (let flowId of Object.keys(action.meta.arg.flowIdsWithVersions)) {
            if (flow.id === parseInt(flowId)) {
              return { ...flow, loading_live_data: "fulfilled", last_live_data_loaded_on: new Date().getTime() }
            }
          }
        }
        return flow
      })

      if (action.payload.live_data !== null) {
        for (let [flowId, liveData] of Object.entries(action.payload.live_data)) {
          for (let flow of state.flow) {
            if (flow.id === parseInt(flowId)) {
              
              for (const [componentIdAsString, componentData] of Object.entries(liveData))  {
                const componentId = parseInt(componentIdAsString)
                if (flow.liveData[componentId] === undefined)  {
                  flow.liveData[componentId] = {}
                }
                flow.liveData[componentId] = {...flow.liveData[componentId], ...componentData}
              }
              //flow.liveData = {...flow.liveData, ...liveData}
              
              if (action.payload.live_data_versions !== undefined && action.payload.live_data_versions !== null && action.payload.live_data_versions[flow.id] !== undefined) {
                flow.have_live_data_version = action.payload.live_data_versions[flow.id]
              }
              break
            }
          }
        }
      }
    },

    [getFlowLiveDataById.rejected]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (action.meta.arg.flowId !== undefined && flow.id === action.meta.arg.flowId) {
          return { ...flow, loading_live_data: "rejected" }
        }
        if (action.meta.arg.flowIds !== undefined) {
          for (let flowId of action.meta.arg.flowIds) {
            if (flow.id === flowId) {
              return { ...flow, loading_live_data: "rejected" }
            }
          }
        }
        if (action.meta.arg.flowIdsWithVersions !== undefined) {
          for (let flowId of Object.keys(action.meta.arg.flowIdsWithVersions)) {
            if (flow.id === parseInt(flowId)) {
              return { ...flow, loading_live_data: "rejected" }
            }
          }
        }
        return flow
      })
    },


    



    [getFlowConfigurationMap.pending]: (state) => {
      state.loadingConfigurationMaps = 'pending';
    },

    [getFlowConfigurationMap.fulfilled]: (state, action) => {
      state.loadingConfigurationMaps = 'fulfilled';
      if (action.payload.maps !== null) {
        for (let [configurationId, maps] of Object.entries(action.payload.maps)) {
          let foundConfigurationMap = state.configurationMaps.find((cM) => cM.id === parseInt(configurationId))
          if (foundConfigurationMap === undefined) {
            foundConfigurationMap = { id: parseInt(configurationId), component_map: {}, io_map: {} }
            state.configurationMaps.push(foundConfigurationMap)
          }
          for (let [mapKey, map] of Object.entries(maps)) {
            foundConfigurationMap[mapKey] = map
          }
        }
      }
    },

    [getFlowConfigurationMap.rejected]: (state) => {
      state.loadingConfigurationMaps = 'rejected';
    },

    [getFlowUniqueConfigurationMap.pending]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (!action.meta.arg.flowIds.includes(flow.id))  {
          return flow
        }
        return { ...flow, loading_unique_configuration_map: "pending" }
      })
    },

    [getFlowUniqueConfigurationMap.fulfilled]: (state, action) => {
      if (action.payload.unique_configuration !== null) {

        state.flow = state.flow.map((flow) => {
          if (action.payload.unique_configuration[flow.id] === undefined)  {
            return flow
          }
          return { ...flow, unique_configuration: action.payload.unique_configuration[flow.id], loaded_unique_configuration_map: true, loading_unique_configuration_map: "fulfilled" }          
        })
      }
    },

    [getFlowUniqueConfigurationMap.rejected]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (!action.meta.arg.flowIds.includes(flow.id))  {
          return flow
        }
        return { ...flow, loading_unique_configuration_map: "rejected" }
      })
    },





    [getFlowProperty.pending]: (state) => {
      state.status = 'pending';
    },

    [getFlowProperty.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload)
      
      const flow = state.flow.find((n) => { return n.id === action.meta.arg.flowId; })
      if (flow !== undefined)  {
        //Do something with the resulting property
        if (action.payload.value !== null) {
          processFlowPropertyFromAPI(flow, action.meta.arg.key, action.payload.value)
        }
        if (action.payload.values !== null) {
          for (let [key, value] of Object.entries(action.payload.values))  {
            processFlowPropertyFromAPI(flow, key, value)
          }
        }
      }
    },

    [getFlowProperty.rejected]: (state) => {
      state.status = 'rejected';
    },

    [setFlowProperty.pending]: (state) => {
      state.status = 'pending';
    },

    [setFlowProperty.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true)
      }
      
      //Do something with the resulting property
    },

    [setFlowProperty.rejected]: (state, action) => {
      state.status = 'rejected';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },


    
    [setFlowRuntimeProperty.pending]: (state) => {
      state.settingFlowRuntimeProperty = 'pending';
    },

    [setFlowRuntimeProperty.fulfilled]: (state, action) => {
      state.settingFlowRuntimeProperty = 'fulfilled';
      const foundFlow = state.flow.find((n) => n.id === action.meta.arg.flowId)
      if (foundFlow !== undefined) {
        foundFlow.runtime_information_requested = {...foundFlow.runtime_information_requested, ...action.meta.arg.properties}
      }
    
      //runtime_information_requested
      if (action.meta.arg.callback) {
        action.meta.arg.callback(true)
      }
    },

    [setFlowRuntimeProperty.rejected]: (state, action) => {
      state.settingFlowRuntimeProperty = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },

    [manageFlowAssignedBladeZoneEntry.pending]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (action.meta.arg.flowId !== undefined && flow.id === action.meta.arg.flowId)  {
          return {...flow, updatingFlowAssignedBladeZoneEntriesStatus: "pending"}
        }
        return flow
      })
    },

    [manageFlowAssignedBladeZoneEntry.fulfilled]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (action.meta.arg.flowId !== undefined && flow.id === action.meta.arg.flowId)  {
          return {...flow, updatingFlowAssignedBladeZoneEntriesStatus: "fulfilled"}
        }
        return flow
      })
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true)
      }
    },

    [manageFlowAssignedBladeZoneEntry.rejected]: (state, action) => {
      state.flow = state.flow.map((flow) => {
        if (action.meta.arg.flowId !== undefined && flow.id === action.meta.arg.flowId)  {
          return {...flow, updatingFlowAssignedBladeZoneEntriesStatus: "rejected"}
        }
        return flow
      })
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },

    [getFlowMapVersions.pending]: (state, action) => {
      for (let flowId of action.meta.arg.flowIds) {
        const foundFlow = state.flow.find((n) => n.id === flowId)
        if (foundFlow !== undefined) {
          foundFlow.retrievingMapVersionsStatus = "pending"
        }
      }
    },
    [getFlowMapVersions.fulfilled]: (state, action) => {
      for (let flowId of action.meta.arg.flowIds) {
        const foundFlow = state.flow.find((n) => n.id === flowId)
        if (foundFlow !== undefined) {
          foundFlow.retrievingMapVersionsStatus = "fulfilled"
          foundFlow.lastMapVersionsLoadedOn = new Date().getTime()
        }
      }

      for (let [flowId, flowMapVersion] of Object.entries(action.payload.versions)) {
        const foundFlow = state.flow.find((n) => n.id === parseInt(flowId))
        if (foundFlow !== undefined)  {
          for (let [key, version] of Object.entries(flowMapVersion)) {
            foundFlow.maps.versions[key] = version
          }
        }
      }
    },

    [getFlowMapVersions.rejected]: (state, action) => {
      for (let flowId of action.meta.arg.flowIds) {
        const foundFlow = state.flow.find((n) => n.id === flowId)
        if (foundFlow !== undefined) {
          foundFlow.retrievingMapVersionsStatus = "rejected"
          foundFlow.lastMapVersionsLoadedOn = new Date().getTime()
        }
      }

    },


    [getFlowMaps.pending]: (state, action) => {
      for (let flowId of Object.keys(action.meta.arg.flowMapKeys)) {
        const foundFlow = state.flow.find((n) => n.id === parseInt(flowId))
        if (foundFlow !== undefined) {
          foundFlow.retrievingMapsStatus = "pending"
        }
      } 
    },
    [getFlowMaps.fulfilled]: (state, action) => {
      for (let flowId of Object.keys(action.meta.arg.flowMapKeys)) {
        const foundFlow = state.flow.find((n) => n.id === parseInt(flowId))
        if (foundFlow !== undefined) {
          foundFlow.retrievingMapsStatus = "fulfilled"
          foundFlow.lastMapsLoadedOn = new Date().getTime()
        }
      } 

      for (let [flowId, flowMapInfo] of Object.entries(action.payload.flow_maps_info)) {
        const foundFlow = state.flow.find((n) => n.id === parseInt(flowId))
        if (foundFlow !== undefined) {
          for (let [key, version] of Object.entries(flowMapInfo.versions)) {
            foundFlow.maps.versions[key] = version
            foundFlow.haveMapVersions[key] = version
          }
          for (let [key, map] of Object.entries(flowMapInfo.maps)) {
            foundFlow.maps.maps[key] = map
          }

          updateNutrientReservoirsForFlow(foundFlow)
          updateAssignedBladeZoneMapForFlow(foundFlow)
        }
      }
      
    },

    [getFlowMaps.rejected]: (state, action) => {
      for (let flowId of Object.keys(action.meta.arg.flowMapKeys)) {
        const foundFlow = state.flow.find((n) => n.id === parseInt(flowId))
        if (foundFlow !== undefined) {
          foundFlow.retrievingMapsStatus = "rejected"
          foundFlow.lastMapsLoadedOn = new Date().getTime()
        }
      } 
    },
  }
})





export const InitialLoadAllFlows = ({}) => {
  const dispatch = useDispatch()

  const loadedAllFlows = useSelector((state) => state.loadedAllFlows)
  const loadingAllFlowsStatus = useSelector((state) => state.loadingAllFlowsStatus)
  useEffect(() => {
    if (!loadedAllFlows && loadingAllFlowsStatus !== "pending")   {
      dispatch(getAllFlows({}))
    }
  }, [loadedAllFlows, loadingAllFlowsStatus])
}


/*Load flow status data*/
export const MaintainFlowStatus = ({ flowIds, flowUIDs, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allFlows = useSelector((state) => selectAllFlows(state))
  /*Load flow status data*/
  const validateFlowStatusDataToLoad = () => {
    let flowsToLoadStatusData = []
    let currentTime = new Date().getTime()

    let flowsToValidate = []
    if (flowIds !== undefined) {
      for (let flowId of Object.values(flowIds)) {
        let foundFlow = allFlows.find((b) => b.id === flowId)
        if (foundFlow !== undefined) {
          flowsToValidate.push(foundFlow)
        }
      }
    }
    if (flowUIDs !== undefined) {
      for (let flowUID of Object.values(flowUIDs)) {
        let foundFlow = allFlows.find((b) => b.uid === flowUID)
        if (foundFlow !== undefined) {
          if (flowsToValidate.find((b) => b.uid === flowUID) === undefined) {
            flowsToValidate.push(foundFlow)
          }
        }
      }
    }
    for (let flow of flowsToValidate) {
      let requiresStatusUpdate = false
      if (flow.loading_status_data === "idle") {
        requiresStatusUpdate = true
      } else if (flow.loading_status_data === "fulfilled" || flow.loading_status_data === "rejected") {
        if (flow.last_status_data_loaded_on !== undefined) {
          let elapsedTime = currentTime - flow.last_status_data_loaded_on
          if (elapsedTime > interval) {
            requiresStatusUpdate = true
          }
        }
      }
      if (requiresStatusUpdate) {
        flowsToLoadStatusData.push(flow.id)
      }
    }

    if (flowsToLoadStatusData.length > 0) {
      dispatch(getFlowStatusById({ flowIds: flowsToLoadStatusData }))
    }
  }
  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateFlowStatusDataToLoad()
    }, interval / 10);
    //validateFlowStatusDataToLoad()
    return () => clearInterval(statusLoadInterval);
  }, [allFlows, flowIds, flowUIDs]);
}





export const MaintainFlowLiveData = ({ flowIds, flowUIDs, interval = 1000 }) => {
  const dispatch = useDispatch()
  const allFlows = useSelector((state) => selectAllFlows(state))

  const validateFlowLiveDataToLoad = () => {
    let flowsToLoadLiveData = {}
    let currentTime = new Date().getTime()

    let flowsToValidate = []
    if (flowIds !== undefined) {
      for (let flowId of Object.values(flowIds)) {
        let foundFlow = allFlows.find((b) => b.id === flowId)
        if (foundFlow !== undefined) {
          flowsToValidate.push(foundFlow)
        }
      }
    }
    if (flowUIDs !== undefined) {
      for (let flowUID of Object.values(flowUIDs)) {
        let foundFlow = allFlows.find((b) => b.uid === flowUID)
        if (foundFlow !== undefined) {
          if (flowsToValidate.find((b) => b.uid === flowUID) === undefined) {
            flowsToValidate.push(foundFlow)
          }
        }
      }
    }
    for (let flow of flowsToValidate) {
      if (flow.loading_live_data === "idle") {
        flowsToLoadLiveData[flow.id] = flow.have_live_data_version
      } else if (flow.loading_live_data === "fulfilled" || flow.loading_live_data === "rejected") {
        if (flow.last_live_data_loaded_on === undefined || currentTime - flow.last_live_data_loaded_on > interval) {
          flowsToLoadLiveData[flow.id] = flow.have_live_data_version
        }
      }
    }

    if (Object.entries(flowsToLoadLiveData).length > 0) {
      dispatch(getFlowLiveDataById({ flowIdsWithVersions: flowsToLoadLiveData }))
    }
  }

  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateFlowLiveDataToLoad()
    }, interval / 10);
    //validateFlowLiveDataToLoad()
    return () => clearInterval(statusLoadInterval);
  }, [allFlows, flowIds, flowUIDs]);
}




export const MaintainLatestFlowMaps = ({flowIds, mapKeys, interval=5000}) => {
  
  const dispatch = useDispatch()
  const allFlows = useSelector((state) => selectAllFlows(state))

 
  const performMapValidationCheck = () => {
    let flowIdsToValidate = []
    for (let flowId of Object.values(flowIds)) {
      let flow = allFlows.find((b) => b.id === flowId)
      if (flow !== undefined) {
        if (flow.retrievingMapVersionsStatus !== "pending" && new Date().getTime() - flow.lastMapVersionsLoadedOn > 5000) {
          flowIdsToValidate.push(flowId)
        }
      }
    }
    if (flowIdsToValidate.length > 0) {
      dispatch(getFlowMapVersions({flowIds: flowIdsToValidate}))
    }
  }
  useEffect(() => {
    const mapValidationCheckInterval = setInterval(() => {
      performMapValidationCheck()
    }, interval / 10);
    performMapValidationCheck()
    return () => clearInterval(mapValidationCheckInterval);
  }, [allFlows, flowIds]);



  const validateFlowMapsToLoad = () => {
    let mapsRequireUpdate = {}
    let currentTime = new Date().getTime()

    for (let flowId of Object.values(flowIds)) {
      let flow = allFlows.find((b) => b.id === flowId)
      if (flow !== undefined) {
        if (flow.retrievingMapsStatus !== "pending" && new Date().getTime() - flow.lastMapsLoadedOn > 1000) {

          if (flow.maps !== undefined && flow.maps.versions !== undefined)  {
            let currentFlowMapKeysToUpdate = []
            for (let key of Object.keys(flow.maps.versions))  {
              if (flow.haveMapVersions[key] !== undefined)  {
                if (flow.maps.versions[key] !== flow.haveMapVersions[key])  {
                  currentFlowMapKeysToUpdate.push(key)
                }
              }else {
                currentFlowMapKeysToUpdate.push(key)
              }
            }
            if (currentFlowMapKeysToUpdate.length > 0)  {
              mapsRequireUpdate[flow.id] = currentFlowMapKeysToUpdate
            }
          }
        }
      }
    }
      
    

    if (Object.entries(mapsRequireUpdate).length > 0) {
      dispatch(getFlowMaps({flowMapKeys: mapsRequireUpdate}))
    }
  }


  useEffect(() => {
    const mapValidationCheckInterval = setInterval(() => {
      validateFlowMapsToLoad()
    }, interval / 10);
    validateFlowMapsToLoad()
    return () => clearInterval(mapValidationCheckInterval);
  }, [allFlows, flowIds]);
}




export const MaintainFlowConfigurationMaps = ({ flowIds, flowUIDs, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allFlows = useSelector((state) => selectAllFlows(state))
  const allFlowConfigurationMaps = useSelector(selectAllFlowConfigurationMaps)

  /*Load flow status data*/
  const validateFlowConfigurationMapsToLoad = () => {

    let flowConfigurationMapsToLoad = {}
    if (flowIds !== undefined) {
      for (let flowId of Object.values(flowIds)) {
        let foundFlow = allFlows.find((b) => b.id === flowId)
        if (foundFlow !== undefined) {
          if (flowConfigurationMapsToLoad[foundFlow.configuration_id] === undefined && allFlowConfigurationMaps.find((cM) => cM.id === foundFlow.configuration_id) === undefined)  {
            flowConfigurationMapsToLoad[foundFlow.configuration_id] = { "component_map": null }
          }
        }
      }
    }
    if (flowUIDs !== undefined) {
      for (let flowUID of Object.values(flowUIDs)) {
        let foundFlow = allFlows.find((b) => b.uid === flowUID)
        if (foundFlow !== undefined) {
          if (flowConfigurationMapsToLoad[foundFlow.configuration_id] === undefined && allFlowConfigurationMaps.find((cM) => cM.id === foundFlow.configuration_id) === undefined)  {
            flowConfigurationMapsToLoad[foundFlow.configuration_id] = { "component_map": null }
          }
        }
      }
    }
    if (Object.entries(flowConfigurationMapsToLoad).length > 0) {
      dispatch(getFlowConfigurationMap({ maps: flowConfigurationMapsToLoad }))
    }
  }
  useEffect(() => {
    const configurationMapsLoadInterval = setInterval(() => {
      validateFlowConfigurationMapsToLoad()
    }, interval / 10);
    //validateFlowConfigurationMapsToLoad()
    return () => clearInterval(configurationMapsLoadInterval);
  }, [allFlows, allFlowConfigurationMaps, flowIds, flowUIDs]);
}

export const MaintainFlowUniqueConfigurationMaps = ({ flowIds, flowUIDs, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allFlows = useSelector((state) => selectAllFlows(state))
  /*Load flow status data*/
  const validateFlowUniqueConfigurationMapsToLoad = () => {
    let flowToLoadUniqueConfigurationMaps = []
    let currentTime = new Date().getTime()

    let flowToValidate = []
    if (flowIds !== undefined) {
      for (let flowId of Object.values(flowIds)) {
        let foundFlow = allFlows.find((b) => b.id === flowId)
        if (foundFlow !== undefined) {
          flowToValidate.push(foundFlow)
        }
      }
    }
    if (flowUIDs !== undefined) {
      for (let flowUID of Object.values(flowUIDs)) {
        let foundFlow = allFlows.find((b) => b.uid === flowUID)
        if (foundFlow !== undefined) {
          if (flowToValidate.find((b) => b.uid === flowUID) === undefined) {
            flowToValidate.push(foundFlow)
          }
        }
      }
    }
    for (let flow of flowToValidate) {
      if (flow.loading_unique_configuration_map !== "pending") {
        if (flow.unique_configuration === null || flow.unique_configuration_version !== flow.unique_configuration.version) {
          flowToLoadUniqueConfigurationMaps.push(flow.id)
        }
      }
    }

    if (flowToLoadUniqueConfigurationMaps.length > 0) {
      //console.log(flowToLoadUniqueConfigurationMaps)
      dispatch(getFlowUniqueConfigurationMap({flowIds: flowToLoadUniqueConfigurationMaps}))
    }
  }
  useEffect(() => {
    const uniqueConfigurationMapsLoadInterval = setInterval(() => {
      validateFlowUniqueConfigurationMapsToLoad()
    }, interval / 10);
    //validateFlowUniqueConfigurationMapsToLoad()
    return () => clearInterval(uniqueConfigurationMapsLoadInterval);
  }, [allFlows, flowIds, flowUIDs]);
}





// Action creators are generated for each case reducer function
export const { } = flowsSlice.actions

export default flowsSlice.reducer

export const selectAllFlows = state => state.flow.flow
export const selectFlowById = (state, flowId) => state.flow.flow.find((f) => f.id === flowId)
export const selectFlowByUID = (state, flowUID) => state.flow.flow.find((f) => f.uid === flowUID)
export const selectAllFlowConfigurationMaps = state => state.flow.configurationMaps

export const selectFlowDisplayNameById = (state, flowId) => {
  let flow = state.flow.flow.find((b) => b.id === flowId)
  if (flow !== undefined) {
    return flow.display_name 
  }
  return "Unknown Blade"
}

export const selectFlowControlDeviceConnectedState = (state, flowId) => {
  let flow = state.flow.flow.find((b) => b.id === flowId)
  if (flow !== undefined) {
    return (flow.control_device !== null && flow.control_device.connected)
  }
  return false
}

export const selectFlowNutrientReservoirsByFlowId = (state, flowId) => {
  let flow = state.flow.flow.find((b) => b.id === flowId)
  if (flow !== undefined) {
    return (flow.nutrient_reservoirs !== null && flow.nutrient_reservoirs)
  }
  return {}
}
export const selectFlowCurrentActionByFlowId = (state, flowId) => {
  let flow = state.flow.flow.find((b) => b.id === flowId)
  if (flow !== undefined) {
    return (flow.currentAction !== null && flow.currentAction)
  }
  return "N/A"
}
export const selectFlowCurrentSubactionByFlowId = (state, flowId) => {
  let flow = state.flow.flow.find((b) => b.id === flowId)
  if (flow !== undefined) {
    return (flow.currentSubaction !== null && flow.currentSubaction)
  }
  return null
}
export const selectFlowCurrentActionInfoByFlowId = (state, flowId) => {
  let flow = state.flow.flow.find((b) => b.id === flowId)
  if (flow !== undefined) {
    return (flow.currentActionInfo !== null && flow.currentActionInfo)
  }
  return {}
}



export const selectFlowDosingReservoirByIndex = (state, flowId, reservoirIndex) => {
  let reservoirInfo = {}
  let flow = state.flow.flow.find((b) => b.id === flowId)
  if (flow !== undefined) {
    //Process map
    if (flow.maps.maps["dosing_map"]["entries"][reservoirIndex] !== undefined) {
      reservoirInfo = {...flow.maps.maps["dosing_map"]["entries"][reservoirIndex]}
    }
    reservoirInfo.runtime_information = JSON.parse(flow.runtime_information_requested["dosing_reservoir_info_" + reservoirIndex.toString()] ?? flow.runtime_information["dosing_reservoir_info_" + reservoirIndex.toString()] ?? "{}")
  }
  return reservoirInfo
}
 
//Returns usable value, active value, requested value
export const selectFlowRuntimeInformation = (state, flowId, key) => {
  let flow = state.flow.flow.find((b) => b.id === flowId)
  if (flow !== undefined) {
    const usableValue = flow.runtime_information_requested[key] ?? flow.runtime_information[key] ?? ""
    return [usableValue, flow.runtime_information[key] ?? "", flow.runtime_information_requested[key] ?? ""]
  }
  return ["", "", ""]
}




export const selectFlowComponentInfo = (state, flowId, componentName, identifiers) => {
  
  const flow = state.flow.flow.find((b) => b.id === flowId)
  if (flow !== undefined)  {
    const configMap = state.flow.configurationMaps.find((cM) => cM.id === flow.configuration_id)
    if (configMap !== undefined && configMap.component_map)  {
      let component = configMap.component_map.components.find((c) => c.name === componentName)
      if (component !== undefined)  {
        let returnInfo = []
        
        for (let identifier of identifiers) {
          let identifierInfo = component[0] !== undefined ? (component[0].data_types ? component[0].data_types.find((dT) => dT.identifier === identifier) : undefined) : undefined
          returnInfo.push({
            componentId: component.id,
            identifier: identifier
          })
        }

        return returnInfo
      }
    }
  }


  let returnInfo = []
  for (let i = 0; i < identifiers.length; i++)  {
    returnInfo[i] = null
  }
  return returnInfo
}

export const selectFlowLiveDataByComponentInfo = (state, flowId, componentInfo) => {
  const flow = state.flow.flow.find((b) => b.id === flowId)
  if (flow !== undefined && componentInfo && componentInfo.componentId !== undefined && componentInfo.identifier !== undefined)  {
    if (flow.liveData[componentInfo.componentId] !== undefined && flow.liveData[componentInfo.componentId][componentInfo.identifier] !== undefined) {
      return flow.liveData[componentInfo.componentId][componentInfo.identifier]
    }
  }
  return {value: null, retrieved_on: null}
}
