import './FlowsPage.scss';
import variables from '../../../globals.scss';
import React from 'react'
import _ from 'underscore';


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { InitialLoadAllBladeZones, selectAllBladeZones, selectAllBlades, selectBladeZoneByUID, selectBladeZoneDisplayNameByUID, useMaintainBladeStatus,  } from '../../../redux/entities/service/Blade';
import { RoundToNearest, parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import { AddItem, AdditionalOptions, BadX, GoodCheckmark, HalfFullCup, Pause, Play, Plus, PlusAlt, RSSI, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import { InitialLoadAllFlows, MaintainFlowStatus, getFlowMapVersions, getFlowMaps, getFlowStatusById, selectAllFlow, selectFlowControlDeviceConnectedState, selectFlowCurrentActionByFlowId, selectFlowCurrentActionInfoByFlowId, selectFlowCurrentSubactionByFlowId, selectFlowDisplayNameById, selectFlowNutrientReservoirsByFlowId, selectFlowRuntimeInformation, setFlowProperty, setFlowRuntimeProperty } from '../../../redux/entities/service/Flow';
import AddFlowWalkthrough from './AddFlowWalkthrough';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import { ScanFlow } from '../../../assets/icons/FlowIcons';
import DropDownButton from '../../../components/DropDownButton';
import TopUpReservoirWalkthrough from './TopUpReservoirWalkthrough';
import { getInventoryItemTypeById, selectAllInventoryItemTypes } from '../../../redux/entities/Inventory';
import FlowZonePortsManager from './FlowZonePortsManager';
import FlowInstance_ReservoirsContent from './FlowInstance_ReservoirsContent';
import FlowInstance_StatusContent from './FlowInstance_StatusContent';
import { selectAllAccountPrivileges } from '../../../redux/entities/Account';
import { TabControl, TabControlTab } from '../../../components/TabControl';
import DropDownInput from '../../../components/input/DropDownInput';




const FlowInstance = ({flowId, isDefaultExpanded, manageZonePortsCallback}) =>  {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

  const dispatch = useDispatch()


  const accountPrivileges = useSelector((state) => selectAllAccountPrivileges(state), _.isEqual)
  const accountHasServicingPriviliges = (accountPrivileges["servicing"] !== undefined)


  const flowDisplayName = useSelector((state) => selectFlowDisplayNameById(state, flowId), _.isEqual)
  const isFlowOnline = useSelector((state) => selectFlowControlDeviceConnectedState(state, flowId), _.isEqual)

  const [inServiceModeRuntimeInformation] = useSelector((state) => selectFlowRuntimeInformation(state, flowId, "service_mode"), _.isEqual)
  const inServiceMode = accountHasServicingPriviliges ? parseBool(inServiceModeRuntimeInformation) : false



  const isFlowPaused = false
  const [currentSubtitle, SetCurrentSubtitle] = React.useState("idle")
  const [currentAction, SetCurrentAction] = React.useState("idle")




  const cancelOperationClicked = () => {
    dispatch(setFlowRuntimeProperty({flowId: flowId, properties: {"subcontrol_state": "remote_cancel"}, callback: (success) => {
      
    }}))
  }




  /*const solutions = useSelector(selectAllSolutions)
  const solutionsStatus = useSelector((state) => state.solutions.status)
  const nutrientSolutions = useSelector(selectAllNutrientSolutions);
  const nutrientSolutionsStatus = useSelector((state) => state.nutrientSolutions.status)*/
  
  const [flowControlState] = useSelector((state) => selectFlowRuntimeInformation(state, flowId, "control_state"), _.isEqual)
  const flowCurrentAction = useSelector((state) => selectFlowCurrentActionByFlowId(state, flowId), _.isEqual)
  const flowCurrentSubaction = useSelector((state) => selectFlowCurrentSubactionByFlowId(state, flowId), _.isEqual)
  const flowCurrentActionInfo = useSelector((state) => selectFlowCurrentActionInfoByFlowId(state, flowId), _.isEqual)
  

  const [flowDosingZoneUID, SetFlowDosingZoneUID] = React.useState(null)
  const dosingZoneName = useSelector((state) => selectBladeZoneDisplayNameByUID(state, flowDosingZoneUID), _.isEqual)
  //const [activeZoneUIDs, SetActiveZoneUIDs] = React.useState([])
  const zoneStatus = "Idle"




  const [currentContentTab, SetCurrentContentTab] = React.useState("reservoirs")




  React.useEffect(() => {
    if (flowCurrentActionInfo["zone_uid"] !== undefined)  {
      SetFlowDosingZoneUID(flowCurrentActionInfo["zone_uid"].split("-")[0])
    }else {
      SetFlowDosingZoneUID(null)
    }
  }, [flowCurrentActionInfo]) 

  React.useEffect(() => {
    if (flowControlState === "rack_dose") {
      if (flowCurrentActionInfo["zone_uid"] !== undefined && flowCurrentActionInfo["zone_uid"].indexOf("-")) {
        const subZoneKey = flowCurrentActionInfo["zone_uid"].split("-")[1]
        if (subZoneKey === "A") {
          SetCurrentAction("Dosing Zone: " + dosingZoneName + " - Upper")          
        }else if (subZoneKey === "B") {
          SetCurrentAction("Dosing Zone: " + dosingZoneName + " - Lower")
        }else {
          SetCurrentAction("Dosing Zone: " + dosingZoneName)
        }
      }else {
        SetCurrentAction("Dosing Zone: " + dosingZoneName)
      }
    }else {
      SetCurrentAction(flowCurrentAction) 
    }
  }, [flowControlState, flowCurrentAction, flowCurrentActionInfo, dosingZoneName])

  React.useEffect(() => {
    SetCurrentSubtitle(currentAction)
  }, [currentAction])









  const flowActiveIssues = {}//useSelector((state) => selectFlowActiveIssuesById(state, flowId), _.isEqual)
  
  const [viewingIssues, SetViewingIssues] = React.useState(false)
  const issuesClicked = () => {
    SetViewingIssues(true)
  }
  const closeViewGerminationStationIssues = () => {
    SetViewingIssues(false)
  }


  const [toppingUpReservoirIndex, SetToppingUpReservoirIndex] = React.useState(null)
  const [toppingUpReservoir, SetToppingUpReservoir] = React.useState(false)
  const topUpFlowReservoir = React.useCallback((reservoirIndex) => {
    SetToppingUpReservoirIndex(reservoirIndex)
    SetToppingUpReservoir(true)
  })

  const topUpFlowReservoirWalkthroughCloseCallback = React.useCallback(() => {
    SetToppingUpReservoir(false)  
  })





  
  
  let subtitle = ""

  let additionalFunctions = [
    {key: "manage_zone_ports", label: "Manage Ports", callback: () => {manageZonePortsCallback(flowId)}},
    {key: "rename", label: "Rename Flow"},
    {key: "cancel_operation", label: "Cancel Operation", status: "Critical", callback: () => {cancelOperationClicked(flowId)}},
  ]
 

  if (accountHasServicingPriviliges) {
    if (!inServiceMode) {
      additionalFunctions.push(...[
        {
          key: "activate_service_mode", label: "Turn On Service Mode", status: "Critical", callback: () => {
            dispatch(setFlowRuntimeProperty({ flowId: flowId, properties: { "service_mode": 1 } }))
          }
        },
      ])
    } else {
      additionalFunctions.push(...[
        {
          key: "activate_service_mode", label: "Turn Off Service Mode", status: "Critical", callback: () => {
            dispatch(setFlowRuntimeProperty({ flowId: flowId, properties: { "service_mode": 0 } }))
          }
        },
      ])
    }
  }

  
  if (isMobile) {
    additionalFunctions.splice(2, 0, 
      {key: "pause_flow", label: "Pause Flow"}  
    );
  }


  additionalFunctions.push(...[
    { key: "delete", label: "Delete", status: "Critical" },
  ])



  let secondaryHeaderContent = null
  if (!isMobile)  {
    secondaryHeaderContent = [
      {key: "pause_state", content: 
        <div className="FlexContent-H-10">
          {isFlowPaused && 
            <div className="FlexContent-H-20 FlexContent-Center">
              <span className="FlowStatus-Flow-PauseInfo-State">Paused 16:34</span>

              <div className="FlowStatus-Flow-PauseInfo-AddTimeButton"><span>Add Time</span></div>
            </div>
          }
          <div className="FlexContent-H-10">
            {isFlowPaused && <>
              <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
            </>}
            {!isFlowPaused &&
              <Button status={"Small-IconAction"} state={false} content={<Pause/>}/>
            }
          </div>
        </div>
      },
      {key: "rssi", content: <RSSI/>}
    ]
  }else {
    secondaryHeaderContent = <><RSSI/></>
  }

  let extendedHeaderContent = null
  if (isMobile && isFlowPaused) {
    extendedHeaderContent = {content: <>
      <div className="FlexContent-H-10">
        <div className="FlexContent-H-10 FlexContent-Center">
          <div className="FlowStatus-Flow-PauseInfo-AddTimeButton"><span>Paused 16:34</span><PlusAlt/></div>
        </div>
        <div className="FlexContent-H-10">
          <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
        </div>
      </div>
    </>}
  }else {

  }


  const [expandedContentPage, SetExpandedContentPage] = React.useState("reservoirs")
  let tabSelected = (tab) => {
    SetExpandedContentPage(tab)
  }

  return (<>
  <RowGroupRow
    key={flowId}
    title={flowDisplayName}
    hasFlexCell={true}
    subtitle={<div className="FlowStatus-Flow-Subtitle">{currentSubtitle}</div>}
    showStatusIndicator={true}
    status={isFlowOnline ? "Connected" : "Not Connected"}
    statusColor={isFlowOnline ? "#4DBE3B" : "#f00"}
    hasIssues={true}
    numberOfIssues={Object.entries(flowActiveIssues).length}
    issuesClicked={issuesClicked}
    customRowPadding={0}
    isExpandable={true}
    expandedState={isDefaultExpanded}
    hasAdditionalFunctions={true} 
    secondaryContent={secondaryHeaderContent}
    extendedHeaderContent={extendedHeaderContent}
    additionalFunctions={additionalFunctions}
    renderContentOnlyWhenOpen={true}
    content={<>
      <div className="FlowStatus-Flow-Content">
        {!isMobile && <>
          <div className="FlowStatus-Flow-InfoSide">
            <div className="FlowStatus-Flow-CurrentActionCard">
                <div>Current Action:</div>
                <div className="FlowStatus-Flow-CurrentActions">
                  <div>{currentAction}</div>
                  <div>-</div>
                  <div>{flowCurrentSubaction ? flowCurrentSubaction : <>-</>}</div>
                </div>
            </div>
            <div className={"FlowStatus-Flow-OnlineStatusCard" + (isFlowOnline ? " FlowStatus-Flow-OnlineStatusCard-Active" : " FlowStatus-Flow-OnlineStatusCard-Inactive")}>
              <div className="FlowStatus-Flow-OnlineStatusCard-StatusIndicator"></div>
              <div>Status: </div>
              <div>{isFlowOnline ? "Active" : "Stopped"}</div>
            </div>
          </div>
          <TabControl
            onTabSelected={tabSelected}
            tabLocation={"flex"}
            tabs={[
              new TabControlTab("Reservoirs", "reservoirs", "", expandedContentPage === "reservoirs"),
              new TabControlTab("Status", "status", "", expandedContentPage === "status"),
              new TabControlTab("Dosing History", "dosing_history", "", expandedContentPage === "dosing_history"),
              new TabControlTab("Projections", "projections", "", expandedContentPage === "projections"),
            ]}
          />
        </>}
        {isMobile && <>
          <div className="FlexContent-10 FlexContent-HFill FlexContent-HFlex">
            <div className={"FlowStatus-Flow-OnlineStatusCard" + (isFlowOnline ? " FlowStatus-Flow-OnlineStatusCard-Active" : " FlowStatus-Flow-OnlineStatusCard-Inactive")}>
              <div className="FlowStatus-Flow-OnlineStatusCard-StatusIndicator"></div>
              <div>Status: </div>
              <div>{isFlowOnline ? "Active" : "Stopped"}</div>
            </div>
            <div className="FlowStatus-Flow-CurrentActionCard">
                <div>Current Action:</div>
                <div className="FlowStatus-Flow-CurrentActions">
                  <div>{currentAction}</div>
                  <div>{flowCurrentSubaction ? flowCurrentSubaction : <>-</>}</div>
                </div>
            </div>
            <DropDownInput
              status="Neutral"
              value={expandedContentPage}
              options={[
                { key: 'reservoirs', label: 'Reservoirs' },
                { key: 'status', label: 'Status' },
                { key: 'dosing_history', label: 'Dosing History' },
                { key: 'projections', label: 'Projections' },
              ]}
              optionsKey="key"
              onSelectionChange={(action) => {
                SetExpandedContentPage(action)
              }} />
            </div>
        </>}

        {expandedContentPage === "reservoirs" && <>
          <FlowInstance_ReservoirsContent flowId={flowId} topUpFlowReservoirCallback={topUpFlowReservoir} />
        </>}
        {expandedContentPage === "status" && <>
          <FlowInstance_StatusContent flowId={flowId} dosingZoneUID={flowDosingZoneUID} />
        </>}

        {expandedContentPage === "dosing_history" && <>
          Dosing History
        </>}
        {expandedContentPage === "projections" && <>
          Projections
        </>}
      </div>
    </>} />
    {/*viewingIssues && <GerminationStationIssuesPopup flowId={flowId} closeCallback={closeViewGerminationStationIssues} />*/}
    {toppingUpReservoir && <TopUpReservoirWalkthrough flowId={flowId} reservoirIndex={toppingUpReservoirIndex} closeCallback={topUpFlowReservoirWalkthroughCloseCallback}/>}

  </>)
}
export default FlowInstance




const FlowInstance_Content = ({flowId, SetCurrentSubtitle}) => {

  //console.log(Flow)

  const dispatch = useDispatch();





  return (<>
    
  </>)




  /*return (<>
    <div className="RowGroupRow-Content-Card-H">
      <div className="FlowStatus-Flow-Content">
        {isMobile && <>
          <div className="FlexContent-10 FlexContent-HFill FlexContent-HFlex">
            <div className={"FlowStatus-Flow-OnlineStatusCard" + (isFlowOnline ? " FlowStatus-Flow-OnlineStatusCard-Active" : " FlowStatus-Flow-OnlineStatusCard-Inactive")}>
              <div className="FlowStatus-Flow-OnlineStatusCard-StatusIndicator"></div>
              <div>Status: </div>
              <div>{isFlowOnline ? "Active" : "Stopped"}</div>
            </div>
            <div className="FlowStatus-Flow-CurrentActionCard">
                <div>Current Action:</div>
                <div className="FlowStatus-Flow-CurrentActions">
                  <div>{currentAction}</div>
                  <div>{flowCurrentSubaction ? flowCurrentSubaction : <>-</>}</div>
                </div>
            </div>
            <Button 
              status={"Primary-Toggle"} 
              content="Reservoirs" 
              state={currentContentTab === "reservoirs"} 
              width={"Flex33"}
              onClick={() => {SetCurrentContentTab("reservoirs")}}/>
            <div className="FlexContent-10 FlexContent-HFill FlexContent-HFlex">
              <div className={"FlowStatus-Flow-Reservoirs-List FlowStatus-Flow-Reservoirs-ListSize1"}>
                {Object.entries(dosingReservoirs).map(([index, info]) => {
                  //console.log(info)
                  let reservoirVolume = 0
                  if (info["reservoir_volume"] !== undefined)  {
                    reservoirVolume = parseFloat(info["reservoir_volume"]) / 1000;
                  }
                  let reservoirLowVolume = 0
                  if (info["reservoir_low_volume"] !== undefined)  {
                    reservoirLowVolume = parseFloat(info["reservoir_low_volume"]) / 1000;
                  }

                  let volumeRemaining = 0
                  if (info["runtime_information"]["volume_remaining"] !== undefined)  {
                    volumeRemaining = parseFloat(info["runtime_information"]["volume_remaining"]) / 1000;
                  }

                  let reservoirDisplayName = "N/A"
                  if (info["item"]["name"] !== undefined) {
                    reservoirDisplayName = info["item"]["name"]
                  }

                  let reservoirVolumeState = "Okay"
                  if (volumeRemaining == 0) {
                    reservoirVolumeState = "Empty"
                  }else if (volumeRemaining < reservoirLowVolume) {
                    reservoirVolumeState = "Low"
                  }



                  const reservoirStatusInfo = handleReservoirStateColorAndStatus(info)

                  return (
                    <div className="FlowStatus-Flow-Reservoirs-Reservoir-Container" key={index}>
                      <div className="FlowStatus-Flow-Reservoirs-Reservoir" style={{borderColor: reservoirStatusInfo.borderColor}}>
                        <div className="FlowStatus-Flow-Reservoirs-Reservoir-MainContent">

                          <div className="FlowStatus-Flow-Reservoirs-Reservoir-MainContent-Header">
                            <div>Reservoir {index}</div>
                            <div>
                              {reservoirStatusInfo.activated && <>
                                {RoundToNearest(volumeRemaining, 0.1)}L/{RoundToNearest(reservoirVolume, 0.1)}L
                              </>}
                              {!reservoirStatusInfo.activated && <>-</>}
                            </div>
                          </div>

                          <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Container">
                            <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Name">{reservoirDisplayName}</div>
                            <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Status" style={{color:reservoirStatusInfo.color}}>{reservoirStatusInfo.status}</div>
                          </div>

                          <div className="FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Container">
                            <div className={"FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background-" + reservoirVolumeState}></div>
                            {reservoirStatusInfo.activated && <>
                              <div className={"FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar-" + reservoirVolumeState}
                                style={{width: ((volumeRemaining / reservoirVolume) * 100) + "%"}}
                                ></div>
                              <div className="FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-LowVolumeIndicator"
                                style={{left: ((reservoirLowVolume / reservoirVolume) * 100) + "%"}}></div>
                              </>
                            }
                          </div>

                          <div className="FlowStatus-Flow-Reservoirs-Reservoir-Functions-Container">
                            {reservoirStatusInfo.activated && <>
                              <Button 
                                content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><HalfFullCup/><span>Refill</span></div>} 
                                status="Small-IconAction" 
                                width={"Flex100"}
                                onClick={() => {topUpFlowReservoir(info.index)}}/>
                            </>}
                            {!reservoirStatusInfo.activated && <>
                              <Button 
                                content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><Plus width={14.5} fill={"#4D5563"}/><span>Activate</span></div>} 
                                status="Small-IconAction" 
                                width={"Flex100"}
                                onClick={() => {topUpFlowReservoir(info.index)}}/>
                            </>}
                            <DropDownButton 
                              content={<div style={{display:"flex", padding:"7px 0px"}}><AdditionalOptions/></div>} 
                              options={processReservoirAdditionalOptions(info)}
                              onOptionSelected={((option) => {
                                handleReservoirAdditionalOptionSelected(index, option.key)
                              })}/>
                          </div>
                        </div>

                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </>}

        {!isMobile && <>
          <div className="FlexContent-10 FlexContent-HFlex">
            <div className="FlowStatus-Flow-InfoSide">
              {/*<div>
                <Button status="Interactive-Neutral" style={{paddingBottom:10}} size="Large-IconAction" content={<>
                      <div className="Button-Large-Icon"><ScanFlow width={isWideDesktop ? 150 : isDesktop ? 120 : 85}/></div>
                  </>}/>
                </div>* /}
              <div className={"FlowStatus-Flow-OnlineStatusCard" + (isFlowOnline ? " FlowStatus-Flow-OnlineStatusCard-Active" : " FlowStatus-Flow-OnlineStatusCard-Inactive")}>
                <div className="FlowStatus-Flow-OnlineStatusCard-StatusIndicator"></div>
                <div>Status: </div>
                <div>{isFlowOnline ? "Active" : "Stopped"}</div>
              </div>
              <div className="FlowStatus-Flow-CurrentActionCard">
                  <div>Current Action:</div>
                  <div className="FlowStatus-Flow-CurrentActions">
                    <div>{currentAction}</div>
                    <div>-</div>
                    <div>{flowCurrentSubaction ? flowCurrentSubaction : <>-</>}</div>
                  </div>
              </div>
            </div>
            <div className="FlowStatus-Flow-ManageCard">
              <div className="FlowStatus-Flow-ManageCard-Header">
                <Button 
                  status={"Primary-Toggle"} 
                  content="Reservoirs" 
                  state={currentContentTab === "reservoirs"} 
                  width={"Flex33"}
                  onClick={() => {SetCurrentContentTab("reservoirs")}}/>
                <Button 
                  status={"Primary-Toggle"} 
                  content="Dosing History" 
                  state={currentContentTab === "dosing_history"} 
                  width={"Flex33"}
                  onClick={() => {SetCurrentContentTab("dosing_history")}}/>
                <Button 
                  status={"Primary-Toggle"} 
                  content="Projections" 
                  state={currentContentTab === "projections"} 
                  width={"Flex33"}
                  onClick={() => {SetCurrentContentTab("projections")}}/>
              </div>
              <div className="FlowStatus-Flow-ManageCard-Content">
                {currentContentTab === "reservoirs" && <>
                  {(() => {
                    

                    let numberOfReservoirsPerRow = 2;
                    if (isTablet) {
                      numberOfReservoirsPerRow = 3;
                    }else if (isDesktop && !isWideDesktop)  {
                      numberOfReservoirsPerRow = 4;
                    }else if (isWideDesktop) {
                      numberOfReservoirsPerRow = 4;
                    }

                    
                    return <>
                      <div className="FlowStatus-Flow-Reservoirs-List-Container">
                        <div className={"FlowStatus-Flow-Reservoirs-List FlowStatus-Flow-Reservoirs-ListSize" + numberOfReservoirsPerRow}>
                          {Object.entries(dosingReservoirs).map(([index, info]) => {
                            let reservoirVolume = 0
                            if (info["reservoir_volume"] !== undefined)  {
                              reservoirVolume = parseFloat(info["reservoir_volume"]) / 1000;
                            }
                            let reservoirLowVolume = 0
                            if (info["reservoir_low_volume"] !== undefined)  {
                              reservoirLowVolume = parseFloat(info["reservoir_low_volume"]) / 1000;
                            }

                            let volumeRemaining = 0
                            if (info["runtime_information"]["volume_remaining"] !== undefined)  {
                              volumeRemaining = parseFloat(info["runtime_information"]["volume_remaining"]) / 1000;
                            }

                            let reservoirDisplayName = "-"
                            if (info["item"]["name"] !== undefined) {
                              reservoirDisplayName = info["item"]["name"]
                            }

                            const reservoirStatusInfo = handleReservoirStateColorAndStatus(info)


                            let reservoirVolumeState = "Okay"
                            if (reservoirStatusInfo.activated) {
                              if (volumeRemaining == 0) {
                                reservoirVolumeState = "Empty"
                              }else if (volumeRemaining < reservoirLowVolume) {
                                reservoirVolumeState = "Low"
                              }
                            }

                            return (
                              <div className="FlowStatus-Flow-Reservoirs-Reservoir-Container" key={index}>
                                <div className="FlowStatus-Flow-Reservoirs-Reservoir" style={{borderColor: reservoirStatusInfo.borderColor}}>
                                  <div className="FlowStatus-Flow-Reservoirs-Reservoir-MainContent">
    
                                    <div className="FlowStatus-Flow-Reservoirs-Reservoir-MainContent-Header">
                                      <div>Reservoir {index}</div>
                                      <div>
                                        {reservoirStatusInfo.activated && <>
                                          {RoundToNearest(volumeRemaining, 0.1)}L/{RoundToNearest(reservoirVolume, 0.1)}L
                                        </>}
                                        {!reservoirStatusInfo.activated && <>-</>}
                                      </div>
                                    </div>
    
                                    <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Container">
                                      <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Name">{reservoirDisplayName}</div>
                                      <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Status" style={{color:reservoirStatusInfo.color}}>{reservoirStatusInfo.status}</div>
                                    </div>

                                    <div className="FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Container">
                                      <div className={"FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background-" + reservoirVolumeState}></div>
                                      {reservoirStatusInfo.activated && <>
                                        <div className={"FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar-" + reservoirVolumeState}
                                          style={{width: ((volumeRemaining / reservoirVolume) * 100) + "%"}}
                                          ></div>
                                        <div className="FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-LowVolumeIndicator"
                                          style={{left: ((reservoirLowVolume / reservoirVolume) * 100) + "%"}}></div>
                                      </>}
                                    </div>

                                    <div className="FlowStatus-Flow-Reservoirs-Reservoir-Functions-Container">
                                      {reservoirStatusInfo.activated && <>
                                        <Button 
                                          content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><HalfFullCup/><span>Refill</span></div>} 
                                          status="Small-IconAction" 
                                          width={"Flex100"}
                                          onClick={() => {topUpFlowReservoir(info.index)}}/>
                                      </>}
                                      {!reservoirStatusInfo.activated && <>
                                        <Button 
                                          content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><Plus width={14.5} fill={"#4D5563"}/><span>Activate</span></div>} 
                                          status="Small-IconAction" 
                                          width={"Flex100"}
                                          onClick={() => {topUpFlowReservoir(info.index)}}/>
                                      </>}
                                      <DropDownButton 
                                        content={<div style={{display:"flex", padding:"7px 0px"}}><AdditionalOptions/></div>} 
                                        options={processReservoirAdditionalOptions(info)}
                                        onOptionSelected={((option) => {
                                          handleReservoirAdditionalOptionSelected(index, option.key)
                                        })}/>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </>
                  })()}
                </>}
              </div>
            </div>
          </div>
        </>}
      </div>
    </div>
    {toppingUpReservoir && <TopUpReservoirWalkthrough flowId={flowId} reservoirIndex={toppingUpReservoirIndex} reservoirInfo={dosingReservoirs[toppingUpReservoirIndex]} closeCallback={topUpFlowReservoirWalkthroughCloseCallback}/>}

  </>)*/
}