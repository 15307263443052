import './ManageFlowWalkthrough.scss';
import React from 'react';
import PopupModal from '../../../model_components/PopupModal';
import Button from '../../../components/Button';
import ControlBar from '../../../components/ControlBar';
import { useSelector } from 'react-redux';
import Walkthrough from '../../../components/Walkthrough';
import ProgressBar from '../../../components/ProgressBar';
import { selectAllFlows } from '../../../redux/entities/service/Flow';
import ScanFlowWalkthroughStep from './ManageFlowWalkthroughSteps/ScanFlowWalkthroughStep';
import ConfirmPowerOnWalkthroughStep from './ManageFlowWalkthroughSteps/ConfirmPowerOnWalkthroughStep'
import ConfirmNewFlowWalkthroughStep from './ManageFlowWalkthroughSteps/ConfirmNewFlowWalkthroughStep';
import SelectFlowNameWalkthroughStep from './ManageFlowWalkthroughSteps/SelectFlowNameWalkthroughStep';
import ConnectingFlowWalkthroughStep from './ManageFlowWalkthroughSteps/ConnectingFlowWalkthroughStep';
import ManagePortsWalkthroughStep from './ManageFlowWalkthroughSteps/ManagePortsWalkthroughStep';
import ManageReservoirsWalkthroughStep from './ManageFlowWalkthroughSteps/ManageReservoirsWalkthroughStep';


  

const AddFlowWalkthrough = ({facilityId, completeCallback, closeCallback}) => {
   
    const [numberOfSteps, SetNumberOfSteps] = React.useState(5)
    const [currentStepIndex, SetCurrentStepIndex] = React.useState(1)

    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }
    const confirmedCancelClicked = (e) =>  {
        

        if (completeCallback !== undefined) {
            completeCallback()
        }
    }

    
    const currentTheme = "white"

    const [FlowCode, SetFlowCode] = React.useState("")
    const [codeWasScanned, SetCodeScanned] = React.useState(false)

    const [selectedConnectionOption, SetSelectedConnectionOption] = React.useState("provide_credentials")

    const [FlowUID, SetFlowUID] = React.useState("")

    const [FlowName, SetFlowName] = React.useState(null)
    const [FlowNumberOfPorts, SetFlowNumberOfPorts] = React.useState(18)
    const [FlowPorts, SetFlowPorts] = React.useState({})

    React.useEffect(() => {
        //Create X amount of ports in nds ports
        let ports = {}
        for (let i = 0; i < FlowNumberOfPorts; i++)  {
            ports[i] = {active: false, linked: false, inactive: false, offline: false, rack_group_uid: null, rack_uid: null, zone_uid: null} 
        }
        SetFlowPorts(ports)
    }, [FlowNumberOfPorts])
    

    const [FlowNumberOfReservoirs, SetFlowNumberOfReservoirs] = React.useState(16)
    const [FlowReservoirs, SetFlowResevoirs] = React.useState({})

    React.useEffect(() => {
        //Create X amount of ports in nds ports
        let reservoirs = {}
        for (let i = 0; i < FlowNumberOfReservoirs; i++)  {
            reservoirs[i] = {active: false, has_reservoir_volume: false, reservoir_volume: null, reservoir_volume_type: "litres"} 
        }
        SetFlowResevoirs(reservoirs)
    }, [FlowNumberOfReservoirs])
    
    
    
    
    const Flows = useSelector(selectAllFlows)
    




    


    //Animation
    //let CurrentWalkthroughContent = walkthroughContentStack[0]
    let [currentWalkthroughContent, SetCurrentWalkthroughStep] = React.useState(["power_on", null])
    //let [currentWalkthroughContent, SetCurrentWalkthroughStep] = React.useState(["select_rack_group", "rack_group_listing"])
    let [previousWalkthroughContent, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughContent, SetNextWalkthroughStep] = React.useState(null)

    let [walkthroughTransitionToPreviousCompleted, SetWalkthroughTransitionToPreviousCompleted] = React.useState(false)
    React.useEffect(() => {
        if (walkthroughTransitionToPreviousCompleted)   {
            console.log(previousWalkthroughContent)
            SetCurrentWalkthroughStep(previousWalkthroughContent)
            SetPreviousWalkthroughStep(null)
            SetWalkthroughTransitionToPreviousCompleted(false)
            return
        }
    }, [walkthroughTransitionToPreviousCompleted, previousWalkthroughContent])

    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetWalkthroughTransitionToPreviousCompleted(true)
    })


    let [walkthroughTransitionToNextCompleted, SetWalkthroughTransitionToNextCompleted] = React.useState(false)
    React.useEffect(() => {
        if (walkthroughTransitionToNextCompleted)   {
            console.log(nextWalkthroughContent)
            SetCurrentWalkthroughStep(nextWalkthroughContent)
            SetNextWalkthroughStep(null)
            SetWalkthroughTransitionToNextCompleted(false)
            return
        }
    }, [walkthroughTransitionToNextCompleted, nextWalkthroughContent])

    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetWalkthroughTransitionToNextCompleted(true)
    })


    React.useEffect(() => {
        const stepKey = currentWalkthroughContent[0]
        switch (stepKey)  {
            case "power_on":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            case "get_Flow_code":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            case "connecting_to_Flow":
                if (currentStepIndex !== 2) {
                    SetCurrentStepIndex(2)
                }
                break
                
            case "name_Flow":
                if (currentStepIndex !== 3) {
                    SetCurrentStepIndex(3)
                }
                break
            case "manage_ports":
                if (currentStepIndex !== 4) {
                    SetCurrentStepIndex(4)
                }
                break
            case "manage_reservoirs":
                if (currentStepIndex !== 5) {
                    SetCurrentStepIndex(5)
                }
                break

            case "final_confirm":
                if (currentStepIndex !== 6) {
                    SetCurrentStepIndex(6)
                }
                break


            default:
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
        }
    }, [currentWalkthroughContent, currentStepIndex])


    let showsPopupHeader = true




    
    const transitionToPreviousStep = (stepKeys) =>  {
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetNextWalkthroughStep(stepKeys)
    }
    const loadWalkthroughStep = (stepKeys) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]

        switch (stepKey)    {
            case "power_on":
                return (<ConfirmPowerOnWalkthroughStep transitionToNextStep={transitionToNextStep} />) 

            case "get_Flow_code":
                const scanFlowCallback = ({currentFlowCode, codeWasScanned}) =>  {
                    SetFlowCode(currentFlowCode)
                    SetCodeScanned(codeWasScanned)
                }
                return (<ScanFlowWalkthroughStep transitionToNextStep={transitionToNextStep} scanFlowCallback={scanFlowCallback} FlowCode={FlowCode}/>)  

            case "connecting_to_Flow":
                const FlowConnectedCallback = ({FlowUID}) =>  {
                    SetFlowUID(FlowUID)
                }
                return (<ConnectingFlowWalkthroughStep
                            FlowCode={FlowCode}
                            selectedConnectionOption={selectedConnectionOption}
                            FlowConnectionTypeSelectionCallback={(type) => SetSelectedConnectionOption(type)}
                            currentStep={subStepKey} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep} 
                            FlowConnectedCallback={FlowConnectedCallback}/>)        
            
            case "name_Flow":
                const FlowNameSelectedCallback = ({FlowName}) =>  {
                    SetFlowName(FlowName)
                }
                let initialFlowName = FlowName !== null ? FlowName : "Flow " + 1
                
                return (<SelectFlowNameWalkthroughStep
                            initialFlowName={initialFlowName} 
                            facilityId={facilityId} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep} 
                            FlowNameSelectedCallback={FlowNameSelectedCallback}/>)
            
            case "manage_ports":
                const FlowPortsUpdatedCallback = ({ports}) => {
                    SetFlowPorts({...ports})
                }
                return (<ManagePortsWalkthroughStep
                    ports={FlowPorts} 
                    numberOfPorts={FlowNumberOfPorts}
                    facilityId={facilityId} 
                    transitionToPreviousStep={transitionToPreviousStep} 
                    transitionToNextStep={transitionToNextStep} 
                    FlowPortsUpdatedCallback={FlowPortsUpdatedCallback}/>)


            case "manage_reservoirs":
                const FlowReservoirsUpdatedCallback = ({reservoirs}) => {
                    SetFlowResevoirs({...reservoirs})
                }
                return (<ManageReservoirsWalkthroughStep
                    reservoirs={FlowReservoirs} 
                    numberOfReservoirs={FlowNumberOfReservoirs}
                    facilityId={facilityId} 
                    transitionToPreviousStep={transitionToPreviousStep} 
                    transitionToNextStep={transitionToNextStep} 
                    FlowReservoirsUpdatedCallback={FlowReservoirsUpdatedCallback}/>)                    
            
            case "final_confirm":
                const rackConfirmedCallback = () =>  {
                    //SetCurrentStep("confirm")
                }

                
                return (<ConfirmNewFlowWalkthroughStep
                            facilityId={facilityId}
                            FlowName={FlowName}
                            ports={FlowPorts}
                            reservoirs={FlowReservoirs}
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            rackConfirmedCallback={rackConfirmedCallback}/>)

            default:
                return <></>
        }
    }


    const walkthroughComponentProps = {}
    if (previousWalkthroughContent !== null)    {
        walkthroughComponentProps.transitionBackToContent = loadWalkthroughStep(previousWalkthroughContent)
    }
    if (nextWalkthroughContent !== null)    {
        walkthroughComponentProps.transitionToContent = loadWalkthroughStep(nextWalkthroughContent)
    }


    return (<>
        <Walkthrough
            id={"ManageRackWalkthrough_Popup"}
            title={<>
                {showsPopupHeader && <>
                    <span>Add Flow</span>
                    {/*<span>Step {currentStepIndex}/7</span>*/}
                </>}
            </>}
            subHeaderContent={<>
                <div className="FlexContent-HFill" style={{flex:1}}>
                    <ProgressBar numberOfSteps={numberOfSteps} currentStep={currentStepIndex} completedBarFill={"#ABC7ED"}/>
                </div>
            </>}
            hideMainSeparators={true}
            closeCallback={closePopup}
            size={"full_screen"}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}
            content={<>
                {(() => {
                    return loadWalkthroughStep(currentWalkthroughContent)
                })()}
            </>} {...walkthroughComponentProps}/>
        </>)

}



export default AddFlowWalkthrough