import './../ManageFlowWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { useDispatch, useSelector } from 'react-redux';


import { nanoid, customAlphabet  } from 'nanoid'
import LoadingBar from '../../../../components/LoadingBar';
import { selectAllFacilities } from '../../../../redux/entities/Facilities';
import { selectAllFlows } from '../../../../redux/entities/service/Flow';
import { ScanFlow } from '../../../../assets/icons/FlowIcons';

  

const SelectFlowNameWalkthroughStep = ({initialFlowName, facilityId, transitionToPreviousStep, transitionToNextStep, FlowNameSelectedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const facilities = useSelector(selectAllFacilities)
    const Flows = useSelector(selectAllFlows)




    const [selectedFlowName, SetSelectedFlowName] = React.useState(initialFlowName)
    const FlowNameInputChanged = (value) => {
        SetSelectedFlowName(value)
    }

    const nextClicked = () =>   {
        FlowNameSelectedCallback({FlowName: selectedFlowName})
        transitionToNextStep(["manage_ports", null])
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Name your Flow</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    {!isMobile && 
                        <div className="FlexContent-H-50">
                            <div className="FlexContent-HFill">
                                <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                    <div className="Button-Large-Icon"><ScanFlow width={isShortDisplay ? 70 : ((isTablet || isDesktop) ? 135 : 105)}/></div>
                                </>}/>
                            </div>
                            <div className="FlexContent-30 FlexContent-Center FlexContent-HFill">
                                <div className="Walkthrough-FormItem">
                                    <div className="Walkthrough-FormItem-Question">Enter Name</div>
                                    <div className="Walkthrough-FormItem-Answer">
                                        <TextInput value={selectedFlowName} placeHolder="Flow Name" onChange={FlowNameInputChanged}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {isMobile && <>
                        <div className="FlexContent-HFill">
                            <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                <div className="Button-Large-Icon"><ScanFlow width={isShortDisplay ? 70 : ((isTablet || isDesktop) ? 200 : 105)}/></div>
                            </>}/>
                        </div>
                        <div className="FlexContent-HFill">
                            <div className="FlexContent-30 FlexContent-Center FlexContent-HFill">
                                <div className="Walkthrough-FormItem">
                                    <div className="Walkthrough-FormItem-Question">Enter Name</div>
                                    <div className="Walkthrough-FormItem-Answer">
                                        <TextInput value={selectedFlowName} placeHolder="Flow Name" onChange={FlowNameInputChanged}/>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </>}
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Next Step"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex100"}/>
            </div>
        </div>
    )
    
}


export default SelectFlowNameWalkthroughStep