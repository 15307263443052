import './../TasksPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate } from '../../../helpers'
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import Button from '../../../components/Button';
import { getFlowById, getFlowProperty, setFlowProperty} from '../../../redux/entities/service/Flow';
import ControlBar from '../../../components/ControlBar';
import PopupModal from '../../../model_components/PopupModal';
import NumberInput from '../../../components/input/NumberInput';
  

const TaskPopup_FlowNutrientTopUp = ({task, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

 
  const closePopup = () =>  {
    onClosePopup()
  }

  const [currentTimelineStep, SetCurrentTimelineStep] = React.useState("select_reservoir")
  const timelineSteps = {
    select_reservoir: { render: (<span>Reservoirs</span>) },
    resevoir_removal_prep: { render: (<span>Prep</span>) },
    resevoir_top_up_prep: { render: (<span>Top Up</span>) },
  }


  let ndsList = useSelector((state) => state.Flow.Flow)
  const [Flow, SetFlow] = React.useState(null)

  const [selectedReservoirIndex, SetSelectedReservoirIndex] = React.useState(null)
  const [volumeToBeAdded, SetVolumeToBeAdded] = React.useState(0)
  
  
  React.useEffect(() => {
    let foundFlow = ndsList.find((n) => n.id === task.reference_id)
    if (foundFlow === undefined)  {
      dispatch(getFlowById({FlowId: task.reference_id}))
    }else {
      SetFlow({...foundFlow})
    }
  }, [task, ndsList])


  const requestLoopRef = React.useRef({lastRequestLoopCompletedOn: 0})
  const requestLoop = React.useCallback(() => {
    if (Flow)  {
      dispatch(getFlowProperty({FlowId: Flow.id, keys: ["nutrient_reservoirs"]}))
    }

    requestLoopRef.current.lastRequestLoopCompletedOn = new Date().getTime()
  })
  React.useEffect(() => {

    //Step #2: Set up recurring load runtime properties loop
    const requestInterval = setInterval(() => {
      if (requestLoopRef.current.lastRequestLoopCompletedOn + 200 < new Date().getTime()) {
          requestLoop()
      }
    }, 1);
    return () => {
      clearInterval(requestInterval)
    };
  }, [Flow])

  



  const switchToReservoirSelectionClicked = React.useCallback(() => {
    SetCurrentTimelineStep("select_reservoir")
  })

  const switchToReservoirRemovalClicked = React.useCallback(() => {
    SetCurrentTimelineStep("resevoir_removal_prep")
  })

  const switchToReservoirTopUpClicked = React.useCallback(() => {
    SetCurrentTimelineStep("resevoir_top_up")
  })

  

  const startTaskClicked = React.useCallback(() => {
    //dispatch(markTaskAsStarted({taskId: task.id}))
    //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
  })

  const completeTaskClicked = React.useCallback(() => {
    //dispatch(markTaskAsCompleted({taskId: task.id, params: {"number_of_plants": selectedNumberOfPlugs}}))
    onClosePopup()
  })


  



  let bodyContent = (<></>)
  let footerContent = (<></>)
  switch (currentTimelineStep)    {
      case "select_reservoir":
          const reservoirClicked = (nutrientReservoir) => {
            SetSelectedReservoirIndex(nutrientReservoir.index)
          }

          const beginTopUpClicked = () => {
            switchToReservoirTopUpClicked()
          }

          bodyContent = (<>
            {Flow && 
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  Select a nutrient reservoir to top up
                </div>
                <div className="PopupModal-FormItem-Answer">
                  
                  {Flow.nutrientReservoirs && 
                    <div className="FlowReservoirTopUp-Reservoirs">
                      {Object.entries(Flow.nutrientReservoirs).map(([nutrientReservoirIndex, nutrientReservoir]) => {
                        console.log(nutrientReservoir)
                        let remainingVolume = Math.round((nutrientReservoir.volume_remaining / 1000) * 10) / 10
                        return (
                          <div 
                            key={nutrientReservoir.index}
                            className={"FlowReservoirTopUp-Reservoir FlowReservoirTopUp-Reservoir-Selectable " + (selectedReservoirIndex && selectedReservoirIndex === nutrientReservoir.index ? "FlowReservoirTopUp-Reservoir-Selected" : "")}
                            onClick={(e) => {reservoirClicked(nutrientReservoir)}}>
                            <div className="FlowSensorCalibration-SensorBank-Header">
                              Reservoir {nutrientReservoir.index}
                            </div>
                            <div className="FlowSensorCalibration-SensorBank-Body">
                              <div>
                                Volume: {remainingVolume}L
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  }
                </div>
              </div>
            }
          </>)
          footerContent = (
              <ControlBar controls={(<>
                  <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
              </>)} secondaryControls={(<>
                  {selectedReservoirIndex && <Button status="Primary" content="Begin Top Up" onClick={beginTopUpClicked}/>}
              </>)}/>
          )
          break

      case "resevoir_top_up":
        let selectedReservoir = Flow.nutrientReservoirs[selectedReservoirIndex]
        let remainingVolume = Math.round((selectedReservoir.volume_remaining / 1000) * 10) / 10
        if (selectedReservoir.installed)  {
          const confirmForTopUpClicked = () =>  {
            dispatch(setFlowProperty({FlowId: Flow.id, values: {"nutrient_reservoir_installed_state": 0, "nutrient_reservoir_index": selectedReservoir.index}}))
          }
          bodyContent = (<>
            {Flow && 
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  The following solution is required to top up. Please confirm inventory. Upon selecting confirm, the Flow will temporarily disable dosing from this reservoir until top up is complete.
                </div>
                <div className="PopupModal-FormItem-Answer">
                  {Flow.nutrientReservoirs && 
                    <div className="FlowReservoirTopUp-Reservoir">
                      <div className="FlowSensorCalibration-SensorBank-Header">
                        Reservoir {selectedReservoir.index}
                      </div>
                      <div className="FlowSensorCalibration-SensorBank-Body">
                        <div>
                          Volume: {remainingVolume}L
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </>)
          footerContent = (
              <ControlBar controls={(<>
                <Button content={"Back: Reservoirs"} status={"Neutral"} onClick={switchToReservoirSelectionClicked}/>
              </>)} secondaryControls={(<>
                <Button status="Primary" content="Confirm" onClick={confirmForTopUpClicked}/>
              </>)}/>
          )
        }else {
          const confirmReinstalledClicked = () =>  {
            dispatch(setFlowProperty({FlowId: Flow.id, values: {"nutrient_reservoir_installed_state": 1, "nutrient_reservoir_index": selectedReservoir.index}}))
          }
          const volumeToBeAddedChanged = (v) => {

          }
          const volumeToBeAddedFinalize = (v) =>  {
            console.log(v)
            SetVolumeToBeAdded(v)
          }

          const confirmAddVolumeClicked = () => {
            let newVolume = selectedReservoir.volume_remaining + (volumeToBeAdded * 1000)
            dispatch(setFlowProperty({FlowId: Flow.id, values: {"nutrient_reservoir_new_volume": newVolume, "nutrient_reservoir_index": selectedReservoir.index}}))
            SetVolumeToBeAdded(0)
          }

          bodyContent = (<>
            {Flow.nutrientReservoirs && <>
              <div className="FlowReservoirTopUp-Reservoir">
                <div className="FlowSensorCalibration-SensorBank-Header">
                  Reservoir {selectedReservoir.index}
                </div>
                <div className="FlowSensorCalibration-SensorBank-Body">
                  <div>
                    Volume: {remainingVolume}L
                  </div>
                </div>
              </div>

              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                  Please provide how much has been added to the reservoir here.
                </div>
                <div className="PopupModal-FormItem-Answer">
                  <NumberInput 
                    value={volumeToBeAdded} 
                    suffix={"L"}
                    size={8}
                    onChange={volumeToBeAddedChanged}
                    onBlur={volumeToBeAddedFinalize}/>
                </div>
              </div>
            </>}
          </>)
          footerContent = (
            <ControlBar controls={(<>
                <Button content={"Back: Reservoirs"} status={"Neutral"} onClick={switchToReservoirSelectionClicked}/>
                <Button status="Neutral" content="Cancel and Confirm Reinstall" onClick={confirmReinstalledClicked}/>  
            </>)} secondaryControls={(<>
                {volumeToBeAdded > 0 && <Button status="Primary" content="Add Volume" onClick={confirmAddVolumeClicked}/>}
            </>)}/>
          )
        }
        break

      
      default: 
          break
  }


  return (
    <PopupModal 
      title={"Nutrient Top Up for Flow #" + (Flow ? Flow.id.toString() : "X")}
      closeCallback={closePopup}
      timelineSteps={timelineSteps} 
      timelineStep={currentTimelineStep} 
      footer={footerContent}>
      {bodyContent}
    </PopupModal>

  )
} 

export default TaskPopup_FlowNutrientTopUp