import './PlantSeedsForGerminationTask.scss';
import React from 'react';


import { useSelector, useDispatch} from 'react-redux'

import { getGrowById, growUpdate } from '../../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../../redux/entities/GrowPlans';
import { getRecipeById } from '../../../../redux/entities/Recipes'
import { useMediaQuery } from 'react-responsive';
import { getRaftTypeById, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes.js';
import Walkthrough from '../../../../components/Walkthrough.js';
import PlantSeedsForGermination_SeedingWalkthroughStep from './PlantSeedsForGermination_SeedingWalkthroughStep.js';
import PlantSeedsForGermination_LocationTrackingStep from './PlantSeedsForGermination_LocationTrackingStep.js';
import { getAllServicesForFacility, selectAllFacilities } from '../../../../redux/entities/Facilities.js';
import { getGerminationChamberByServiceId, selectAllGerminationChamber } from '../../../../redux/entities/service/GerminationChamber.js';
import PlantSeedsForGermination_SeedingListWalkthroughStep from './PlantSeedsForGermination_SeedingListWalkthroughStep.js';
import PlantSeedsForGermination_LocationTrackingOptionsStep from './PlantSeedsForGermination_LocationTrackingOptionsStep.js';
import { markTaskAsCompleted } from '../../../../redux/entities/Tasks.js';
import { selectAllGrowSeeds } from '../../../../redux/entities/PlantTypes.js';
import { InitialLoadAllGerminationStations } from '../../../../redux/entities/service/GerminationStation.js';
  

const PlantSeedsForGerminationTask = ({task, onCloseTask, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const closeTask = () =>  {
    onCloseTask()
  }
  const closePopup = () =>  {
    onClosePopup()
  }


  const allFacilities = useSelector(selectAllFacilities)




  let allGrows = useSelector((state) => state.grows.grows)
  const activeGrow = allGrows.find((g) => g.id === task.reference_id)
  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }
  }, [task, allGrows])


  let allGrowPlans = useSelector((state) => state.growPlans.growPlans)
  const activeGrowPlan =  activeGrow !== undefined ? allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id) : undefined
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let foundGrowPlan = allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id)
      if (foundGrowPlan === undefined)  {
        dispatch(getGrowPlanById({growPlanId: activeGrow.grow_plan_id}))
      }
    }
  }, [activeGrow, allGrowPlans])
  
  
  let allRecipes = useSelector((state) => state.recipes.recipes)
  const activeRecipe =  activeGrow !== undefined ? allRecipes.find((r) => r.id === activeGrow.recipe_id && r.version === activeGrow.recipe_version) : undefined
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let foundRecipe = allRecipes.find((r) => r.id === activeGrow.recipe_id && r.version === activeGrow.recipe_version)
      if (foundRecipe === undefined)  {
        dispatch(getRecipeById({recipes: [{id: activeGrow.recipe_id, version: activeGrow.recipe_version}]}))
      }
    }
  }, [activeGrow, allGrowPlans])
  



  const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))
  const allSeeds = useSelector((state) => selectAllGrowSeeds(state))
  /*const activeSeedlingRaftType =  activeGrowPlan !== undefined ? allRaftTypes.find((rT) => rT.id === activeGrowPlan.seedling_raft_type_id) : undefined
  React.useEffect(() => {
    if (activeGrowPlan !== undefined) {
      let foundRaftType = allRaftTypes.find((rT) => rT.id === activeGrowPlan.seedling_raft_type_id)
      if (foundRaftType === undefined)  {
        dispatch(getRaftTypeById({raftTypeId: activeGrowPlan.seedling_raft_type_id}))
      }
    }
  }, [activeGrowPlan, allRaftTypes])*/


  const [seederChoices, SetSeederChoices] = React.useState(null)
  const [variantLocationsTracked, SetVariantLocationsTracked] = React.useState(null)
  const [activelySeedingPlantVariantId, SetActivelySeedingPlantVariantId] = React.useState(null)
  const [activelySeedingPlantVariantIndex, SetActivelySeedingPlantVariantIndex] = React.useState(0)
  const [performingSeeding, SetPerformingSeeding] = React.useState(false)
  const [seedingCompleted, SetSeedingCompleted] = React.useState(false)
  React.useEffect(() => {
    if (activeGrow !== undefined) {
       let foundGrowPlan = allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id)
       if (foundGrowPlan !== undefined) {
          let choices = {}
          for (let variant of foundGrowPlan.plant_variants) {
              choices[variant.id] = "use_seeder"
          }
          SetSeederChoices(choices)
       }
    }
  }, [activeGrow, allGrowPlans])
  const seederChoicesConfirmed = ({seederChoices}) => {
    SetSeederChoices(seederChoices)
    SetActivelySeedingPlantVariantId(parseInt(Object.keys(seederChoices)[0]))
    SetActivelySeedingPlantVariantIndex(0)
    SetPerformingSeeding(true)
  }

  const trackingOptionConfirmed = ({variantId, trackingOption}) => {
    console.log(variantId, trackingOption)
    SetVariantLocationsTracked({...variantLocationsTracked, [variantId]: trackingOption})
  }
  const setVariantIndex = (index) => {
    SetActivelySeedingPlantVariantIndex(index)
    SetActivelySeedingPlantVariantId(parseInt(Object.keys(seederChoices)[index]))
  }

  const [trackingLocations, SetTrackingLocations] = React.useState({})
  const locationsConfirmed = ({variantId, locations}) => {
    SetTrackingLocations(locations)
  }

  const [performingPlantSeedsCompleted, SetPerformingPlantSeedsCompleted] = React.useState(false)
  const [pendingPostPlantSeedsCompleted, SetPendingPostPlantSeedsCompleted] = React.useState(false)
  const finishedSeeding = () => {
    SetPerformingPlantSeedsCompleted(true)
    dispatch(markTaskAsCompleted({taskId: task.id, params: {
        number_of_units: activeGrow.number_of_units,
        locations: trackingLocations
    }, callback: (success) => {
        if (success)    {
          SetPendingPostPlantSeedsCompleted(true)
        }
        SetPerformingPlantSeedsCompleted(false)
    }}))
  }
  

  React.useEffect(() => {
    if (pendingPostPlantSeedsCompleted) {
      dispatch(growUpdate({growId: activeGrow.id, properties: {
        stage: "in_germination",
        pending_start: false,
        started_on: new Date().toISOString()
      }}))
      closeTask()
    }
  }, [pendingPostPlantSeedsCompleted])


  /* Walkthrough requirements */
  let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["seeding", null])
  let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
  let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
  const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })
  const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })

  const transitionToPreviousStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetPreviousWalkthroughStep(stepKeys)
  }
  const transitionToNextStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetNextWalkthroughStep(stepKeys)
  }




  const loadWalkthroughStep = (stepKeys) =>    {
      const stepKey = stepKeys[0]
      const subStepKey = stepKeys[1]
      switch (stepKey)    {
        case "seeding_list":
          return (<PlantSeedsForGermination_SeedingListWalkthroughStep
            task={task}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            initialSeederChoices={seederChoices}
            seedingBegun={performingSeeding}
            seedingCompleted={seedingCompleted}
            variantLocationsTracked={variantLocationsTracked}
            seedingPlantVariantIndex={activelySeedingPlantVariantIndex}
            seederChoicesConfirmedCallback={seederChoicesConfirmed}
            finishedSeedingCallback={finishedSeeding}
            performingPlantSeedsCompleted={performingPlantSeedsCompleted}
            cancelTaskCallback={onCloseTask}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 

        case "seeding":
          return (<PlantSeedsForGermination_SeedingWalkthroughStep
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            activeRecipe={activeRecipe}
            allRaftTypes={allRaftTypes}
            allSeeds={allSeeds}
            seederChoice={seederChoices[activelySeedingPlantVariantId]}
            seedingPlantVariantId={activelySeedingPlantVariantId}
            seedingPlantVariantIndex={activelySeedingPlantVariantIndex}
            plantVariant={activeGrowPlan.plant_variants.find((pV) => pV.id === activelySeedingPlantVariantId)}
            cancelTaskCallback={onCloseTask}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 
        
                
        case "location_tracking_options":
          return (<PlantSeedsForGermination_LocationTrackingOptionsStep
            activeGrowPlan={activeGrowPlan}
            seedingPlantVariantId={activelySeedingPlantVariantId}
            seedingPlantVariantIndex={activelySeedingPlantVariantIndex}
            trackingOptionConfirmedCallback={trackingOptionConfirmed}
            setVariantIndexCallback={setVariantIndex}
            seedingCompletedCallback={() => {SetSeedingCompleted(true)}}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 
                
        case "location_tracking":
          return (<PlantSeedsForGermination_LocationTrackingStep
            task={task}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            allSeeds={allSeeds}
            seedingPlantVariantId={activelySeedingPlantVariantId}
            seedingPlantVariantIndex={activelySeedingPlantVariantIndex}
            plantVariant={activeGrowPlan.plant_variants.find((pV) => pV.id === activelySeedingPlantVariantId)}
            initialLocations={trackingLocations}
            setVariantIndexCallback={setVariantIndex}
            seedingCompletedCallback={() => {SetSeedingCompleted(true)}}
            locationsConfirmedCallback={locationsConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 

        default:
            return <></>
      }
  }


  /*let initialWalkthroughStep = null
  if (activeGrowPlan !== undefined) {
    if (activeGrowPlan.plant_variants.length > 1) {
      initialWalkthroughStep = "seeding_list"
    }else {
      initialWalkthroughStep = "seeding"
    }
    console.log(activeGrowPlan.plant_variants)
  }*/


  return (<>
    <InitialLoadAllGerminationStations/>
    <Walkthrough 
      title="Plant Seeds"
      subtitle={<>#G{activeGrow !== undefined ? activeGrow.id : ""}</>}
      size="full_screen"
      closeCallback={closePopup}
      hideMainSeparators={false}
      initialWalkthroughStep={["seeding_list", null]}
      transitionToWalkthroughStep={nextWalkthroughStep}
      transitionBackToWalkthroughStep={previousWalkthroughStep}
      buildWalkthroughStepCallback={loadWalkthroughStep}
      transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
      transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}

    />
  </>)
} 

export default PlantSeedsForGerminationTask