import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'underscore';
import { binaryClosestIdx } from '../../../helpers'
import { Repository as APIRepository, FetchPost } from '../../api'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { getAccountSessionData } from '../../../pages/Account/Common'
import { selectAllDataRecordingTimePeriodTypes } from '../../AppInfo'
import { selectAllFarmFacilities } from '../Facilities';


const processGerminationStationFromAPI = (state, germinationStation) => {
  //germinationStationGroup.lastStatusUpdatedOn = new Date().getTime();

  germinationStation.loadedFromDatabase = true;

  germinationStation.lastStatusUpdatedOn = new Date().getTime();
  germinationStation.lastLiveDataUpdatedOn = 0;

  if (germinationStation.liveData === undefined) {
    germinationStation.liveData = {}
  }

  if (germinationStation.analyticsData === undefined) {
    germinationStation.analyticsData = {}
  }

  if (germinationStation.initializedDataRecordingTimePeriodTypes === undefined) {
    germinationStation.initializedDataRecordingTimePeriodTypes = false
  }

  if (germinationStation.unique_configuration === undefined) {
    germinationStation.unique_configuration = {properties:{}}
  }

  if (germinationStation.control_device === undefined) {
    germinationStation.control_device = null
  }



  if (germinationStation.loading_status_data === undefined) {
    germinationStation.loading_status_data = "idle"
  }
  if (germinationStation.loading_live_data === undefined) {
    germinationStation.loading_live_data = "idle"
  }
  if (germinationStation.have_live_data_version === undefined) {
    germinationStation.have_live_data_version = -1
  }
  if (germinationStation.loading_analytics_data === undefined) {
    germinationStation.loading_analytics_data = "idle"
  }
  if (germinationStation.runtime_information === undefined) {
    germinationStation.runtime_information = {}
  }
  if (germinationStation.runtime_information_requested === undefined) {
    germinationStation.runtime_information_requested = {}
  }

  if (germinationStation.issues === undefined) {
    germinationStation.issues = []
  }

  if (germinationStation.active_issues === undefined) {
    germinationStation.active_issues = []
  }


  return germinationStation
}




const uniqueConfigurationMapKeys = {
  
}
export const getUniqueConfigurationByName = (germinationStation, name) => {
  if (germinationStation.unique_configuration === undefined) {
    return undefined;
  }
  if (uniqueConfigurationMapKeys[name] === undefined) {
    return undefined;
  }
  return germinationStation.unique_configuration.properties[uniqueConfigurationMapKeys[name]];
}



export const getAllGerminationStations = createAsyncThunk('germ/getAllGerminationStations', async ({ callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  return await FetchPost(APIRepository.GerminationStation.GetAllGerminationStations, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.loadingAllGerminationStationsStatus === 'pending') {
        return false
      }
    },
})


  


export const getGerminationStationById = createAsyncThunk('germ/getGerminationStationById', async ({ germinationStationId, germinationStationIds }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (germinationStationId !== undefined) {
    payload.germination_station_id = germinationStationId
  }
  if (germinationStationIds !== undefined) {
    payload.germination_station_ids = germinationStationIds
  }
  return await FetchPost(APIRepository.GerminationStation.GetGerminationStationById, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.loadingGerminationStationStatus === 'pending') {
        return false
      }
    },
  })


export const getGerminationStationByServiceId = createAsyncThunk('germ/getGerminationStationByServiceId', async ({ germinationStationServiceId, germinationStationServiceIds, germinationStationServiceUIDs }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (germinationStationServiceId !== undefined) {
    payload.service_id = germinationStationServiceId
  }
  if (germinationStationServiceIds !== undefined) {
    payload.service_ids = germinationStationServiceIds
  }
  return await FetchPost(APIRepository.GerminationStation.GetGerminationStationByServiceId, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.loadingGerminationStationStatus === 'pending') {
        return false
      }
    },
  })


export const getGerminationStationBySerialNumber = createAsyncThunk('germ/getGerminationStationBySerialNumber', async ({ germinationStationSerialNumber, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    germination_station_serial_number: germinationStationSerialNumber
  }
  return await FetchPost(APIRepository.GerminationStation.GetGerminationStationBySerialNumber, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.loadingGerminationStationStatus === 'pending') {
        return false
      }
    },
  })


export const getGerminationStationByUID = createAsyncThunk('germ/getGerminationStationByUID', async ({ germinationStationUID, germinationStationUIDs, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (germinationStationUID !== undefined) {
    payload.germination_station_uid = germinationStationUID
  }
  if (germinationStationUIDs !== undefined) {
    payload.germination_station_uids = germinationStationUIDs
  }
  return await FetchPost(APIRepository.GerminationStation.GetGerminationStationByUID, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.loadingGerminationStationStatus === 'pending') {
        return false
      }
    },
  })



export const getGerminationStationStatusById = createAsyncThunk('germ/getGerminationStationStatusById', async ({ germinationStationId, germinationStationIds }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (germinationStationId !== undefined) {
    payload.germination_station_id = germinationStationId
  }
  if (germinationStationIds !== undefined) {
    payload.germination_station_ids = germinationStationIds
  }
  return await FetchPost(APIRepository.GerminationStation.GetGerminationStationStatusById, payload)
})


export const setGerminationStationRuntimeProperty = createAsyncThunk('germ/setGerminationStationRuntimeProperty', async ({ germinationStationId, properties, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    germination_station_id: germinationStationId,
    properties: properties
  }
  return await FetchPost(APIRepository.GerminationStation.SetGerminationStationRuntimeProperty, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.settingGerminationStationRuntimeProperty === 'pending') {
        return false
      }
    },
  })



export const getGerminationStationLiveDataById = createAsyncThunk('germ/getGerminationStationLiveDataById', async ({ germinationStationId, germinationStationIds, germinationStationIdsWithVersions }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (germinationStationId !== undefined) {
    payload.germination_station_id = germinationStationId
  }
  if (germinationStationIds !== undefined) {
    payload.germination_station_ids = germinationStationIds
  }
  if (germinationStationIdsWithVersions !== undefined) {
    payload.germination_station_ids_with_versions = germinationStationIdsWithVersions
  }


  return await FetchPost(APIRepository.GerminationStation.GetGerminationStationLiveDataById, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.loadingRackLiveData === 'pending') {
        return false
      }
    },
  })


export const getGerminationStationAnalyticsData = createAsyncThunk('germ/getGerminationStationAnalyticsData', async ({ germinationStationIds, entries }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    entries: entries //map[int]map[string]map[int][]int -- time period type id : map of start/end date string to map of rack ids to list of component ids
  }
  return await FetchPost(APIRepository.GerminationStation.GetGerminationStationAnalyticsData, payload)
})



export const getGerminationStationConfigurationMap = createAsyncThunk('germ/getGerminationStationConfigurationMap', async ({ maps }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    maps: maps //Must be []configurationId:{mapKey:*version
  }
  return await FetchPost(APIRepository.GerminationStation.GetGerminationStationConfigurationMap, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.loadingConfigurationMaps === 'pending') {
        return false
      }
    },
  })


export const getGerminationStationUniqueConfigurationMap = createAsyncThunk('germ/getGerminationStationUniqueConfigurationMap', async ({ germinationStationIds }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    germination_station_ids: germinationStationIds
  }
  return await FetchPost(APIRepository.GerminationStation.GetGerminationStationUniqueConfigurationMap, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.loading_unique_configuration_map === 'pending') {
        return false
      }
    },
  })




export const validateGerminationStationSystemConnection = createAsyncThunk('germ/validateVerticalRackSystemConnection', async ({ germinationStationUID, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    germination_station_uid: germinationStationUID,
  }

  return await FetchPost(APIRepository.GerminationStation.ValidateGerminationStationSystemConnection, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.validatingGerminationStationConnectedToSystem === 'pending') {
        return false
      }
    },
  })


export const createNewGerminationStation = createAsyncThunk('germ/createNewGerminationStation', async ({ facilityId, germinationStationInfo, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    facility_id: facilityId,
    germination_station_info: germinationStationInfo,
  }

  return await FetchPost(APIRepository.GerminationStation.CreateNewGerminationStation, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.creatingNewGerminationStation === 'pending') {
        return false
      }
    },
  })




export const linkGerminationStationToControlDevice = createAsyncThunk('germ/linkGerminationStationToControlDevice', async ({ germinationStationUID, controlDeviceSerialNumber, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    germination_station_uid: germinationStationUID,
    control_device_serial_number: controlDeviceSerialNumber,
  }

  return await FetchPost(APIRepository.GerminationStation.LinkGerminationStationToControlDevice, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.linkingGerminationStationToControlDevice === 'pending') {
        return false
      }
    },
  })

export const addGerminationStationToFarm = createAsyncThunk('germ/addGerminationStationToFarm', async ({ facilityId, germinationStationUID, germinationStationName, germinationStationIndex, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    facility_id: facilityId,
    germination_station_uid: germinationStationUID,
    germination_station_name: germinationStationName,
    germination_station_index: germinationStationIndex,
  }

  console.log(payload)

  return await FetchPost(APIRepository.GerminationStation.AddGerminationStationToFarm, payload)
},
  {
    condition: (args, { getState }) => {
      const { germinationStation } = getState()
      if (germinationStation.addingGerminationStationToFarm === 'pending') {
        return false
      }
    },
  })









export const germinationStationSlice = createSlice({
  name: 'germinationStation',
  initialState: {
    germinationStation: [],
    germinationStationUIDsToIds: {},
    loadedGerminationStationServiceIds: [],
    loadedGerminationStationUIDs: [],



    loadedAllGerminationStations: false,
    loadingAllGerminationStationsStatus: "idle",


    tempVerticalRackGroupId: 1,
    tempVerticalRackId: 1,
    configurationMaps: [],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false,
    loadingGerminationStationStatus: 'idle',
    loadingGroupsStatus: 'idle',
    loadingGerminationStationStatusInfo: 'idle',
    settingGerminationStationRuntimeProperty: 'idle',
    settingGerminationStationZoneRuntimeProperty: 'idle',
    loadingRackLiveData: 'idle',
    loadingGerminationStationAnalyticsData: 'idle',
    loadingConfigurationMaps: 'idle',
    loadingZoneConfigurationMapsStatus: 'idle',

    creatingNewGerminationStation: 'idle',
    creatingNewGerminationStationGroup: 'idle',
    movingGerminationStationToGroup: 'idle',
    linkingGerminationStationToControlDevice: 'idle',
    addingGerminationStationToFarm: 'idle',
    validatingGerminationStationConnectedToSystem: 'idle',
  },
  reducers: {
    initializeComponentForAnalyticsData: (state, action) => { //Action includes rack group id, zone id, rack id, component id
      let updates = {
        ...state,

        germinationStation: state.germinationStation.map((germinationStation, index) => {
          if (germinationStation.id !== action.payload.germinationStationId) {
            return germinationStation
          }



          let dataTimePeriods = { ...germinationStation.analyticsData, [action.payload.rackComponentId]: {} }
          for (let dataRecordingTimePeriodType of action.payload.dataRecordingTimePeriodTypes) {
            dataTimePeriods[action.payload.rackComponentId][dataRecordingTimePeriodType.id] = {
              loadingStatus: "idle",
              data: {},
              dataChunks: {},
              haveNewData: {},
              haveDataUpUntil: 0,
              changedVersion: 0,

              energyData: {},
              energyDataChunks: {},
              haveNewEnergyData: {},
              haveEnergyDataUpUntil: 0,
              changedEnergyVersion: 0


            }
          }

          return {
            ...germinationStation,
            analyticsData: dataTimePeriods,
            initializedDataRecordingTimePeriodTypes: true
          }

        }),

      }

      return updates

    }

  },
  extraReducers: {

    
    [getAllGerminationStations.pending]: (state) => {
      state.loadingAllGerminationStationsStatus = 'pending';
    },

    [getAllGerminationStations.fulfilled]: (state, action) => {
      state.loadingAllGerminationStationsStatus = 'fulfilled';
      if (action.payload.error !== undefined && action.payload.error !== null)  {
        //Failed in some way
      }else {
        state.loadedAllGerminationStations = true
        if (action.payload.germination_stations !== null) {
          for (let germinationStation of action.payload.germination_stations) {
            state.germinationStationUIDsToIds[germinationStation.uid] = germinationStation.id

            if (germinationStation) {
              let exists = false
              for (let germinationStationIndex in state.germinationStation) {
                if (state.germinationStation[germinationStationIndex].id === germinationStation.id) {
                  //Get the UID

                  state.germinationStation[germinationStationIndex] = processGerminationStationFromAPI(state, { ...state.germinationStation[germinationStationIndex], ...germinationStation })
                  exists = true
                  break
                }
              }
              if (!exists) {
                state.germinationStation.push(processGerminationStationFromAPI(state, germinationStation))
                state.loadedGerminationStationServiceIds.push(germinationStation.service_id)
                state.loadedGerminationStationUIDs.push(germinationStation.uid)
              }
            }
          }
        }
      }
    },

    [getAllGerminationStations.rejected]: (state) => {
      state.loadingAllGerminationStationsStatus = 'rejected';
    },



    [getGerminationStationById.pending]: (state) => {
      state.loadingGerminationStationStatus = 'pending';
    },

    [getGerminationStationById.fulfilled]: (state, action) => {
      state.loadingGerminationStationStatus = 'fulfilled';
      if (action.payload.germination_stations !== null) {
        for (let germinationStation of action.payload.germination_stations) {
          if (germinationStation) {
            let exists = false
            for (let germinationStationIndex in state.germinationStation) {
              if (state.germinationStation[germinationStationIndex].id === germinationStation.id) {
                //Get the UID

                state.germinationStation[germinationStationIndex] = processGerminationStationFromAPI(state, { ...state.germinationStation[germinationStationIndex], ...germinationStation })
                exists = true
                break
              }
            }
            if (!exists) {
              state.germinationStation.push(processGerminationStationFromAPI(state, germinationStation))
              state.loadedGerminationStationServiceIds.push(germinationStation.service_id)
              state.loadedGerminationStationUIDs.push(germinationStation.uid)
            }
          }
        }
      }
    },

    [getGerminationStationById.rejected]: (state) => {
      state.loadingGerminationStationStatus = 'rejected';
    },


    [getGerminationStationByServiceId.pending]: (state) => {
      state.loadingGerminationStationStatus = 'pending';
    },

    [getGerminationStationByServiceId.fulfilled]: (state, action) => {
      state.loadingGerminationStationStatus = 'fulfilled';
      if (action.payload.germination_stations !== null) {
        for (let germinationStation of action.payload.germination_stations) {
          if (germinationStation) {
            let exists = false
            for (let germinationStationIndex in state.germinationStation) {
              if (state.germinationStation[germinationStationIndex].id === germinationStation.id) {
                //Get the UID

                state.germinationStation[germinationStationIndex] = processGerminationStationFromAPI(state, { ...state.germinationStation[germinationStationIndex], ...germinationStation })
                exists = true
                break
              }
            }
            if (!exists) {
              state.germinationStation.push(processGerminationStationFromAPI(state, germinationStation))
              state.loadedGerminationStationServiceIds.push(germinationStation.service_id)
              state.loadedGerminationStationUIDs.push(germinationStation.uid)
            }
          }
        }
      }
    },

    [getGerminationStationByServiceId.rejected]: (state) => {
      state.loadingGerminationStationStatus = 'rejected';
    },




    [getGerminationStationBySerialNumber.pending]: (state) => {
      state.loadingGerminationStationStatus = 'pending';
    },

    [getGerminationStationBySerialNumber.fulfilled]: (state, action) => {
      state.loadingGerminationStationStatus = 'fulfilled';
      if (action.payload.germinationStation !== null) {
        let exists = false
        let updatedGerminationStation
        for (let germinationStationIndex in state.germinationStation) {
          if (state.germinationStation[germinationStationIndex].id === action.payload.germinationStation.id) {
            //Get the UID
            updatedGerminationStation = processGerminationStationFromAPI(state, { ...state.germinationStation[germinationStationIndex], ...action.payload.germinationStation })
            state.germinationStation[germinationStationIndex] = updatedGerminationStation
            exists = true
            break
          }
        }
        if (!exists) {
          updatedGerminationStation = processGerminationStationFromAPI(state, action.payload.germinationStation)
          state.germinationStation.push(updatedGerminationStation)
          state.loadedGerminationStationServiceIds.push(updatedGerminationStation.service_id)
          state.loadedGerminationStationUIDs.push(updatedGerminationStation.uid)
        }

        if (action.meta.arg.callback) {
          action.meta.arg.callback(true, updatedGerminationStation)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },

    [getGerminationStationBySerialNumber.rejected]: (state, action) => {
      state.loadingGerminationStationStatus = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },

    [getGerminationStationByUID.pending]: (state) => {
      state.loadingGerminationStationStatus = 'pending';
    },

    [getGerminationStationByUID.fulfilled]: (state, action) => {
      state.loadingGerminationStationStatus = 'fulfilled';
      if (action.payload.germination_stations !== null) {
        for (let germinationStation of action.payload.germination_stations) {
          if (germinationStation) {
            let exists = false
            for (let germinationStationIndex in state.germinationStation) {
              if (state.germinationStation[germinationStationIndex].id === germinationStation.id) {
                //Get the UID

                state.germinationStation[germinationStationIndex] = processGerminationStationFromAPI(state, { ...state.germinationStation[germinationStationIndex], ...germinationStation })
                exists = true
                break
              }
            }
            if (!exists) {
              state.germinationStation.push(processGerminationStationFromAPI(state, germinationStation))
              state.loadedGerminationStationServiceIds.push(germinationStation.service_id)
              state.loadedGerminationStationUIDs.push(germinationStation.uid)
            }
          }
        }

        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },

    [getGerminationStationByUID.rejected]: (state, action) => {
      state.loadingGerminationStationStatus = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },


    [getGerminationStationStatusById.pending]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (action.meta.arg.germinationStationId !== undefined && germinationStation.id === action.meta.arg.germinationStationId) {
          return { ...germinationStation, loading_status_data: "pending" }
        }
        if (action.meta.arg.germinationStationIds !== undefined) {
          for (let germinationStationId of action.meta.arg.germinationStationIds) {
            if (germinationStation.id === germinationStationId) {
              return { ...germinationStation, loading_status_data: "pending" }
            }
          }
        }
        return germinationStation
      })
    },

    [getGerminationStationStatusById.fulfilled]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (action.meta.arg.germinationStationId !== undefined && germinationStation.id === action.meta.arg.germinationStationId) {
          return { ...germinationStation, loading_status_data: "fulfilled", last_status_data_loaded_on: new Date().getTime() }
        }
        if (action.meta.arg.germinationStationIds !== undefined) {
          for (let germinationStationId of action.meta.arg.germinationStationIds) {
            if (germinationStation.id === germinationStationId) {
              return { ...germinationStation, loading_status_data: "fulfilled", last_status_data_loaded_on: new Date().getTime() }
            }
          }
        }
        return germinationStation
      })

      if (action.payload.germination_station_status_data !== null) {

        for (let [germinationStationIdAsString, germinationStationStatusPayload] of Object.entries(action.payload.germination_station_status_data)) {
          const germinationStationId = parseInt(germinationStationIdAsString)
          state.germinationStation = state.germinationStation.map((germinationStation) => {
            if (germinationStation.id !== germinationStationId)
              return germinationStation
            return { ...germinationStation, ...germinationStationStatusPayload }
          })
        }
      }
    },

    [getGerminationStationStatusById.rejected]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (action.meta.arg.germinationStationId !== undefined && germinationStation.id === action.meta.arg.germinationStationId) {
          return { ...germinationStation, loading_status_data: "rejected" }
        }
        if (action.meta.arg.germinationStationIds !== undefined) {
          for (let germinationStationId of action.meta.arg.germinationStationIds) {
            if (germinationStation.id === germinationStationId) {
              return { ...germinationStation, loading_status_data: "rejected" }
            }
          }
        }
        return germinationStation
      })
    },


    [setGerminationStationRuntimeProperty.pending]: (state) => {
      state.settingGerminationStationRuntimeProperty = 'pending';
    },

    [setGerminationStationRuntimeProperty.fulfilled]: (state, action) => {
      state.settingGerminationStationRuntimeProperty = 'fulfilled';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(true)
      }
    },

    [setGerminationStationRuntimeProperty.rejected]: (state, action) => {
      state.settingGerminationStationRuntimeProperty = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },


    [getGerminationStationLiveDataById.pending]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (action.meta.arg.germinationStationId !== undefined && germinationStation.id === action.meta.arg.germinationStationId) {
          return { ...germinationStation, loading_live_data: "pending" }
        }
        if (action.meta.arg.germinationStationIds !== undefined) {
          for (let germinationStationId of action.meta.arg.germinationStationIds) {
            if (germinationStation.id === germinationStationId) {
              return { ...germinationStation, loading_live_data: "pending" }
            }
          }
        }
        if (action.meta.arg.germinationStationIdsWithVersions !== undefined) {
          for (let germinationStationId of Object.keys(action.meta.arg.germinationStationIdsWithVersions)) {
            if (germinationStation.id === parseInt(germinationStationId)) {
              return { ...germinationStation, loading_live_data: "pending" }
            }
          }
        }
        return germinationStation
      })
    },

    [getGerminationStationLiveDataById.fulfilled]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (action.meta.arg.germinationStationId !== undefined && germinationStation.id === action.meta.arg.germinationStationId) {
          return { ...germinationStation, loading_live_data: "fulfilled", last_live_data_loaded_on: new Date().getTime() }
        }
        if (action.meta.arg.germinationStationIds !== undefined) {
          for (let germinationStationId of action.meta.arg.germinationStationIds) {
            if (germinationStation.id === germinationStationId) {
              return { ...germinationStation, loading_live_data: "fulfilled", last_live_data_loaded_on: new Date().getTime() }
            }
          }
        }
        if (action.meta.arg.germinationStationIdsWithVersions !== undefined) {
          for (let germinationStationId of Object.keys(action.meta.arg.germinationStationIdsWithVersions)) {
            if (germinationStation.id === parseInt(germinationStationId)) {
              return { ...germinationStation, loading_live_data: "fulfilled", last_live_data_loaded_on: new Date().getTime() }
            }
          }
        }
        return germinationStation
      })

      if (action.payload.live_data !== null) {
        for (let [germinationStationId, liveData] of Object.entries(action.payload.live_data)) {
          for (let germinationStation of state.germinationStation) {
            if (germinationStation.id === parseInt(germinationStationId)) {
              
              for (const [componentIdAsString, componentData] of Object.entries(liveData))  {
                const componentId = parseInt(componentIdAsString)
                if (germinationStation.liveData[componentId] === undefined)  {
                  germinationStation.liveData[componentId] = {}
                }
                germinationStation.liveData[componentId] = {...germinationStation.liveData[componentId], ...componentData}
              }
              //germinationStation.liveData = {...germinationStation.liveData, ...liveData}
              
              if (action.payload.live_data_versions !== undefined && action.payload.live_data_versions !== null && action.payload.live_data_versions[germinationStation.id] !== undefined) {
                germinationStation.have_live_data_version = action.payload.live_data_versions[germinationStation.id]
              }
              break
            }
          }
        }
      }
    },

    [getGerminationStationLiveDataById.rejected]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (action.meta.arg.germinationStationId !== undefined && germinationStation.id === action.meta.arg.germinationStationId) {
          return { ...germinationStation, loading_live_data: "rejected" }
        }
        if (action.meta.arg.germinationStationIds !== undefined) {
          for (let germinationStationId of action.meta.arg.germinationStationIds) {
            if (germinationStation.id === germinationStationId) {
              return { ...germinationStation, loading_live_data: "rejected" }
            }
          }
        }
        if (action.meta.arg.germinationStationIdsWithVersions !== undefined) {
          for (let germinationStationId of Object.keys(action.meta.arg.germinationStationIdsWithVersions)) {
            if (germinationStation.id === parseInt(germinationStationId)) {
              return { ...germinationStation, loading_live_data: "rejected" }
            }
          }
        }
        return germinationStation
      })
    },



    [getGerminationStationAnalyticsData.pending]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (!action.meta.arg.germinationStationIds.includes(germinationStation.id))  {
          return germinationStation
        }
        return { ...germinationStation, loading_analytics_data: "pending" } 
      })
    },

    [getGerminationStationAnalyticsData.fulfilled]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (!action.meta.arg.germinationStationIds.includes(germinationStation.id))  {
          return germinationStation
        }
        return { ...germinationStation, loading_analytics_data: "fulfilled", last_analytics_data_loaded_on: new Date().getTime() }
      })
      if (action.payload.analytics_data !== null) {
        //console.log(action.payload.analytics_data)
        for (let componentDataChunk of action.payload.analytics_data) {
          state.germinationStation = state.germinationStation.map((germinationStation) => {
            if (germinationStation.id !== componentDataChunk.rack_id) {
              return germinationStation
            }

            if (componentDataChunk["component_id"] in germinationStation.analyticsData) {
              let componentDataTimePeriodsTypes = germinationStation.analyticsData[componentDataChunk["component_id"]]
              const currentDataRecordingTimePeriodType = action.meta.arg.timePeriodTypes.find((dataRecordingTimePeriodType) => { return dataRecordingTimePeriodType.id === componentDataChunk["time_period_type_id"]; })
              if (currentDataRecordingTimePeriodType !== undefined && componentDataChunk["time_period_type_id"].toString() in componentDataTimePeriodsTypes) {
                let componentData = componentDataTimePeriodsTypes[componentDataChunk["time_period_type_id"].toString()]
                for (let dataChunk of componentDataChunk["data"]) {
                  const chunkStartedOn = parseInt(dataChunk["entry_index"]) * currentDataRecordingTimePeriodType.duration * 1000
                  var newData = false;

                  let currentDataChunk = componentData.dataChunks[parseInt(dataChunk["entry_index"])]
                  if (currentDataChunk === undefined) {
                    currentDataChunk = componentData.dataChunks[parseInt(dataChunk["entry_index"])] = dataChunk
                  }

                  //console.log(currentDataChunk)



                  if (dataChunk.completed) {


                    const dataItemList = dataChunk.values.split('\n')

                    const processedData = {}

                    //console.log(dataChunk.values)
                    for (let dataItemString of dataItemList) {
                      const dataItemInfo = dataItemString.split(',')
                      const identifier = dataItemInfo[0]
                      let forTime
                      if (currentDataRecordingTimePeriodType["max_number_of_points"] == 0) {
                        forTime = chunkStartedOn + parseInt(dataItemInfo[1])
                      } else {
                        forTime = chunkStartedOn + parseInt(dataItemInfo[1] * 1000)
                      }

                      const value = parseFloat(dataItemInfo[2])
                      const flag = dataItemInfo[3]

                      if (processedData[identifier] === undefined) {
                        processedData[identifier] = []
                      }
                      processedData[identifier].push({ x: forTime - action.meta.arg.dateOffset, y: value })

                      componentData.haveNewData[identifier] = true
                      newData = true;

                    }

                    for (let identifier in processedData) {
                      if (componentData.data[identifier] === undefined) {
                        componentData.data[identifier] = []
                      }
                      if (identifier === "lightr" || identifier === "lightg" || identifier === "lightb" || identifier === "lightfr") {
                        processedData[identifier].sort(function (a, b) {
                          if (a.x > b.x)
                            return 1;
                          if (a.x < b.x)
                            return -1;
                          return 0

                        });

                        let lastValue = null
                        for (let dataItem of processedData[identifier]) {
                          if (!lastValue && componentData.data[identifier].length > 0) {
                            let lastDataItem = binaryClosestIdx(componentData.data[identifier], dataItem.x - 1, 'x')
                            if (lastDataItem && componentData.data[identifier][lastDataItem] !== undefined) {
                              if (componentData.data[identifier][lastDataItem].x < dataItem.x) {
                                lastValue = componentData.data[identifier][lastDataItem].y
                              }
                            }
                          }
                          if (lastValue) {
                            componentData.data[identifier].push({ x: dataItem.x - 1 - action.meta.arg.dateOffset, y: lastValue })
                          }
                          componentData.data[identifier].push({ x: dataItem.x - action.meta.arg.dateOffset, y: dataItem.y })
                          lastValue = dataItem.y
                        }
                      } else {
                        componentData.data[identifier].push(...processedData[identifier])
                      }
                    }



                  } else {
                    //Live data chunk
                    for (let identifier in dataChunk["live_values"]) {
                      let isNew = false

                      if (componentData.data[identifier] === undefined) {
                        componentData.data[identifier] = []
                        isNew = true
                      }

                      let lastValue = null
                      for (let forTime in dataChunk["live_values"][identifier]) {
                        const pointTime = forTime
                        if (identifier === "lightr" || identifier === "lightg" || identifier === "lightb" || identifier === "lightfr") {
                          if (!lastValue && componentData.data[identifier].length > 0) {
                            let lastDataItem = binaryClosestIdx(componentData.data[identifier], pointTime - 1, 'x')
                            if (lastDataItem && componentData.data[identifier][lastDataItem] !== undefined) {
                              if (componentData.data[identifier][lastDataItem].x < pointTime) {
                                lastValue = componentData.data[identifier][lastDataItem].y
                              }
                            }
                          }
                          if (lastValue) {
                            componentData.data[identifier].push({ x: pointTime - 1 - action.meta.arg.dateOffset, y: lastValue })
                          }
                          componentData.data[identifier].push({ x: pointTime - action.meta.arg.dateOffset, y: dataChunk["live_values"][identifier][forTime] })
                        } else {
                          componentData.data[identifier].push({ x: pointTime - action.meta.arg.dateOffset, y: dataChunk["live_values"][identifier][forTime] })
                        }
                        newData = true;
                        componentData.haveNewData[identifier] = true

                        if (pointTime > componentData.haveDataUpUntil) {
                          componentData.haveDataUpUntil = parseInt(pointTime)
                        }

                        lastValue = dataChunk["live_values"][identifier][forTime]
                      }
                    }

                    currentDataChunk.to = componentData.haveDataUpUntil
                  }


                  if (newData) {
                    for (let identifier in componentData.data) {
                      componentData.data[identifier].sort(function (a, b) {
                        if (a.x > b.x)
                          return 1;
                        if (a.x < b.x)
                          return -1;
                        return 0

                      });
                    }

                    componentData.changedVersion += 1
                  }

                }
              }

            }

            return germinationStation
          })
        }
      }
    },

    [getGerminationStationAnalyticsData.rejected]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (!action.meta.arg.germinationStationIds.includes(germinationStation.id))  {
          return germinationStation
        }
        return { ...germinationStation, loading_analytics_data: "rejected" }
      })
    },





    [getGerminationStationConfigurationMap.pending]: (state) => {
      state.loadingConfigurationMaps = 'pending';
    },

    [getGerminationStationConfigurationMap.fulfilled]: (state, action) => {
      state.loadingConfigurationMaps = 'fulfilled';
      if (action.payload.maps !== null) {
        for (let [configurationId, maps] of Object.entries(action.payload.maps)) {
          let foundConfigurationMap = state.configurationMaps.find((cM) => cM.id === parseInt(configurationId))
          if (foundConfigurationMap === undefined) {
            foundConfigurationMap = { id: parseInt(configurationId), component_map: {}, io_map: {} }
            state.configurationMaps.push(foundConfigurationMap)
          }
          for (let [mapKey, map] of Object.entries(maps)) {
            foundConfigurationMap[mapKey] = map
          }
        }
      }
    },

    [getGerminationStationConfigurationMap.rejected]: (state) => {
      state.loadingConfigurationMaps = 'rejected';
    },

    [getGerminationStationUniqueConfigurationMap.pending]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (!action.meta.arg.germinationStationIds.includes(germinationStation.id))  {
          return germinationStation
        }
        return { ...germinationStation, loading_unique_configuration_map: "pending" }
      })
    },

    [getGerminationStationUniqueConfigurationMap.fulfilled]: (state, action) => {
      if (action.payload.unique_configuration !== null) {

        state.germinationStation = state.germinationStation.map((germinationStation) => {
          if (action.payload.unique_configuration[germinationStation.id] === undefined)  {
            return germinationStation
          }
          return { ...germinationStation, unique_configuration: action.payload.unique_configuration[germinationStation.id], loaded_unique_configuration_map: true, loading_unique_configuration_map: "fulfilled" }          
        })
      }
    },

    [getGerminationStationUniqueConfigurationMap.rejected]: (state, action) => {
      state.germinationStation = state.germinationStation.map((germinationStation) => {
        if (!action.meta.arg.germinationStationIds.includes(germinationStation.id))  {
          return germinationStation
        }
        return { ...germinationStation, loading_unique_configuration_map: "rejected" }
      })
    },





    [createNewGerminationStation.pending]: (state) => {
      state.creatingNewGerminationStation = 'pending';
    },
    [createNewGerminationStation.fulfilled]: (state, action) => {
      state.creatingNewGerminationStation = 'fulfilled';
      if (action.payload.germinationStation) {
        state.germinationStation.push(processGerminationStationFromAPI(state, action.payload.germinationStation))
        state.loadedGerminationStationServiceIds.push(action.payload.germinationStation.service_id)
        state.loadedGerminationStationUIDs.push(action.payload.germinationStation.uid)

        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },
    [createNewGerminationStation.rejected]: (state, action) => {
      state.creatingNewGerminationStation = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },


    [linkGerminationStationToControlDevice.pending]: (state) => {
      state.linkingGerminationStationToControlDevice = 'pending';
    },

    [linkGerminationStationToControlDevice.fulfilled]: (state, action) => {
      state.linkingGerminationStationToControlDevice = 'fulfilled';
      if (action.payload.success && action.payload.germinationStation !== null) {
        let exists = false
        for (let germinationStationIndex in state.germinationStation) {
          if (state.germinationStation[germinationStationIndex].id === action.payload.germinationStation.id) {
            //Get the UID

            state.germinationStation[germinationStationIndex] = processGerminationStationFromAPI(state, { ...state.germinationStation[germinationStationIndex], ...action.payload.germinationStation })
            exists = true
            break
          }
        }

        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },

    [linkGerminationStationToControlDevice.rejected]: (state, action) => {
      state.linkingGerminationStationToControlDevice = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },



    [addGerminationStationToFarm.pending]: (state) => {
      state.addingGerminationStationToFarm = 'pending';
    },

    [addGerminationStationToFarm.fulfilled]: (state, action) => {
      state.addingGerminationStationToFarm = 'fulfilled';
      if (action.payload.confirmed && action.payload.germinationStation) {
        let exists = false
        for (let germinationStationIndex in state.germinationStation) {
          if (state.germinationStation[germinationStationIndex].id === action.payload.germinationStation.id) {
            //Get the UID

            state.germinationStation[germinationStationIndex] = processGerminationStationFromAPI(state, { ...state.germinationStation[germinationStationIndex], ...action.payload.germinationStation })
            exists = true
            break
          }
        }
        state.germinationStation.push(processGerminationStationFromAPI(state, action.payload.germinationStation))
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },

    [addGerminationStationToFarm.rejected]: (state, action) => {
      state.addingGerminationStationToFarm = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },





    [validateGerminationStationSystemConnection.pending]: (state) => {
      state.validatingGerminationStationConnectedToSystem = 'pending';
    },

    [validateGerminationStationSystemConnection.fulfilled]: (state, action) => {
      state.validatingGerminationStationConnectedToSystem = 'fulfilled';
      if (action.payload.success && action.payload.connected !== null) {


        if (action.payload.connected) {
          if (action.meta.arg.callback !== undefined) {
            action.meta.arg.callback(true)
          }
        } else {
          if (action.meta.arg.callback !== undefined) {
            action.meta.arg.callback(false)
          }
        }
      } else {
        if (action.meta.arg.callback !== undefined) {
          action.meta.arg.callback(false)
        }
      }
    },

    [validateGerminationStationSystemConnection.rejected]: (state, action) => {
      state.validatingGerminationStationConnectedToSystem = 'rejected';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },

  }
})





/* Request Calls */

export const InitialLoadAllGerminationStations = ({}) => {
  const dispatch = useDispatch()

  const loadedAllGerminationStations = useSelector((state) => state.germinationStation.loadedAllGerminationStations)
  const loadingAllGerminationStationsStatus = useSelector((state) => state.germinationStation.loadingAllGerminationStationsStatus)
  useEffect(() => {
    if (!loadedAllGerminationStations && loadingAllGerminationStationsStatus !== "pending")   {
      dispatch(getAllGerminationStations({}))
    }
  }, [loadedAllGerminationStations, loadingAllGerminationStationsStatus])
}


/*Load germinationStation status data*/
export const MaintainGerminationStationStatus = ({ germinationStationIds, germinationStationUIDs, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allGerminationStations = useSelector((state) => selectAllGerminationStations(state))
  /*Load germinationStation status data*/
  const validateGerminationStationStatusDataToLoad = () => {
    let germinationStationToLoadStatusData = []
    let currentTime = new Date().getTime()

    let germinationStationToValidate = []
    if (germinationStationIds !== undefined) {
      for (let germinationStationId of Object.values(germinationStationIds)) {
        let foundGerminationStation = allGerminationStations.find((b) => b.id === germinationStationId)
        if (foundGerminationStation !== undefined) {
          germinationStationToValidate.push(foundGerminationStation)
        }
      }
    }
    if (germinationStationUIDs !== undefined) {
      for (let germinationStationUID of Object.values(germinationStationUIDs)) {
        let foundGerminationStation = allGerminationStations.find((b) => b.uid === germinationStationUID)
        if (foundGerminationStation !== undefined) {
          if (germinationStationToValidate.find((b) => b.uid === germinationStationUID) === undefined) {
            germinationStationToValidate.push(foundGerminationStation)
          }
        }
      }
    }
    for (let germinationStation of germinationStationToValidate) {
      let requiresStatusUpdate = false
      if (germinationStation.loading_status_data === "idle") {
        requiresStatusUpdate = true
      } else if (germinationStation.loading_status_data === "fulfilled" || germinationStation.loading_status_data === "rejected") {
        if (germinationStation.last_status_data_loaded_on !== undefined) {
          let elapsedTime = currentTime - germinationStation.last_status_data_loaded_on
          if (elapsedTime > interval) {
            requiresStatusUpdate = true
          }
        }
      }
      if (requiresStatusUpdate) {
        germinationStationToLoadStatusData.push(germinationStation.id)
      }
    }

    if (germinationStationToLoadStatusData.length > 0) {
      dispatch(getGerminationStationStatusById({ germinationStationIds: germinationStationToLoadStatusData }))
    }
  }
  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateGerminationStationStatusDataToLoad()
    }, interval / 10);
    //validateGerminationStationStatusDataToLoad()
    return () => clearInterval(statusLoadInterval);
  }, [allGerminationStations, germinationStationIds, germinationStationUIDs]);
}

export const MaintainGerminationStationConfigurationMaps = ({ germinationStationIds, germinationStationUIDs, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allGerminationStations = useSelector((state) => selectAllGerminationStations(state))
  const allGerminationStationConfigurationMaps = useSelector(selectAllGerminationStationConfigurationMaps)

  /*Load germinationStation status data*/
  const validateGerminationStationConfigurationMapsToLoad = () => {

    let germinationStationConfigurationMapsToLoad = {}
    if (germinationStationIds !== undefined) {
      for (let germinationStationId of Object.values(germinationStationIds)) {
        let foundGerminationStation = allGerminationStations.find((b) => b.id === germinationStationId)
        if (foundGerminationStation !== undefined) {
          if (germinationStationConfigurationMapsToLoad[foundGerminationStation.configuration_id] === undefined && allGerminationStationConfigurationMaps.find((cM) => cM.id === foundGerminationStation.configuration_id) === undefined)  {
            germinationStationConfigurationMapsToLoad[foundGerminationStation.configuration_id] = { "component_map": null }
          }
        }
      }
    }
    if (germinationStationUIDs !== undefined) {
      for (let germinationStationUID of Object.values(germinationStationUIDs)) {
        let foundGerminationStation = allGerminationStations.find((b) => b.uid === germinationStationUID)
        if (foundGerminationStation !== undefined) {
          if (germinationStationConfigurationMapsToLoad[foundGerminationStation.configuration_id] === undefined && allGerminationStationConfigurationMaps.find((cM) => cM.id === foundGerminationStation.configuration_id) === undefined)  {
            germinationStationConfigurationMapsToLoad[foundGerminationStation.configuration_id] = { "component_map": null }
          }
        }
      }
    }
    if (Object.entries(germinationStationConfigurationMapsToLoad).length > 0) {
      dispatch(getGerminationStationConfigurationMap({ maps: germinationStationConfigurationMapsToLoad }))
    }
  }
  useEffect(() => {
    const configurationMapsLoadInterval = setInterval(() => {
      validateGerminationStationConfigurationMapsToLoad()
    }, interval / 10);
    //validateGerminationStationConfigurationMapsToLoad()
    return () => clearInterval(configurationMapsLoadInterval);
  }, [allGerminationStations, allGerminationStationConfigurationMaps, germinationStationIds, germinationStationUIDs]);
}

export const MaintainGerminationStationUniqueConfigurationMaps = ({ germinationStationIds, germinationStationUIDs, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allGerminationStations = useSelector((state) => selectAllGerminationStations(state))
  /*Load germinationStation status data*/
  const validateGerminationStationUniqueConfigurationMapsToLoad = () => {
    let germinationStationToLoadUniqueConfigurationMaps = []
    let currentTime = new Date().getTime()

    let germinationStationToValidate = []
    if (germinationStationIds !== undefined) {
      for (let germinationStationId of Object.values(germinationStationIds)) {
        let foundGerminationStation = allGerminationStations.find((b) => b.id === germinationStationId)
        if (foundGerminationStation !== undefined) {
          germinationStationToValidate.push(foundGerminationStation)
        }
      }
    }
    if (germinationStationUIDs !== undefined) {
      for (let germinationStationUID of Object.values(germinationStationUIDs)) {
        let foundGerminationStation = allGerminationStations.find((b) => b.uid === germinationStationUID)
        if (foundGerminationStation !== undefined) {
          if (germinationStationToValidate.find((b) => b.uid === germinationStationUID) === undefined) {
            germinationStationToValidate.push(foundGerminationStation)
          }
        }
      }
    }
    for (let germinationStation of germinationStationToValidate) {
      if (germinationStation.loading_unique_configuration_map !== "pending") {
        if (germinationStation.unique_configuration === null || germinationStation.unique_configuration_version !== germinationStation.unique_configuration.version) {
          germinationStationToLoadUniqueConfigurationMaps.push(germinationStation.id)
        }
      }
    }

    if (germinationStationToLoadUniqueConfigurationMaps.length > 0) {
      //console.log(germinationStationToLoadUniqueConfigurationMaps)
      dispatch(getGerminationStationUniqueConfigurationMap({germinationStationIds: germinationStationToLoadUniqueConfigurationMaps}))
    }
  }
  useEffect(() => {
    const uniqueConfigurationMapsLoadInterval = setInterval(() => {
      validateGerminationStationUniqueConfigurationMapsToLoad()
    }, interval / 10);
    //validateGerminationStationUniqueConfigurationMapsToLoad()
    return () => clearInterval(uniqueConfigurationMapsLoadInterval);
  }, [allGerminationStations, germinationStationIds, germinationStationUIDs]);
}




export const MaintainGerminationStationLiveData = ({ germinationStationIds, germinationStationUIDs, interval = 1000 }) => {
  const dispatch = useDispatch()
  const allGerminationStations = useSelector((state) => selectAllGerminationStations(state))

  const validateGerminationStationLiveDataToLoad = () => {
    let germinationStationToLoadLiveData = {}
    let currentTime = new Date().getTime()

    let germinationStationToValidate = []
    if (germinationStationIds !== undefined) {
      for (let germinationStationId of Object.values(germinationStationIds)) {
        let foundGerminationStation = allGerminationStations.find((b) => b.id === germinationStationId)
        if (foundGerminationStation !== undefined) {
          germinationStationToValidate.push(foundGerminationStation)
        }
      }
    }
    if (germinationStationUIDs !== undefined) {
      for (let germinationStationUID of Object.values(germinationStationUIDs)) {
        let foundGerminationStation = allGerminationStations.find((b) => b.uid === germinationStationUID)
        if (foundGerminationStation !== undefined) {
          if (germinationStationToValidate.find((b) => b.uid === germinationStationUID) === undefined) {
            germinationStationToValidate.push(foundGerminationStation)
          }
        }
      }
    }
    for (let germinationStation of germinationStationToValidate) {
      if (germinationStation.loading_live_data === "idle") {
        germinationStationToLoadLiveData[germinationStation.id] = germinationStation.have_live_data_version
      } else if (germinationStation.loading_live_data === "fulfilled" || germinationStation.loading_live_data === "rejected") {
        if (germinationStation.last_live_data_loaded_on !== undefined && currentTime - germinationStation.last_live_data_loaded_on > interval) {
          germinationStationToLoadLiveData[germinationStation.id] = germinationStation.have_live_data_version
        }
      }
    }

    if (Object.entries(germinationStationToLoadLiveData).length > 0) {
      dispatch(getGerminationStationLiveDataById({ germinationStationIdsWithVersions: germinationStationToLoadLiveData }))
    }
  }

  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateGerminationStationLiveDataToLoad()
    }, interval / 10);
    //validateGerminationStationLiveDataToLoad()
    return () => clearInterval(statusLoadInterval);
  }, [allGerminationStations, germinationStationIds, germinationStationUIDs]);
}






export const MaintainGerminationStationAnalyticsData = ({ germinationStationComponents, chartOriginDate, mainRange, overviewRange, mainDataRecordingTimePeriodType, overviewDataRecordingTimePeriodType, interval = 2000 }) => {
  const dispatch = useDispatch()
  const allGerminationStations = useSelector((state) => selectAllGerminationStations(state))
  const dataRecordingTimePeriodTypes = useSelector(selectAllDataRecordingTimePeriodTypes)
  const germinationStationAnalyticsData = useSelector((state) => selectGerminationStationAnalyticsDataForGerminationStationIds(state, Object.keys(germinationStationComponents).map((bId) => parseInt(bId))), _.isEqual)

  //const loadingGerminationStationAnalyticsData = useSelector((state) => state.germinationStation.loadingGerminationStationAnalyticsData)

  const germinationStationRef = useRef(allGerminationStations)
  useEffect(() => {
    germinationStationRef.current = allGerminationStations
  }, [allGerminationStations])

  const germinationStationAnalyticsDataRef = useRef(germinationStationAnalyticsData)
  useEffect(() => {
    germinationStationAnalyticsDataRef.current = germinationStationAnalyticsData
  }, [germinationStationAnalyticsData])


  const germinationStationComponentsRef = useRef(germinationStationComponents)
  useEffect(() => {
    germinationStationComponentsRef.current = germinationStationComponents
  }, [germinationStationComponents])

  const mainRangeRef = useRef(mainRange)
  useEffect(() => {
    mainRangeRef.current = mainRange
  }, [mainRange])

  const overviewRangeRef = useRef(overviewRange)
  useEffect(() => {
    overviewRangeRef.current = overviewRange
  }, [overviewRange])

  const mainDataRecordingTimePeriodTypeRef = useRef(mainDataRecordingTimePeriodType)
  useEffect(() => {
    mainDataRecordingTimePeriodTypeRef.current = mainDataRecordingTimePeriodType
  }, [mainDataRecordingTimePeriodType])

  const overviewDataRecordingTimePeriodTypeRef = useRef(overviewDataRecordingTimePeriodType)
  useEffect(() => {
    overviewDataRecordingTimePeriodTypeRef.current = overviewDataRecordingTimePeriodType
  }, [overviewDataRecordingTimePeriodType])

  const validateGerminationStationAnalyticsDataToLoad = () => {
    /*const allGerminationStations = germinationStationRef.current
    const germinationStationAnalyticsData = germinationStationAnalyticsDataRef.current
    const germinationStationComponents = germinationStationComponentsRef.current
    const mainRange = mainRangeRef.current
    const overviewRange = overviewRangeRef.current
    const mainDataRecordingTimePeriodType = mainDataRecordingTimePeriodTypeRef.current
    const overviewDataRecordingTimePeriodType = overviewDataRecordingTimePeriodTypeRef.current*/
    if (mainDataRecordingTimePeriodType === null || overviewDataRecordingTimePeriodType === null)
      return

    //console.log(allGerminationStations, germinationStationAnalyticsData, germinationStationComponents, mainRange, overviewRange, mainDataRecordingTimePeriodType, overviewDataRecordingTimePeriodType)
    let germinationStationToRequestEntriesFor = []
    let analyticsRequestEntries = {}

    let germinationStationToLoadAnalyticsData = []
    let currentTime = new Date().getTime()
    for (let germinationStationId of Object.keys(germinationStationComponents)) {
      let germinationStation = allGerminationStations.find((b) => b.id === parseInt(germinationStationId))
      if (germinationStation !== undefined) {
        if (germinationStation.loading_analytics_data === "idle") {
          germinationStationToLoadAnalyticsData.push(germinationStation)
        } else if (germinationStation.loading_analytics_data === "fulfilled" || germinationStation.loading_analytics_data === "rejected") {
          if (germinationStation.last_analytics_data_loaded_on !== undefined) {
            if (currentTime - germinationStation.last_analytics_data_loaded_on > interval) {
              germinationStationToLoadAnalyticsData.push(germinationStation)
            }
          }
        }
      }
    }

    const addComponentRequest = (timePeriodTypeId, requestFrom, requestTo, germinationStationId, componentId) => {
      let requestKey = requestFrom + "-" + requestTo
      if (analyticsRequestEntries[timePeriodTypeId] === undefined) {
        analyticsRequestEntries[timePeriodTypeId] = {}
      }
      if (analyticsRequestEntries[timePeriodTypeId][requestKey] === undefined) {
        analyticsRequestEntries[timePeriodTypeId][requestKey] = {}
      }
      if (analyticsRequestEntries[timePeriodTypeId][requestKey][germinationStationId] === undefined) {
        analyticsRequestEntries[timePeriodTypeId][requestKey][germinationStationId] = []
      }
      if (analyticsRequestEntries[timePeriodTypeId][requestKey][germinationStationId].indexOf(componentId) === -1) {
        analyticsRequestEntries[timePeriodTypeId][requestKey][germinationStationId].push(componentId)
      }

      if (germinationStationToRequestEntriesFor.indexOf(germinationStationId) === -1)  {
        germinationStationToRequestEntriesFor.push(germinationStationId)
      }
    }


    for (let germinationStation of germinationStationToLoadAnalyticsData) {
      for (const componentId of germinationStationComponents[germinationStation.id]) {
        if (germinationStationAnalyticsData[germinationStation.id] !== undefined && germinationStationAnalyticsData[germinationStation.id][componentId] !== undefined) {

          let analyticsData = germinationStationAnalyticsData[germinationStation.id][componentId]
          let mainAnalyticsData = analyticsData[mainDataRecordingTimePeriodType.id]
          let overviewAnalyticsData = analyticsData[overviewDataRecordingTimePeriodType.id]
          //Check if we can load overview data

          if (overviewAnalyticsData !== undefined) {
            let overviewFromEntryIndex = Math.floor((overviewRange.start / 1000) / (overviewDataRecordingTimePeriodType.duration))
            let overviewToEntryIndex = Math.floor((overviewRange.end / 1000) / (overviewDataRecordingTimePeriodType.duration))
            //console.log(overviewAnalyticsData.dataChunks)
            for (let entryIndex = overviewFromEntryIndex; entryIndex <= overviewToEntryIndex; entryIndex++) {
              if (overviewAnalyticsData.dataChunks[entryIndex] !== undefined) {
                //console.log(overviewAnalyticsData.dataChunks[entryIndex])
                if (!overviewAnalyticsData.dataChunks[entryIndex].completed && overviewAnalyticsData.haveDataUpUntil !== 0) {
                  //Lets build a request for the portion of data we don't have
                  let requestRange = { "from": overviewAnalyticsData.haveDataUpUntil + 1, "to": (entryIndex + 1) * overviewDataRecordingTimePeriodType.duration * 1000 }
                  if (requestRange["to"] - requestRange["from"] > 1000) { //minimum request range
                    addComponentRequest(overviewDataRecordingTimePeriodType.id, requestRange["from"], requestRange["to"], germinationStation.id, componentId)
                  }
                }
              } else {
                addComponentRequest(overviewDataRecordingTimePeriodType.id, overviewRange.start, overviewRange.end, germinationStation.id, componentId)
              }

            }
          }
          if (overviewDataRecordingTimePeriodType != mainDataRecordingTimePeriodType) {
            //Check if we can load main data
            let mainFromEntryIndex = Math.floor((mainRange.start / 1000) / (mainDataRecordingTimePeriodType.duration))
            let mainToEntryIndex = Math.floor((mainRange.end / 1000) / (mainDataRecordingTimePeriodType.duration))


            //console.log(mainAnalyticsData.dataChunks)
            for (let entryIndex = mainFromEntryIndex; entryIndex <= mainToEntryIndex; entryIndex++) {
              if (mainAnalyticsData.dataChunks[entryIndex] !== undefined) {
                //console.log(mainAnalyticsData.dataChunks[entryIndex])
                if (!mainAnalyticsData.dataChunks[entryIndex].completed && mainAnalyticsData.haveDataUpUntil !== 0) {
                  //Lets build a request for the portion of data we don't have
                  let requestRange = { "from": mainAnalyticsData.haveDataUpUntil + 1, "to": (entryIndex + 1) * mainDataRecordingTimePeriodType.duration * 1000 }
                  if (requestRange["to"] - requestRange["from"] > 1000) { //minimum request range
                    addComponentRequest(mainDataRecordingTimePeriodType.id, requestRange["from"], requestRange["to"], germinationStation.id, componentId)
                  }
                }
              } else {
                addComponentRequest(mainDataRecordingTimePeriodType.id, mainRange.start, mainRange.end, germinationStation.id, componentId)
              }

            }
          }
        }
      }
    }

    if (Object.entries(analyticsRequestEntries).length > 0) {
      dispatch(getGerminationStationAnalyticsData({ germinationStationIds: germinationStationToRequestEntriesFor, entries: analyticsRequestEntries, timePeriodTypes: dataRecordingTimePeriodTypes, dateOffset: chartOriginDate }))
    }
  }

  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateGerminationStationAnalyticsDataToLoad()
    }, interval / 10);
    validateGerminationStationAnalyticsDataToLoad()
    return () => clearInterval(statusLoadInterval);
  //}, [germinationStationRef, germinationStationAnalyticsDataRef, germinationStationComponentsRef, mainRangeRef, overviewRangeRef, mainDataRecordingTimePeriodTypeRef, overviewDataRecordingTimePeriodTypeRef]);
  }, [allGerminationStations, germinationStationAnalyticsData, germinationStationComponents, mainRange, overviewRange, mainDataRecordingTimePeriodType, overviewDataRecordingTimePeriodType]);
}



// Action creators are generated for each case reducer function
export const { initializeComponentForAnalyticsData, assignActiveRecipeToZone, disassignActiveRecipeForZone } = germinationStationSlice.actions

export default germinationStationSlice.reducer

export const selectAllGerminationStations = state => state.germinationStation.germinationStation
export const selectAllGerminationStationConfigurationMaps = state => state.germinationStation.configurationMaps

export const selectGerminationStationAnalyticsDataForGerminationStationIds = (state, germinationStationIds) => {
  let germinationStationAnalyticsData = {}
  let germinationStation = state.germinationStation.germinationStation.filter((b) => germinationStationIds.includes(b.id))
  for (let germinationStation of germinationStation) {
    germinationStationAnalyticsData[germinationStation.id] = germinationStation.analyticsData
  }
  return germinationStationAnalyticsData
}


export const selectGerminationStationUIDById = (state, germinationStationId) => {
  let germinationStation = state.germinationStation.germinationStation.find((b) => b.id === germinationStationId)
  if (germinationStation !== undefined) {
    return germinationStation.uid
  }
  return null
}
export const selectGerminationStationDisplayNameById = (state, germinationStationId) => {
  let germinationStation = state.germinationStation.germinationStation.find((b) => b.id === germinationStationId)
  if (germinationStation !== undefined) {
    return germinationStation.display_name
  }
  return "Unknown GerminationStation"
}

export const selectGerminationStationControlDeviceConnectedState = (state, germinationStationId) => {
  let germinationStation = state.germinationStation.germinationStation.find((b) => b.id === germinationStationId)
  if (germinationStation !== undefined) {
    return (germinationStation.control_device !== null && germinationStation.control_device.connected)
  }
  return false
}
 
//Returns usable value, active value, requested value
export const selectGerminationStationRuntimeInformation = (state, germinationStationId, key) => {
  let germinationStation = state.germinationStation.germinationStation.find((b) => b.id === germinationStationId)
  if (germinationStation !== undefined) {
    const usableValue = germinationStation.runtime_information_requested[key] ?? germinationStation.runtime_information[key] ?? ""
    return [usableValue, germinationStation.runtime_information[key] ?? "", germinationStation.runtime_information_requested[key] ?? ""]
  }
  return ["", "", ""]
}


export const selectGerminationStationActiveIssuesById = (state, germinationStationId) => {
  let germinationStation = state.germinationStation.germinationStation.find((b) => b.id === germinationStationId)
  if (germinationStation !== undefined) {
    return Object.entries(germinationStation.active_issues).filter(([_, issue]) => issue.active)
  }
  return {}
}




export const selectGerminationStationComponentInfo = (state, germinationStationId, componentName, identifiers) => {
  
  const germinationStation = state.germinationStation.germinationStation.find((b) => b.id === germinationStationId)
  if (germinationStation !== undefined)  {
    const configMap = state.germinationStation.configurationMaps.find((cM) => cM.id === germinationStation.configuration_id)
    if (configMap !== undefined && configMap.component_map)  {
      let component = configMap.component_map.components.find((c) => c.name === componentName)
      if (component !== undefined)  {
        let returnInfo = []
        
        for (let identifier of identifiers) {
          let identifierInfo = component[0] !== undefined ? (component[0].data_types ? component[0].data_types.find((dT) => dT.identifier === identifier) : undefined) : undefined
          returnInfo.push({
            componentId: component.id,
            identifier: identifier 
          })
        }

        return returnInfo
      }
    }
  }


  let returnInfo = []
  for (let i = 0; i < identifiers.length; i++)  {
    returnInfo[i] = null
  }
  return returnInfo
}

export const selectGerminationStationLiveDataByComponentInfo = (state, germinationStationId, componentInfo) => {
  const germinationStation = state.germinationStation.germinationStation.find((b) => b.id === germinationStationId)
  if (germinationStation !== undefined && componentInfo && componentInfo.componentId !== undefined && componentInfo.identifier !== undefined)  {
    if (germinationStation.liveData[componentInfo.componentId] !== undefined && germinationStation.liveData[componentInfo.componentId][componentInfo.identifier] !== undefined) {
      return germinationStation.liveData[componentInfo.componentId][componentInfo.identifier]
    }
  }
  return {value: null, retrieved_on: null}
}
