import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';

import { EmptyContainer } from '../../../../assets/icons/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectFlowRuntimeInformation, setFlowProperty, setFlowRuntimeProperty } from '../../../../redux/entities/service/Flow';

  

const ConfirmReservoirRemovalWalkthroughStep = ({flowId, reservoirInfo, transitionToPreviousStep, transitionToNextStep, reservoirRemovalConfirmedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const dispatch = useDispatch()
    const reservoirRuntimeInformationKey = "dosing_reservoir_info_" + reservoirInfo["index"].toString()
    const [, , pendingReservoirInfoUpdatesString] = useSelector((state) => selectFlowRuntimeInformation(state, flowId, reservoirRuntimeInformationKey))

    const [isApplyingReservoirRemoval, SetIsApplyingReservoirRemoval] = React.useState(false)
    const nextClicked = () =>   {
        if (!isApplyingReservoirRemoval) {
            SetIsApplyingReservoirRemoval(true)

            let pendingReservoirInfoUpdates = {}
            if (pendingReservoirInfoUpdatesString !== "")   {
                pendingReservoirInfoUpdates = JSON.parse(pendingReservoirInfoUpdatesString)
            }
            pendingReservoirInfoUpdates["installed"] = 0
            pendingReservoirInfoUpdates["primed"] = 0
            dispatch(setFlowRuntimeProperty({flowId: flowId, properties: {
                [reservoirRuntimeInformationKey]: JSON.stringify({...pendingReservoirInfoUpdates})
            }, callback: (success) => {
                SetIsApplyingReservoirRemoval(false)
                if (success)    {
                    reservoirRemovalConfirmedCallback()
                    transitionToNextStep(["confirm_initial_reservoir_volume", null])
                }else {
                    //something went wrong
                }
            }}))

            /*dispatch(setFlowProperty({flowId: flowId, values: {
                "nutrient_reservoir_installed_state": 0,
                "nutrient_reservoir_index": reservoirInfo.index,
            }, callback: (success) => {
                
            }}))*/
        }
    }
    
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Disconnect Reservoir {reservoirInfo.index}</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20 FlexContent-Center" style={{padding: isMobile ? "0 20px" : "0 150px"}}>
                        <EmptyContainer width={100}/>
                        <div style={{fontSize:18,textAlign:"center",fontWeight:300}}>Continuing with the refill will pause the reservoir, confirm pause to continue</div>
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={isApplyingReservoirRemoval ? "Applying..." : "Confirm Pause"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex100"} disabled={isApplyingReservoirRemoval}/>
            </div>
        </div>
    )
    
}


export default ConfirmReservoirRemovalWalkthroughStep