import './FlowsPage.scss';
import variables from '../../../globals.scss';
import React from 'react'
import _ from 'underscore';


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { InitialLoadAllBladeGroups, InitialLoadAllBladeZones, InitialLoadAllBlades, selectAllBladeZones, selectAllBlades, selectBladeZoneByUID, useMaintainBladeStatus,  } from '../../../redux/entities/service/Blade';
import { RoundToNearest, parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import { AddItem, AdditionalOptions, BadX, GoodCheckmark, HalfFullCup, Pause, Play, Plus, PlusAlt, RSSI, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import { InitialLoadAllFlows, MaintainFlowConfigurationMaps, MaintainFlowLiveData, MaintainFlowStatus, MaintainFlowUniqueConfigurationMaps, MaintainLatestFlowMaps, getFlowMapVersions, getFlowMaps, getFlowStatusById, selectAllFlow, selectFlowControlDeviceConnectedState, selectFlowCurrentActionByFlowId, selectFlowCurrentActionInfoByFlowId, selectFlowCurrentSubactionByFlowId, selectFlowDisplayNameById, selectFlowNutrientReservoirsByFlowId, selectFlowRuntimeInformation, setFlowProperty, useMaintainFlowStatus, useMaintainLatestMaps } from '../../../redux/entities/service/Flow';
import AddFlowWalkthrough from './AddFlowWalkthrough';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import { ScanFlow } from '../../../assets/icons/FlowIcons';
import DropDownButton from '../../../components/DropDownButton';
import TopUpReservoirWalkthrough from './TopUpReservoirWalkthrough';
import { getInventoryItemTypeById, selectAllInventoryItemTypes } from '../../../redux/entities/Inventory';
import FlowZonePortsManager from './FlowZonePortsManager';
import FlowInstance from './FlowInstance';






const FlowsPage = ({selectedFacility, setRightSideContent}) => {
  


  
  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

  const dispatch = useDispatch()

  const activeFlowIds = useSelector((state) => state.flow.flow.filter((f) => f.facility_id === selectedFacility).map((f) => f.id), _.isEqual)

  //const verticalRackConfigurationMaps = useSelector(selectAllVerticalRackConfigurationMaps)

  //useMaintainFlowStatus({FlowIds: Flows.map((b) => {return b.id})})
  
 
  const updateHeader = React.useCallback(() =>  {
    if (setRightSideContent === undefined)
      return
    setRightSideContent(
      <>
        {activeFlowIds.length > 0 && 
          <Button content="Add Flow" status="Primary" onClick={addNewFlow}/>  
        }
      </>
    )
  })

  React.useLayoutEffect(() => {
    updateHeader()
  }, [])
  React.useLayoutEffect(() => {
    updateHeader()
  }, [activeFlowIds])

  
  
  const [addingNewFlow, SetAddingNewFlow] = React.useState(false)
  const addNewFlow = React.useCallback(() => {
    SetAddingNewFlow(true)
  })

  

  const addFlowWalkthroughCloseCallback = React.useCallback(() => {
    SetAddingNewFlow(false)  
  })




  const [managingZonePortsForFlowId, SetManagingZonePortsForFlowId] = React.useState(null)
  const manageZonePortsClicked = (Flow) => {
    SetManagingZonePortsForFlowId(Flow.id)
  }
  const closeManagingZonePorts = () => {
    SetManagingZonePortsForFlowId(null)
  }
  




  ///const FlowEditing = React.useState(null);

  return (<>
    <InitialLoadAllFlows/>
    <InitialLoadAllBladeZones/>
    <MaintainFlowStatus flowIds={activeFlowIds} /> 
    <MaintainLatestFlowMaps flowIds={activeFlowIds} />
    <MaintainFlowConfigurationMaps flowIds={activeFlowIds} />
    <MaintainFlowUniqueConfigurationMaps flowIds={activeFlowIds} />
    <MaintainFlowLiveData flowIds={activeFlowIds}/>
    <div id="FlowStatus">
      
      {activeFlowIds.length === 0 && 
        <div className="FlowStatus-NoGroupsContent">
          <span>No active Flows</span>
        </div>
      }
      {activeFlowIds.length > 0 &&
        <div className="FlowStatus-Flows">
          <RowGroup
            hasSettings={false}
            hasGroupHeader={false}
            hasColumnHeaders={false}
            verticalFill={true}
            >
              {activeFlowIds.map((flowId) => {
                return <FlowInstance 
                  key={flowId} 
                  flowId={flowId} 
                  isDefaultExpanded={activeFlowIds.length === 1}
                  manageZonePortsCallback={() => {SetManagingZonePortsForFlowId(flowId)}}/>
                

                
              })}

            </RowGroup>
          </div>
            
        }
    </div>
    {addingNewFlow && <AddFlowWalkthrough facilityId={selectedFacility} closeCallback={addFlowWalkthroughCloseCallback}/>}
    {managingZonePortsForFlowId && <FlowZonePortsManager facilityId={selectedFacility} flowId={managingZonePortsForFlowId} closeCallback={closeManagingZonePorts}/>}
  </>)
} 

export default FlowsPage