import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';

import { EmptyContainer } from '../../../../assets/icons/Icons';
import { KeypadInput_DisplayField, KeypadInput_Keypad } from '../../../../components/input/KeypadInput';
import DropDownInput from '../../../../components/input/DropDownInput';
import { ProcessSuffixForUnit, ProcessValueForUnit, RoundToNearest } from '../../../../helpers';
import { selectAllInventoryItemTypes } from '../../../../redux/entities/Inventory';
import { useSelector } from 'react-redux';

  

const ConfirmInitialReservoirVolumeWalkthroughStep = ({flowId, selectedUnit, selectedUnitChanged, reservoirInfo, reservoirItemName, transitionToPreviousStep, transitionToNextStep, initialReservoirVolumeConfirmedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const allInventoryItemTypes = useSelector((state) => selectAllInventoryItemTypes(state))
    const activeInventoryItemType = allInventoryItemTypes.find(iT => reservoirInfo["reference_id"])

    const mixType = reservoirInfo["mix_type"]
    const [updatedReservoirVolume, SetUpdatedReservoirVolume] = React.useState(() => {
        let reservoirVolumeRemaining = 0
        if (reservoirInfo["runtime_information"]["volume_remaining"] !== undefined)  {
            reservoirVolumeRemaining = RoundToNearest(parseFloat(reservoirInfo["runtime_information"]["volume_remaining"]) / 1000, 0.1);
        }
        return RoundToNearest(ProcessValueForUnit(parseFloat(reservoirVolumeRemaining), selectedUnit, "litres"), 0.01)
    })

    const [currentSelectedUnit, SetCurrentSelectedUnit] = React.useState(selectedUnit)
    React.useLayoutEffect(() => {
        if (currentSelectedUnit !== selectedUnit)   {
            SetUpdatedReservoirVolume(RoundToNearest(ProcessValueForUnit(updatedReservoirVolume, selectedUnit, currentSelectedUnit), 0.01))
            SetCurrentSelectedUnit(selectedUnit)
        }
    }, [updatedReservoirVolume, selectedUnit])


    const reservoirVolumeChanged = (value) => {
        SetUpdatedReservoirVolume(parseFloat(value))
    }

    const availableUnits = [
            {"value": "litres", "label": "Litres"},
            {"value": "gallons", "label": "Gallons"},
    ]
    const unitChanged = (unit) => {
        if (selectedUnitChanged !== undefined)  {
            selectedUnitChanged(unit)
        }
    }

    const cancelClicked = () => {
        transitionToNextStep(["confirm_reservoir_reinstall", null])        
    }
    const nextClicked = () =>   {
        initialReservoirVolumeConfirmedCallback(ProcessValueForUnit(parseFloat(updatedReservoirVolume), "litres", selectedUnit))
        if (mixType === "solids")   {
            transitionToNextStep(["select_topup_type", null])
        }else if (mixType === "liquid") {
            transitionToNextStep(["confirm_reservoir_solution_topup", null])            
        }
    }

    const currentReservoirVolumeInput = updatedReservoirVolume
    const keypadInputFieldRef = React.useRef()
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Confirm Volume</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    {isMobile && <>
                        <div className="FlexContent-20 FlexContent-HFill FlexContent-HFlex">
                            <div style={{textAlign:"center", fontSize:18, margin: "0 10px"}}>How much of {reservoirItemName} is currently stored?</div>
                            <div className="FlexContent-10 FlexContent-Center">
                                <KeypadInput_DisplayField value={currentReservoirVolumeInput}  resolution={0.1} maxLength={8} suffix={ProcessSuffixForUnit(selectedUnit)} active={true} verticalPadding={14} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                <DropDownInput value={selectedUnit} options={availableUnits} onSelectionChange={unitChanged}/>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={currentReservoirVolumeInput} 
                                    buttonVerticalPadding={5} 
                                    buttonHorizontalPadding={10}
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={reservoirVolumeChanged}/>
                            </div>
                        </div>
                    </>}
                    {isTablet && <>
                        <div className="FlexContent-20 FlexContent-Center">
                            <div className="FlexContent-40" style={{margin:"20px 0"}}>
                                <div style={{textAlign:"center", fontSize:24}}>How much of {reservoirItemName} is currently stored?</div>
                                <div className="FlexContent-10 FlexContent-Center">
                                    <KeypadInput_DisplayField value={currentReservoirVolumeInput} resolution={0.1} maxLength={8} suffix={ProcessSuffixForUnit(selectedUnit)} verticalPadding={15} active={true} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                    <DropDownInput value={selectedUnit} options={availableUnits} onSelectionChange={unitChanged}/>
                                </div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={currentReservoirVolumeInput} 
                                    buttonVerticalPadding={15} 
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={reservoirVolumeChanged}/>
                            </div>
                        </div>
                    </>}
                    {(!isTablet && !isMobile) && <>
                        <div className="FlexContent-H-30">
                            <div className="FlexContent-40" style={{margin:"20px 0"}}>
                                <div style={{textAlign:"center", fontSize:24}}>How much of {reservoirItemName} is currently stored?</div>
                                <div className="FlexContent-10 FlexContent-Center">
                                    <KeypadInput_DisplayField value={currentReservoirVolumeInput} resolution={0.1} maxLength={8} suffix={ProcessSuffixForUnit(selectedUnit)} verticalPadding={30} active={true} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                    <DropDownInput value={selectedUnit} options={availableUnits} onSelectionChange={unitChanged}/>
                                </div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={currentReservoirVolumeInput} 
                                    buttonVerticalPadding={15} 
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={reservoirVolumeChanged}/>
                            </div>
                        </div>
                        
                    </>}
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status={"Neutral"} content={"Cancel"} onClick={cancelClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default ConfirmInitialReservoirVolumeWalkthroughStep