import './HarvestTask.scss';
import React from 'react';

import _ from 'underscore';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllBladeZones, selectBladeIdsForBladeZoneUID, selectBladeZoneDisplayNameByUID, selectBladeZoneTypeByUID, setBladeRuntimeProperty, setBladeZoneRuntimeProperty } from '../../../../redux/entities/service/Blade';
import { SoftWarning } from '../../../../assets/icons/Icons';


const HarvestTask_PostPartialHarvestConfirmStep = ({
        activeGrow,
        activeGrowPlan,
        allBladeZones,
        harvestingZoneUID,
        cancelTaskCallback,
        containerContentsConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const dispatch = useDispatch()
    const activeZoneDisplayName = useSelector((state) => selectBladeZoneDisplayNameByUID(state, harvestingZoneUID))
    const activeZoneType = useSelector((state) => selectBladeZoneTypeByUID(state, harvestingZoneUID), _.isEqual)

    const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, harvestingZoneUID), _.isEqual)


    const backClicked = () => {
        transitionToPreviousStep(["review_containers"], null)
    }

    const [applyingPartialHarvestState, SetApplyingPartialHarvestState] = React.useState(false)

    const [pendingApplyHarvestStateToBlades, SetPendingApplyHarvestStateToBlades] = React.useState(false)
    const [wasHarvestStateApplySuccessful, SetWasHarvestStateApplySuccessful] = React.useState(null)
    const [bladeFeaturesToResume, SetBladeFeaturesToResume] = React.useState([])
    const continueClicked = () => {
        SetApplyingPartialHarvestState(true)

        /*dispatch(addPartialHarvestEntryToGrow({
            growId: activeGrow.id,
            bladeZoneUID: harvestingZoneUID,
            //units and etc
            callback: (success) => {
              if (success)  {
                        
                SetPendingApplyHarvestStateToBlades(true)
                if (activeZoneType === "nursery")    {
                    SetBladeFeaturesToResume([
                        {bladeId: bladeIds["nursery"], features: { "air_control_status": "resume", "lights_control_status": "resume", "aux_a_liquid_control_status": "resume", "aux_b_liquid_control_status": "resume" } }
                    ])
                }else {
                    SetBladeFeaturesToResume([
                        {bladeId: bladeIds["grow"], features: { "primary_liquid_control_status": "resume"} },
                        {bladeId: bladeIds["environment"], features: {"air_control_status": "resume", "lights_control_status": "resume"} }
                    ])
                }                      
              }else {
    
              }
            }
        }))*/
                
    }

    const settingBladeRuntimePropertyStatus = useSelector((state) => state.blade.settingBladeRuntimeProperty)
    React.useLayoutEffect(() => {
        if (pendingApplyHarvestStateToBlades)   {
            if (bladeFeaturesToResume.length > 0 && settingBladeRuntimePropertyStatus !== "pending") {
                dispatch(setBladeRuntimeProperty({
                    bladeId: bladeFeaturesToResume[0].bladeId,
                    properties: bladeFeaturesToResume[0].features,
                    callback: (success) => {
                        bladeFeaturesToResume.shift()
                        SetBladeFeaturesToResume([...bladeFeaturesToResume])
                    }
                }))
            }else if (bladeFeaturesToResume.length === 0)   {
                SetWasHarvestStateApplySuccessful(true)
                SetPendingApplyHarvestStateToBlades(false)
                //SetApplyingHarvestState(false)
            }
        }
    }, [pendingApplyHarvestStateToBlades, bladeFeaturesToResume, settingBladeRuntimePropertyStatus])


    React.useLayoutEffect(() => {
        if (wasHarvestStateApplySuccessful === true)    {
            transitionToNextStep(["zone_list", null])
        }
    }, [wasHarvestStateApplySuccessful])


    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Prepare to resume {activeZoneDisplayName}
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"} style={{flex:1}}>
                    <div className={"FlexContent-30 FlexContent-Center"} style={{padding: isMobile ? "0px 10px" : "0px 100px"}}>
                        <div className="FlexContent-H-10 Linebreak-30">
                            <SoftWarning fill={"#EC3C3C"}/>
                            <div className="Text-Medium-S16 Text-Wrap">The zone recipe will continue on meaning:</div>
                        </div>
                        <div className="FlexContent-30" style={{alignSelf:"stretch"}}>
                            <div className="Text-S16">Lights will go back to normal</div>
                            <div className="Text-S16">Air conditioning will resume</div>
                            {activeZoneType !== undefined && <div className="Text-S16">
                                {activeZoneType === "grow_boards" && "Aeroponics"}
                                {activeZoneType === "berry_troughs" && "Drip Irrigation"}
                                &nbsp;will run again
                            </div>}
                        </div>
                    </div>                    
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Confirm and Complete"} onClick={continueClicked} size={"Flex50"} disabled={applyingPartialHarvestState}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_PostPartialHarvestConfirmStep