import './FlowsPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { getInventoryItemTypeById, selectAllInventoryItemTypes } from '../../../redux/entities/Inventory';
import { selectFlowNutrientReservoirsByFlowId } from '../../../redux/entities/service/Flow';
import { RoundToNearest } from '../../../helpers';
import DropDownButton from '../../../components/DropDownButton';
import Button from '../../../components/Button';
import { AdditionalOptions, HalfFullCup, Plus } from '../../../assets/icons/Icons';




const FlowInstance_ReservoirsContent = ({ flowId, topUpFlowReservoirCallback }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  const allInventoryItemTypes = useSelector((state) => selectAllInventoryItemTypes(state))
  const loadingItemTypesStatus = useSelector((state) => state.inventory.loadingItemTypesStatus)


  const flowNutrientReservoirs = useSelector((state) => selectFlowNutrientReservoirsByFlowId(state, flowId), _.isEqual)
  const [dosingReservoirs, SetDosingResevoirs] = React.useState({})
  React.useEffect(() => {

    let dosingReservoirs = {}
    for (let [index, info] of Object.entries(flowNutrientReservoirs)) {
      dosingReservoirs[index] = {...info}
      let referenceId = parseInt(info["reference_id"]);

      let foundInventoryItemType = allInventoryItemTypes.find((iT) => iT.id === referenceId)
      if (foundInventoryItemType !== undefined)  {
        dosingReservoirs[parseInt(index)]["item"] = foundInventoryItemType
      }
    }
  


    SetDosingResevoirs(dosingReservoirs)

  }, [flowNutrientReservoirs, allInventoryItemTypes])



  React.useEffect(() => {
    let inventoryItemTypesToLoad = []

    for (let [index, info] of Object.entries(dosingReservoirs)) {
      if (info.connected) {
        let referenceId = parseInt(info["reference_id"]);

        let foundInventoryItemType = allInventoryItemTypes.find((iT) => iT.id === referenceId)
        if (foundInventoryItemType === undefined)  {
          if (inventoryItemTypesToLoad.indexOf(referenceId) === -1)  {
            inventoryItemTypesToLoad.push(referenceId)
          }          
        }
      }
    }

    if (loadingItemTypesStatus === "idle" || loadingItemTypesStatus === "fulfilled")  {
      if (inventoryItemTypesToLoad.length > 0) {
        dispatch(getInventoryItemTypeById({itemTypes: inventoryItemTypesToLoad}))
      }
    }
  }, [dosingReservoirs, allInventoryItemTypes, loadingItemTypesStatus])

  
  
  const processReservoirAdditionalOptions = (reservoirInfo) =>  {
    let reservoirOptions = [
      {key: "change_contents", label: "Change Contents"},
      {key: "prime", label: "Prime"},
      {key: "set_volume", label: "Set Volume"},
      {key: "reinstall", label: "Reinstall"},
      {key: "deactivate", label: "Deactivate"},
    ]

    return reservoirOptions
  }
  
  const handleReservoirAdditionalOptionSelected = (reservoirIndex, option) =>  {
    switch (option) {
      case "change_contents":

        break
      case "prime":
        
        break
      case "set_volume":
      
        break
      case "reinstall":
      
        break
      default:
        break
    }
  }


  const handleReservoirStateColorAndStatus = (reservoirInfo) => {
    //console.log(reservoirInfo)
    let statusInfo = {}


    if (reservoirInfo["connected"] !== true) {
      statusInfo.activated = false
      statusInfo = {...statusInfo, color: "#3C3C3C", status: "Unused", borderColor: "#DDE3EB"}
      return statusInfo
    }
    statusInfo.activated = true

    if (reservoirInfo.runtime_information["installed"] !== 1) {
      statusInfo.installedState = false
      statusInfo = {...statusInfo, color: "#EC3C3C", status: "Not Installed", borderColor: "#EC3C3C"}
      return statusInfo
    }

    statusInfo.installedState = true

    if (reservoirInfo.runtime_information["primed"] !== 1) {
      statusInfo.primedState = false
      if (reservoirInfo.runtime_information["priming"] === 1) {
        statusInfo = {...statusInfo, color: "#E99E0B", status: "Priming", borderColor: "#E99E0B"}
        return statusInfo
      }
      statusInfo = {...statusInfo, color: "#E99E0B", status: "Not Primed", borderColor: "#E99E0B"}
      return statusInfo
    }

    statusInfo.primedState = true
    return {...statusInfo, color: "#4F5562", status: "Primed", borderColor: "#DDE3EB"}
  }



  let numberOfReservoirsPerRow = 2;
  if (isTablet) {
    numberOfReservoirsPerRow = 3;
  }else if (isDesktop && !isWideDesktop)  {
    numberOfReservoirsPerRow = 4;
  }else if (isWideDesktop) {
    numberOfReservoirsPerRow = 4;
  }


  return (<>
    <div className="FlowStatus-Flow-Reservoirs-List-Container">
      <div className={"FlowStatus-Flow-Reservoirs-List FlowStatus-Flow-Reservoirs-ListSize" + numberOfReservoirsPerRow}>
        {Object.entries(dosingReservoirs).map(([index, info]) => {
          let reservoirVolume = 0
          if (info["reservoir_volume"] !== undefined)  {
            reservoirVolume = parseFloat(info["reservoir_volume"]) / 1000;
          }
          let reservoirLowVolume = 0
          if (info["reservoir_low_volume"] !== undefined)  {
            reservoirLowVolume = parseFloat(info["reservoir_low_volume"]) / 1000;
          }

          let volumeRemaining = 0
          if (info["runtime_information"]["volume_remaining"] !== undefined)  {
            volumeRemaining = parseFloat(info["runtime_information"]["volume_remaining"]) / 1000;
          }

          let reservoirDisplayName = "-"
          if (info["item"]["name"] !== undefined) {
            reservoirDisplayName = info["item"]["name"]
          }

          const reservoirStatusInfo = handleReservoirStateColorAndStatus(info)


          let reservoirVolumeState = "Okay"
          if (reservoirStatusInfo.activated) {
            if (volumeRemaining == 0) {
              reservoirVolumeState = "Empty"
            }else if (volumeRemaining < reservoirLowVolume) {
              reservoirVolumeState = "Low"
            }
          }

          return (
            <div className="FlowStatus-Flow-Reservoirs-Reservoir-Container" key={index}>
              <div className="FlowStatus-Flow-Reservoirs-Reservoir" style={{borderColor: reservoirStatusInfo.borderColor}}>
                <div className="FlowStatus-Flow-Reservoirs-Reservoir-MainContent">

                  <div className="FlowStatus-Flow-Reservoirs-Reservoir-MainContent-Header">
                    <div>Reservoir {index}</div>
                    <div>
                      {reservoirStatusInfo.activated && <>
                        {RoundToNearest(volumeRemaining, 0.1)}L/{RoundToNearest(reservoirVolume, 0.1)}L
                      </>}
                      {!reservoirStatusInfo.activated && <>-</>}
                    </div>
                  </div>

                  <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Container">
                    <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Name">{reservoirDisplayName}</div>
                    <div className="FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Status" style={{color:reservoirStatusInfo.color}}>{reservoirStatusInfo.status}</div>
                  </div>

                  <div className="FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Container">
                    <div className={"FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background-" + reservoirVolumeState}></div>
                    {reservoirStatusInfo.activated && <>
                      <div className={"FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar-" + reservoirVolumeState}
                        style={{width: ((volumeRemaining / reservoirVolume) * 100) + "%"}}
                        ></div>
                      <div className="FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-LowVolumeIndicator"
                        style={{left: ((reservoirLowVolume / reservoirVolume) * 100) + "%"}}></div>
                    </>}
                  </div>

                  <div className="FlowStatus-Flow-Reservoirs-Reservoir-Functions-Container">
                    {reservoirStatusInfo.activated && <>
                      <Button 
                        content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><HalfFullCup/><span>Refill</span></div>} 
                        status="Small-IconAction" 
                        width={"Flex100"}
                        onClick={() => {topUpFlowReservoirCallback(info.index)}}/>
                    </>}
                    {!reservoirStatusInfo.activated && <>
                      <Button
                        content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><Plus width={14.5} fill={"#4D5563"}/><span>Activate</span></div>} 
                        status="Small-IconAction" 
                        width={"Flex100"}
                        onClick={() => {topUpFlowReservoirCallback(info.index)}}/>
                    </>}
                    <DropDownButton
                      content={<div style={{display:"flex", padding:"7px 0px"}}><AdditionalOptions/></div>} 
                      options={processReservoirAdditionalOptions(info)}
                      onOptionSelected={((option) => {
                        handleReservoirAdditionalOptionSelected(index, option.key)
                      })}/>
                  </div>
                </div>

              </div>
            </div>
          )
        })}
      </div>
    </div>
  </>)
}

export default FlowInstance_ReservoirsContent