import './GerminationStationInstance.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';


import { useDispatch, useSelector } from 'react-redux';
import { parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import { Play, PlusAlt, RSSI } from '../../../assets/icons/Icons';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import { selectAllAccountPrivileges } from '../../../redux/entities/Account';
import { TabControl, TabControlTab } from '../../../components/TabControl';
import DropDownInput from '../../../components/input/DropDownInput';
import { selectAllGerminationStationConfigurationMaps, selectGerminationStationActiveIssuesById, selectGerminationStationControlDeviceConnectedState, selectGerminationStationDisplayNameById, selectGerminationStationRuntimeInformation, setGerminationStationRuntimeProperty } from '../../../redux/entities/service/GerminationStation';
import GerminationStationInstance_StatusContent from './GerminationStationInstance_StatusContent';







const GerminationStationInstance = ({ germinationStationId, isDefaultExpanded }) => {

  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });





  const dispatch = useDispatch()

  const [managingLights, SetManagingLights] = React.useState(false)
  const manageLightsClicked = () => {
    SetManagingLights(true)
  }
  const closeManagingLights = () => {
    SetManagingLights(false)
  }

  const [viewingIssues, SetViewingIssues] = React.useState(false)
  const issuesClicked = () => {
    SetViewingIssues(true)
  }
  const closeViewGerminationStationIssues = () => {
    SetViewingIssues(false)
  }


  const accountPrivileges = useSelector((state) => selectAllAccountPrivileges(state), _.isEqual)
  const accountHasServicingPriviliges = (accountPrivileges["servicing"] !== undefined)

  const germinationStationConfigurationMaps = useSelector(selectAllGerminationStationConfigurationMaps, _.isEqual)

  const germinationStationDisplayName = useSelector((state) => selectGerminationStationDisplayNameById(state, germinationStationId), _.isEqual)
  const isGerminationStationOnline = useSelector((state) => selectGerminationStationControlDeviceConnectedState(state, germinationStationId), _.isEqual)


  const isRackPaused = false

  const [inServiceModeRuntimeInformation] = useSelector((state) => selectGerminationStationRuntimeInformation(state, germinationStationId, "service_mode"), _.isEqual)
  const inServiceMode = accountHasServicingPriviliges ? parseBool(inServiceModeRuntimeInformation) : false
 

  let subtitle = ""

  let additionalFunctions = [
    { key: "manage_lights", label: "Manage Lights", callback: manageLightsClicked },
    { key: "rename", label: "Rename Rack" },
    { key: "move", label: "Move Rack" },
    { key: "change_sides", label: "Change Sides" }]

 

  if (accountHasServicingPriviliges) {
    if (!inServiceMode) {
      additionalFunctions.push(...[
        {
          key: "activate_service_mode", label: "Turn On Service Mode", status: "Critical", callback: () => {
            dispatch(setGerminationStationRuntimeProperty({ germinationStationId: germinationStationId, properties: { "service_mode": 1 } }))
          }
        },
      ])
    } else {
      additionalFunctions.push(...[
        {
          key: "activate_service_mode", label: "Turn Off Service Mode", status: "Critical", callback: () => {
            dispatch(setGerminationStationRuntimeProperty({ germinationStationId: germinationStationId, properties: { "service_mode": 0 } }))
          }
        },
      ])
    }
  }

  additionalFunctions.push(...[
    { key: "delete", label: "Delete", status: "Critical" },
  ])


  let secondaryHeaderContent = null
  if (!isMobile) {
    secondaryHeaderContent = [
      {
        key: "pause_state", content:
          <div className="FlexContent-H-10">
            {isRackPaused &&
              <div className="FlexContent-H-20 FlexContent-Center">
                <span className="GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-State">Paused 16:34</span>

                <div className="GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-AddTimeButton"><span>Add Time</span></div>
              </div>
            }
            {/*<div className="FlexContent-H-10">
              {isRackPaused && <>
                <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
              </>}
              {!isRackPaused &&
                <Button status={"Small-IconAction"} state={false} content={<Pause/>}/>
              }
            </div>*/}
          </div>
      },
      { key: "rssi", content: <RSSI /> }
    ]
  } else {
    secondaryHeaderContent = <><RSSI /></>
  }

  let extendedHeaderContent = null
  if (isMobile && isRackPaused) {
    extendedHeaderContent = {
      content: <>
        <div className="FlexContent-H-10">
          <div className="FlexContent-H-10 FlexContent-Center">
            <div className="GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-AddTimeButton"><span>Paused 16:34</span><PlusAlt /></div>
          </div>
          <div className="FlexContent-H-10">
            <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"} />} />
          </div>
        </div>
      </>
    }
  } else {

  }


  const germinationStationActiveIssues = useSelector((state) => selectGerminationStationActiveIssuesById(state, germinationStationId), _.isEqual)
 


  const [expandedContentPage, SetExpandedContentPage] = React.useState("status")
  let tabSelected = (tab) => {
    SetExpandedContentPage(tab)
  }

  return (<>
    <RowGroupRow
      key={germinationStationId}
      title={germinationStationDisplayName}
      hasFlexCell={true}
      subtitle={<div className="GrowRacksStatus-VerticalRackGroup-Rack-Sides">{subtitle}</div>}
      showStatusIndicator={true}
      status={isGerminationStationOnline ? "Connected" : "Not Connected"}
      statusColor={isGerminationStationOnline ? "#4DBE3B" : "#f00"}
      hasIssues={true}
      numberOfIssues={Object.entries(germinationStationActiveIssues).length}
      issuesClicked={issuesClicked}
      customRowPadding={0}
      isExpandable={true}
      expandedState={isDefaultExpanded}
      hasAdditionalFunctions={true} 
      secondaryContent={secondaryHeaderContent}
      extendedHeaderContent={extendedHeaderContent}
      additionalFunctions={additionalFunctions}
      renderContentOnlyWhenOpen={true}
      content={<>
        <div style={{ display: "flex", flexDirection: "column", border: "1px solid #DDE3EB", gap: 0, borderRadius: 4, backgroundColor: "#FFF" }}>
          <div style={{ padding: "12px 20px 0px 20px" }}>
            {!isMobile && <>
              <TabControl
                onTabSelected={tabSelected}
                tabLocation={"flex"}
                tabs={[
                  new TabControlTab("Status", "status", "", expandedContentPage === "status"),
                  new TabControlTab("Configure", "configure", "", expandedContentPage === "configure"),
                ]}
              />
            </>}
            {isMobile && <>
              <DropDownInput
                status="Neutral"
                value={expandedContentPage}
                options={[
                  { key: 'status', label: 'Status' },
                  { key: 'configure', label: 'Configure' },
                ]}
                optionsKey="key"
                onSelectionChange={(action) => {
                  SetExpandedContentPage(action)
                }} />
            </>}
          </div>

          {expandedContentPage === "status" && <>
            <GerminationStationInstance_StatusContent germinationStationId={germinationStationId} />
          </>}

          {expandedContentPage === "configure" && <>
            Configure``
          </>}
        </div>
      </>} />
    {/*viewingIssues && <GerminationStationIssuesPopup germinationStationId={germinationStationId} closeCallback={closeViewGerminationStationIssues} />*/}
  </>)
}

export default GerminationStationInstance