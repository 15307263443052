import './PlantSeedsForGerminationTask.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../../../components/input/DropDownInput';
import TextInput from '../../../../components/input/TextInput';
import Tag from '../../../../components/Tag';
import { useDispatch, useSelector } from 'react-redux';
import { markTaskAsCompleted } from '../../../../redux/entities/Tasks';
import { selectAllGerminationStations } from '../../../../redux/entities/service/GerminationStation';

const PlantSeedsForGermination_LocationTrackingStep = ({
        task,
        allSeeds,
        activeGrow,
        activeGrowPlan,
        seedingPlantVariantId,
        seedingPlantVariantIndex,
        plantVariant,
        initialLocations,
        setVariantIndexCallback,
        seedingCompletedCallback,
        locationsConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const allGerminationChambers = useSelector((state) => selectAllGerminationStations(state))

    const dispatch = useDispatch()


    const [currentSelectedGerminationChamber, SetCurrentSelectedGerminationChamber] = React.useState(null)
    const germinationChamberSelected = (uid) =>  {
        SetCurrentSelectedGerminationChamber(uid)
    }

    const [currentLocation, SetCurrentLocation] = React.useState("")
    const currentLocationChanged = (value) =>   {
        SetCurrentLocation(value)
    }



    const [currentLocations, SetCurrentLocations] = React.useState({})
    React.useLayoutEffect(() => {
        SetCurrentLocations(initialLocations)
    }, [initialLocations])
    const addLocationClicked = () =>    {
        let chamberLocations = []
        if (currentLocations[currentSelectedGerminationChamber] !== undefined)  {
            chamberLocations = currentLocations[currentSelectedGerminationChamber]
        }
        if (chamberLocations.indexOf(currentLocation) === -1)   {
            chamberLocations.push(currentLocation)
        }
        SetCurrentLocations({...currentLocations, [currentSelectedGerminationChamber]: chamberLocations})
        SetCurrentLocation("")
    }

    const removeLocation = (growChamberUID, location) => {
        let chamberLocations = currentLocations[growChamberUID].filter(l => l !== location)
        if (chamberLocations.length === 0)  {
            let remainingLocations = currentLocations
            delete remainingLocations[growChamberUID]
            SetCurrentLocations(remainingLocations)
        }else {
            SetCurrentLocations({...currentLocations, [growChamberUID]: chamberLocations})
        }
    }



    const backClicked = () => {
        transitionToPreviousStep(["location_tracking_options", null])
    }

    const finishedClicked = () =>  {
        locationsConfirmedCallback({variantId: seedingPlantVariantId, locations: currentLocations})
        
        if (seedingPlantVariantIndex < activeGrowPlan.plant_variants.length - 1)  {
            setVariantIndexCallback(seedingPlantVariantIndex + 1)
            transitionToNextStep(["seeding", null])
        }else {
            seedingCompletedCallback()
            transitionToNextStep(["seeding_list", null])
        }
    }



    let germinationChamberOptions = allGerminationChambers.map((gC) => {
        return {value: gC.uid, label: gC.display_name}
    })
    


    
    let growSeed = null
    if (plantVariant !== undefined) {
        growSeed = allSeeds.find((s) => s.id === plantVariant.seed_id)
    }
    

    let isLocationValid = (currentSelectedGerminationChamber && currentLocation !== "") ? true : false
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    {(growSeed !== undefined) && <>
                        Enter the locations of all of the {growSeed.name} seedling boards
                    </>}
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "0 10px" : "0 100px"}}>
                        <div className={isMobile ? "FlexContent-10" : "FlexContent-20"} style={{flex: 1}}>
                            <div className="FlexContent-H-10" style={{alignSelf:"flex-start"}}>
                                <div className="Text-FormHeader-Medium">Enter Locations of all of the Germination Boards</div>
                            </div>
                            <div className="FlexContent-10">
                                <div className="">
                                    <div className="Text-FormAltLabel-Small" style={{paddingBottom:2}}>Germination Chamber</div>
                                    <DropDownInput 
                                        options={germinationChamberOptions} 
                                        placeholder={"Select chamber"}
                                        onSelectionChange={germinationChamberSelected}/>
                                </div>
                                <div className="">
                                    <div className="Text-FormAltLabel-Small" style={{paddingBottom:2}}>Location</div>
                                    <TextInput 
                                        placeHolder={"Enter Location"} 
                                        submitButton={<div className="Text-Hyperlink" 
                                        style={{opacity: isLocationValid ? 1 : 0.4}}>Add</div>} 
                                        value={currentLocation}
                                        onChange={currentLocationChanged}
                                        onSubmit={addLocationClicked}/>
                                </div>
                                <div className="FlexContent-10">
                                    {Object.entries(currentLocations).map(([growChamberUID, locations]) => {
                                        let germinationChamber = allGerminationChambers.find((gC) => gC.uid === growChamberUID)
                                        return <>
                                            <div className="FlexContent-5">
                                                <div className="Text-FormAltLabel-Small" style={{fontWeigth:400}}>{germinationChamber.display_name}</div>
                                                <div className="FlexWrap FlexWrap-Size5" style={{flexGrow: 0}}>
                                                    {locations.map((location) => {
                                                        const removeLocationClicked = () => {
                                                            removeLocation(growChamberUID, location)
                                                        }
                                                        return (
                                                            <Tag canRemove={true} onRemove={removeLocationClicked} content={location} status={"Neutral"}/>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </>
                                    })}
                                    {Object.entries(currentLocations).length === 0 &&                                 
                                        <div className="FlexContent-H-5">
                                            <Tag content={"---"} status={"Neutral"}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Done"} onClick={finishedClicked} size={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default PlantSeedsForGermination_LocationTrackingStep