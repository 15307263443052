import '../FlowsPage.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore'; 
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button'; 
import { AdditionalOptions, Pause, Play, TrendDownArrow, TrendUpArrow } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { selectFlowComponentInfo, selectFlowLiveDataByComponentInfo } from '../../../../redux/entities/service/Flow';


const FlowInstance_StatusContent_ORPSensorCell = ({ flowId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  
  const [leftSensorORPComponentInfo, rightSensorORPComponentInfo, activeORPComponentInfo] 
  = useSelector((state) => selectFlowComponentInfo(state, flowId, "LiquidORPSensor", ["input-1", "input-2", "orp"]), _.isEqual)
  const leftSensorORP = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, leftSensorORPComponentInfo), _.isEqual)
  const rightSensorORP = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, rightSensorORPComponentInfo), _.isEqual)
  const activeORP = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, activeORPComponentInfo), _.isEqual)
  
  let dataListItems = [
    {label: "Active ORP", value: activeORP.value, suffix: "mV", visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 1000, status: "normal"}
    }},  
    {label: "Left ORP", value: leftSensorORP.value, suffix: "mV", visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 1000, status: "normal"}
    }},  
    {label: "Right ORP", value: rightSensorORP.value, suffix: "mV", visualization: {
      type: "bar", info: {usesRange: true, min: 0, max: 1000, status: "normal"}
    }},  
  ]

  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">ORP Sensor</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} /> 
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default FlowInstance_StatusContent_ORPSensorCell