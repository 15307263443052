import { current } from '@reduxjs/toolkit';
import { LeftArrow } from '../../assets/icons/Icons';
import './KeypadInput.scss';

import * as React from 'react';



export const KeypadInput_Keypad = ({value, cursorIndex, resolution, disabled, buttonHorizontalPadding, buttonVerticalPadding, buttonSize, onChange, inputFieldRef}) => {
  const [loadedInitialValue, SetLoadedInitialValue] = React.useState(true)
  const [currentValue, SetCurrentValue] = React.useState("")
  const [currentCursorIndex, SetCurrentCursorIndex] = React.useState(0)

  const [haveInitialValue, SetHaveInitialValue] = React.useState(null)
  const updateInitialValues = () => {
    if (value <= 0) {
      SetCurrentValue("")
      SetCurrentCursorIndex(0)
    }else {
      SetCurrentValue(value.toString())
      SetCurrentCursorIndex(value.toString().length)
    }
    SetHaveInitialValue(value)
  }
  React.useLayoutEffect(() => {
    updateInitialValues()
  }, [])
  React.useLayoutEffect(() => {
    if (value !== haveInitialValue) {
      updateInitialValues()
    }
    if (!loadedInitialValue)  {
      if (value <= 0)  {
        //SetCurrentValue("")
        //SetCurrentCursorIndex(0)
      }else {
        //SetCurrentValue(value.toString())
        //SetCurrentCursorIndex(value.toString().length)
      }
      SetLoadedInitialValue(true)
    }
  }, [value, loadedInitialValue])
  


  const [activeInputFieldRef, SetActiveInputFieldRef] = React.useState(undefined)
  /*React.useLayoutEffect(() => {
    if (activeInputFieldRef !== undefined)  {
      if (activeInputFieldRef != inputFieldRef) {
        SetActiveInputFieldRef(inputFieldRef)
        //SetLoadedInitialValue(false)  
      }
    }else {
      SetActiveInputFieldRef(inputFieldRef)
      //SetLoadedInitialValue(false)
    }
  }, [activeInputFieldRef, inputFieldRef])*/

  React.useEffect(() => {
    if (inputFieldRef !== activeInputFieldRef)  {
      SetActiveInputFieldRef(inputFieldRef)
      SetLoadedInitialValue(false)
      return
    }
    if (activeInputFieldRef !== undefined)  {
      activeInputFieldRef.setValue(currentValue)
      activeInputFieldRef.setCursorIndex(currentCursorIndex)
    }

    if (loadedInitialValue && onChange !== undefined)  {
      //console.log(currentValue)
      if (currentValue !== "")  {
        onChange(currentValue)
      }else {
        onChange(0)
      }
    }
  }, [currentValue, currentCursorIndex, inputFieldRef, loadedInitialValue])


  
  const normalKeyPressed = (key) => {
    if (key === "." && currentValue.indexOf(".") !== -1) {
      return
    }

    const valueAsString = currentValue.toString()
    if (valueAsString.length > 0) {
      SetCurrentValue(valueAsString.substring(0, currentCursorIndex) + key.toString() + valueAsString.substring(currentCursorIndex, valueAsString.length))
      SetCurrentCursorIndex(currentCursorIndex + 1)
    }else {
      SetCurrentValue(key.toString())
      SetCurrentCursorIndex(1)
    }
  }
  const backspacePressed = () => {
    const valueAsString = currentValue.toString()
    if (valueAsString.length > 0) {
      SetCurrentValue(valueAsString.slice(0, currentCursorIndex - 1) + valueAsString.slice(valueAsString.length))
      SetCurrentCursorIndex(currentCursorIndex - 1)
    }
  }

  let keypadProps = {style:{}}
  let keypadRowProps = {style:{}}
  

  let buttonProps = {style:{}}
  if (buttonSize === "normal")  {
    buttonProps.style.padding = buttonVerticalPadding + "px " + buttonHorizontalPadding + "px"
  }else if (buttonSize === "flex")  {
    keypadProps.style.flex = 1
    keypadRowProps.style.flex = 1
    buttonProps.style.flex = 1
  }
  return (
      <>
        <div className={"KeypadInput_Keypad" + (disabled ? " KeypadInput_Keypad-Disabled" : "")} {...keypadProps}>
          <div className="KeypadInput_Keypad-KeyRow" {...keypadRowProps}>
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(1)}} {...buttonProps}>
              1
            </div>
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(2)}} {...buttonProps}>
              2
            </div>
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(3)}} {...buttonProps}>
              3
            </div>
          </div>
          <div className="KeypadInput_Keypad-KeyRow">
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(4)}} {...buttonProps}>
              4
            </div>
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(5)}} {...buttonProps}>
              5
            </div>
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(6)}} {...buttonProps}>
              6
            </div>
          </div>
          <div className="KeypadInput_Keypad-KeyRow">
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(7)}} {...buttonProps}>
              7
            </div>
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(8)}} {...buttonProps}>
              8
            </div>
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(9)}} {...buttonProps}>
              9
            </div>
          </div>
          <div className="KeypadInput_Keypad-KeyRow">
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-BackspaceKey" onClick={() => {backspacePressed()}} {...buttonProps}>
              <LeftArrow/>
            </div>
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(0)}} {...buttonProps}>
              0
            </div>
            <div className="KeypadInput_KeyPad-Key KeypadInput_KeyPad-DefaultKey" onClick={() => {normalKeyPressed(".")}} {...buttonProps}>
              .
            </div>
          </div>
        </div>
      </>
  )
} 

KeypadInput_Keypad.defaultProps = {
  value: 0,
  cursorIndex: 0,
  disabled: false,
  buttonHorizontalPadding: 40,
  buttonVerticalPadding: 20,
  buttonSize: "normal"
}






export const KeypadInput_DisplayField = ({value, cursorIndex, placeholderValue, verticalPadding, suffix, active, disabled, inputFieldRefCb, onFocus}) => {
  
  const [currentValue, SetCurrentValue] = React.useState("")
  React.useLayoutEffect(() => {
    SetCurrentValue(value)
  }, [value])
  const [currentCursorIndex, SetCurrentCursorIndex] = React.useState(0)
  React.useLayoutEffect(() => {
    SetCurrentCursorIndex(cursorIndex)
  }, [cursorIndex])


  const [focusState, SetFocusState] = React.useState(false)
  React.useLayoutEffect(() => {
    SetFocusState(active)
  }, [active])


  const [currentValuePre, SetCurrentValuePre] = React.useState("")
  const [currentValuePost, SetCurrentValuePost] = React.useState("")
  const [showValuePlaceholder, SetShowValuePlaceholder] = React.useState(false)

  React.useEffect(() => {
    let valueString = currentValue.toString()
    if (valueString === "") {
      SetCurrentValuePre("")
      SetCurrentValuePost("")
      SetShowValuePlaceholder(true)
    }else if (valueString.length === 1 || currentCursorIndex === -1) {
      SetCurrentValuePre(valueString)
      SetCurrentValuePost("")
      SetShowValuePlaceholder(false)
    }else {
      SetCurrentValuePre(valueString.substr(0, currentCursorIndex))
      SetCurrentValuePost(valueString.substr(currentCursorIndex, valueString.length))
      SetShowValuePlaceholder(false)
    }
  }, [currentValue, currentCursorIndex])

  const externalMethods = {
    setValue: (value) => {
      SetCurrentValue(value)
    },
    setCursorIndex: (index) => {
      SetCurrentCursorIndex(index)
    }
  }

  React.useEffect(() => {
    if (inputFieldRefCb !== undefined)  {
      inputFieldRefCb(externalMethods)
    }
  }, [inputFieldRefCb])

  const handleFocus = (e) => {
    if (disabled) {
      SetFocusState(false)
      e.preventDefault()
      e.stopPropagation()
      return;
    }
    if (!focusState)  {
      SetFocusState(true)
    }
    if (onFocus !== undefined)  {
      onFocus()
    }
  }



  const displayFieldProps = {style:{paddingTop: verticalPadding, paddingBottom: verticalPadding}}


  return (
      <>
        <div 
          className={"KeypadInput_DisplayField" + (active ? " KeypadInput_DisplayField-Active" : "") + (disabled ? " KeypadInput_DisplayField-Disabled" : "")} 
          onClick={handleFocus}
          {...displayFieldProps}>

          <div className="KeypadInput_DisplayField-Value-Container">
            <div className="KeypadInput_DisplayField-Value-Content">
              {showValuePlaceholder && 
                <div className="KeypadInput_DisplayField-Value-PlaceholderDisplay">
                  {placeholderValue}
                </div>
              }
              <div className="KeypadInput_DisplayField-Value-Display">{currentValuePre}</div>
              {(focusState && cursorIndex !== -1) &&
                <div className="KeypadInput_DisplayField-Value-Caret"></div>
              }
              <div className="KeypadInput_DisplayField-Value-Display">{currentValuePost}</div>
              
            </div>
            {suffix && 
              <div className="KeypadInput_DisplayField-Suffix">
                {suffix}
              </div>
            }
          </div>
        </div>
      </>
  )
} 

KeypadInput_DisplayField.defaultProps = {
  value: 0,
  cursorIndex: 0,
  placeholderValue: 0,
  resolution: 0.01,
  maxLength: 10,
  verticalPadding: 14,
  suffix: false,
  active: false,
  disabled: false,
}