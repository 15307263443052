import './RowGroupSubGroup.scss';

import * as React from 'react';
import { AdditionalOptions, CollapseContent, ExpandContent, Gear, SoftWarning } from '../../assets/icons/Icons';
import Button from '../Button';
import { useMediaQuery } from 'react-responsive';
import DropDownButton from '../DropDownButton';
import useMeasure from '../../useMeasure';
import { useSpring, animated, easings, config } from 'react-spring';





const RowGroupSubGroup = ({
                  title, titleFontSize, showNumRows, numRowsDisplay, emptyRowsDisplay,
                  rows, showRowSeperators, emptyRowsContent, secondaryContent,
                  isCollapsible,
                  expandedState, onContentExpandChanged,
                  hasAdditionalFunctions,
                  additionalFunctions, secondaryContentTriggersExpandToggle,
                  hasIssues, numberOfIssues, customIssuesContent,
                }) => {


  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop
  

  const [isExpanded, SetIsExpanded] = React.useState(true)
  const [lastExpandedState, SetLastExpandedState] = React.useState(false)
  React.useLayoutEffect(() => {
    SetIsExpanded(expandedState)
  }, [expandedState])
  const toggleExpandedState = (state) =>  {
    SetIsExpanded(state)
    if (onContentExpandChanged !== undefined) {
      onContentExpandChanged(state)
    }
  }

  const [rowContentHandleRef, {height: rowContentHeight}] = useMeasure()


  const [isExpandedStateChanging, SetIsExpandedStateChanging] = React.useState(false)
  React.useLayoutEffect(() => {
    if (isExpanded !== lastExpandedState) {
      SetLastExpandedState(isExpanded)
      if (isExpanded) {
        toggleSubgroupExpandAnimationAPI.start({
          from: { height: 0},
          to: { height: rowContentHeight},
          config: {duration: 150},
          easing: easings.easeInOutQuart,
          onRest: () => { 
            SetIsExpandedStateChanging(false)
          }
        })
        SetIsExpandedStateChanging(true)
      }else {
        toggleSubgroupExpandAnimationAPI.start({
          from: { height: rowContentHeight},
          to: { height: 0},
          config: {duration: 150},
          easing: easings.easeInOutQuart,
          onRest: () => { 
            SetIsExpandedStateChanging(false)
          }
        })
        SetIsExpandedStateChanging(true)
      }
    }
  }, [isExpanded, lastExpandedState, rowContentHeight])
  

  /*const [nextCardTransitionToCurrentCardAnimation, nextCardTransitionToCurrentCardAnimationAPI] = useSpring(() => ({
    from: { left: walkthroughContentWidth },
  }))*/

  const [toggleSubgroupExpandAnimation, toggleSubgroupExpandAnimationAPI] = useSpring(() => ({}))



  const subgroupContentProps = {}
  if (isExpandedStateChanging)  {
    subgroupContentProps.style = toggleSubgroupExpandAnimation
  }else {
    subgroupContentProps.style = {height:isExpanded ? rowContentHeight : 0}
  }
    
  return (<>
    <div className={"RowGroupSubGroup-Container" + (isExpanded ? " RowGroupSubGroup-Container-Expanded" : "")}>
      <div className={"RowGroupSubGroup"}>
        <div className={"RowGroupSubGroup-Header"}>
          <div className="RowGroupSubGroup-Header-Content"  onClick={() => toggleExpandedState(!isExpanded)}>
            <div className="RowGroupSubGroup-Header-PrimaryContent">
              {isCollapsible && 
                <div className="RowGroupSubGroup-Header-ExpandedContentToggle">
                  {isExpanded && <CollapseContent width={8}/>}
                  {!isExpanded && <ExpandContent  width={8}/>}
                </div>
              }
              <div className="RowGroupSubGroup-Header-StatusContent" style={{paddingLeft: isCollapsible ? 0 : 20}}>
                <div className="RowGroupSubGroup-Header-StatusContent-Title">
                  <div className="RowGroupSubGroup-Header-StatusContent-Title-Content noselect">
                    {title}
                  </div>
                </div>
                      
                {showNumRows && 
                  <div className="RowGroupSubGroup-Header-StatusContent-NumRows">
                    <div className="RowGroupSubGroup-Header-StatusContent-NumRows-Content noselect">
                      {rows.length === 0 && <>
                        {emptyRowsDisplay}
                      </>}
                      {rows.length > 0 && <>
                        {rows.length} {numRowsDisplay}                      
                      </>}
                    </div>
                  </div>
                }
              </div>
            </div>
          
            {hasAdditionalFunctions &&
              <div className="RowGroupSubGroup-Header-AdditionalFunctions">
                <div className="RowGroupSubGroup-Header-CellWrapper">
                  <div className="RowGroupSubGroup-Header-AdditionalFunctions-Button">
                    <DropDownButton content={<div style={{display:"flex", padding:"7px 0px"}}><AdditionalOptions/></div>} options={additionalFunctions}/>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>



        <animated.div className="RowGroupSubGroup-Content-Container RowGroupSubGroup-Content-Container-Expandable" {...subgroupContentProps}>
          <div className="RowGroupSubGroup-Content" ref={rowContentHandleRef}>
            {rows.length > 0 &&  <>
              {(() => {
                let returnList = []
                rows.map((r, index) => {
                  returnList.push(r)
                  if (showRowSeperators && index !== rows.length - 1)  {
                    returnList.push(
                      <div key={"s-" + index.toString()} style={{margin:"0 20px", height:1, backgroundColor: "#DDE3EB"}}></div>
                    )
                  }
                })

                return(<>
                  {returnList.map((r) => {
                    return r
                  })}
                </>)
              })()}
              
            </>}
            {rows.length === 0 && <>
              <div className="RowGroupSubGroup-EmptyContent" ref={rowContentHandleRef}>
                {emptyRowsContent}
              </div>
            </>}
          </div>
        </animated.div>
      
      </div>
    </div>
  </>)
} 

RowGroupSubGroup.defaultProps = {
  title: "",
  rows: [],
  showRowSeperators: true,
  showNumRows: true,
  numRowsDisplay: <>Items</>,
  emptyRowsDisplay: <>No Items Found</>,
  secondaryContent: [],
  secondaryContentTriggersExpandToggle: false,
  hasFlexCell: false,
  isCollapsible: true,
  expandedState: false,
  hasAdditionalFunctions: false,
  additionalFunctions: [],
  hasIssues: false,
  numberOfIssues: 0,
  customIssuesContent: false,
  columnSizes: {}
}

export default RowGroupSubGroup