import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'
import { getAccountSessionData } from '../../pages/Account/Common'
import { useSelector, useDispatch } from 'react-redux'
import React from 'react'

const processTaskFromAPI = (task) =>  {
  if (task.completed) {
    task.status = "Completed"
  }
  task.startDate = new Date(task.scheduled_for).getTime()
  /*task.endDate = new Date(task.end_date).getTime()
  task.dueDate = task.endDate
  task.duration = task.endDate - task.startDate*/
  if (task.duration < 3600) {
    task.duration = 3600
  }
  //task.key = task.id
  return task
}

export const getAllTasks = createAsyncThunk('tasks/getAllTasks', async ({}, { getState }) => {
  return await FetchPost(APIRepository.Tasks.GetAllTasksForAccount, {
    ...getAccountSessionData(getState()),
    have_tasks: {}
  })  
})



export const getTaskById = createAsyncThunk('tasks/getTaskById', async ({taskId}, { getState }) => {
  return await FetchPost(APIRepository.Tasks.GetTaskById, {
    ...getAccountSessionData(getState()),
    task_id: taskId,
  })  
},
{
  condition: (args, { getState }) => {
    const { tasks } = getState()
    if (tasks.loadingTasksStatus === 'pending') {
      return false
    }
  },
})



export const getTasksByCategoryAndReferenceId = createAsyncThunk('tasks/getTasksByCategoryAndReferenceId', async ({category, referenceId}, { getState }) => {
  return await FetchPost(APIRepository.Tasks.GetTasksByCategoryAndReferenceId, {
    ...getAccountSessionData(getState()),
    category: category,
    ref_id: referenceId
  })  
},
{
  condition: (args, { getState }) => {
    const { tasks } = getState()
    if (tasks.loadingTasksStatus === 'pending') {
      return false
    }
  },
})


export const markTaskAsStarted = createAsyncThunk('tasks/markTaskAsStarted', async ({taskId, callback}, { getState }) => {
  return await FetchPost(APIRepository.Tasks.MarkTaskAsStarted, {
    ...getAccountSessionData(getState()),
    task_id: taskId
  })
})


export const completeTaskStep = createAsyncThunk('tasks/completeTaskStep', async ({taskId, step, params}, { getState }) => {
  return await FetchPost(APIRepository.Tasks.CompleteTaskStep, {
    ...getAccountSessionData(getState()),
    task_id: taskId,
    step: step,
    params: params
  })
})

export const setTaskStep = createAsyncThunk('tasks/setTaskStep', async ({taskId, step, params}, { getState }) => {
  return await FetchPost(APIRepository.Tasks.SetTaskStep, {
    ...getAccountSessionData(getState()),
    task_id: taskId,
    step: step,
    params: params
  })
})

export const markTaskAsCompleted = createAsyncThunk('tasks/markTaskAsCompleted', async ({taskId, params, callback}, { getState }) => {
  return await FetchPost(APIRepository.Tasks.MarkTaskAsCompleted, {
    ...getAccountSessionData(getState()),
    task_id: taskId,
    params: params
  })
})

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasks:  [

    ],
    loadedTasksForCategoryAndReferenceIds: {},
    status: 'idle',
    loadingTasksStatus: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false
  },
  reducers: {
    
    setTaskLayoutBounds: (state, action) =>  {
      
      let hasChanged = false
      let newTasks = { ...state, tasks: state.tasks.map((task) => {
        if (task.id !== action.payload.taskId) {
          return task
        }
    
        if (task.bounds === undefined || task.bounds.y !== action.payload.bounds.y || task.bounds.height !== action.payload.bounds.height)  {
          hasChanged = true
        }
        return {
          ...task,
          bounds: action.payload.bounds
        }
      })}

      if (hasChanged) {
        return newTasks
      }
    },

    taskUpdate: (state, action) => {
      let hasChanged = false
      let newTasks = { ...state, tasks: state.tasks.map((task, index) => {
        if (task.id !== action.payload.taskId) {
          return task
        }
    
        if (task[action.payload.prop] === undefined || task[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...task,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newTasks
      }
    }
  },
  extraReducers: {
    [getAllTasks.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllTasks.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      action.payload.tasks.map(function(task){ processTaskFromAPI(task); return task });
      state.tasks = action.payload.tasks;
    },

    [getAllTasks.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getTaskById.pending]: (state) => {
      state.loadingTasksStatus = 'pending';
    },

    [getTaskById.fulfilled]: (state, action) => {
      state.loadingTasksStatus = 'fulfilled';

      if (action.payload.task !== null)  {
        let foundTask = false
        for (let taskIndex in state.tasks) {
          if (state.tasks[taskIndex].id === action.payload.task.id)  {
            //Get the UID
            
            state.tasks[taskIndex] = processTaskFromAPI(action.payload.task)
            foundTask = true
            break
          }
        }
        
        if (!foundTask)  {
          state.tasks.push(processTaskFromAPI(action.payload.task))              
        }
      }
      
    },

    [getTaskById.rejected]: (state) => {
      state.loadingTasksStatus = 'rejected';
    },


    
    [getTasksByCategoryAndReferenceId.pending]: (state) => {
      state.loadingTasksStatus = 'pending';
    },

    [getTasksByCategoryAndReferenceId.fulfilled]: (state, action) => {
      state.loadingTasksStatus = 'fulfilled';
     
      if (action.payload.tasks !== null)  {
        if (state.loadedTasksForCategoryAndReferenceIds[action.meta.arg.category] === undefined)  {
          state.loadedTasksForCategoryAndReferenceIds[action.meta.arg.category] = []
        }
        state.loadedTasksForCategoryAndReferenceIds[action.meta.arg.category].push(action.meta.arg.referenceId)

        for (let task of action.payload.tasks) {
          let foundTask = false
          for (let taskIndex in state.tasks) {
            if (state.tasks[taskIndex].id === task.id)  {
              //Get the UID
              
              state.tasks[taskIndex] = processTaskFromAPI(task)
              foundTask = true
              break
            }
          }
          
          if (!foundTask)  {
            state.tasks.push(processTaskFromAPI(task))              
          }
        }
      }
    },

    [getTasksByCategoryAndReferenceId.rejected]: (state) => {
      state.loadingTasksStatus = 'rejected';
    },
    

    [markTaskAsStarted.pending]: (state) => {
      state.status = 'pending';
    },

    [markTaskAsStarted.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      let foundTask = state.tasks.find((t) => t.id === action.meta.arg.taskId)
      if (foundTask !== undefined)  {
        foundTask.started = true
      }
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true)
      }
    },

    [markTaskAsStarted.rejected]: (state, action) => {
      state.status = 'rejected';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },


    [setTaskStep.pending]: (state) => {
      state.status = 'pending';
    },

    [setTaskStep.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      let foundTask = state.tasks.find((t) => t.id === action.meta.arg.taskId)
      if (action.payload.success) {
        foundTask.current_step = action.meta.arg.step
      }
    },

    [setTaskStep.rejected]: (state) => {
      state.status = 'rejected';
    },

    
    [completeTaskStep.pending]: (state) => {
      state.status = 'pending';
    },

    [completeTaskStep.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      let foundTask = state.tasks.find((t) => t.id === action.meta.arg.taskId)
      if (action.payload.success && action.payload.step) {
        foundTask.current_step = action.payload.step
      }
    },

    [completeTaskStep.rejected]: (state) => {
      state.status = 'rejected';
    },


    [markTaskAsCompleted.pending]: (state) => {
      state.status = 'pending';
    },

    [markTaskAsCompleted.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      let foundTask = state.tasks.find((t) => t.id === action.meta.arg.taskId)
      if (foundTask !== undefined)  {
        foundTask.completed = true
      }
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true)
      }
  },

    [markTaskAsCompleted.rejected]: (state, action) => {
      state.status = 'rejected';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
  }
    
  }
})


export const MaintainTasksForCategoryAndReferenceId = ({category, referenceId}) => {
  const loadingTasksStatus = useSelector((state) => state.tasks.loadingTasksStatus)
  const loadedTasksForCategoryAndReferenceIds = useSelector((state) => state.tasks.loadedTasksForCategoryAndReferenceIds)

  const dispatch = useDispatch()
  React.useLayoutEffect(() => {
    if (loadingTasksStatus !== "pending") {
      if (loadedTasksForCategoryAndReferenceIds[category] === undefined || loadedTasksForCategoryAndReferenceIds[category].indexOf(referenceId) === -1) {
        dispatch(getTasksByCategoryAndReferenceId({category: category, referenceId: referenceId}))
      }
    }
  }, [category, referenceId, loadedTasksForCategoryAndReferenceIds, loadingTasksStatus])
}



// Action creators are generated for each case reducer function
export const { taskUpdate, setTaskLayoutBounds} = tasksSlice.actions

export default tasksSlice.reducer

export const selectAllTasks = state => state.tasks.tasks

export const selectTaskById = (state, taskId) =>
  state.tasks.tasks.find(task => task.id === taskId)


export const selectTasksByCategoryAndReferenceId = (state, category, refId) =>
  state.tasks.tasks.filter(task => task.category === category && task.reference_id === refId)

export const selectTaskNameById = (state, taskId) => {
  const foundTask = state.tasks.tasks.find(task => task.id === taskId)
  if (foundTask !== undefined) {
    return foundTask.name
  }
  return "Unnamed Task"
}