import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { ProcessSuffixForUnit, ProcessValueForUnit, RoundToNearest } from '../../../../helpers';
import { KeypadInput_DisplayField, KeypadInput_Keypad } from '../../../../components/input/KeypadInput';
import DropDownInput from '../../../../components/input/DropDownInput';
import { useDispatch, useSelector } from 'react-redux';
import { selectFlowRuntimeInformation, setFlowRuntimeProperty } from '../../../../redux/entities/service/Flow';

  

const ConfirmFinalReservoirVolumeWalkthroughStep = ({flowId, selectedUnit, selectedUnitChanged, reservoirInfo, topUpType, expectedReservoirVolume, transitionToPreviousStep, transitionToNextStep, finalReservoirVolumeCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const dispatch = useDispatch()
    const reservoirRuntimeInformationKey = "dosing_reservoir_info_" + reservoirInfo["index"].toString()
    const [, , pendingReservoirInfoUpdatesString] = useSelector((state) => selectFlowRuntimeInformation(state, flowId, reservoirRuntimeInformationKey))


    const [finalReservoirVolume, setFinalReservoirVolume] = React.useState(() => {
        return RoundToNearest(ProcessValueForUnit(parseFloat(expectedReservoirVolume), selectedUnit, "litres"), 0.01)
    })


    const [currentSelectedUnit, SetCurrentSelectedUnit] = React.useState(selectedUnit)
    React.useLayoutEffect(() => {
        if (currentSelectedUnit !== selectedUnit)   {
            setFinalReservoirVolume(RoundToNearest(ProcessValueForUnit(finalReservoirVolume, selectedUnit, currentSelectedUnit), 0.01))
            SetCurrentSelectedUnit(selectedUnit)
        }
    }, [finalReservoirVolume, selectedUnit])

    
    const reservoirVolumeChanged = (value) => {
        setFinalReservoirVolume(parseFloat(value))
    }

    const availableUnits = [
            {"value": "litres", "label": "Litres"},
            {"value": "gallons", "label": "Gallons"},
    ]
    const unitChanged = (unit) => {
        if (selectedUnitChanged !== undefined)  {
            selectedUnitChanged(unit)
        }
    }
    


    const mixType = reservoirInfo["mix_type"]
    const backClicked = () => {
        if (mixType === "solids")   {
            if (topUpType === "within_reservoir")   {
                transitionToPreviousStep(["confirm_internal_mix", null])        
            }else if (topUpType === "external_reservoir") {
                transitionToPreviousStep(["confirm_external_mix", null])        
            }
        }else if (mixType === "liquid")    {
            transitionToPreviousStep(["confirm_reservoir_solution_topup", null])        
        }
    }
    const [isApplyingFinalVolume, SetIsApplyingFinalVolume] = React.useState(false)
    const nextClicked = () =>   {
        if (!isApplyingFinalVolume) {
            SetIsApplyingFinalVolume(true)
            let actualFinalReservoirVolume = ProcessValueForUnit(parseFloat(finalReservoirVolume), "litres", selectedUnit)
            //dispatch(setFlowRuntime)
            let pendingReservoirInfoUpdates = {}
            if (pendingReservoirInfoUpdatesString !== "")   {
                pendingReservoirInfoUpdates = JSON.parse(pendingReservoirInfoUpdatesString)
            }
            pendingReservoirInfoUpdates["volume_remaining"] = actualFinalReservoirVolume * 1000
            dispatch(setFlowRuntimeProperty({flowId: flowId, properties: {
                [reservoirRuntimeInformationKey]: JSON.stringify({...pendingReservoirInfoUpdates})
            }, callback: (success) => {
                SetIsApplyingFinalVolume(false)
                if (success)    {
                    finalReservoirVolumeCallback(actualFinalReservoirVolume)
                    transitionToNextStep(["confirm_reservoir_reinstall", null])
                }else {
                    //something went wrong
                }
            }}))
        }
    }

    
    const keypadInputFieldRef = React.useRef()
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Final Volume</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    
                    {isMobile && <>
                        <div className="FlexContent-20 FlexContent-HFill FlexContent-HFlex">
                            <div style={{textAlign:"center", fontSize:18, margin: "0 10px"}}>What is the new volume of the reservoir?</div>
                            <div className="FlexContent-10 FlexContent-Center">
                                <KeypadInput_DisplayField value={finalReservoirVolume} resolution={0.1} maxLength={8} suffix={ProcessSuffixForUnit(selectedUnit)} active={true} verticalPadding={14} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                <DropDownInput value={selectedUnit} options={availableUnits} onSelectionChange={unitChanged}/>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={finalReservoirVolume} 
                                    buttonVerticalPadding={5} 
                                    buttonHorizontalPadding={10}
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={reservoirVolumeChanged}/>
                            </div>
                        </div>
                    </>}
                    {isTablet && <>
                        <div className="FlexContent-20 FlexContent-Center">
                            <div className="FlexContent-40" style={{margin:"20px 0"}}>
                                <div style={{textAlign:"center", fontSize:24}}>What is the new volume of the reservoir?</div>
                                <div className="FlexContent-10 FlexContent-Center">
                                    <KeypadInput_DisplayField value={finalReservoirVolume} resolution={0.1} maxLength={8} suffix={ProcessSuffixForUnit(selectedUnit)} verticalPadding={15} active={true} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                    <DropDownInput value={selectedUnit} options={availableUnits} onSelectionChange={unitChanged}/>
                                </div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={finalReservoirVolume} 
                                    buttonVerticalPadding={15} 
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={reservoirVolumeChanged}/>
                            </div>
                        </div>
                    </>} 
                    {(!isTablet && !isMobile) && <>
                        <div className="FlexContent-H-40">
                            <div className="FlexContent-40" style={{margin:"20px 0"}}>
                                <div style={{textAlign:"center", fontSize:24}}>What is the new volume of the reservoir?</div>
                                <div className="FlexContent-10 FlexContent-Center">
                                    <KeypadInput_DisplayField value={finalReservoirVolume} resolution={0.1} maxLength={8} suffix={ProcessSuffixForUnit(selectedUnit)} active={true} verticalPadding={30} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                    <DropDownInput value={selectedUnit} options={availableUnits} onSelectionChange={unitChanged}/>
                                </div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={finalReservoirVolume} 
                                    buttonVerticalPadding={15} 
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={reservoirVolumeChanged}/>
                            </div>
                        </div>
                            
                    </>}


                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={isApplyingFinalVolume ? "Applying..." : "Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={isApplyingFinalVolume}/>
            </div>
        </div>
    )
    
}


export default ConfirmFinalReservoirVolumeWalkthroughStep