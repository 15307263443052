import './../FlowZonePortsManager.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { Blade_LightsManager_Standard24, RackPowerOnTutorial, ScanRack } from '../../../../assets/icons/RackIcons';
import DropDownButton from '../../../../components/DropDownButton';
import { CloseDropdown, GoBack, GoForward, OpenDropdown } from '../../../../assets/icons/Icons';
import { parseBool } from '../../../../helpers';
import { selectAllBladeGroups, selectAllBladeZones, selectAllBlades, setBladeRuntimeProperty, useLoadAllBladeGroupsForFacility, useLoadAllBladeZonesByUIDs, useLoadAllBladesByUIDs } from '../../../../redux/entities/service/Blade';
import { useDispatch, useSelector } from 'react-redux';
import { FlowConnectionPortSide, FlowPortsSelection } from '../../../../assets/icons/FlowIcons';
import DropDownInput from '../../../../components/input/DropDownInput';
import { manageFlowAssignedBladeZoneEntry } from '../../../../redux/entities/service/Flow';


  

const FlowZonePortsManager_Overview = ({facilityId, flow, transitionToNextStep, transitionToSubStep, completeCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const dispatch = useDispatch()
    
    const nextClicked = React.useCallback(() => {
        //powerOnConfirmedCallback()

        //transitionToNextStep(["get_blade_code", null])
    })

    const [selectedPortIndex, SetSelectedPortIndex] = React.useState(1)
    const [isModifyingPort, SetIsModifiyingPort] = React.useState(false)

    const portIndexSelected = (portIndex) => {
        if (isModifyingPort)    {
            return
        }
        
        SetSelectedPortIndex(portIndex)
    }
    const changeActivePortClicked = () => {
        SetIsModifiyingPort(true)
    }

    const [activeBladeZoneMap, SetActiveBladeZoneMap] = React.useState({})
    React.useEffect(() => {
        if (flow !== undefined && flow.maps.maps["assigned_blade_zone_map"] !== undefined && flow.maps.maps["assigned_blade_zone_map"].entries !== undefined)   {
            SetActiveBladeZoneMap(flow.maps.maps["assigned_blade_zone_map"].entries)
        }else {
            SetActiveBladeZoneMap({})
        }
    }, [flow])

    const [isSelectedPortActive, SetIsSelectedPortActive] = React.useState(false)
    const [selectedPortActiveInfo, SetSelectedPortActiveInfo] = React.useState(undefined)
    React.useLayoutEffect(() => {
        SetIsSelectedPortActive(activeBladeZoneMap[selectedPortIndex] !== undefined)
        SetSelectedPortActiveInfo(activeBladeZoneMap[selectedPortIndex])

    }, [selectedPortIndex, activeBladeZoneMap])



    const [selectedBladeGroupId, SetSelectedBladeGroupId] = React.useState(null)
    const [selectedBladeGroup, SetSelectedBladeGroup] = React.useState(undefined)
    const allBladeGroups = useSelector(state => selectAllBladeGroups(state))
    useLoadAllBladeGroupsForFacility({facilityId: facilityId})
    const [allBladeGroupsOptions, SetAllBladeGroupsOptions] = React.useState([])
    React.useEffect(() => {
        let currentBladeGroupsOptions = []
        for (let group of allBladeGroups)   {
            currentBladeGroupsOptions.push({label: group.display_name, value: group.id})
        }
        SetAllBladeGroupsOptions(currentBladeGroupsOptions)
    }, [allBladeGroups, facilityId])
    React.useEffect(() => {
        SetSelectedBladeGroup(allBladeGroups.find((bG) => bG.id === selectedBladeGroupId))
    }, [selectedBladeGroupId, allBladeGroups])


    const [selectedBladeUID, SetSelectedBladeUID] = React.useState(null)
    const [selectedBlade, SetSelectedBlade] = React.useState(undefined)
    const allBlades = useSelector(state => selectAllBlades(state))
    useLoadAllBladesByUIDs({bladeUIDs: selectedBladeGroup !== undefined ? selectedBladeGroup.blades : []})
    const [allBladeOptions, SetAllBladeOptions] = React.useState([])
    React.useEffect(() => {
        let currentBladeOptions = []
        if (selectedBladeGroup !== undefined)   {
            for (let bladeUID of selectedBladeGroup.blades)   {
                let foundBlade = allBlades.find((b) => b.uid === bladeUID)
                if (foundBlade !== undefined)   {
                    currentBladeOptions.push({label: foundBlade.display_name, value: foundBlade.uid})
                }
            }
        }
        SetAllBladeOptions(currentBladeOptions)
    }, [allBlades, selectedBladeGroup])
    React.useEffect(() => {
        SetSelectedBlade(allBlades.find((b) => b.uid === selectedBladeUID))
    }, [selectedBladeUID, allBlades])


    const [selectedBladeZoneUID, SetSelectedBladeZoneUID] = React.useState(null)
    const [selectedBladeZone, SetSelectedBladeZone] = React.useState(undefined)
    const allBladeZones = useSelector(state => selectAllBladeZones(state))
    useLoadAllBladeZonesByUIDs({bladeZoneUIDs: selectedBladeGroup !== undefined ? selectedBladeGroup.zones : []})
    const [allBladeZoneOptions, SetAllBladeZoneOptions] = React.useState([])
    React.useEffect(() => {
        let currentBladeZoneOptions = []
        if (selectedBladeGroup !== undefined && selectedBlade !== undefined)   {
            for (let zoneUID of selectedBladeGroup.zones)   {
                let foundBladeZone = allBladeZones.find((bZ) => bZ.uid === zoneUID)
                if (foundBladeZone !== undefined)   {
                    if (foundBladeZone.zone_type === "nursery") {
                        if (foundBladeZone.zone_props.rack_uid === selectedBlade.uid)   {
                            currentBladeZoneOptions.push({label: foundBladeZone.display_name + " - Top", value: foundBladeZone.uid + "-A"})
                            currentBladeZoneOptions.push({label: foundBladeZone.display_name + " - Bottom", value: foundBladeZone.uid + "-B"})
                        }
                    }else if (foundBladeZone.zone_type === "grow_boards" ||foundBladeZone.zone_type == "berry_troughs")   {
                        if (foundBladeZone.zone_props.grow_rack_uid === selectedBlade.uid)   {
                            currentBladeZoneOptions.push({label: foundBladeZone.display_name, value: foundBladeZone.uid})
                        }
                    }
                }
            }
        }
        SetAllBladeZoneOptions(currentBladeZoneOptions)
    }, [allBladeZones, selectedBlade, selectedBladeGroup])
    React.useEffect(() => {
        if (selectedBladeZoneUID)   {
            if (selectedBladeZoneUID.indexOf("-"))   {
                SetSelectedBladeZone(allBladeZones.find((bZ) => bZ.uid === selectedBladeZoneUID.split("-")[0]))
            }else {
                SetSelectedBladeZone(allBladeZones.find((bZ) => bZ.uid === selectedBladeZoneUID))
            }
        }else {
            SetSelectedBladeZone(undefined)
        }
    }, [selectedBladeZoneUID, allBladeZones])

    
    React.useEffect(() => {
        //Make sure our blades/zones/groups are loaded
        if (!isModifyingPort)   {
            if (selectedPortActiveInfo !== undefined)   {
                SetSelectedBladeUID(selectedPortActiveInfo.blade_uid)
                SetSelectedBladeZoneUID(selectedPortActiveInfo.zone_uid)
                let foundBlade = allBlades.find((b) => b.uid === selectedPortActiveInfo.blade_uid)
                if (foundBlade !== undefined)   {
                    SetSelectedBladeGroupId(foundBlade.group_id)
                }else {
                    SetSelectedBladeGroupId(null)
                }
            }else {
                SetSelectedBladeGroupId(null)
                SetSelectedBladeUID(null)
                SetSelectedBladeZoneUID(null)
            }
        }
    }, [allBlades, allBladeGroups, allBladeZones, selectedPortActiveInfo, isModifyingPort])


    const clearPortClicked = () => {
        SetSelectedBladeGroupId(null)
        SetSelectedBladeUID(null)
        SetSelectedBladeZoneUID(null)
    }

    const discardChangesClicked = () => {
        SetSelectedBladeGroupId(null)
        SetSelectedBladeUID(null)
        SetSelectedBladeZoneUID(null)                
        SetIsModifiyingPort(false)
    }

    const [isSavingEntryChange, SetIsSavingEntryChange] = React.useState(false)
    const saveChangedClicked = () => {
        if (selectedBladeUID === null || selectedBladeGroupId === null || selectedBladeZoneUID === null)    {
            //Check if we are removing an entry
            if (flow.maps.maps["assigned_blade_zone_map"].entries[selectedPortIndex] !== undefined)   {
                dispatch(manageFlowAssignedBladeZoneEntry({flowId: flow.id, portIndex: selectedPortIndex, changeType: "remove", entryInfo: {}, callback: (success) => {
                    SetIsSavingEntryChange(false)
                    SetIsModifiyingPort(false)
                }}))
                SetIsSavingEntryChange(true)
            }
        }else {
            let entryInfo = {}
            entryInfo["blade_uid"] = selectedBladeUID
            entryInfo["zone_uid"] = selectedBladeZoneUID
            dispatch(manageFlowAssignedBladeZoneEntry({flowId: flow.id, portIndex: selectedPortIndex, changeType: "apply", entryInfo: entryInfo, callback: (success) => {
                SetIsSavingEntryChange(false)
                SetIsModifiyingPort(false)
            }}))
            SetIsSavingEntryChange(true)
        }
        
    }

    const canSaveEntry = () => {
        if (isSavingEntryChange)   {
            return false
        }
        if (!isSelectedPortActive)  {
            if (selectedBladeUID === null || selectedBladeGroupId === null || selectedBladeZoneUID === null)    {
                return false
            }
        }else {

        }
        return true
    }


    const visualContent = <>
        <div className="FlexContent FlexContent-VCenter" style={{overflowX:"hidden"}}>
            <FlowConnectionPortSide maxWidth={"100%"}/>
        </div>
    </>
    const portsContent = <>
        <div className="FlexContent-H-10" style={{padding: "0px 10px"}}>
            <div className="FlexContent" style={{gap:0, alignItems:"flex-end"}}>
                {[1,2,3,4,5,6,7,8,9].map((portIndex) => {
                    const isPortConnected = activeBladeZoneMap[portIndex] !== undefined
                    return <>
                        <div className="FlexContent-H-5" style={{alignItems:"center", cursor:"pointer"}} onClick={() => {portIndexSelected(portIndex)}}>
                            <div>{portIndex}</div>
                            <div><FlowPortsSelection selected={selectedPortIndex === portIndex} active={isPortConnected}/></div>
                        </div>
                    </>
                })}
            </div>
            <div className="FlexContent" style={{gap:0, alignItems:"flex-end"}}>
                {[10,11,12,13,14,15,16,17,18].map((portIndex) => {
                    const isPortConnected = activeBladeZoneMap[portIndex] !== undefined
                    return <>
                        <div className="FlexContent-H-5" style={{alignItems:"center", cursor:"pointer"}} onClick={() => {portIndexSelected(portIndex)}}>
                            <div>{portIndex}</div>
                            <div><FlowPortsSelection selected={selectedPortIndex === portIndex} active={isPortConnected}/></div>
                        </div>
                    </>
                })}
            </div>
        </div>
    </>
    const inputsContent = <>
        {(isSelectedPortActive && !isModifyingPort) && <>
            
            <div className="FlexContent-20 FlexContent-HFlex">
                <div className={"Text-Medium-S18"}>Port connected to</div>
                <div className="FlexContent-15 FlexContent-HFlex">
                    <div className="FlexContent-5">
                        <div className="Text-S14">Group</div>
                        <div className="Text-S22">{selectedBladeGroup !== undefined ? selectedBladeGroup.display_name : "Unknown Group"}</div>
                    </div>
                    <div className="FlexContent-5">
                        <div className="Text-S14">Blade</div>
                        <div className="Text-S22">{selectedBlade !== undefined ? selectedBlade.display_name : "Unknown Blade"}</div>
                    </div>
                    <div className="FlexContent-5">
                        <div className="Text-S14">Zone</div>
                        <div className="Text-S22">{selectedBladeZone !== undefined ? <>
                            {selectedBladeZone.zone_type === "nursery" && <>
                                {selectedBladeZone.display_name} -&nbsp;
                                {selectedBladeZoneUID?.indexOf("-A") !== -1 && <>Top</>}
                                {selectedBladeZoneUID?.indexOf("-B") !== -1 && <>Bottom</>}
                            </>}
                            {(selectedBladeZone.zone_type === "grow_boards" || selectedBladeZone.zone_type == "berry_troughs") && <>
                                {selectedBladeZone.display_name}                            
                            </>}
                        </> : "Unknown Zone"}</div>
                    </div>
                    <Button content={"Change"} status={"Primary"} onClick={changeActivePortClicked}/>
                </div>
            </div>
        </>}
        {(!isSelectedPortActive || isModifyingPort) && <>
            <div className="FlexContent-20 FlexContent-HFlex">
                <div className={"Text-Medium-S18"}>Connect selected port to</div>
                <div className="FlexContent-15 FlexContent-HFlex">
                    <div className="FlexContent-5">
                        <div className="Text-S14">Blade Group</div>
                        <DropDownInput options={allBladeGroupsOptions} placeholder={"Select Group"} value={selectedBladeGroupId} onSelectionChange={(key) => {SetSelectedBladeGroupId(key)}}/>
                    </div>
                    <div className="FlexContent-5">
                        <div className="Text-S14">Blade</div>
                        <DropDownInput options={allBladeOptions} placeholder={"Select Blade"} value={selectedBladeUID} onSelectionChange={(key) => {SetSelectedBladeUID(key)}}/>                    
                    </div>
                    <div className="FlexContent-5">
                        <div className="Text-S14">Zone</div>
                        <DropDownInput options={allBladeZoneOptions} placeholder={"Select Zone"} value={selectedBladeZoneUID} onSelectionChange={(key) => {SetSelectedBladeZoneUID(key)}}/>                    
                    </div>
                    <Button content={<>Clear</>} status={"Hyperlink"} onClick={clearPortClicked}/>
                    <div className="FlexContent-H-5 FlexContent-HFlex">
                        <Button content={"Discard Changes"} status={"Critical"} onClick={discardChangesClicked} width={"Flex50"}/>
                        <Button content={"Save"} status={"Primary"} onClick={saveChangedClicked} width={"Flex50"} disabled={!canSaveEntry()}/>
                    </div>
                </div>
            </div>
        </>}

    </>

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Manage Ports
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper" + (isMobile ? "" : " Walkthrough-Card-Content-VCenter")}>
                <div className={"Walkthrough-Card-Content"}>
                    {isMobile && <>
                        <div className="FlexContent-20 FlexContent-HFlex">
                            <div className={"FlexContent-H-30"} style={{justifyContent:"space-between"}}>
                                {visualContent}
                                <div className="Linebreak-R-10"></div>
                                {portsContent}
                            </div>
                            <div className="Linebreak-10"></div>
                            {inputsContent}
                        </div>
                    </>}
                    {!isMobile && <>
                        <div className="FlexContent-H-30 FlexContent-HFlex" style={{padding: isDesktop ? "20px 100px" : "20px 50px"}}>
                            <div>
                                {visualContent}
                            </div>
                            <div className="Linebreak-R-10"></div>
                            <div>
                                {portsContent}
                            </div>
                            <div className="Linebreak-R-10"></div>
                            {inputsContent}
                        </div>
                    </>}
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content="Complete" onClick={completeCallback} status={"Neutral"} size={isMobile ? "Medium" : "Large"} width="Flex100"/>
            </div>
        </div>
    )
}

  


export default FlowZonePortsManager_Overview