import './FlowsPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

import { parseBool } from '../../../helpers';
import FlowInstance_StatusContent_ECSensorCell from './FlowInstance_StatucContent_Cells/FlowInstance_StatusContent_ECSensorCell';
import FlowInstance_StatusContent_PHSensorCell from './FlowInstance_StatucContent_Cells/FlowInstance_StatusContent_PHSensorCell';
import FlowInstance_StatusContent_ORPSensorCell from './FlowInstance_StatucContent_Cells/FlowInstance_StatusContent_ORPSensorCell';
import FlowInstance_StatusContent_DOSensorCell from './FlowInstance_StatucContent_Cells/FlowInstance_StatusContent_DOSensorCell';
import FlowInstance_StatusContent_EnergyCell from './FlowInstance_StatucContent_Cells/FlowInstance_StatusContent_EnergyCell';
import FlowInstance_StatusContent_ROPumpCell from './FlowInstance_StatucContent_Cells/FlowInstance_StatusContent_ROPumpCell';
import FlowInstance_StatusContent_AuxCell from './FlowInstance_StatucContent_Cells/FlowInstance_StatusContent_AuxCell';
import { InitialLoadAllBlades, MaintainBladeConfigurationMaps, MaintainBladeLiveData, MaintainBladeStatus, selectBladeIdsForBladeZoneUID, selectBladeZoneTypeByUID } from '../../../redux/entities/service/Blade';
import GrowZone_StatusContent_PrimaryWaterCell from '../../GrowZones/GrowZone_StatusContent_Cells/GrowZone_StatusContent_PrimaryWaterCell';
import GrowZone_StatusContent_PrimaryWaterCellForFlow from '../../GrowZones/GrowZone_StatusContent_Cells/GrowZone_StatusContent_PrimaryWaterCellForFlow';
import { selectFlowCurrentActionInfoByFlowId } from '../../../redux/entities/service/Flow';
import GrowZone_StatusContent_NurseryLowerWaterCellForFlow from '../../GrowZones/GrowZone_StatusContent_Cells/GrowZone_StatusContent_NurseryLowerWaterCellForFlow';
import GrowZone_StatusContent_NurseryUpperWaterCellForFlow from '../../GrowZones/GrowZone_StatusContent_Cells/GrowZone_StatusContent_NurseryUpperWaterCellForFlow';


 


const FlowInstance_StatusContent = ({ flowId, dosingZoneUID }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const zoneType = useSelector((state) => selectBladeZoneTypeByUID(state, dosingZoneUID), _.isEqual)
  const activeBladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, dosingZoneUID), _.isEqual)
  const flowCurrentActionInfo = useSelector((state) => selectFlowCurrentActionInfoByFlowId(state, flowId), _.isEqual)



  let tileColumns = []
  if (isWideDesktop) {
    tileColumns.push(["ec_sensor", "ro_pump", "dosing_zone"])
    tileColumns.push(["ph_sensor", "aux"])
    tileColumns.push(["orp_sensor"])
    tileColumns.push(["do_sensor"])
  } else if (isDesktop) {
    tileColumns.push(["ec_sensor", "do_sensor", "dosing_zone"])
    tileColumns.push(["ph_sensor", "ro_pump"])
    tileColumns.push(["orp_sensor", "aux"])
  } else if (isTablet) {
    tileColumns.push(["ec_sensor", "orp_sensor", "ro_pump", "dosing_zone"])
    tileColumns.push(["ph_sensor", "do_sensor", "aux"])
  } else {
    tileColumns.push(["ec_sensor", "ph_sensor", "orp_sensor", "do_sensor", "ro_pump", "aux", "dosing_zone"])
  }



  return (<>
    <div className="BladeInstance_StatusContent">
      {tileColumns.map((cellKeys) => {

        return (<>
          <div className="BladeInstance_StatusContent-Column">
            {cellKeys.map((cellKey) => {
              switch (cellKey) {
                case "ec_sensor":
                  return <FlowInstance_StatusContent_ECSensorCell flowId={flowId}/>
                case "ph_sensor":
                  return <FlowInstance_StatusContent_PHSensorCell flowId={flowId}/>
                case "orp_sensor":
                  return <FlowInstance_StatusContent_ORPSensorCell flowId={flowId}/>
                case "do_sensor":
                  return <FlowInstance_StatusContent_DOSensorCell flowId={flowId}/>
                case "energy":
                  return <FlowInstance_StatusContent_EnergyCell flowId={flowId}/>
                case "ro_pump":
                  return <FlowInstance_StatusContent_ROPumpCell flowId={flowId}/>
                case "aux":
                  return <FlowInstance_StatusContent_AuxCell flowId={flowId}/>
                case "dosing_zone":
                  return <>
                    {dosingZoneUID && <>
                      {(() => {
                        if (zoneType === "nursery" && flowCurrentActionInfo["zone_uid"] !== undefined) {
                          const subZoneKey = flowCurrentActionInfo["zone_uid"].split("-")[1]
                          if (subZoneKey === "A") {
                            return <>
                              <InitialLoadAllBlades/>
                              <MaintainBladeLiveData bladeIds={[activeBladeIds.nursery]}/>
                              <MaintainBladeStatus bladeIds={[activeBladeIds.nursery]}/>
                              <MaintainBladeConfigurationMaps bladeIds={[activeBladeIds.nursery]} />
                              <GrowZone_StatusContent_NurseryUpperWaterCellForFlow zoneUID={dosingZoneUID}/>
                            </>
                          }else if (subZoneKey === "B") {
                            return <>
                              <InitialLoadAllBlades/>
                              <MaintainBladeLiveData bladeIds={[activeBladeIds.nursery]}/>
                              <MaintainBladeStatus bladeIds={[activeBladeIds.nursery]}/>
                              <MaintainBladeConfigurationMaps bladeIds={[activeBladeIds.nursery]} />
                              <GrowZone_StatusContent_NurseryLowerWaterCellForFlow zoneUID={dosingZoneUID}/>
                            </>
                          }
                        }else if (zoneType === "grow_boards" || zoneType === "berry_troughs") {
                          return <>
                            <InitialLoadAllBlades/>
                            <MaintainBladeLiveData bladeIds={[activeBladeIds.grow]}/>
                            <MaintainBladeStatus bladeIds={[activeBladeIds.grow]}/>
                            <MaintainBladeConfigurationMaps bladeIds={[activeBladeIds.grow]} />
                            <GrowZone_StatusContent_PrimaryWaterCellForFlow zoneUID={dosingZoneUID}/>
                          </>
                        }
                      })()}
                    </>}
                  </>
                default:
                  break
              }
            })}
          </div>
        </>)
      })}
      

    </div>
  </>)
}

export default FlowInstance_StatusContent