import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';

import { FullContainer, LowContainer, MixContainer, UnmixedContainer } from '../../../../assets/icons/Icons';
import { RoundToNearest } from '../../../../helpers';

  

const ConfirmInternalReservoirMixWalkthroughStep = ({NDS, reservoirInfo, reservoirItemName, waterBeingMixed, existingWaterVolume, transitionToPreviousStep, transitionToNextStep}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop
 


    let mixType = reservoirInfo["mix_type"]
    let mixRatio = reservoirInfo["mix_ratio"]

    let mixAmount = RoundToNearest(waterBeingMixed * mixRatio, 0.1)

    
    const backClicked = () => {
        transitionToPreviousStep(["top_up_internal_reservoir", null])
    }

    const nextClicked = () =>   {
        transitionToNextStep(["confirm_final_volume", null])
    }

    let mixDisplayContent = <>{mixAmount}{mixType === "solids" && "g"}{mixType === "liquid" && "L"}</>
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Mix Reservoir</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    {isMobile && <>
                        <div className="FlexContent-40">
                            <div className="FlexContent-10">
                                <div className="FlexContent-10 FlexContent-Center">
                                    <div className="Text-Alt14">Step 1/2</div>
                                </div>                                
                                <div className="FlexContent-H-5 FlexContent-Center">Add <div style={{color:"#2E72D2"}}>{mixDisplayContent}</div> of {reservoirItemName}</div>
                            </div>
                            <div className="FlexContent-10">
                                <div className="FlexContent-10 FlexContent-Center">
                                    <div className="Text-Alt14">Step 2/2</div>
                                </div>                                
                                <div className="FlexContent-H-5 FlexContent-Center">Stir mixture for at least <div style={{color:"#2E72D2"}}>60 seconds</div></div>
                            </div> 
                        </div>
                    </>}
                    {!isMobile && <>
                        <div className="FlexContent-30" style={{margin:"40px 30px"}}>
                            <div className="FlexContent-H-30" style={{alignItems:"center"}}>
                                <div><UnmixedContainer width={47}/></div>
                                <div className="FlexContent-10">
                                    <div className="Text-Alt14">Step 1/2</div>
                                    <div className="Text-S24">Add <span style={{color:"#2E72D2"}}>{mixDisplayContent}</span> of {reservoirItemName}</div>
                                </div>                                
                            </div>
                            <div className="FlexContent-H-30" style={{alignItems:"center"}}>
                                <div><MixContainer width={47}/></div>
                                <div className="FlexContent-10">
                                    <div className="Text-Alt14">Step 2/2</div>
                                    <div className="Text-S24">Stir mixture for at least <span style={{color:"#2E72D2"}}>60 seconds</span></div>
                                </div>                                
                            </div> 
                        </div>
                    </>}
                </div>        
            </div>        
            <div className="Walkthrough-Card-FooterButtons">
                <Button status={"Neutral"} content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default ConfirmInternalReservoirMixWalkthroughStep