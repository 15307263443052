import '../FlowsPage.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllFlowConfigurationMaps, selectFlowComponentInfo, selectFlowLiveDataByComponentInfo } from '../../../../redux/entities/service/Flow';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';



const FlowInstance_StatusContent_EnergyCell = ({ flowId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

 
  const [flowCurrentComponentInfo] 
  = useSelector((state) => selectFlowComponentInfo(state, flowId, "CurrentTransducer", ["input"]), _.isEqual)

  let flowCurrent = useSelector((state) => selectFlowLiveDataByComponentInfo(state, flowId, flowCurrentComponentInfo), _.isEqual)
  let flowVoltage = 110 //hacked
  let flowWattage = flowCurrent !== null ? flowCurrent.value * flowVoltage : 0

  console.log(flowCurrent)
  
  let dataListItems = [
    {
      label: "Current", value: flowCurrent.value, suffix: "A", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 20, status: "normal" }
      }
    },
    {
      label: "Wattage", value: flowWattage, suffix: "W", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 5000, status: "normal" }
      }
    },
    {
      label: "Voltage", value: flowVoltage, suffix: "V"
    },
  ]


  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Energy</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default FlowInstance_StatusContent_EnergyCell